import React from 'react';
import { Text, View } from 'react-native';
import ValidatedTextInput from '/components/ValidatedForm/ValidatedTextInput';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';

type Props = {
  onChangeContribution: (text: string) => void;
  ourContribution: string;
};

const OurContributionForm = ({ onChangeContribution, ...props }: Props) => {
  return (
    <View style={styles.itemContainers}>
      <View style={styles.itemContentRows}>
        <Text style={styles.itemContentTitle}>Our Contribution</Text>
      </View>
      <View style={styles.itemContentRows}>
        <Text style={styles.itemBodyText}>
          This is a general outline of what your organization already has
          prepared and can share with the selected supporter.
        </Text>
      </View>
      <View style={styles.itemContentRows}>
        <ValidatedTextInput
          name="our_contribution"
          containerStyle={{
            flex: 1,
          }}
          placeholderTextColor="gray"
          maxLength={400}
          multiline={true}
          numberOfLines={4}
          style={styles.goalDescriptionBox}
          placeholder="Description of your contribution"
          onChangeText={onChangeContribution}
          value={props.ourContribution}
        />
      </View>
    </View>
  );
};

export default OurContributionForm;
