import { StackNavigationProp } from '@react-navigation/stack';
import { updatePassword } from 'aws-amplify/auth';
import React, { useState } from 'react';
import { Keyboard, Text, TextInput, View } from 'react-native';
import styles from './ChangePassword.style';
import Alert from '/Alert';
import PasswordTooltip from '/components/Auth/PasswordTooltip/PasswordTooltip';
import Button from '/components/Button';
import {
  useFormValidationContext,
  ValidatedTextInput,
  withFormValidation,
} from '/components/ValidatedForm';
import {
  DESKTOP_MAX_WIDTH,
  KEY_GREEN,
  SECTION_CONTAINER,
  TEXT_INPUT,
} from '/constants';
import { isStrongPassword, shortenEmail, userHasPassword } from '/util';
import { useAuthContext } from '/context';
import withAuthRequired from '/components/withAuthRequired';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';

interface Props {
  navigation: StackNavigationProp<any>;
}

function ChangePassword(props: Props) {
  const {
    user,
    userAttributes,
    resetPassword,
    confirmResetPassword,
    setUserAttribute,
  } = useAuthContext();
  const { validateForm } = useFormValidationContext();

  const [sendingResetCode, setSendingResetCode] = useState(false);
  const [resetCodeSent, _setResetCodeSent] = useState(false);
  function setResetCodeSent(value: boolean) {
    _setResetCodeSent(value);
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
    setConfirmationCode('');
  }
  const [confirmationCode, setConfirmationCode] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [loading, setLoading] = useState(false);

  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  async function changePassword() {
    if (!validateForm()) return;

    try {
      setLoading(true);

      await updatePassword({
        oldPassword: currentPassword,
        newPassword,
      });

      props.navigation.goBack();

      Alert.notify({
        message: 'Password changed successfully!',
        color: KEY_GREEN,
      });
    } catch (err) {
      console.log((err as any)?.message);
      if ((err as any)?.message === 'Incorrect username or password.') {
        Alert.alert(
          'Incorrect password',
          'Please re-renter your current password.',
        );
      } else if (
        (err as any)?.message?.includes?.(
          'Password did not conform with policy',
        )
      ) {
        Alert.alert(
          'Password Too Weak',
          'Password must contain between 8 and 24 characters, no spaces and at least 3 of the following: 1 uppercase letter, 1 lower case letter, 1 number and 1 special character',
        );
      } else if ((err as any)?.message?.includes?.('Attempt limit exceeded')) {
        Alert.alert(
          'Too many attempts',
          'You have attempted to change your password too many times. Please try again later.',
        );
      } else {
        Alert.alert(
          'Oh no',
          'We were unable to change your password. Please try again.',
        );
      }
    } finally {
      setLoading(false);
    }
  }

  function onResetPassword() {
    if (!userAttributes?.email) {
      console.error('No email found for user');
      return;
    }
    if (newPassword !== newPasswordConfirm) {
      Alert.alert(
        'Passwords do not match',
        'Please make sure you have entered your password correctly both times',
      );
      return;
    }
    setSendingResetCode(true);
    resetPassword(userAttributes?.email)
      .then(() => {
        Alert.alert(
          'Code Sent',
          `We sent an email with a verification code you can use to reset your password to ${shortenEmail(
            userAttributes?.email,
            40,
          )}.`,
        );
        setResetCodeSent(true);
      })
      .catch((err) => {
        Alert.alert('Error', 'Something went wrong. Please try again later.');
        console.error(err);
      })
      .finally(() => {
        setSendingResetCode(false);
      });
  }

  function onConfirmResetPassword() {
    if (!validateForm()) return;

    setLoading(true);
    confirmResetPassword(userAttributes?.email, confirmationCode, newPassword)
      .then(() => {
        props.navigation.goBack();

        Alert.notify({
          message: 'Password set successfully!',
          color: KEY_GREEN,
        });

        setResetCodeSent(false);
        setNewPassword('');
        setNewPasswordConfirm('');
        setConfirmationCode('');

        return setUserAttribute('custom:has_auto_password', 'false');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const hasPassword = userHasPassword(user, userAttributes);

  return (
    <KeyboardAwareScrollView style={DESKTOP_MAX_WIDTH}>
      <View
        style={SECTION_CONTAINER}
        onTouchStart={() => {
          Keyboard.dismiss();
        }}
      >
        {hasPassword ? (
          <>
            <Text style={styles.sectionTitle}>CHANGE PASSWORD</Text>
            <Text style={styles.sectionText}>
              To change your password, enter your current password and then
              enter your new password twice.
            </Text>

            <View style={styles.fieldContainer}>
              <Text style={styles.fieldLabel}>CURRENT PASSWORD</Text>
              <ValidatedTextInput
                pointerEvents={loading ? 'none' : 'auto'}
                validate={(value) => !!value && /^[\S]+.*[\S]+$/.test(value)}
                name="password-current"
                style={TEXT_INPUT}
                placeholder="Current Password"
                value={currentPassword}
                textContentType="password"
                secureTextEntry
                onChangeText={setCurrentPassword}
              />
            </View>

            <View style={styles.fieldContainer}>
              <Text style={styles.fieldLabel}>NEW PASSWORD</Text>
              <ValidatedTextInput
                validate={(value) => !!value && isStrongPassword(value)}
                pointerEvents={loading ? 'none' : 'auto'}
                name="password-new"
                placeholder="New Password"
                onFocus={() => {
                  setShowPasswordTooltip(true);
                }}
                onBlur={() => {
                  setShowPasswordTooltip(false);
                }}
                textContentType="newPassword"
                style={TEXT_INPUT}
                value={newPassword}
                secureTextEntry
                onChangeText={setNewPassword}
              />
            </View>
            <View style={styles.fieldContainer}>
              <Text style={styles.fieldLabel}>CONFIRM NEW PASSWORD</Text>
              <ValidatedTextInput
                validate={(value) => value === newPassword}
                pointerEvents={loading ? 'none' : 'auto'}
                name="password-new-confirn"
                textContentType="newPassword"
                placeholder="Confirm New Password"
                secureTextEntry
                style={TEXT_INPUT}
                value={newPasswordConfirm}
                onChangeText={setNewPasswordConfirm}
              />
            </View>
            <Button
              label="Change Password"
              loading={loading}
              onPress={changePassword}
              containerStyle={{
                alignSelf: 'flex-end',
                marginTop: 8,
              }}
            />
          </>
        ) : (
          <>
            <Text style={styles.sectionTitle}>SET PASSWORD</Text>

            {resetCodeSent ? (
              <>
                <Text style={styles.confirmationCodeText}>
                  We've sent a confirmation code to your email address. Please
                  enter it here, enter your new password, and click "Set
                  Password". Be sure to check your spam/junk folders if you
                  cannot find it.
                </Text>
                <TextInput
                  placeholder="Confirmation Code"
                  textContentType="oneTimeCode"
                  keyboardType="number-pad"
                  style={TEXT_INPUT}
                  value={confirmationCode}
                  onChangeText={setConfirmationCode}
                />
                <ValidatedTextInput
                  validate={(value) => !!value && isStrongPassword(value)}
                  name="password-new"
                  placeholder="New Password"
                  textContentType="newPassword"
                  secureTextEntry
                  onFocus={() => {
                    setShowPasswordTooltip(true);
                  }}
                  onBlur={() => {
                    setShowPasswordTooltip(false);
                  }}
                  style={TEXT_INPUT}
                  value={newPassword}
                  onChangeText={setNewPassword}
                />
                <ValidatedTextInput
                  validate={(value) => value === newPassword}
                  name="password-new-confirm"
                  secureTextEntry
                  placeholder="Confirm New Password"
                  textContentType="newPassword"
                  style={TEXT_INPUT}
                  value={newPasswordConfirm}
                  onChangeText={setNewPasswordConfirm}
                />
                <Button
                  label="Set Password"
                  containerStyle={{
                    alignSelf: 'flex-end',
                    marginTop: 8,
                  }}
                  loading={loading}
                  onPress={onConfirmResetPassword}
                />
              </>
            ) : (
              <>
                <Text style={styles.sectionText}>
                  Your account is not set up with a password. To set a password
                  for your account, click "Reset Password" below.
                </Text>

                <Button
                  label="Reset Password"
                  containerStyle={{
                    alignSelf: 'flex-end',
                    marginTop: 8,
                  }}
                  loading={sendingResetCode}
                  onPress={onResetPassword}
                />
              </>
            )}
          </>
        )}

        <View>
          <PasswordTooltip
            position="bottom"
            onTapToHide={() => {
              setShowPasswordTooltip(false);
            }}
            password={newPassword}
            show={showPasswordTooltip}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
}

export default withAuthRequired(withFormValidation(ChangePassword));
