import React, { useRef } from 'react';
import { Platform } from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import CampaignBuilderFooter from '../components/CampaignBuilderFooter';
import SkillDescriptionForm from './SkillDescriptionForm';
import { useFormValidationContext } from '/components/ValidatedForm';
import withFormValidation from '/components/ValidatedForm/withFormValidationHOC';
import styles from '/constants/CampaignBuilder';
import { SkillRequest } from '/generated/graphql';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import { ScrollView } from 'react-native';

const DescribeSkills = (props: CampaignBuilderComponentProps) => {
  const skillRequests = props.data?.skilled_impact_requests ?? [];

  const scrollViewRef = useRef<ScrollView | null>(null);

  const { fields, validateForm } = useFormValidationContext(scrollViewRef);

  return (
    <>
      <KeyboardAwareScrollView
        ref={(r) => (scrollViewRef.current = r)}
        style={{
          paddingTop: 120,
        }}
        extraKeyboardSpace={64}
        contentContainerStyle={[
          styles.scrollView,
          { paddingBottom: Platform.OS === 'web' ? 0 : 120, minHeight: '100%' },
        ]}
      >
        {skillRequests.map((request, index) => {
          return (
            <SkillDescriptionForm
              fields={fields}
              key={index}
              skill={request}
              onChange={(data: Partial<SkillRequest>) => {
                const newRequests = Array.from(skillRequests) ?? [];

                newRequests[index] = {
                  ...newRequests[index],
                  ...data,
                };

                props.setData({
                  skilled_impact_requests: newRequests,
                });
              }}
            />
          );
        })}
      </KeyboardAwareScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() => props.next()}
        validateForm={validateForm}
      />
    </>
  );
};

export default withFormValidation(DescribeSkills);
