import {
  View,
  Text,
  TextInput,
  Pressable,
  Platform,
  StyleSheet,
  ScrollView,
} from 'react-native';
import React, { useState } from 'react';
import { User, UserRole } from '/generated/graphql';
import { ISpeciesPickerSpecies } from '../SpeciesPicker/SpeciesPickerModal';
import useDebouncedState from '/hooks/useDebouncedState';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import { EvilIcons, FontAwesome5 } from '@expo/vector-icons';
import { TEXT_INPUT } from '/constants';

import UserRoleFilterButton from '../FilterButtons/UserRoleFilterButton/UserRoleFilterButton';
import SpeciesFilterButton from '../FilterButtons/SpeciesFilterButton/SpeciesFilterButton';
import ResearchTopicFilterButton from '../FilterButtons/ResearchTopicFilterButton/ResearchTopicFilterButton';
import UserSearch from '../Search/UserSearch';

export default function UserPickerModal({
  onClose,
  ...props
}: {
  onClose: (data: Pick<User, 'id' | 'name' | 'profile_image'>) => void;
  role?: UserRole;
  excludeUserIds: string[];
  showFilters?: boolean;
  includeSelf?: boolean;
}) {
  const [role, setRole] = useState<UserRole | undefined>(props.role);
  const [species, setSpecies] = useState<ISpeciesPickerSpecies[]>([]);
  const [topics, setTopics] = useState<string[]>([]);

  const [query, queryDebounced, setQuery] = useDebouncedState('');

  const userTypeText =
    role === UserRole.Supporter
      ? 'supporters'
      : role === UserRole.Conservationist
      ? 'organizations'
      : 'users';

  return (
    <>
      {props.showFilters ? (
        <HorizontalContainer
          style={{
            marginBottom: 2,
          }}
        >
          <FontAwesome5
            name="filter"
            size={16}
            color="gray"
            style={{
              marginRight: 2,
            }}
          />
          <Text
            style={{
              fontFamily: 'LeagueSpartan-Bold',
              fontSize: 16,
              color: 'gray',
            }}
          >
            FILTER
          </Text>
        </HorizontalContainer>
      ) : null}
      <HorizontalContainer>
        <TextInput
          autoCorrect={false}
          autoCapitalize="none"
          style={[TEXT_INPUT, { flex: 1 }]}
          placeholderTextColor={'gray'}
          placeholder={`Search all ${userTypeText}...`}
          onChangeText={setQuery}
          enterKeyHint="search"
        />
        <Pressable
          style={{
            width: 42,
            height: 32,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EvilIcons
            style={{
              marginHorizontal: 8,
            }}
            name="search"
            size={28}
            color="black"
          />
        </Pressable>
      </HorizontalContainer>
      {props.showFilters ? (
        <ScrollView
          horizontal
          contentContainerStyle={{
            paddingBottom: 4,
          }}
          showsHorizontalScrollIndicator={Platform.OS === 'web'}
          style={{
            overflow: 'visible',
            flexGrow: 0,
            flexShrink: 0,
            backgroundColor: 'white',
          }}
        >
          {props.role ? null : (
            <View style={styles.fieldContainer}>
              <Text style={styles.fieldLabel}>USER TYPE</Text>
              <UserRoleFilterButton value={role} onChange={setRole} />
            </View>
          )}
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>SPECIES</Text>
            <SpeciesFilterButton species={species} onChange={setSpecies} />
          </View>
          <View style={styles.fieldContainer}>
            <Text style={styles.fieldLabel}>TOPICS</Text>
            <ResearchTopicFilterButton topics={topics} onChange={setTopics} />
          </View>
        </ScrollView>
      ) : null}
      <UserSearch
        query={queryDebounced}
        key={JSON.stringify({
          query,
          role,
          topics,
          species: species.map((s) => s.acceptedNameUsageID),
        })}
        style={
          Platform.OS === 'web' ? undefined : { flex: undefined, flexGrow: 0 }
        }
        filter={{
          role,
          topics,
          speciesTaxonIds: species.map((s) => s.acceptedNameUsageID),
        }}
        includeSelf={props.includeSelf}
        excludeUsers={props.excludeUserIds}
        emptyPlaceholderText={`Begin typing to search for ${userTypeText}`}
        onSelectUser={(data) => {
          onClose(data);
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  fieldLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    marginTop: 2,
    marginBottom: 4,
  },
  fieldContainer: {
    marginRight: 6,
  },
});
