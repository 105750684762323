import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CombinedError, OperationResult } from 'urql';
import { useDiscussionBoardSendingOnBehalfOfUser } from './DiscussionBoard/useDiscussionBoardSendingAsUser';
import Alert from '/Alert';
import { IUserMention } from '/components/UserMentionTextInput/UserMentionTextInput';
import { useAuthContext } from '/context';
import {
  CreateMessageMutation,
  GetDiscussionBoardQuery,
  Message,
  NewMessageSubscription,
  useCreateMessageMutation,
  useGetDiscussionBoardQuery,
  useNewMessageSubscription,
  User,
  useReadMessageMutation,
} from '/generated/graphql';
import { DeepPartial } from '/types';

interface IDiscussionBoardMessageUser
  extends Pick<User, 'id' | 'name' | 'profile_image'> {}

export interface IDiscussionBoardMessage
  extends Pick<Message, 'id' | 'created_at' | 'body' | 'read' | 'media'> {
  on_behalf_of?: IDiscussionBoardMessageUser | null;
  user: IDiscussionBoardMessageUser;
}

type UseDiscussionBoardHookOptions<IncludeParent extends boolean> = {
  discussionBoardId: string | undefined;
  messageOnBehalfOfUserId?: string;
  disableSubscription?: boolean;
  messagesPerPage?: number;
  includeParent?: IncludeParent;
  /** If set, hook will send requests to mark own messages as read. */
  markReadOwnMessages?: boolean;
  /** If set, messages won't be marked as read automatically */
  disableAutoReadMarking?: boolean;
};

type UseDiscussionBoardHookReturns<IncludeParent extends boolean> = {
  messages: IDiscussionBoardMessage[];
  totalMessages: number;
  loading: boolean;
  error: CombinedError | undefined;
  hasNextPage: boolean;
  isSendingMessage: boolean;
  /** Will temporarily contain data while we are sending a message to render as in-progress */
  sendingMessage: DeepPartial<Message> | undefined;
  messageSendError: CombinedError | undefined;
  /** Can be used to render a divider between old messages and messages received via subscription */
  firstUnreadMessage: NewMessageSubscription['newMessage'] | undefined;
  isAuthorizedToManage: boolean;
  hideMessage: (messageId: string) => void;
  sendMessage: (
    message: string,
    mentions: IUserMention[],
    media: string[],
  ) => Promise<OperationResult<CreateMessageMutation> | undefined>;
  markLatestMessageRead: () => void;
  refetch: () => void;
  /** Fetches the next page of messages, if any */
  fetchNextPage: () => void;
} & (IncludeParent extends true
  ? {
      parent: GetDiscussionBoardQuery['getDiscussionBoard']['parent'];
    }
  : {});

export default function useDiscussionBoard<
  IncludeParent extends boolean = false,
>(
  options: UseDiscussionBoardHookOptions<IncludeParent>,
): UseDiscussionBoardHookReturns<IncludeParent> {
  const { userData } = useAuthContext();
  const messageOnBehalfOfUser = useDiscussionBoardSendingOnBehalfOfUser(
    options.messageOnBehalfOfUserId,
  );

  const [nextToken, setNextToken] = useState('');

  /** Used to temporary show message in loading state while sending it */
  const [sendingMessage, setSendingMessage] = useState<DeepPartial<Message>>();

  /** Used to determine where to render a divider between all messages and new
   * messages received via subscription */
  const [firstUnreadMessage, setFirstUnreadMessage] =
    useState<NewMessageSubscription['newMessage']>();

  const [hiddenMessages, setHiddenMessages] = useState<Record<string, boolean>>(
    {},
  );

  const [{ data, fetching: loading, error, stale }, getDiscussionBoard] =
    useGetDiscussionBoardQuery({
      variables: {
        id: options.discussionBoardId!,
        nextToken,
        limit: options.messagesPerPage,
        includeParent: options.includeParent,
      },
      pause: !options.discussionBoardId,
      requestPolicy: 'cache-and-network',
    });

  const [, markReadMessage] = useReadMessageMutation();

  const [
    { fetching: isSendingMessage, error: sendMessageError },
    createMessage,
  ] = useCreateMessageMutation();

  const [{ data: subscriptionData }] = useNewMessageSubscription<
    NewMessageSubscription['newMessage'][]
  >(
    {
      variables: { discussionBoardId: options.discussionBoardId },
      pause: !options.discussionBoardId || options.disableSubscription,
    },
    (accum, current) => {
      if (!current) return [...(accum ?? [])];

      // If we got a new message from another participant, let them know
      // new messages have popped in
      if (!firstUnreadMessage && current.newMessage.user.id !== userData?.id) {
        setFirstUnreadMessage(current.newMessage);
      }

      return [...(accum ?? []), current.newMessage];
    },
  );

  const isMarkingMessageRead = useRef(false);
  const markLatestMessageRead = useCallback(
    async function () {
      if (isMarkingMessageRead.current || data?.getDiscussionBoard.public)
        return;

      try {
        isMarkingMessageRead.current = true;

        /** Marks latest message as read */
        const _allMessages = [
          subscriptionData,
          data?.getDiscussionBoard.messages?.items,
        ]
          .flat()
          .filter((m) => !!m) as Message[];

        if (!_allMessages.length) return;

        let latestMessage = _allMessages.reduce((prev, current) => {
          if (
            !prev?.created_at ||
            new Date(Number(prev.created_at)).getTime() <
              new Date(Number(current!.created_at)).getTime()
          ) {
            return current;
          } else return prev;
        });

        if (!latestMessage || latestMessage.read) return;

        await markReadMessage({
          messageId: latestMessage.id as string,
        });
      } catch (err) {
        console.log(err);
      } finally {
        isMarkingMessageRead.current = false;
      }
    },
    [
      data?.getDiscussionBoard.messages?.items,
      data?.getDiscussionBoard.public,
      markReadMessage,
      subscriptionData,
    ],
  );

  useEffect(() => {
    if (
      data?.getDiscussionBoard.messages?.items?.[0]?.body ===
      sendingMessage?.body
    ) {
      setSendingMessage(undefined);
    }
  }, [data?.getDiscussionBoard.messages, sendingMessage?.body]);

  useEffect(
    function autoMarkRead() {
      if (options.disableAutoReadMarking) return;

      const newestMessage = subscriptionData?.[subscriptionData.length - 1];
      if (
        newestMessage?.user.id !== userData?.id ||
        options.markReadOwnMessages
      ) {
        // Immediately mark the new messages as read if they're not from the current user
        markLatestMessageRead();
      }
    },
    [
      markLatestMessageRead,
      subscriptionData,
      userData?.id,
      options.disableAutoReadMarking,
      options.markReadOwnMessages,
    ],
  );

  function fetchNextPage() {
    const _nextToken = data?.getDiscussionBoard.messages?.nextToken;

    if (_nextToken && _nextToken !== nextToken) {
      setNextToken(_nextToken);
    }
  }

  async function sendMessage(
    text: string,
    mentions: IUserMention[],
    media: string[],
  ): Promise<OperationResult<CreateMessageMutation> | undefined> {
    if (!options.discussionBoardId) return;

    if (!text.trim().length && !media.length) return;

    let result;

    try {
      setFirstUnreadMessage(undefined);

      setSendingMessage({
        body: text,
        mentions,
        on_behalf_of:
          messageOnBehalfOfUser && messageOnBehalfOfUser.id !== userData?.id
            ? {
                id: messageOnBehalfOfUser.id,
                name: messageOnBehalfOfUser.name,
                profile_image: messageOnBehalfOfUser.profile_image,
              }
            : undefined,
        user: {
          id: userData?.id,
          name: userData?.name,
          profile_image: userData?.profile_image,
        },
        media,
      });

      result = await createMessage({
        input: {
          body: text,
          mentions: mentions.map((m) => ({
            userId: m.user.id,
            start: m.start,
            end: m.end,
          })),
          discussionBoardId: options.discussionBoardId,
          onBehalfOfUserId:
            messageOnBehalfOfUser && messageOnBehalfOfUser.id !== userData?.id
              ? messageOnBehalfOfUser.id
              : undefined,
          media,
        },
      });

      if (result.error) throw result.error;

      return result;
    } catch (err) {
      console.log(err);
      Alert.alert(
        'Error',
        'There was a problem while sending your message. Please try again or report this issue if it persists.',
      );
      return result;
    } finally {
      setSendingMessage(undefined);
    }
  }

  const allMessages = useMemo(() => {
    // Combine all sources of data and remove `undefined`
    let _allMessages = [
      // sendingMessage,
      subscriptionData,
      data?.getDiscussionBoard.messages?.items,
    ]
      .flat()
      .filter((i): i is IDiscussionBoardMessage => {
        return !!i && !hiddenMessages[i.id];
      });

    const alreadyAdded = new Set();

    // Remove duplicates if any
    _allMessages = _allMessages.reduce((accum, current) => {
      if (!alreadyAdded.has(current.id)) {
        accum.push(current);
        alreadyAdded.add(current.id);
      }

      return accum;
    }, [] as IDiscussionBoardMessage[]);

    // Sort in descending order
    _allMessages = _allMessages.sort(
      (a, b) =>
        new Date(Number(b.created_at)).getTime() -
        new Date(Number(a.created_at)).getTime(),
    );

    return _allMessages;
  }, [subscriptionData, data, hiddenMessages]);

  function hideMessage(messageId: string) {
    setHiddenMessages((prev) => ({ ...prev, [messageId]: true }));
  }

  return {
    isAuthorizedToManage: data?.getDiscussionBoard.manageable ?? false,
    messages: allMessages,
    totalMessages: data?.getDiscussionBoard.messages?.total ?? 0,
    loading: loading || stale,
    error,
    sendingMessage,
    isSendingMessage,
    firstUnreadMessage,
    messageSendError: sendMessageError,
    markLatestMessageRead,
    sendMessage,
    fetchNextPage,
    refetch: getDiscussionBoard,
    hasNextPage:
      !!data?.getDiscussionBoard.messages?.nextToken &&
      nextToken !== data.getDiscussionBoard.messages.nextToken,
    hideMessage,
    parent: data?.getDiscussionBoard.parent,
  };
}
