import { Slider } from '@rneui/themed';
import React, { useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Button from '/components/Button';
import ErrorText from '/components/common/Generic/ErrorText';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import LocationInput from '/components/LocationInput/LocationInput';
import { KEY_GREEN, REQUIRED_INPUT_INVALID, TEXT_INPUT } from '/constants';
import { EventFilterInput } from '/generated/graphql';
import { useLocales } from 'expo-localization';
import { ScrollView } from 'react-native';

type Props = {
  initialLocation?:
    | (EventFilterInput['location'] & {
        name: string;
      })
    | null;
  onRequestClose: (
    filter: (EventFilterInput['location'] & { name: string }) | null,
  ) => void;
};

const regionsUsingMiles = ['US', 'GB', 'LR', 'MM'];

export default function EventLocationFilterModal({
  initialLocation = null,
  onRequestClose,
}: Props) {
  const locales = useLocales();

  const shouldUseMiles = useMemo(() => {
    const locale = locales[0];
    return regionsUsingMiles.includes(locale.regionCode ?? '');
  }, [locales]);

  const [input, setInput] = useState<string>(initialLocation?.name ?? '');
  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(initialLocation);
  const [radius, setRadius] = useState<number>(
    initialLocation?.radius ?? (shouldUseMiles ? 80 / 1.60934 : 50),
  );
  const [invalidInput, setInvalidInput] = useState<boolean>(false);

  const displayRadius = useMemo(() => {
    const result = shouldUseMiles ? radius * 1.60934 : radius;
    return Math.round(result);
  }, [radius, shouldUseMiles]);

  return (
    <View>
      <ScrollView>
        <View style={{ zIndex: 1 }}>
          <LocationInput
            value={input}
            onBlur={() => {
              if (input.trim().length && !location) {
                setInvalidInput(true);
              }
            }}
            onChangeText={(text, valid, selection) => {
              setInput(text);

              if (valid || !text.trim()) setInvalidInput(false);

              if (
                valid &&
                typeof selection?.latitude === 'number' &&
                typeof selection?.longitude === 'number'
              ) {
                setLocation({
                  latitude: selection.latitude,
                  longitude: selection.longitude,
                });
              } else {
                setLocation(null);
              }
            }}
            placeholder="Search for a location"
            style={[
              styles.locationInput,
              invalidInput && REQUIRED_INPUT_INVALID,
            ]}
          />
        </View>

        {invalidInput ? (
          <ErrorText>
            Please select a location from the suggestion list
          </ErrorText>
        ) : null}

        <View
          style={{
            opacity: location ? 1 : 0.5,
            pointerEvents: location ? 'auto' : 'none',
          }}
        >
          <Text style={styles.radiusLabel}>
            Radius: {displayRadius} {shouldUseMiles ? 'mi' : 'km'}
          </Text>
          <Slider
            value={radius}
            onValueChange={setRadius}
            minimumValue={1}
            maximumValue={500}
            step={1}
            style={styles.slider}
            thumbStyle={{
              backgroundColor: KEY_GREEN,
              width: 24,
              height: 24,
            }}
          />
        </View>
      </ScrollView>

      <HorizontalContainer
        style={{
          justifyContent: 'flex-end',
        }}
      >
        {location ? (
          <Button
            label="Reset"
            onPress={() => {
              setInput('');
              setLocation(null);
              setRadius(50);
            }}
            containerStyle={{
              marginRight: 8,
            }}
          />
        ) : null}

        <Button
          label="Apply"
          onPress={() =>
            onRequestClose(
              location
                ? {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    radius,
                    name: input.trim(),
                  }
                : null,
            )
          }
          style={styles.applyButton}
        />
      </HorizontalContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  locationInput: {
    ...TEXT_INPUT,
    marginBottom: 8,
    zIndex: 1,
  },
  radiusLabel: {
    fontSize: 16,
    marginBottom: 8,
    marginTop: 8,
  },
  slider: {
    marginBottom: 16,
    zIndex: -1,
  },
  applyButton: {
    backgroundColor: KEY_GREEN,
  },
});
