import { FontAwesome5 } from '@expo/vector-icons';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useHeaderHeight } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import { ResizeMode } from 'expo-av';
import React, { useCallback, useRef, useState } from 'react';
import {
  FlatList,
  Image,
  Platform,
  Pressable,
  Text,
  View,
  ViewStyle,
  ViewToken,
  useWindowDimensions,
} from 'react-native';
import Avatar from '../Avatar';
import Button from '../Button';
import CommentsButton from '../CampaignPost/elements/CommentsButton';
import styles from '../FeedScreen/MomentInNatureCarousel.style';
import LikeButtonComponent from '../LikeButtonComponent';
import VideoPlayer from '../VideoPlayer';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import MapMarker from '/assets/jsicons/headerIcons/map-marker';
import { KEY_GRAY } from '/constants';
import {
  GetMomentInNatureFeedQuery,
  useAddLikeMutation,
  useRemoveLikeMutation,
} from '/generated/graphql';
import getCDNImageUri from '/util/getCDNImageUri';

interface Props {
  items: GetMomentInNatureFeedQuery['getMomentInNatureFeed']['items'];
  hasMore: boolean;
  loading: boolean;
  containerStyle?: ViewStyle;
  onLoadMore: () => void;
  shouldAutoplay?: boolean;
  compact?: boolean;
}

const VIEWABILITY_CONFIG = {
  viewAreaCoveragePercentThreshold: Platform.OS === 'web' ? 30 : 50,
};

export default function MomentInNatureCarouselBase(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [, addLike] = useAddLikeMutation();
  const [, removeLike] = useRemoveLikeMutation();

  const [viewableItemIndices, setViewableItemIndices] = useState<number[]>([]);

  const windowDimensions = useWindowDimensions();

  const thumbnailWidth =
    Math.max(Math.min(windowDimensions.width - 80, 420), 280) *
    (props.compact ? 0.5 : 1);

  const tabBarHeight = useBottomTabBarHeight();
  const headerHeight = useHeaderHeight();

  const MINIMUM_HEIGHT =
    Math.min(windowDimensions.height - tabBarHeight - headerHeight, 560) *
    (props.compact ? 0.5 : 1);

  const mediaHeight = Math.min(thumbnailWidth * 2, MINIMUM_HEIGHT);

  function goToMomentInNature(id: string) {
    navigate('MomentInNature', { id });
  }

  function goToProfile(userId: string) {
    navigate('Profile', { id: userId });
  }

  const onViewableItemsChanged = useCallback(
    (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      setViewableItemIndices(
        info.viewableItems.map((item) => item.index as number) || [],
      );
    },
    [],
  );

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: VIEWABILITY_CONFIG,
      onViewableItemsChanged,
    },
  ]);

  return (
    <View style={{ height: mediaHeight + 100 }}>
      <FlatList
        horizontal
        data={props.items}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        renderItem={({ item, index }) => {
          return (
            <View
              style={[
                styles.listItemContainer,
                {
                  width: thumbnailWidth,
                },
              ]}
            >
              <Pressable onPress={() => goToMomentInNature(item.id)}>
                {Platform.OS === 'web' || props.shouldAutoplay === false ? (
                  <Image
                    style={[
                      styles.thumbnail,
                      {
                        height: mediaHeight,
                      },
                    ]}
                    source={{
                      uri: getCDNImageUri({
                        uri: item.video,
                        isThumbnail: true,
                        dimensions: {
                          width: thumbnailWidth,
                          height: mediaHeight,
                        },
                      }),
                    }}
                    resizeMode={ResizeMode.COVER}
                  />
                ) : (
                  <VideoPlayer
                    style={[
                      styles.thumbnail,
                      {
                        height: mediaHeight,
                        pointerEvents: 'none',
                      },
                    ]}
                    sourceUri={item.video}
                    thumbnailSource={{
                      uri: getCDNImageUri({
                        uri: item.video,
                        isThumbnail: true,
                        dimensions: {
                          width: thumbnailWidth,
                          height: mediaHeight,
                        },
                      }),
                    }}
                    hideControls
                    resizeMode={ResizeMode.COVER}
                    isMuted
                    isLooping
                    shouldPlay={viewableItemIndices.includes(index)}
                    preload={viewableItemIndices.some(
                      (viewableIndex) =>
                        viewableIndex - index <= 0 &&
                        viewableIndex - index >= -1,
                    )}
                    suppress={
                      !viewableItemIndices.some(
                        (viewableIndex) =>
                          viewableIndex - index <= 0 &&
                          viewableIndex - index >= -1,
                      )
                    }
                  />
                )}

                <View style={styles.topLeftOverlay}>
                  {Platform.OS === 'web' ? (
                    <FontAwesome5
                      style={{
                        marginLeft: 2,
                      }}
                      size={props.compact ? 16 : 24}
                      name="play"
                      color="white"
                    />
                  ) : null}
                  {item.is_good_news ? (
                    <FontAwesome5
                      name="key"
                      size={props.compact ? 16 : 24}
                      color="white"
                      style={styles.goodNewsIcon}
                    />
                  ) : null}
                </View>

                <View style={styles.expandButton}>
                  <FontAwesome5
                    name="expand-alt"
                    size={props.compact ? 14 : 20}
                    color="black"
                  />
                </View>
              </Pressable>

              <Pressable
                onPress={() => !props.compact && goToProfile(item.user.id)}
                style={styles.listItemFooter}
              >
                {props.compact ? null : (
                  <Avatar
                    rounded
                    size={56}
                    source={{ uri: item.user.profile_image || '' }}
                  />
                )}
                <View style={{ flex: 1, marginLeft: 6 }}>
                  {props.compact ? (
                    <View>
                      {!item.caption?.text?.trim() ? null : (
                        <Text numberOfLines={2} style={styles.caption}>
                          {item.caption?.text}
                        </Text>
                      )}
                      <Text style={styles.timestamp}>
                        {dayjs(Number(item.created_at)).fromNow()}
                      </Text>
                    </View>
                  ) : (
                    <Text style={styles.authorName}>{item.user.name}</Text>
                  )}
                  {item.location && !props.compact ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <MapMarker fill={KEY_GRAY} />
                      <Text style={styles.locationText}>
                        {item.location.name}
                      </Text>
                    </View>
                  ) : null}
                  <HorizontalContainer
                    style={{
                      marginTop: 4,
                    }}
                  >
                    <LikeButtonComponent
                      size={28}
                      style={{
                        borderRadius: 8,
                      }}
                      returnToOnLogIn={`/moment-in-nature/${item.id}`}
                      isLiked={item.interactions.is_liked}
                      likeCount={item.interactions.likes}
                      onPress={() => {
                        if (item.interactions.is_liked) {
                          removeLike({
                            interactionsId: item.interactions.id,
                          });
                        } else {
                          addLike({
                            interactionsId: item.interactions.id,
                          });
                        }
                      }}
                    />
                    <CommentsButton
                      size={28}
                      commentCount={item.interactions.comments.total}
                      interactionsId={item.interactions.id}
                    />
                  </HorizontalContainer>
                </View>
              </Pressable>
            </View>
          );
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        ListFooterComponent={() =>
          props.hasMore ? (
            <View style={[styles.footerContainer, { width: thumbnailWidth }]}>
              <Button
                label="Load More"
                loading={props.loading}
                onPress={props.onLoadMore}
                containerStyle={styles.loadMoreButton}
              />
            </View>
          ) : null
        }
        onEndReached={props.hasMore ? props.onLoadMore : undefined}
        style={props.containerStyle}
      />
    </View>
  );
}
