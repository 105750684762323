import { FontAwesome5 } from '@expo/vector-icons';
import { HeaderBackButton } from '@react-navigation/elements';
import React, { useCallback, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import useBinaryTimingAnimation from '/hooks/useBinaryTimingAnimation';
import commonStyles from './styles';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import GridList from '/components/GridList';
import { KEY_GRAY, KEY_GREEN, TITLE_FONT_SIZE } from '/constants';
import { useAuthContext } from '/context';
import {
  SpeciesUserContentFact,
  useCountSpeciesUserContentFactCopiedMutation,
  useDeleteSpeciesUserContentFactMutation,
  useListSpeciesUserContentFactsQuery,
} from '/generated/graphql';
import { DeepPartial } from '/types';
import { isCloseToBottom } from '/util';
import { ScrollView } from 'react-native';

const MAX_ALLOWED_FACTS = 5;

interface Props {
  visible: boolean;
  userId: string | undefined;
  speciesTaxonId: number | undefined;
  alreadyInlcudedIds: string[] | undefined;
  onRequestClose: () => void;
  onUseFact: (fact: Pick<SpeciesUserContentFact, 'id' | 'body'>) => void;
  onDeleteTopFacts: (id: string) => void;
}

const MAX_GRID_TILE_WIDTH = 600;

export default function BrowseUserContentFactsModal({
  onDeleteTopFacts: _onDeleteTopFacts,
  onRequestClose,
  ...props
}: Props) {
  const { userData } = useAuthContext();

  const userId = props.userId || userData?.id;

  const animatedOpacity = useBinaryTimingAnimation({
    value: !!props.visible,
  });
  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: animatedOpacity.value,
  }));

  const [ownTopFactsNextToken, setOwnTopFactsNextToken] = useState<string>();
  const [publicTopFactsNextToken, setPublicTopFactsNextToken] =
    useState<string>();

  const [promptDeleteTopFacts, setPromptDeleteTopFacts] = useState<string>();
  const [deletingTopFactsIds, setDeletingTopFactsIds] = useState<{
    [key: string]: boolean;
  }>({});

  const [
    {
      data: ownTopFacts,
      fetching: fetchingOwnTopFacts,
      error: ownTopFactsError,
    },
    getMyOwnTopFacts,
  ] = useListSpeciesUserContentFactsQuery({
    variables: {
      taxonId: props.speciesTaxonId!,
      limit: 6,
      createdByUserId: userId,
      nextToken: ownTopFactsNextToken,
    },
    pause: !userId || !props.visible || !props.speciesTaxonId,
    requestPolicy: 'cache-and-network',
  });

  const [
    {
      data: publicTopFacts,
      fetching: fetchingPublicTopFacts,
      error: publicTopFactsError,
    },
    getPublicTopFacts,
  ] = useListSpeciesUserContentFactsQuery({
    variables: {
      taxonId: props.speciesTaxonId!,
      nextToken: publicTopFactsNextToken,
      notCreatedByUserId: userId,
    },
    pause: !props.visible || !props.speciesTaxonId,
    requestPolicy: 'cache-and-network',
  });

  const hasReachedLimit =
    (props.alreadyInlcudedIds?.length || 0) >= MAX_ALLOWED_FACTS;

  const [, countSpeciesUserContentFactCopied] =
    useCountSpeciesUserContentFactCopiedMutation();

  const [, deleteSpeciesUserContentFact] =
    useDeleteSpeciesUserContentFactMutation();

  const onUseFact = useCallback(
    function (fact: DeepPartial<SpeciesUserContentFact>) {
      if (!fact?.id) return;

      // When someone uses someone else's fact, we should increment "count" on it so we can
      // sort all facts by popularity
      if (fact.created_by?.id && fact.created_by?.id !== userId) {
        countSpeciesUserContentFactCopied({
          countSpeciesUserContentFactCopiedId: fact.id,
        });
      }

      props.onUseFact(fact as Pick<SpeciesUserContentFact, 'id' | 'body'>);
    },
    [countSpeciesUserContentFactCopied, props, userId],
  );

  const onDeleteTopFacts = useCallback(
    async function (topFacts: DeepPartial<SpeciesUserContentFact>) {
      try {
        if (!topFacts.id)
          throw new Error('Cannot delete SpeciesUserContentTopFacts: No ID');

        setDeletingTopFactsIds((prevState) => ({
          ...prevState,
          [topFacts.id as string]: true,
        }));

        const { error } = await deleteSpeciesUserContentFact({
          id: topFacts.id,
        });

        if (error) throw error;

        /** Notify parent of deletion */
        _onDeleteTopFacts(topFacts.id);

        Alert.notify({ message: 'Deleted successfully', color: KEY_GREEN });
      } catch (err) {
        console.log(err);
      } finally {
        setDeletingTopFactsIds((prevState) => ({
          ...prevState,
          [topFacts.id as string]: false,
        }));
      }
    },
    [deleteSpeciesUserContentFact, _onDeleteTopFacts],
  );

  const OwnTopFacts = useMemo(
    () => (
      <>
        <Text
          style={[commonStyles.sectionTitle, { fontSize: TITLE_FONT_SIZE }]}
        >
          CREATED BY YOU
        </Text>
        {ownTopFacts?.listSpeciesUserContentFacts.total === 0 &&
        !fetchingOwnTopFacts ? (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              You have not yet created your own "Interesting Facts" for this
              species
            </Text>
          </View>
        ) : null}
        {fetchingOwnTopFacts &&
        !ownTopFacts?.listSpeciesUserContentFacts?.items.length ? (
          <View style={styles.emptyContainer}>
            <ActivityIndicator color={KEY_GRAY} size="large" />
          </View>
        ) : null}
        {ownTopFactsError && !fetchingOwnTopFacts ? (
          <View>
            <Text style={commonStyles.errorText}>
              There was a problem fetching your Interesting Facts
            </Text>
            <Button label="Retry" onPress={getMyOwnTopFacts} />
          </View>
        ) : (
          <GridList
            maxTileWidth={MAX_GRID_TILE_WIDTH}
            data={ownTopFacts?.listSpeciesUserContentFacts.items}
            renderItem={({ item }) => {
              const alreadyIncluded = props.alreadyInlcudedIds?.includes(
                item.id,
              );

              return (
                <View style={styles.topFactsCard}>
                  <Text style={styles.topFactText}>
                    {/* <Text style={{ fontFamily: 'Lato-Bold' }}>1.</Text>{' '} */}
                    {item.body}
                  </Text>

                  <View
                    style={{
                      marginVertical: 6,
                    }}
                  >
                    <View style={commonStyles.createdByContainer}>
                      <Avatar
                        rounded
                        size={20}
                        source={{ uri: item.created_by.profile_image ?? '' }}
                      />
                      <Text style={commonStyles.createdByText}>
                        {item.created_by.name}
                      </Text>
                    </View>
                    <View style={commonStyles.createdByContainer}>
                      {item.public ? (
                        <FontAwesome5
                          name="globe-africa"
                          size={18}
                          color="gray"
                        />
                      ) : (
                        <FontAwesome5 name="lock" color={KEY_GRAY} size={18} />
                      )}
                      <Text style={commonStyles.createdByText}>
                        {item.public ? 'PUBLIC' : 'PRIVATE'}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {promptDeleteTopFacts === item.id ? (
                      <>
                        <Text style={styles.promptDeleteText}>
                          Are you sure?
                        </Text>
                        <Button
                          loading={deletingTopFactsIds[item.id]}
                          label="Delete"
                          style={{
                            backgroundColor: 'crimson',
                          }}
                          labelStyle={{
                            color: 'white',
                          }}
                          onPress={() => onDeleteTopFacts(item)}
                          containerStyle={{
                            marginRight: 8,
                          }}
                        />
                        <Button
                          label="Cancel"
                          onPress={() => setPromptDeleteTopFacts(undefined)}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          containerStyle={{
                            marginRight: 8,
                          }}
                          onPress={() => setPromptDeleteTopFacts(item.id)}
                          label={'Delete'}
                        />
                        <Button
                          style={{
                            backgroundColor:
                              alreadyIncluded || hasReachedLimit
                                ? undefined
                                : KEY_GREEN,
                          }}
                          disabled={alreadyIncluded || hasReachedLimit}
                          onPress={() => onUseFact(item)}
                          label={alreadyIncluded ? 'Used' : 'Use'}
                        />
                      </>
                    )}
                  </View>
                </View>
              );
            }}
          />
        )}
        {ownTopFacts?.listSpeciesUserContentFacts.nextToken ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginVertical: 8,
              marginBottom: 16,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setOwnTopFactsNextToken(
                  ownTopFacts.listSpeciesUserContentFacts.nextToken!,
                );
              }}
              style={styles.seeAllMyTopFactsButtonContainer}
            >
              {fetchingOwnTopFacts ? (
                <ActivityIndicator color={KEY_GRAY} />
              ) : (
                <Text style={styles.seeAllMyTopFactsButtonText}>See More</Text>
              )}
            </TouchableOpacity>
          </View>
        ) : null}
      </>
    ),
    [
      ownTopFacts?.listSpeciesUserContentFacts.total,
      ownTopFacts?.listSpeciesUserContentFacts.items,
      ownTopFacts?.listSpeciesUserContentFacts.nextToken,
      fetchingOwnTopFacts,
      ownTopFactsError,
      getMyOwnTopFacts,
      props.alreadyInlcudedIds,
      promptDeleteTopFacts,
      deletingTopFactsIds,
      hasReachedLimit,
      onDeleteTopFacts,
      onUseFact,
    ],
  );

  const PublicTopFacts = useMemo(
    () => (
      <>
        <Text
          style={[commonStyles.sectionTitle, { fontSize: TITLE_FONT_SIZE }]}
        >
          FROM THE COMMUNITY
        </Text>
        <View style={commonStyles.createdByContainer}>
          <FontAwesome5 name="globe-africa" size={16} color={KEY_GRAY} />
          <Text style={commonStyles.createdByText}>PUBLIC</Text>
        </View>
        {publicTopFacts?.listSpeciesUserContentFacts.total === 0 &&
        !fetchingPublicTopFacts ? (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              No other organization has created "Interesting Facts" for this
              species yet
            </Text>
          </View>
        ) : null}
        {!publicTopFacts?.listSpeciesUserContentFacts.items.length &&
        fetchingPublicTopFacts ? (
          <View style={styles.emptyContainer}>
            <ActivityIndicator color={KEY_GRAY} size="large" />
          </View>
        ) : null}
        {publicTopFactsError && !fetchingPublicTopFacts ? (
          <View>
            <Text style={commonStyles.errorText}>
              There was a problem fetching community Interesting Facts
            </Text>
            <Button label="Retry" onPress={getPublicTopFacts} />
          </View>
        ) : (
          <GridList
            data={publicTopFacts?.listSpeciesUserContentFacts.items}
            maxTileWidth={MAX_GRID_TILE_WIDTH}
            renderItem={({ item }) => {
              const alreadyIncluded = props.alreadyInlcudedIds?.includes(
                item.id,
              );

              return (
                <View style={styles.topFactsCard}>
                  <Text style={styles.topFactText}>{item.body}</Text>
                  <View style={commonStyles.createdByContainer}>
                    <Avatar
                      rounded
                      size={20}
                      source={{ uri: item.created_by.profile_image ?? '' }}
                    />
                    <Text style={commonStyles.createdByText}>
                      {item.created_by.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          alreadyIncluded || hasReachedLimit
                            ? undefined
                            : KEY_GREEN,
                      }}
                      onPress={() => onUseFact(item)}
                      disabled={alreadyIncluded || hasReachedLimit}
                      label={alreadyIncluded ? 'Used' : 'Use'}
                    />
                  </View>
                </View>
              );
            }}
          />
        )}
        {
          /* Pagination Indicator */
          publicTopFacts?.listSpeciesUserContentFacts.nextToken ? (
            <View style={styles.emptyContainer}>
              <ActivityIndicator color={KEY_GRAY} size="large" />
            </View>
          ) : null
        }
      </>
    ),
    [
      publicTopFacts?.listSpeciesUserContentFacts.total,
      publicTopFacts?.listSpeciesUserContentFacts.items,
      publicTopFacts?.listSpeciesUserContentFacts.nextToken,
      fetchingPublicTopFacts,
      publicTopFactsError,
      getPublicTopFacts,
      props.alreadyInlcudedIds,
      hasReachedLimit,
      onUseFact,
    ],
  );

  return (
    <Animated.View
      pointerEvents={props.visible ? 'auto' : 'none'}
      style={[
        StyleSheet.absoluteFill,
        {
          backgroundColor: 'white',
          zIndex: props.visible ? 1 : -1,
        },
        animatedContainerStyle,
      ]}
    >
      <View style={commonStyles.headerContainer}>
        <HeaderBackButton
          onPress={() => onRequestClose()}
          tintColor={KEY_GRAY}
        />
        <Text style={commonStyles.headerText}>BROWSE INTERESTING FACTS</Text>
      </View>
      <ScrollView
        style={{ flex: 1 }}
        onScroll={(event) => {
          if (
            isCloseToBottom(event.nativeEvent) &&
            publicTopFacts?.listSpeciesUserContentFacts.nextToken
          )
            setPublicTopFactsNextToken(
              publicTopFacts.listSpeciesUserContentFacts.nextToken,
            );
        }}
        scrollEventThrottle={100}
        contentContainerStyle={commonStyles.contentContainerStyle}
      >
        <Text style={commonStyles.sectionText}>
          Find facts about this species that you or others have already written
        </Text>
        {OwnTopFacts}
        {PublicTopFacts}
      </ScrollView>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  topFactsCard: {
    width: '100%',
    padding: 8,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ddd',
    backgroundColor: 'white',
  },
  topFactText: {
    fontFamily: 'Lato',
    fontSize: 16,
    marginBottom: 4,
  },
  seeAllMyTopFactsButtonContainer: {
    padding: 8,
  },
  seeAllMyTopFactsButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    color: KEY_GRAY,
  },
  emptyContainer: {
    width: '100%',
    padding: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 16,
    textAlign: 'center',
  },
  promptDeleteText: {
    fontFamily: 'Lato-Bold',
    marginRight: 6,
  },
});
