import AsyncStorage from '@react-native-async-storage/async-storage';
import { ResizeMode, Video } from 'expo-av';
import { useRef } from 'react';
import { Animated, StyleSheet, Text, View } from 'react-native';
import {
  getTesterOnlyModeHeroVideoThumbnailUri,
  getTesterOnlyModeHeroVideoUri,
} from './TesterOnlyModeLandingScreen';
import Button from '/components/Button';
import { KEY_GREEN } from '/constants';
import { isValidJson } from '/util';
import { useAuthContext } from '/context';

type Props = {
  onDismiss: () => void;
};

function TesterDashboardReadyToJumpInSplash(props: Props) {
  const { userData } = useAuthContext();

  // Initially visible
  const opacity = useRef(new Animated.Value(1)).current;

  const onClose = () => {
    // Fade out before dismissing
    Animated.timing(opacity, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      props.onDismiss();
    });
  };

  return (
    <Animated.View
      style={[StyleSheet.absoluteFill, styles.container, { opacity }]}
    >
      <Video
        source={{
          uri: getTesterOnlyModeHeroVideoUri(
            'AntarcticPenguinJump',
            '720p',
          ) as string,
        }}
        usePoster
        resizeMode={ResizeMode.COVER}
        posterSource={{
          uri: getTesterOnlyModeHeroVideoThumbnailUri('AntarcticPenguinJump'),
        }}
        posterStyle={{
          resizeMode: 'cover',
        }}
        videoStyle={{
          width: '100%',
          height: '100%',
        }}
        style={[StyleSheet.absoluteFill, { zIndex: -1 }]}
        shouldPlay
        isLooping
        isMuted
      />

      <View
        style={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: [{ translateY: -100 }],
          alignItems: 'flex-end',
          minWidth: 330,
          width: '50%',
          padding: 24,
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            padding: 24,
            paddingVertical: 20,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Text style={styles.heroText}>Ready to jump in?</Text>
        </View>

        <Button
          onPress={async () => {
            const userIds =
              (await AsyncStorage.getItem(
                'has-seen-ready-to-jump-in-splash',
              )) ?? '[]';
            const userIdsArray = isValidJson(userIds)
              ? JSON.parse(userIds)
              : [];
            userIdsArray.push(userData?.id);
            await AsyncStorage.setItem(
              'has-seen-ready-to-jump-in-splash',
              JSON.stringify(userIdsArray),
            );
            onClose();
          }}
          label={"Let's go!"}
          containerStyle={{
            flexShrink: 1,
            alignSelf: 'flex-end',
            shadowOpacity: 0,
            marginTop: 10,
          }}
          style={{
            paddingVertical: 11,
            minWidth: 140,
            backgroundColor: KEY_GREEN,
          }}
        />
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 100,
    backgroundColor: 'black',
  },
  heroText: {
    fontSize: 56,
    color: 'white',
    fontFamily: 'FranklinGothic-Demi',
    maxWidth: 480,
    textAlign: 'right',
  },
  heroSubtext: {
    fontSize: 18,
    color: 'white',
    fontFamily: 'Lato-Bold',
  },
});

export default TesterDashboardReadyToJumpInSplash;
