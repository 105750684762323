import {
  NavigationProp,
  RouteProp,
  useFocusEffect,
  useScrollToTop,
} from '@react-navigation/native';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Platform,
  RefreshControl,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import ProfileHeader from '../components/Profile/ProfileHeader/ProfileHeader';
import UserEvents from '../components/Profile/common/UserEvents';
import GenericError from '../components/common/Generic/GenericError';
import { useScrollViewScrollToIndex } from '../hooks/useScrollViewScrollToIndex';
import Alert from '/Alert';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import MetaTags from '/components/MetaTags';
import MyNewsPosts from '/components/MyNewsPosts/MyNewsPosts';
import UserCreativeConnect from '/components/Profile/common/UserCreativeConnect';
import UserGroups from '/components/Profile/common/UserGroups';
import AboutUs from '/components/Profile/conservationist/AboutUs';
import ClosedCampaigns from '/components/Profile/conservationist/ClosedCampaigns';
import CurrentCampaigns from '/components/Profile/conservationist/CurrentCampaigns';
import OurJobPosts from '/components/Profile/conservationist/OurJobPosts';
import OurSpecies from '/components/Profile/conservationist/OurSpecies';
import OurTeam, { ITeam } from '/components/Profile/conservationist/OurTeam';
import Partnerships from '/components/Profile/conservationist/Partnerships';
import ProfileNavbar from '/components/Profile/conservationist/ProfileNavbar';
import ProfileScreenCompletionWidget from '/components/Profile/conservationist/ProfileScreenCompletionWidget';
import TheBigIssues from '/components/Profile/conservationist/TheBigIssues';
import UserResearchTopics, {
  IResearchTopic,
} from '/components/Profile/conservationist/UserResearchTopics';
import { useCommonStyles } from '/components/Profile/styles';
import AboutMe from '/components/Profile/supporter/AboutMe';
import FollowedTopics from '/components/Profile/supporter/FollowedTopics';
import MyImpact from '/components/Profile/supporter/MyImpact';
import MyLanguages from '/components/Profile/supporter/MyLanguages';
import MySkills from '/components/Profile/supporter/MySkills';
import RecentActivity from '/components/Profile/supporter/RecentActivity';
import UpdateAvailableWidget from '/components/UpdateAvailableWidget';
import ViewportTracker from '/components/ViewportAware/ViewportTracker';
import { SECTION_CONTAINER } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import { useAppInfo } from '/context/AppInfoProvider';
import env from '/env';
import {
  CarouselCardInput,
  LocationInput,
  PartnershipStatus,
  ResearchTopic,
  TeamMemberRole,
  UpdateUserMutationInput,
  useGetUserProfileQuery,
  User,
  UserProfileFragmentFragment,
  UserRole,
  UserSkillPortfolioInput,
  useUpdateUserProfileMutation,
} from '/generated/graphql';
import { ProfileStackParamList } from '/navigation/navigators/nested/ProfileStack';
import { DeepPartial } from '/types';
import { getScrollableNode, isUnderPrivileged, shorten } from '/util';
import { ScrollView } from 'react-native';

interface IProfileScreenProps {
  navigation: NavigationProp<any, 'Profile' | 'MyProfile'>;
  route: RouteProp<ProfileStackParamList, 'Profile' | 'MyProfile'>;
}

interface IFormState
  extends Pick<
    UserProfileFragmentFragment,
    | 'id'
    | 'bio'
    | 'about_us_carousel'
    | 'role'
    | 'name'
    | 'is_verified'
    | 'locations'
  > {
  main_species?: Array<any> | undefined;
  secondary_species?: Array<any> | undefined;
  team?: ITeam | undefined | null;
  topics?: IResearchTopic[];
}

export default function ProfileScreen(props: IProfileScreenProps) {
  const { userData, isAuthenticating, fetching } = useAuthContext();
  const { isFeatureFlagEnabled } = useAppInfo();
  const { styles: commonStyles } = useCommonStyles();

  const { activeTeam, teams, setActiveTeam } = useTeamContext();

  const profileId = useMemo(
    () => props.route.params?.id || userData?.id,
    [userData?.id, props.route.params?.id],
  );

  const [, updateUser] = useUpdateUserProfileMutation();

  const scrollViewRef = useRef<ScrollView | null>(null);
  const scrollY = useRef(new Animated.Value(0));

  useScrollToTop(scrollViewRef);

  // Track profile sections that are 'busy' (Uploading media, for example - so we can delay a 'save' action until after all
  // media uploads are complete)
  const [busySections, setBusySections] = useState<string[]>([]);

  /** If set to true, a useEffect will call updateProfile when busySections.length becomes 0 */
  const saveActionScheduled = useRef(false);

  const [formState, _setFormState] = useState<IFormState>({} as any);
  const setFormState = useCallback((changes: DeepPartial<User>) => {
    _setFormState(
      (prevState) =>
        ({
          ...prevState,
          ...changes,
        } as IFormState),
    );
  }, []);

  const hasScrolledToInitialSection = useRef(false);

  // Used to deterine whether the organization profile navbar should hide or
  // respond to touch
  const isDragging = useRef(false);

  // Are we editing the profile?
  const [isEditing, setEditing] = useState(false);
  const [isSavingChanges, setSavingChanges] = useState(false);

  /** Values used for determining scroll view padding
   * Based on side of sticky header
   */
  const [_contentPaddingTop, setContentPaddingTop] = useState(10);
  const contentPaddingTop = Platform.OS === 'web' ? 0 : _contentPaddingTop;
  const [minimumHeaderHeight, setMinimumHeaderHeight] = useState(0);
  const safeAreaInsets = useSafeAreaInsets();

  const [{ data, fetching: fetchingProfile, error, stale }, refetch] =
    useGetUserProfileQuery({
      pause: !profileId,
      variables: {
        id: profileId!,
        includeCreativeConnect: isFeatureFlagEnabled('CREATIVE_CONNECT'),
        includeEvents: isFeatureFlagEnabled('EVENTS'),
        partnershipsFilter: {
          status: PartnershipStatus.Confirmed,
        },
        partnershipsLimit: 6,
      },
      requestPolicy: 'cache-and-network',
    });
  const profile = data?.getUser;

  const loading = fetchingProfile || isAuthenticating || fetching || stale;

  const currentScrollPosition = useRef(0);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const onRefresh = () => async () => {
    setIsRefreshing(true);
    refetch({ requestPolicy: 'network-only' });
  };
  useEffect(() => {
    setIsRefreshing(false);
  }, [fetchingProfile]);

  useFocusEffect(
    useCallback(() => {
      if (!profileId) return;

      /** Every time we focus the profile, silently refresh */
      refetch();
    }, [refetch, profileId]),
  );

  const setSectionBusy = useCallback((sectionName: string, busy: boolean) => {
    if (busy) {
      setBusySections((prevState) => [...prevState, sectionName]);
    } else {
      setBusySections((prevState) => {
        const newState = [...prevState];
        newState.splice(newState.indexOf(sectionName));

        return newState;
      });
    }
  }, []);

  /**
   * Format user object to only include properties
   * that can be included as input to an UpdateUser query
   */
  const getFormInputFromState = (
    _data: DeepPartial<User> | undefined | null,
  ): UpdateUserMutationInput | undefined => {
    if (!_data?.id) {
      return;
    }

    /**
     * Map data and format where needed so it is a valid input.
     */
    const result: UpdateUserMutationInput = {
      id: _data.id,
      name: _data?.name,
      profile_image: _data?.profile_image,
      cover_image: _data?.cover_image,
      cover_image_thumbnail: _data?.cover_image_thumbnail,
      bio: { text: _data.bio?.text ?? '' },
      donate_link: _data.donate_link,
      about_us_carousel:
        /** Filter out empty items */
        _data.about_us_carousel?.reduce<CarouselCardInput[]>((accum, item) => {
          if (!item?.media) return accum;
          else
            return [
              ...accum,
              {
                media: item.media,
                caption: item.caption ?? '',
                thumbnail: item.thumbnail ?? '',
              },
            ];
        }, []) ?? [],
      website: _data?.website,
      twitter: _data?.twitter,
      facebook: _data?.facebook,
      linkedin: _data?.linkedin,
      instagram: _data?.instagram,
      youtube: _data?.youtube,
      topics: _data?.topics?.map((topic) => ({
        topic: topic.topic,
      })) as ResearchTopic[],
      skills: _data?.skills?.map((skill) => ({
        skillName: skill?.skillName,
        links: skill?.links,
        media_carousel: skill?.media_carousel?.map((card) => ({
          media: card?.media,
          thumbnail: card?.thumbnail,
          caption: card?.caption,
        })) as CarouselCardInput[],
        relevant_experience: skill?.relevant_experience,
        thumbnail: skill?.thumbnail,
      })) as UserSkillPortfolioInput[],
      languages_beginner: _data?.languages_beginner,
      languages_conversational: _data?.languages_conversational,
      languages_fluent: _data?.languages_fluent,
      /** Add stuff here as profile expands */
    };

    // Only add `locations` here for supporters
    if (_data.role === UserRole.Supporter) {
      result.locations =
        (
          _data?.locations?.filter(
            (l) =>
              l.name?.trim() &&
              typeof l.latitude === 'number' &&
              typeof l.longitude === 'number',
          ) as LocationInput[]
        ).map((l) => ({
          latitude: l.latitude,
          longitude: l.longitude,
          name: l.name,
        })) ?? [];
    }

    return JSON.parse(JSON.stringify(result));
  };

  const formHasChanges = useMemo(() => {
    const oldFormState = getFormInputFromState(profile);
    const newFormState = getFormInputFromState(formState);

    return !_.isEqual(oldFormState, newFormState);
  }, [formState, profile]);

  const updateProfile = useMemo(
    () => async () => {
      try {
        setSavingChanges(true);

        // If there are components in the profile that have signalled they are 'busy',
        // then we must defer saving the profile until no components are busy.
        // Setting saveActionScheduled to true will cause a useEffect to call
        // updateProfile again as soon as busySections has a length of 0.
        if (busySections.length) {
          saveActionScheduled.current = true;
          return;
        }
        // Only set editing to false after we made sure no sections are busy as not to
        // cause an interruption
        setEditing(false);

        if (!formHasChanges) return null;

        const { error: err } = await updateUser({
          input: getFormInputFromState(formState)!,
        });

        if (err) throw err;

        // Update state to reflect changes
      } catch (err) {
        console.log(err);
        setEditing(true);

        Alert.alert(
          'Error',
          'We ran into a problem while updating your profile. Please try again at a later time',
        );
      } finally {
        // Keep `isSavingChanges` true if a save event has been scheduled
        if (!saveActionScheduled.current) setSavingChanges(false);
      }
    },
    [busySections.length, formHasChanges, formState, updateUser],
  );

  const onSetEditing = useCallback(() => {
    /** Make sure user has permission to do this */
    const isOwnProfile = profile?.id === userData?.id;
    const authorizingTeam = teams.find((team) => {
      return (
        team.user.id === profileId &&
        !isUnderPrivileged(TeamMemberRole.Creator, team.membership?.team_role)
      );
    });

    if (!isOwnProfile && !authorizingTeam) return;

    if (!isOwnProfile && activeTeam?.user.id !== profileId) {
      setActiveTeam(authorizingTeam!.id);
    }

    setEditing(true);
  }, [
    activeTeam?.user.id,
    profile?.id,
    profileId,
    setActiveTeam,
    teams,
    userData?.id,
  ]);

  const onDiscardChanges = useCallback(() => {
    const discard = () => {
      setEditing(false);
      setFormState(profile!);
    };

    // Check if newFormState is different from formState, and if it is, confirm that user really
    // meant to cancel editing
    if (!formHasChanges) discard();
    else
      Alert.alert(
        'Cancel Editing',
        "Looks like you've made some changes. Would you like to save them?",
        [
          {
            text: 'Discard Changes',
            onPress: discard,
          },
          {
            text: 'Save Changes',
            style: 'default',
            onPress: updateProfile,
          },
          {
            text: 'Go back',
          },
        ],
      );
  }, [formHasChanges, profile, setFormState, updateProfile]);

  const hasHeaderLayout = useRef(false);
  const onHeaderLayout = (height: number, minimumHeight: number) => {
    setContentPaddingTop(height);
    setMinimumHeaderHeight(minimumHeight);
    if (!hasHeaderLayout.current)
      setTimeout(() => {
        hasHeaderLayout.current = true;
      }, 500);
  };

  const { CellRenderer, scrollToIndex, sectionPositions } =
    useScrollViewScrollToIndex(scrollViewRef);

  const getScrollOffset = useCallback(
    (targetPosition: number) => {
      const profileToolbarOffset =
        userData?.id === profile?.id ||
        teams.some(
          (team) =>
            team.user.id === profileId &&
            team.membership?.confirmed_at &&
            team.membership.team_role === TeamMemberRole.Admin,
        )
          ? 40
          : 0;

      /** When scrolling up, the collapsed header slides down, occluding
       * the top of the flatlist. This offset accounts for that. */
      const headerDiffClampOffset =
        sectionPositions.current[targetPosition] < currentScrollPosition.current
          ? minimumHeaderHeight
          : 0;

      return profileToolbarOffset + headerDiffClampOffset + 6;
    },
    [
      minimumHeaderHeight,
      profile?.id,
      profileId,
      teams,
      userData?.id,
      sectionPositions,
    ],
  );

  // Track current scroll position
  const handleScroll = Animated.event(
    [{ nativeEvent: { contentOffset: { y: scrollY.current } } }],
    {
      useNativeDriver: true,
      listener: (event: any) => {
        currentScrollPosition.current = event.nativeEvent.contentOffset.y;
      },
    },
  );

  const onScrollToSection = useCallback(
    (sectionName: string) => {
      if (profile?.role === UserRole.Conservationist) {
        /** Conservationist sections */
        switch (sectionName) {
          case 'about': {
            scrollToIndex(3, getScrollOffset(3));
            break;
          }
          case 'species': {
            const index = 9 + (env.ENV_NAME === 'development' ? 0 : -1);
            scrollToIndex(index, getScrollOffset(index));
            break;
          }
          case 'big_issues': {
            const index = 10 + (env.ENV_NAME === 'development' ? 0 : -1);
            scrollToIndex(index, getScrollOffset(index));
            break;
          }
          case 'campaigns': {
            const index = 13 + (env.ENV_NAME === 'development' ? 0 : -1);
            scrollToIndex(index, getScrollOffset(index));
            break;
          }
        }
      } else if (profile?.role === UserRole.Supporter) {
        /** Supporter sections */
        switch (sectionName) {
          case 'impacted_campaigns': {
            scrollToIndex(3, getScrollOffset(3));
            break;
          }
          case 'about': {
            scrollToIndex(4, getScrollOffset(4));
            break;
          }
          case 'recent_activity': {
            scrollToIndex(5, getScrollOffset(5));
            break;
          }
          case 'skills': {
            const index = 9 + (env.ENV_NAME === 'development' ? 0 : -1);
            scrollToIndex(index, getScrollOffset(index));
            break;
          }
        }
      }
    },
    [profile?.role, scrollToIndex, getScrollOffset],
  );

  const renderItem = useCallback(({ item }: any) => {
    return item;
  }, []);

  useEffect(() => {
    if (saveActionScheduled.current && busySections.length === 0) {
      saveActionScheduled.current = false;

      updateProfile();
    }
  }, [busySections, updateProfile]);

  useEffect(() => {
    // Keep formState up to date (unless we are currently editing)

    if (isEditing) {
      return;
    }

    if (profile) {
      _setFormState(profile!);

      /** If we passed in a target initial section in params when navigating and haven't scrolled to it yet, do it now */
      if (
        props.route.params?.section &&
        hasScrolledToInitialSection.current === false
      ) {
        switch (props.route.params.section) {
          case 'big_issues': {
            onScrollToSection('big_issues');
            break;
          }
          default: {
            console.warn(
              'ProfileScreen: Navigate to ProfileScreen with param `section` set to "' +
                props.route.params.section +
                '", but a section with that name is not handled.',
            );
          }
        }

        hasScrolledToInitialSection.current = true;
      }
    }
  }, [isEditing, onScrollToSection, profile, props.route.params?.section]);

  /** Keep profile route MyProfile up to date */
  useEffect(() => {
    if (isAuthenticating || fetching) return;

    if (!profileId) {
      if (props.navigation.canGoBack()) props.navigation.goBack();
      else props.navigation.navigate('FeedStack');
    }
  }, [profileId, isAuthenticating, fetching, props.navigation]);

  const OrganizationProfileSections = useMemo(() => {
    return [
      /** Bug Workaround
    This View pushes scroll content down by the height of the absolutely positioned
    animated header component. It is done this way instead of using the FlatList's
    contentContainerStyle because for some reason, when the values of _contentPaddingTop
    or headerHeight change, the FlatList component does not re-render properly. */
      <View
        key="padding"
        style={{
          height: contentPaddingTop,
        }}
      />,
      <UpdateAvailableWidget key="update-available-widget" />,
      <ProfileScreenCompletionWidget
        key="profile-completion-widget"
        targetProfile={profile}
      />,
      <AboutUs
        key="about-us"
        data={formState}
        isEditing={isEditing}
        setData={setFormState}
        setBusy={(busy) => {
          setSectionBusy('AboutUs', busy);
        }}
      />,
      <OurTeam key="our-team" isEditing={isEditing} data={formState} />,
      profile && isFeatureFlagEnabled('CREATIVE_CONNECT') ? (
        <UserCreativeConnect key="user-creative-connect" data={profile} />
      ) : null,
      <OurJobPosts
        key="our-job-posts"
        isEditing={isEditing}
        userId={profile?.id}
      />,
      <Partnerships key="partnerships" data={profile} isEditing={isEditing} />,
      <UserGroups key="groups" data={profile} />,
      <OurSpecies
        key="species"
        data={profile || undefined}
        setData={setFormState}
        isEditing={isEditing}
      />,
      <TheBigIssues
        key="big-issues"
        userId={profileId}
        isEditing={isEditing}
        setBusy={(busy) => {
          setSectionBusy('BigIssues', busy);
        }}
      />,
      isFeatureFlagEnabled('EVENTS') ? (
        <UserEvents key="user-events" data={profile} />
      ) : null,
      profile?.id ? (
        <MyNewsPosts
          key="news-posts"
          hideUntilHasContent
          userId={profile?.id}
          titleStyle={commonStyles('sectionTitle')}
        />
      ) : null,
      // <MonthlyDonors data={profile} />,
      <CurrentCampaigns
        key="current-campaigns"
        isEditing={isEditing}
        userId={profile?.id}
        data={profile}
      />,
      <ClosedCampaigns
        key="closed-campaigns"
        isEditing={isEditing}
        userId={profile?.id}
        data={profile}
      />,
      <UserResearchTopics
        key="research-topics"
        data={formState}
        isEditing={isEditing}
        setData={setFormState}
      />,
    ];
  }, [
    contentPaddingTop,
    profile,
    formState,
    isEditing,
    isFeatureFlagEnabled,
    commonStyles,
    profileId,
    setFormState,
    setSectionBusy,
  ]);

  const SupporterProfileSections = useMemo(() => {
    return [
      /** Bug Workaround
    This View pushes scroll content down by the height of the absolutely positioned
    animated header component. It is done this way instead of using the FlatList's
    contentContainerStyle because for some reason, when the values of _contentPaddingTop
    or headerHeight change, the FlatList component does not re-render properly. */
      <View
        style={{
          height: contentPaddingTop,
        }}
        key="padding"
      />,
      <UpdateAvailableWidget key="update-available-widget" />,
      <ProfileScreenCompletionWidget
        targetProfile={profile}
        key="profile-completion-widget"
      />,
      <MyImpact data={profile} key="my-impact" />,
      <AboutMe
        key="about-me"
        data={formState}
        setData={setFormState}
        isEditing={isEditing}
        setBusy={(busy) => setSectionBusy('AboutMe', busy)}
      />,
      <RecentActivity key="recent-activity" data={profile} />,
      <UserGroups key="user-groups" data={profile} />,
      isFeatureFlagEnabled('EVENTS') ? (
        <UserEvents key="user-events" data={profile} />
      ) : null,
      profile && isFeatureFlagEnabled('CREATIVE_CONNECT') ? (
        <UserCreativeConnect key="user-creative-connect" data={profile} />
      ) : null,
      <MySkills
        key="my-skills"
        data={formState as any}
        isEditing={isEditing}
        setData={setFormState}
      />,
      <FollowedTopics key="followed-topics" data={profile} />,
      <MyLanguages
        key="my-languages"
        data={formState as User}
        setData={setFormState}
        isEditing={isEditing}
      />,
    ];
  }, [
    contentPaddingTop,
    profile,
    formState,
    setFormState,
    isEditing,
    isFeatureFlagEnabled,
    setSectionBusy,
  ]);

  const shouldRenderLoading = (() => {
    const hasData = !!profile?.role;
    const isOwnProfileRoute = !props.route.params?.id;
    const isStale =
      isOwnProfileRoute && !!profile?.id && profile.id !== userData?.id;

    return (!hasData || isStale) && loading;
  })();

  /** We conditionally get the role based on isEditing to avoid interrupting a user's edits if they are in edit mode */
  const shouldRenderError = isEditing
    ? !formState.role
    : (error || !profile?.role) && !loading && !fetching;

  return (
    <View style={styles.container}>
      <MetaTags
        title={profile?.name || 'Profile'}
        thumbnail={
          profile?.cover_image_thumbnail || profile?.profile_image || undefined
        }
        description={shorten(profile?.bio?.text || '', 240) || undefined}
      />
      {/* If user viewing their own profile has not been approved and completed their onboarding survey yet, block out the profile and display the intro video */}
      {userData?.id === profileId &&
      userData?.application?.completed_survey === false ? (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              zIndex: 99,
              backgroundColor: 'rgba(10, 10, 10, 0.7)',
              justifyContent: 'center',
              alignItems: 'center',
            },
          ]}
        >
          <View style={SECTION_CONTAINER}>
            <Text>
              You cannot yet use this feature. You will be able to customize
              your profile once your organization is approved.
            </Text>
            {/* <IntroVideo /> */}
          </View>
        </View>
      ) : null}

      {shouldRenderLoading ? (
        <LoadingSpinnerOverlay />
      ) : shouldRenderError ? (
        <SafeAreaView
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <GenericError
            message="There was a problem fetching this profile. Please try again."
            onRetry={refetch}
          />
        </SafeAreaView>
      ) : (
        <View style={{ flex: 1 }}>
          <ProfileNavbar
            onScrollToSection={onScrollToSection}
            scrollY={scrollY.current}
            isDragging={isDragging}
            // Exclude species section if user has no species
            excludeSections={
              formState.main_species?.length ||
              formState.secondary_species?.length
                ? []
                : ['species']
            }
            isEditing={isEditing}
            setData={setFormState}
            profile={formState}
          />
          <ViewportTracker.AnimatedFlatList
            maintainVisibleContentPosition={
              // Wait for header layout to complete before setting minIndexForVisible
              // to avoid following content as it gets pushed down by the header initially
              hasHeaderLayout.current
                ? {
                    minIndexForVisible: 3,
                  }
                : undefined
            }
            ref={(r: any) => {
              scrollViewRef.current = getScrollableNode(r);
            }}
            renderScrollComponent={(svProps) => (
              <KeyboardAwareScrollView {...svProps} />
            )}
            refreshControl={
              <RefreshControl
                enabled={!isEditing}
                refreshing={isRefreshing}
                onRefresh={onRefresh()}
                progressViewOffset={contentPaddingTop - safeAreaInsets.top}
                style={{
                  position: 'absolute',
                  top: 100,
                }}
              />
            }
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled
            onScroll={handleScroll}
            scrollEventThrottle={16}
            onScrollBeginDrag={() => (isDragging.current = true)}
            onScrollEndDrag={() => (isDragging.current = false)}
            style={{ flex: 1 }}
            contentContainerStyle={{
              paddingBottom: 42,
            }}
            removeClippedSubviews={false}
            keyExtractor={(__: any, index: number) => `${index}`}
            data={
              (isEditing ? formState : profile)?.role ===
              UserRole.Conservationist
                ? OrganizationProfileSections
                : SupporterProfileSections
            }
            // FlatList should re-render if these values have changed
            extraData={contentPaddingTop}
            renderItem={renderItem}
            CellRendererComponent={CellRenderer}
            ListHeaderComponentStyle={{
              pointerEvents: Platform.OS === 'web' ? 'none' : 'box-none',
              zIndex: 10,
              position: Platform.OS === 'web' ? ('sticky' as any) : 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
            ListHeaderComponent={
              <ProfileHeader
                onLayout={onHeaderLayout}
                setBusy={(busy) => setSectionBusy('Header', busy)}
                isSaving={isSavingChanges}
                onDiscardChanges={onDiscardChanges}
                onEditProfile={onSetEditing}
                onSaveChanges={updateProfile}
                data={profile}
                formData={formState}
                setData={setFormState}
                isEditing={isEditing}
                scrollY={scrollY.current}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
