import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { KEY_GRAY, TITLE_FONT_SIZE } from '/constants';
import {
  ResearchTopicGroup,
  useCreateResearchTopicGroupMutation,
  useDeleteResearchTopicGroupMutation,
  useGetResearchTopicGroupsQuery,
  UserRole,
} from '/generated/graphql';
import Button from './Button';
import Alert from '/Alert';
import { useAuthContext, useTeamContext } from '/context';
import ButtonToggle from './ButtonToggle/ButtonToggle';
import { Entypo, FontAwesome } from '@expo/vector-icons';
import TrashButton from './TrashButton';

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
  selectedTopics: string[] | undefined;
  onSelectTopic: (topicName: string | string[]) => void;
}

export default function ResearchTopicGroupPicker(props: Props) {
  const { activeTeam } = useTeamContext();
  const { userData } = useAuthContext();

  const userId =
    userData?.role === UserRole.Supporter ? activeTeam?.user.id : userData?.id;

  const [{ data, fetching, error }, getMyResearchTopicGroups] =
    useGetResearchTopicGroupsQuery({
      variables: {
        userId: userId as string,
      },
      pause: !userId,
      requestPolicy: 'cache-and-network',
    });

  const [{ fetching: creating }, createResearchTopicGroup] =
    useCreateResearchTopicGroupMutation();
  const [{ fetching: deleting }, deleteResearchTopicGroup] =
    useDeleteResearchTopicGroupMutation();

  function onCreateNewGroup() {
    if (!props.selectedTopics?.length) {
      // If user has not selected any topics, we cannot create a group
      Alert.alert(
        'Cannot Create Group',
        'Please select at least one topic before creating a group',
      );
      return;
    }

    const _create = (groupTitle: string) => {
      createResearchTopicGroup({
        userId: userId as string,
        title: groupTitle,
        topics: props.selectedTopics as string[],
      }).then(() => getMyResearchTopicGroups());
    };

    Alert.alert(
      'Create Group',
      'Enter a title for this group',
      [
        {
          text: 'Create',
          style: 'default',
          onPress: (e) => _create(e.values.title),
        },
        {
          style: 'cancel',
        },
      ],
      [
        {
          name: 'title',
        },
      ],
    );
  }

  function onDeleteGroup(group: Pick<ResearchTopicGroup, 'id' | 'title'>) {
    Alert.alert(
      'Delete Group',
      `Are you sure you want to delete the hastag group "${group.title}"?`,
      [
        {
          style: 'destructive',
          text: 'Delete',
          onPress: () =>
            deleteResearchTopicGroup({ deleteResearchTopicGroupId: group.id }),
        },
        {
          style: 'cancel',
        },
      ],
    );
  }

  return (
    <View style={props.containerStyle}>
      <Text style={styles.headerText}>SAVED HASHTAG GROUPS</Text>
      <Text style={styles.paragraph}>
        <Text style={styles.paragraphItalic}>(Optional)</Text>
        {` `}Apply hashtags from groups you've created to this campaign
      </Text>

      <Button
        containerStyle={{
          alignSelf: 'center',
          marginTop: 12,
        }}
        label="Create New Group"
        onPress={onCreateNewGroup}
      />
      <Text style={styles.hintText}>
        This will create a new group from your current selection
      </Text>

      {fetching || deleting || creating ? (
        <ActivityIndicator
          size="large"
          style={styles.activityIndicator}
          color={KEY_GRAY}
        />
      ) : error ? (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>
            There was a problem fetching your hashtag groups
          </Text>
          <Button
            label="Retry"
            containerStyle={{
              marginTop: 8,
            }}
            onPress={getMyResearchTopicGroups}
          />
        </View>
      ) : !data?.getResearchTopicGroups.total ? (
        <Text style={styles.emptyText}>You have no hashtag groups</Text>
      ) : (
        data.getResearchTopicGroups.items.map((group) => {
          return (
            <View key={group.id} style={styles.groupContainer}>
              <Text style={styles.headerText}>{group.title}</Text>
              <View style={styles.topicButtonsContainer}>
                {group.topics.map((topic) => {
                  const selected = props.selectedTopics?.includes(topic.topic);

                  return (
                    <ButtonToggle
                      key={topic.topic}
                      selected={selected}
                      label={
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}
                        >
                          {selected ? (
                            <FontAwesome
                              name="check"
                              size={18}
                              style={{
                                paddingHorizontal: 2,
                              }}
                              color={'white'}
                            />
                          ) : (
                            <Entypo name="plus" size={22} color={KEY_GRAY} />
                          )}
                          <Text
                            style={[
                              styles.topicLabel,
                              {
                                color: selected ? 'white' : KEY_GRAY,
                              },
                            ]}
                          >
                            #{topic.topic.replace(/ /g, '')}
                          </Text>
                        </View>
                      }
                      onPress={() => {
                        props.onSelectTopic(topic.topic);
                      }}
                    />
                  );
                })}
              </View>
              <View style={styles.groupFooterContainer}>
                <Button
                  label="Use All"
                  containerStyle={{
                    marginRight: 6,
                  }}
                  style={{
                    height: 40,
                  }}
                  onPress={() =>
                    props.onSelectTopic(
                      group.topics.map((topic) => topic.topic),
                    )
                  }
                />
                <TrashButton
                  style={{
                    height: 40,
                  }}
                  onPress={() => onDeleteGroup(group)}
                />
              </View>
            </View>
          );
        })
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
    textTransform: 'uppercase',
  },
  paragraph: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 15,
  },
  paragraphItalic: {
    fontFamily: 'Lato-BoldItalic',
  },
  groupContainer: {
    padding: 12,
    paddingVertical: 16,
    borderColor: '#ccc',
    borderRadius: 10,
    borderWidth: 1,
    margin: 8,
  },
  topicButtonsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 8,
  },
  topicLabel: {
    fontFamily: 'Lato-Bold',
    letterSpacing: 1,
    color: KEY_GRAY,
    textTransform: 'uppercase',
  },
  activityIndicator: {
    alignSelf: 'center',
    padding: 24,
  },
  emptyText: {
    padding: 24,
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'gray',
  },
  errorText: {
    alignSelf: 'center',
    textAlign: 'center',
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: 'crimson',
  },
  errorContainer: {
    alignItems: 'center',
    padding: 24,
  },
  hintText: {
    fontFamily: 'Lato-Bold',
    color: '#444',
    padding: 6,
    textAlign: 'center',
  },
  groupFooterContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
