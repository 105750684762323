import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  Switch,
  Text,
  TextInput,
  View,
  ViewStyle,
} from 'react-native';
import Button from '../Button';
import MultipleChoice from '../MultipleChoice';
import { useFormValidationContext, ValidatedAny } from '../ValidatedForm';
import { KEY_GREEN, TEXT_INPUT, TEXT_INPUT_LARGE } from '/constants';
import {
  SurveyQuestion,
  SurveyQuestionResponseType,
  SurveyQuestionsFragmentFragment,
  SurveyResponseInput,
} from '/generated/graphql';

type Props = {
  style?: StyleProp<ViewStyle>;
  /** Default is `5` */
  questionsPerPage?: number;
  /** Used to scroll to invalid fields and control scrolling */
  scrollViewRef?: React.RefObject<ScrollView>;
  questions: SurveyQuestionsFragmentFragment['questions'];
  /** Optionally control the current page index */
  currentPageIndex?: number;
  /** Whether to show the back button. If onGoBack is passed, this will be ignored. */
  showBackButton?: boolean;
  onCurrentPageIndexChange?: (index: number) => void;
  onChangeProgress?: (progress: number) => void;
  onSubmit?: (data: SurveyResponseInput[]) => void;
  onGoBack?: () => void;
};

interface ISurveyQuestion
  extends Pick<
    SurveyQuestion,
    | 'id'
    | 'question'
    | 'required'
    | 'response_type'
    | 'response_choices'
    | 'response_choices_multiselect'
  > {}

const DEFAULT_QUESTIONS_PER_PAGE = 5;

function TakeSurveyComponent({
  onCurrentPageIndexChange,
  onChangeProgress,
  ...props
}: Props) {
  const [responses, setResponses] = useState<{
    [questionId: string]: SurveyResponseInput | undefined;
  }>({});

  /** Used to paginate when there are a lot of questions */
  const [currentPageIndex, _setCurrentPageIndex] = useState(0);

  const setCurrentPageIndex = useCallback(
    (index: number) => {
      _setCurrentPageIndex(index);
      onCurrentPageIndexChange?.(index);
    },
    [onCurrentPageIndexChange],
  );

  const { validateForm, fields } = useFormValidationContext(
    props.scrollViewRef,
  );

  function paginateQuestions(direction: 'next' | 'prev') {
    if (
      direction === 'next' &&
      currentPageIndex + 1 < numberOfPages &&
      validateForm()
    ) {
      setCurrentPageIndex(currentPageIndex + 1);
      props.scrollViewRef?.current?.scrollTo({ y: 0, animated: true });
    } else if (direction === 'prev' && currentPageIndex !== 0) {
      setCurrentPageIndex(currentPageIndex - 1);
      props.scrollViewRef?.current?.scrollTo({ y: 0, animated: true });
    }
  }

  function onSubmitSurvey() {
    if (!validateForm()) return;

    const responsesArray = Object.values(responses).filter(
      (r) => !!r,
    ) as SurveyResponseInput[];

    props.onSubmit?.(responsesArray);
  }

  const renderQuestionResponseInput = (question: ISurveyQuestion) => {
    const setResponse = (value: any) =>
      setResponses({
        ...responses,
        [question.id]: {
          questionId: question.id,
          response: value,
        },
      });

    switch (question.response_type) {
      case SurveyQuestionResponseType.Boolean: {
        return (
          <Switch
            value={responses[question.id]?.response === 'Yes'}
            onValueChange={(value) => {
              setResponse(value ? 'Yes' : 'No');
            }}
          />
        );
      }
      case SurveyQuestionResponseType.MultipleChoice: {
        // remove dupes
        const choices = question.response_choices?.reduce((acc, curr) => {
          if (acc.includes(curr)) return acc;
          return [...acc, curr];
        }, [] as string[]);
        const multiselect = question.response_choices_multiselect;

        return (
          <MultipleChoice
            multiselect={multiselect}
            value={
              multiselect
                ? responses[question.id]?.response.split(',') ?? []
                : responses[question.id]?.response || ''
            }
            onChange={(value: any) => {
              setResponse(multiselect ? value.join(',') : value);
            }}
            options={choices ?? []}
          />
        );
      }
      case SurveyQuestionResponseType.Number: {
        return (
          <TextInput
            value={responses[question.id]?.response}
            onChangeText={(value) => {
              /** Filter out non-numerical characters */
              setResponse(value.replace(/[^0-9]/gi, ''));
            }}
            maxLength={256}
            placeholderTextColor={'gray'}
            placeholder="Enter a number..."
            keyboardType="number-pad"
            style={TEXT_INPUT}
          />
        );
      }
      case SurveyQuestionResponseType.Plaintext:
      default: {
        return (
          <TextInput
            multiline
            maxLength={10000}
            placeholderTextColor={'gray'}
            placeholder="Enter text..."
            style={TEXT_INPUT_LARGE}
            value={responses[question.id]?.response}
            onChangeText={setResponse}
          />
        );
      }
    }
  };

  const questionsPerPage = useMemo(
    () => props.questionsPerPage ?? DEFAULT_QUESTIONS_PER_PAGE,
    [props.questionsPerPage],
  );

  const pageStart = currentPageIndex * questionsPerPage;
  const pageEnd = pageStart + questionsPerPage;
  const numberOfPages = useMemo(
    () => Math.ceil((props.questions.length ?? 0) / questionsPerPage),
    [props.questions.length, questionsPerPage],
  );

  const shouldOmitRequiredLabel = (props.questions.length ?? 0) <= 1;

  useEffect(() => {
    const progress = Math.ceil(((currentPageIndex + 1) / numberOfPages) * 100);
    onChangeProgress?.(progress);
  }, [currentPageIndex, numberOfPages, onChangeProgress]);

  return (
    <View style={props.style}>
      {props.questions.slice(pageStart, pageEnd).map((question) => (
        <ValidatedAny
          key={question.id}
          value={responses[question.id]?.response}
          name={`question-${question.id}`}
          required={question.required}
          containerStyle={[
            styles.questionContainer,
            {
              borderWidth: 1,
              borderColor:
                fields[`question-${question.id}`]?.valid !== false
                  ? 'transparent'
                  : 'crimson',
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text style={styles.questionText}>
              {question.question}
              {question.required && !shouldOmitRequiredLabel ? (
                <Text style={styles.required}>REQUIRED</Text>
              ) : null}
            </Text>
          </View>
          <View style={styles.inputContainer}>
            {renderQuestionResponseInput(question)}
          </View>
        </ValidatedAny>
      ))}
      <View
        style={{
          flexDirection: 'row-reverse',
        }}
      >
        <Button
          onPress={() =>
            currentPageIndex + 1 < numberOfPages
              ? paginateQuestions('next')
              : onSubmitSurvey()
          }
          label={currentPageIndex + 1 < numberOfPages ? 'Next' : 'Submit'}
          style={{
            backgroundColor: KEY_GREEN,
          }}
        />
        {props.showBackButton && !props.onGoBack && currentPageIndex !== 0 ? (
          <Button
            onPress={() => paginateQuestions('prev')}
            label="Back"
            containerStyle={{
              marginRight: 8,
            }}
          />
        ) : null}
      </View>
    </View>
  );
}

export default TakeSurveyComponent;

const styles = StyleSheet.create({
  questionContainer: {
    marginBottom: 10,
  },
  inputContainer: {
    paddingVertical: 6,
  },
  questionText: {
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    marginTop: 5,
  },
  required: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    textTransform: 'uppercase',
    color: 'crimson',
  },
});
