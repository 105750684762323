/**
 * getCDNVideoUri
 *
 * This utlity allows for easy creation of dynamic URI's to access videos via the CDN and choose from
 * video quality presets, or just fetch the original video from edge location
 */
import awsconfig from '/aws-exports';
import env from '/env';

type VideoQualityPreset =
  | 'hls'
  | '360p'
  | '480p'
  | '720p'
  | '1080p'
  | 'original';

const domainAndProtocolRegex =
  /^(https?:\/\/)?(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}/i;

// Get CloudFront endpoint
const ENDPOINT = env.CDN_ENDPOINT;

const ALLOWED_VIDEO_TYPES = new Set(['mp4', 'mov', 'avi', 'wmv', 'flv', 'qt']);

type VideoUri = string | undefined | null;

type GetCDNVideoUriOptions<T extends VideoUri> = {
  uri: T;
  preset: VideoQualityPreset;
};

export default function getCDNVideoUri<T extends VideoUri>({
  uri,
  preset,
}: GetCDNVideoUriOptions<T>) {
  // If `uri` is falsy, return undefined so we can use this function directly
  // in our `source` prop where we want to feed the resulting URI
  if (typeof uri !== 'string' || !uri?.trim()) return uri;

  const domainMatch = uri.match(domainAndProtocolRegex);
  const fileType = uri.substring(uri.lastIndexOf('.') + 1);

  const isAllowedVideo = ALLOWED_VIDEO_TYPES.has(fileType);

  // If the URI does not point to the current userassetsstorage bucket,
  // or it is an unsupported format, then do not do anything with this uri
  if (
    !domainMatch?.[0].includes(awsconfig.aws_user_files_s3_bucket) ||
    !isAllowedVideo
  )
    return uri;

  // Replace domain of URI with CloudFront CDN domain
  let newUri = uri.replace(domainAndProtocolRegex, ENDPOINT);

  if (!preset || preset === 'original') {
    // If no video preset provided, just return uri with domain replaced
    return newUri;
  }

  const spliceIndex = newUri.lastIndexOf('/');

  let result = newUri.slice(0, spliceIndex) + '/';

  result += `video_${preset}`;

  result += newUri.slice(result.endsWith('/') ? spliceIndex + 1 : spliceIndex);

  if (preset === 'hls') {
    // Replace add .m3u8 file extension to key
    result = `${result}.m3u8`;
  }

  // Return new uri with dimensions included
  return result;
}
