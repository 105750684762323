import {
  AntDesign,
  Feather,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RouteProp, useLinkTo } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, {
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Easing,
  FlatList,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
  ActivityIndicator,
} from 'react-native';
import Dots from 'react-native-dots-pagination';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DEVICE_SIZES } from 'rn-responsive-styles';
import TesterDashboardReadyToJumpInSplash from '../TesterOnlyMode/TesterDashboardReadyToJumpInSplash';
import PastFeedback from './components/PastFeedback';
import TesterObjectiveCard from './components/TesterObjectiveCard';
import useStyles, { DRAWER_WIDTH } from './TesterDashboard.style';
import Alert from '/Alert';
import AccountSettingsDrawer from '/components/AccountSettingsDrawer/AccountSettingsDrawer';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import WriteComment from '/components/Comments/WriteComment';
import GenericError from '/components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionText from '/components/common/Section/SectionText';
import GridList from '/components/GridList';
import ListLoading from '/components/ListLoading';
import withAuthRequired from '/components/withAuthRequired';
import {
  KEY_DARK_GREEN,
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
} from '/constants';
import { useAuthContext } from '/context';
import {
  OrganizationApplicationStatus,
  TesterStatus,
  useCreateTesterGeneralFeedbackMutation,
  useListMyTesterObjectivesQuery,
  UserRole,
  useSignupAsTesterMutation,
  useUpdateUserProfileMutation,
  useWithdrawAsTesterMutation,
} from '/generated/graphql';
import { useIsTesterOnlyMode } from '/hooks/useIsTesterOnlyMode';
import { isValidEmail, isValidJson, shortenEmail } from '/util';
import GenericListEmptyComponent from '/components/common/Generic/GenericListEmptyComponent';
import ActionSheetPressableCore from '/components/ActionSheet/ActionSheetPressableCore';
import AccountApprovalAlert from '/components/AccountApprovalAlert/AccountApprovalAlert';

// Standard drawer width
const ANIMATION_DURATION = 250; // Animation duration in ms

// This is the same distance from KEY_LIGHT_GRAY as KEY_LIGHT_GRAY is from white
// Useful for maintaining the same contrast when changing between `section` and `default` mode,
// which have background colors KEY_LIGHT_GRAY and white respectively.
const DARKER_KEY_LIGHT_GRAY = '#dadada';

type Props = {
  route: RouteProp<any>;
  navigation: StackNavigationProp<any>;
  /** If set to `section`, the back button and burger menu willbe hidden.
   * Padding will be reduced and scrolling will be disabled. Background color will be white. */
  mode?: 'default' | 'section';
};

function TesterDashboard(props: Props) {
  const { styles, deviceSize } = useStyles();
  const safeAreaInsets = useSafeAreaInsets();
  const linkTo = useLinkTo();
  const isTesterOnlyMode = useIsTesterOnlyMode();
  const { userData } = useAuthContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const slideAnim = React.useRef(new Animated.Value(1)).current;
  const scrollViewRef = React.useRef<any>();
  const pastFeedbackRef = React.useRef<View>(null);
  const [ready, setReady] = useState(false);

  const isExtraSmallDevice = deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE;
  const isApprovedTester =
    userData?.tester_status === TesterStatus.Approved || !!userData?.admin;
  const shouldDisplayChooseHowToParticipateText =
    !isApprovedTester ||
    (userData?.role === UserRole.Conservationist &&
      !userData?.interested_in_godwit);

  const [hasSeenReadyToJumpInSplash, setHasSeenReadyToJumpInSplash] =
    useState(true);
  const [currentObjectives, setCurrentObjectives] = useState(0);

  useEffect(() => {
    if (!isTesterOnlyMode) {
      setReady(true);
      return;
    }

    const checkIfHasSeenReadyToJumpInSplash = async () => {
      const userIds = await AsyncStorage.getItem(
        'has-seen-ready-to-jump-in-splash',
      );
      if (!userIds) {
        setHasSeenReadyToJumpInSplash(false);
        return;
      }
      const userIdsArray = isValidJson(userIds) ? JSON.parse(userIds) : [];
      setHasSeenReadyToJumpInSplash(userIdsArray.includes(userData?.id));
    };
    checkIfHasSeenReadyToJumpInSplash().finally(() => {
      setReady(true);
    });
  }, [isTesterOnlyMode, userData?.id]);

  function onReturnToPlatform() {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    } else {
      linkTo('/impact');
    }
  }

  function closeReadyToJumpInSplash() {
    setHasSeenReadyToJumpInSplash(true);
  }

  const openDrawer = () => {
    setIsDrawerOpen(true);
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: ANIMATION_DURATION,
      easing: Easing.inOut(Easing.poly(4)),
      useNativeDriver: true,
    }).start();
  };

  const closeDrawer = () => {
    Animated.timing(slideAnim, {
      toValue: 1,
      duration: ANIMATION_DURATION,
      easing: Easing.inOut(Easing.poly(4)),
      useNativeDriver: true,
    }).start(() => {
      setIsDrawerOpen(false);
    });
  };

  function onFocusPastFeedback() {
    if (pastFeedbackRef.current && scrollViewRef.current) {
      // Add a small delay to ensure layout is complete
      setTimeout(() => {
        pastFeedbackRef.current?.measureLayout(
          // @ts-ignore - getInnerViewNode exists but is not in types
          scrollViewRef.current?.getInnerViewNode(),
          (x, y) => {
            // Add some offset to account for header content
            const scrollOffset = Math.max(0, y);
            scrollViewRef.current?.scrollTo({
              y: scrollOffset,
              animated: true,
            });
          },
          () => {
            // Error callback - fallback to scrollToEnd if measurement fails
            console.warn(
              'Failed to measure layout, falling back to scrollToEnd',
            );
            scrollViewRef.current?.scrollToEnd({ animated: true });
          },
        );
      }, 100);

      props.navigation.setParams({
        focusObjectiveId: undefined,
      });
    }
  }

  const { Component: ContainerComponent, props: containerProps } =
    useMemo(() => {
      if (props.mode === 'section') {
        return {
          Component: View,
          props: {} as ComponentProps<typeof View>,
        };
      }
      return {
        Component: KeyboardAwareScrollView,
        props: {
          contentInset: safeAreaInsets,
          ref: (ref) => {
            if (ref) scrollViewRef.current = ref;
          },
          contentContainerStyle: styles('contentContainer'),
        } as ComponentProps<typeof KeyboardAwareScrollView>,
      };
    }, [props.mode, safeAreaInsets, styles]);

  return !ready ? null : (
    <>
      {hasSeenReadyToJumpInSplash || props.mode === 'section' ? null : (
        <TesterDashboardReadyToJumpInSplash
          onDismiss={closeReadyToJumpInSplash}
        />
      )}
      <View
        style={{
          flex: 1,
          height: '100%',
          backgroundColor: props.mode === 'section' ? 'white' : KEY_LIGHT_GRAY,
        }}
      >
        <ContainerComponent
          {...containerProps}
          style={[
            {
              flex: 1,
              height: '100%',
              overflow:
                Platform.OS === 'web' && props.mode !== 'section'
                  ? 'scroll'
                  : 'hidden',
            },
            props.mode === 'section'
              ? {
                  padding: 16,
                }
              : undefined,
          ]}
        >
          {props.mode !== 'section' ? (
            <HorizontalContainer
              style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: 12,
              }}
            >
              {isApprovedTester ? (
                <Pressable
                  onPress={onReturnToPlatform}
                  style={{
                    flexShrink: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 8,
                  }}
                >
                  <AntDesign name="arrowleft" size={24} color={KEY_GRAY} />
                  <Text style={styles('backToKeyPlatformText')}>
                    Back to My Impact tab
                  </Text>
                </Pressable>
              ) : null}

              {isExtraSmallDevice ? (
                <Pressable
                  onPress={openDrawer}
                  style={{
                    paddingLeft: 12,
                  }}
                >
                  <Feather size={40} name="menu" />
                </Pressable>
              ) : null}
            </HorizontalContainer>
          ) : null}
          <HorizontalContainer
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Text
              style={[
                styles('title'),
                {
                  flexShrink: 1,
                },
                props.mode === 'section'
                  ? {
                      fontSize: 24,
                    }
                  : undefined,
              ]}
            >
              Your Key {isTesterOnlyMode ? 'Migration' : 'Insider'} Dashboard
            </Text>

            {props.mode === 'section' && !isExtraSmallDevice ? (
              <FontAwesome5
                name="expand-alt"
                size={24}
                style={{
                  padding: 6,
                }}
                onPress={() => {
                  props.navigation.navigate('TesterDashboard');
                }}
                color="black"
              />
            ) : isExtraSmallDevice ? null : (
              <Pressable
                onPress={openDrawer}
                style={{
                  padding: 6,
                  paddingLeft: 20,
                }}
              >
                <Feather size={40} name="menu" />
              </Pressable>
            )}
          </HorizontalContainer>

          <HorizontalContainer
            style={{
              marginVertical: 12,
            }}
          >
            <Avatar
              source={{ uri: userData?.profile_image }}
              rounded
              size={deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? 48 : 56}
              containerStyle={{ marginRight: 12 }}
            />
            <Text style={styles('welcomeText')}>
              Welcome, {userData?.name ?? 'insider'}!
            </Text>
          </HorizontalContainer>

          {shouldDisplayChooseHowToParticipateText ? (
            <SectionText
              style={[
                styles('sectionText'),
                {
                  marginTop: 8,
                },
                props.mode !== 'section'
                  ? {
                      fontSize: 22,
                    }
                  : undefined,
              ]}
            >
              {userData?.role === UserRole.Conservationist
                ? 'Stay involved during our migration into Phase 2 with either (or both!) of the opportunities below:'
                : "Stay involved during our migration into Phase 2 and choose how you'd like to participate below:"}
            </SectionText>
          ) : null}

          <GodwitSignup mode={props.mode ?? 'default'} />

          <TesterSignup
            mode={props.mode ?? 'default'}
            currentObjectives={currentObjectives}
          />

          <TesterObjectives
            mode={props.mode ?? 'default'}
            onChangeCurrentObjectives={setCurrentObjectives}
          />

          <GeneralFeedbackWidget />

          <RelaunchNotificationSignup />

          {userData?.tester_status === TesterStatus.Approved ||
          userData?.admin ? (
            <>
              <Text style={styles('smallHeading')}>Contributions</Text>
              <PastFeedback
                ref={pastFeedbackRef}
                mode={props.mode ?? 'default'}
                onFocusSection={onFocusPastFeedback}
                initiallyFocusedObjectiveId={
                  props.route.params?.focusObjectiveId
                }
              />
            </>
          ) : null}
        </ContainerComponent>
      </View>

      {/* Drawer Overlay */}
      {isDrawerOpen && (
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: 'rgba(0,0,0,0.5)',
              opacity: slideAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0],
              }),
            },
          ]}
        >
          <Pressable style={StyleSheet.absoluteFill} onPress={closeDrawer} />
        </Animated.View>
      )}

      {/* Animated Drawer */}
      {props.mode !== 'section' ? (
        <Animated.View
          style={[
            styles('drawer'),
            {
              transform: [
                {
                  translateX: slideAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, DRAWER_WIDTH],
                  }),
                },
              ],
            },
          ]}
        >
          {isDrawerOpen && (
            <AccountSettingsDrawer
              isTesterDashboard
              onCloseDrawer={closeDrawer}
            />
          )}
        </Animated.View>
      ) : null}
    </>
  );
}

function GodwitSignup(props: { mode: 'section' | 'default' }) {
  const { userData } = useAuthContext();
  const { styles } = useStyles();

  const [signingUpForGodwit, setSigningUpForGodwit] = useState(false);
  const [, updateUser] = useUpdateUserProfileMutation();

  function onSignUpForGodwit() {
    setSigningUpForGodwit(true);
    updateUser({
      input: {
        interested_in_godwit: true,
      },
    })
      .then(({ error }) => {
        if (error) {
          Alert.alert(
            'Failed to sign up',
            'There was a problem signing up for Godwit. Please try again later.',
          );
          return;
        }

        Alert.notify({
          message: 'Signed up successfully!',
          color: KEY_GREEN,
        });
      })
      .finally(() => {
        setSigningUpForGodwit(false);
      });
  }

  return userData?.role === UserRole.Conservationist ? (
    <HorizontalContainer style={styles('signupHorizontalContainer')}>
      <View style={Platform.OS !== 'android' && { flex: 1, marginRight: 32 }}>
        <Text style={styles('smallHeading')}>
          The Next Era of Conservation Funding
        </Text>

        <SectionText
          style={[
            styles('sectionText'),
            {
              marginTop: 12,
              maxWidth: 1024,
            },
          ]}
        >
          Coming soon in Spring 2025, we will be launching Godwit Key Company, a
          groundbreaking platform connecting conservation organizations to
          larger corporate funding opportunities to sustain your work,
          strengthen your team, and take your impact to the next level. Sign up
          now to express your interest in being part of the first cohort of
          organizations to onboard and gain exclusive access. Registering below
          ensures you'll be the first to learn more as we roll out this
          transformative opportunity.
        </SectionText>

        <HorizontalContainer
          style={{
            marginRight: 32,
            marginBottom: 8,
            alignSelf: 'flex-start',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <HorizontalContainer
            style={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              marginRight: 20,
            }}
          >
            <Image
              source={require('/assets/images/nsf-logo.png')}
              resizeMode="contain"
              style={{
                marginHorizontal: 8,
                width: 60,
                height: 60,
                marginTop: 16,
                marginBottom: 24,
              }}
            />
            <Image
              source={require('/assets/images/keyFullBlack.png')}
              resizeMode="contain"
              style={{
                marginHorizontal: 8,
                marginTop: 16,
                width: 50,
                height: 50,
                marginBottom: 29,
              }}
            />
            <Image
              source={require('/assets/images/godwit-logo.png')}
              resizeMode="contain"
              style={{
                marginHorizontal: 0,
                width: 86,
                height: 86,
              }}
            />
          </HorizontalContainer>

          {userData?.interested_in_godwit ? (
            <SectionText
              style={[
                styles('sectionText'),
                {
                  marginTop: 8,
                  fontFamily: 'Lato-Bold',
                  maxWidth: 680,
                },
              ]}
            >
              Thank you for registering your interest in learning more about
              Godwit Key Company. We will be contacting organizations in early
              Spring 2025 with further information. Please make sure to keep an
              eye out for incoming emails as we get closer to this time.
            </SectionText>
          ) : (
            <Button
              loading={signingUpForGodwit}
              disabled={signingUpForGodwit}
              onPress={() => {
                onSignUpForGodwit();
              }}
              containerStyle={[
                styles('signupButtonContainer'),
                {
                  alignSelf: 'center',
                  marginBottom: 16,
                },
              ]}
              label={'Register Your Interest'}
              style={[
                styles('signupButton'),
                {
                  backgroundColor: userData?.interested_in_godwit
                    ? props.mode === 'section'
                      ? KEY_LIGHT_GRAY
                      : DARKER_KEY_LIGHT_GRAY
                    : KEY_GREEN,
                },
              ]}
              labelStyle={{
                paddingHorizontal: 20,
                paddingVertical: 4,
              }}
            />
          )}
        </HorizontalContainer>
      </View>
    </HorizontalContainer>
  ) : null;
}

function TesterSignup(props: {
  mode: 'section' | 'default';
  currentObjectives: number;
}) {
  const { styles, deviceSize } = useStyles();
  const { userData } = useAuthContext();

  const isApprovedTester =
    userData?.tester_status === TesterStatus.Approved || !!userData?.admin;

  const isExtraSmallDevice = deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE;

  const shouldHideBigButton = props.mode === 'section';
  const shouldWrapButtons = isExtraSmallDevice && !shouldHideBigButton;

  return isApprovedTester ? (
    <HorizontalContainer
      style={{
        flexDirection: shouldWrapButtons ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: shouldWrapButtons ? 'flex-start' : 'center',
        marginTop: 12,
      }}
    >
      <SectionText
        style={[
          styles('sectionText'),
          {
            maxWidth: 1024,
            marginRight: 16,
            marginBottom: shouldWrapButtons ? 16 : 0,
          },
        ]}
      >
        Thank you for being a tester! You can opt out any time to no longer
        receive testing opportunity notifications.
      </SectionText>

      <TesterSignupButtons shouldHideBigButton={shouldHideBigButton} />
    </HorizontalContainer>
  ) : (
    <View>
      <View
        style={
          Platform.OS !== 'android' && {
            flex: 1,
          }
        }
      >
        <Text
          style={[
            styles('smallHeading'),
            {
              marginTop: 32,
              marginRight: 32,
            },
          ]}
        >
          Join us in crafting an effective and intuitive experience as we
          overhaul the Key Conservation platform!
        </Text>

        <SectionText
          style={[
            styles('sectionText'),
            {
              marginTop: 12,
              maxWidth: 1024,
            },
          ]}
        >
          When you become a tester, you will gain access to the platform and
          will receive weekly notifications of testing opportunities for new and
          upgraded features. You can opt-out at any time.
        </SectionText>
      </View>

      {userData?.role === UserRole.Conservationist &&
      userData?.application?.status !==
        OrganizationApplicationStatus.Approved ? (
        <AccountApprovalAlert
          style={{
            borderRadius: 6,
          }}
          overrideLabels={{
            submitted:
              'Your access to the platform is limited while we review your account and verify your documentation. You can become a tester once your account is approved.',
          }}
        />
      ) : null}

      <TesterSignupButtons shouldHideBigButton={false} />
    </View>
  );
}

function TesterObjectives({
  onChangeCurrentObjectives,
  ...props
}: {
  mode: 'section' | 'default';
  onChangeCurrentObjectives: (currentObjectives: number) => void;
}) {
  const { userData } = useAuthContext();
  const { styles, deviceSize } = useStyles();

  const isTester =
    !!userData?.admin || userData?.tester_status === TesterStatus.Approved;
  const [activeObjectiveIndex, setActiveObjectiveIndex] = useState(0);
  const [flatListWidth, setFlatListWidth] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 50,
    minimumViewTime: 0,
  });

  const onViewableItemsChanged = useRef(
    ({
      viewableItems,
    }: {
      viewableItems: Array<{
        key?: string;
        isViewable: boolean;
        item: any;
        index: number | null;
        section?: any;
      }>;
    }) => {
      if (!viewableItems.length) return;

      // Use the scroll ratio to bias which viewable item should be focused
      const biasedIndex = Math.min(
        Math.floor(scrollProgress * viewableItems.length),
        viewableItems.length - 1,
      );

      const focusedItem = viewableItems[biasedIndex];
      if (focusedItem && focusedItem.index !== null) {
        setActiveObjectiveIndex(focusedItem.index);
      }
    },
  );

  const [nextToken, setNextToken] = useState<string | null>(null);

  const [{ data, fetching }, refetch] = useListMyTesterObjectivesQuery({
    pause: !isTester,
    variables: {
      nextToken,
      limit: 8,
    },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    onChangeCurrentObjectives(
      (data?.listMyTesterObjectives.total ?? 0) -
        (data?.listMyTesterObjectives.totalComplete ?? 0),
    );
  }, [
    data?.listMyTesterObjectives.total,
    data?.listMyTesterObjectives.totalComplete,
    props,
    onChangeCurrentObjectives,
  ]);

  const renderData = useMemo(() => {
    if (!data?.listMyTesterObjectives.items) return [];

    const list: Array<
      | NonNullable<(typeof data.listMyTesterObjectives.items)[number]>
      | 'load-more'
    > = [...data.listMyTesterObjectives.items];

    if (data.listMyTesterObjectives.nextToken) {
      list.push('load-more' as const);
    }

    return list;
  }, [data]);

  return !isTester ? null : !data ? (
    fetching ? (
      <ListLoading />
    ) : (
      <GenericError
        message="There was a problem fetching test objectives"
        onRetry={refetch}
      />
    )
  ) : (
    <View style={{ flex: 1 }}>
      <Text
        style={[
          styles('smallHeading'),
          {
            marginRight: 32,
            marginTop: 32,
            marginBottom: 32,
            flexShrink: 1,
          },
        ]}
      >
        Current Testing Objectives:{' '}
        {data.listMyTesterObjectives.total -
          data.listMyTesterObjectives.totalComplete}
      </Text>

      {data.listMyTesterObjectives.items?.length === 0 ? (
        <GenericListEmptyComponent
          placeholderText="There are no objectives to test right now. Check back soon!"
          style={{
            marginTop: 32,
            backgroundColor:
              props.mode === 'section' ? 'white' : KEY_LIGHT_GRAY,
          }}
        />
      ) : props.mode === 'section' ||
        deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? (
        <>
          <FlatList
            horizontal
            onLayout={({ nativeEvent }) => {
              setFlatListWidth(nativeEvent.layout.width);
            }}
            onScroll={({ nativeEvent }) => {
              setScrollProgress(
                nativeEvent.contentOffset.x / nativeEvent.contentSize.width,
              );
            }}
            viewabilityConfig={viewabilityConfig.current}
            onViewableItemsChanged={onViewableItemsChanged.current}
            data={renderData}
            contentContainerStyle={{
              paddingVertical: 8,
            }}
            renderItem={({ item }) => {
              if (item === 'load-more') {
                return (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      label="Load More"
                      loading={fetching}
                      containerStyle={{
                        shadowOpacity: 0,
                        borderWidth: 2,
                        margin: 12,
                        marginHorizontal: 24,
                      }}
                      style={{
                        backgroundColor: KEY_LIGHT_GRAY,
                      }}
                      onPress={() => {
                        if (data.listMyTesterObjectives.nextToken) {
                          setNextToken(data.listMyTesterObjectives.nextToken);
                        }
                      }}
                    />
                  </View>
                );
              }

              return (
                <View>
                  <TesterObjectiveCard
                    objective={item}
                    style={{
                      marginRight: 10,
                      minWidth: 280,
                      maxWidth: 540,
                      width: flatListWidth - 24,
                    }}
                    loading={fetching}
                    refreshObjectives={() => {
                      refetch({
                        requestPolicy: 'network-only',
                      });
                    }}
                  />
                </View>
              );
            }}
          />
          <Dots
            alignDotsOnXAxis
            activeBorder={false}
            activeDotHeight={12}
            passiveDotWidth={12}
            passiveDotHeight={12}
            activeDotWidth={12}
            active={activeObjectiveIndex}
            length={renderData.length}
            activeColor={KEY_DARK_GREEN}
            passiveColor={'gray'}
          />
        </>
      ) : (
        <GridList
          style={{
            marginTop: 32,
          }}
          maxTileWidth={540}
          data={renderData}
          renderItem={({ item }) => {
            if (item === 'load-more') {
              return (
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    label="Load More"
                    loading={fetching}
                    containerStyle={{
                      shadowOpacity: 0,
                      borderWidth: 2,
                      margin: 12,
                    }}
                    style={{
                      backgroundColor: KEY_LIGHT_GRAY,
                    }}
                    onPress={() => {
                      if (data.listMyTesterObjectives.nextToken) {
                        setNextToken(data.listMyTesterObjectives.nextToken);
                      }
                    }}
                  />
                </View>
              );
            }

            return (
              <TesterObjectiveCard
                objective={item}
                loading={fetching}
                refreshObjectives={() => {
                  refetch({
                    requestPolicy: 'network-only',
                  });
                }}
              />
            );
          }}
        />
      )}
    </View>
  );
}

function TesterSignupButtons(props: { shouldHideBigButton?: boolean }) {
  const linkTo = useLinkTo();
  const { userData } = useAuthContext();
  const { styles } = useStyles();
  const [{ fetching: signingUp }, signUpAsTester] = useSignupAsTesterMutation();
  const [{ fetching: withdrawing }, withdrawAsTester] =
    useWithdrawAsTesterMutation();

  const canSignupAsTester =
    userData?.role !== UserRole.Conservationist ||
    userData?.application?.status === OrganizationApplicationStatus.Approved;
  const isApprovedTester =
    !!userData?.admin || userData?.tester_status === TesterStatus.Approved;

  return (
    <HorizontalContainer
      style={{
        marginTop: 16,
      }}
    >
      {!props.shouldHideBigButton || !isApprovedTester ? (
        <Button
          loading={signingUp}
          disabled={
            signingUp ||
            withdrawing ||
            userData?.tester_status === TesterStatus.Revoked ||
            !canSignupAsTester
          }
          onPress={() => {
            if (userData?.tester_status === TesterStatus.Revoked) return;

            if (userData?.tester_status === TesterStatus.None) {
              if (!canSignupAsTester) return;
              signUpAsTester({}).then(({ error, data }) => {
                if (error) {
                  console.error(error);
                  Alert.alert(
                    'Error',
                    'There was a problem signing up. Please try again later.',
                  );
                  return;
                }

                if (
                  data?.signupAsTester.tester_status === TesterStatus.Approved
                ) {
                  Alert.notify({
                    message: 'You have been added as a tester!',
                    color: KEY_GREEN,
                  });
                }

                if (
                  data?.signupAsTester.tester_status === TesterStatus.Interested
                ) {
                  Alert.notify({
                    message: "Signed up. You will be notified once you're in!",
                    color: KEY_GREEN,
                  });
                }

                setTimeout(() => {
                  linkTo('/tester-dashboard');
                }, 80);
              });
            } else {
              linkTo('/');
            }
          }}
          label={
            userData?.tester_status === TesterStatus.None
              ? 'Become a Tester'
              : userData?.tester_status === TesterStatus.Interested
              ? 'Pending Approval'
              : userData?.tester_status === TesterStatus.Revoked
              ? 'Revoked'
              : 'Explore the Platform'
          }
          containerStyle={[
            styles('signupButtonContainer'),
            {
              marginTop: 0,
            },
          ]}
          style={[
            styles('signupButton'),
            {
              backgroundColor:
                userData?.tester_status !== TesterStatus.Approved ||
                !canSignupAsTester
                  ? DARKER_KEY_LIGHT_GRAY
                  : KEY_GREEN,
              pointerEvents:
                userData?.tester_status === TesterStatus.Revoked
                  ? 'none'
                  : 'auto',
            },
          ]}
          labelStyle={{
            paddingHorizontal: 20,
            paddingVertical: 4,
          }}
        />
      ) : null}
      {isApprovedTester ? (
        <ActionSheetPressableCore
          disabled={withdrawing}
          options={{
            title: 'Actions',
            options: ['Opt out', 'Cancel'],
            cancelButtonIndex: 1,
            destructiveButtonIndex: 0,
            onPress: (index: number | undefined) => {
              if (index === 0) {
                if (userData?.admin) {
                  Alert.alert(
                    'Error',
                    'Admins cannot opt out of being a tester.',
                  );
                  return;
                }

                Alert.alert(
                  'Opt out',
                  'Are you sure you want to opt out of being a tester? You can always sign up again later.',
                  [
                    {
                      text: 'Cancel',
                      style: 'cancel',
                    },
                    {
                      text: 'Opt out',
                      style: 'destructive',
                      onPress: () => {
                        withdrawAsTester({}).then(({ error }) => {
                          if (error) {
                            Alert.alert(
                              'Error',
                              'There was a problem opting out. Please try again later.',
                            );
                            return;
                          }
                          Alert.notify({
                            message: 'Successfully opted out.',
                            color: KEY_GREEN,
                          });
                        });
                      },
                    },
                  ],
                );
              }
            },
          }}
          style={({ pressed }: { pressed: boolean }) => ({
            padding: 8,
            opacity: pressed ? 0.7 : 1,
            width: 40,
            height: 40,
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          {withdrawing ? (
            <ActivityIndicator color="black" />
          ) : (
            <Feather name="more-vertical" size={24} color="black" />
          )}
        </ActionSheetPressableCore>
      ) : null}
    </HorizontalContainer>
  );
}

function GeneralFeedbackWidget() {
  const { styles } = useStyles();

  const [media, setMedia] = useState<string[]>([]);
  const [body, _setBody] = useState('');
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const setBody = (newBody: string) => {
    _setBody(newBody);
  };

  const { userData } = useAuthContext();

  const [{ fetching: submitting }, createTesterGeneralFeedback] =
    useCreateTesterGeneralFeedbackMutation();

  async function onSubmit() {
    if (isUploadingMedia) {
      Alert.alert(
        'Media Uploading',
        'Please wait for media to finish uploading',
      );
      return false;
    }

    const { error } = await createTesterGeneralFeedback({
      input: {
        body,
        media,
      },
    });

    if (error) {
      Alert.alert('Error', 'There was a problem submitting your feedback');
      return false;
    }

    Alert.notify({
      message: 'Feedback submitted!',
      color: KEY_GREEN,
    });

    setBody('');
    setMedia([]);

    return true;
  }

  return (
    <View>
      <Text style={styles('smallHeading')}>Share Your Ideas</Text>

      <SectionText style={styles('sectionText')}>
        Have some suggestions or feedback on ways we can make the platform even
        better? We want to hear it. Send us a direct message here:
      </SectionText>

      <WriteComment
        enableMediaUpload
        media={media}
        onUploadStart={() => setIsUploadingMedia(true)}
        onUploadEnd={() => setIsUploadingMedia(false)}
        onChangeMedia={setMedia}
        value={body}
        style={{ marginTop: 8 }}
        textInputContainerStyle={{
          borderWidth: 2,
          borderRadius: 7,
          paddingVertical: 2,
        }}
        maxDynamicHeight={500}
        hideSubmitButton
        placeholderText="Write a message..."
        maxLength={5000}
        avatarUri={userData?.profile_image ?? undefined}
        buttonBarPlacement="right"
        onChangeText={setBody}
        mediaUploadIcon={
          <MaterialCommunityIcons name="paperclip" size={22} color="black" />
        }
        enableMentions={false}
        onSubmitComment={onSubmit}
      />

      <Button
        label="Submit"
        disabled={!body.trim() || isUploadingMedia}
        loading={submitting}
        onPress={onSubmit}
        containerStyle={styles('greenButtonContainer')}
        style={[
          styles('greenButton'),
          {
            backgroundColor:
              !body.trim() || isUploadingMedia ? KEY_LIGHT_GRAY : KEY_GREEN,
          },
        ]}
      />
    </View>
  );
}

function RelaunchNotificationSignup() {
  const { styles } = useStyles();
  const { userData } = useAuthContext();

  const [notifyRelaunchEmail, setNotifyRelaunchEmail] = useState('');
  const [
    signingUpForRelaunchNotification,
    setSigningUpForRelaunchNotification,
  ] = useState(false);
  const [, updateUser] = useUpdateUserProfileMutation();

  function onSignUpForRelaunchNotification() {
    if (!isValidEmail(notifyRelaunchEmail)) {
      Alert.alert(
        'Invalid email',
        'Please ensure you have entered a valid email address',
      );
      return;
    }

    setSigningUpForRelaunchNotification(true);
    updateUser({
      input: {
        notify_relaunch_email: notifyRelaunchEmail,
      },
    })
      .then(({ error }) => {
        if (error) {
          Alert.alert(
            'Error',
            'There was a problem signing up for relaunch notifications. Please try again or report this issue if it persists.',
          );
          return;
        }

        Alert.alert(
          'Signed Up!',
          `You will be notified at ${shortenEmail(
            notifyRelaunchEmail,
            42,
          )} when we relaunch in the spring.`,
        );
      })
      .finally(() => {
        setSigningUpForRelaunchNotification(false);
      });
  }

  function onUnsubscribeRelaunchNotification() {
    Alert.alert(
      'Unsubscribe',
      'Are you sure you no longer want to be notified when we relaunch in the spring?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Unsubscribe',
          style: 'destructive',
          onPress: () => {
            setSigningUpForRelaunchNotification(true);
            updateUser({
              input: {
                notify_relaunch_email: null,
              },
            }).finally(() => {
              setSigningUpForRelaunchNotification(false);
            });
          },
        },
      ],
    );
  }

  return userData && !userData?.notify_relaunch_email ? (
    <View style={{ width: '100%', minWidth: 0 }}>
      <Text style={styles('smallHeading')}>Stay in the Loop</Text>

      <SectionText style={styles('sectionText')}>
        Sign up to be notified when we relaunch in the spring.
      </SectionText>

      <View
        style={{
          marginTop: 12,
          width: '100%',
          minWidth: 0,
        }}
      >
        {userData?.notify_relaunch_email ? (
          <HorizontalContainer
            style={{
              alignItems: 'flex-start',
              width: '100%',
              minWidth: 0,
            }}
          >
            <Ionicons
              name="checkmark-circle"
              size={18}
              style={{
                marginTop: 3,
                marginRight: 3,
                flexShrink: 0,
              }}
              color={KEY_DARK_GREEN}
            />
            <View
              style={{
                flex: 1,
                minWidth: 0,
              }}
            >
              <SectionText
                style={{
                  fontFamily: 'Lato-Bold',
                  color: KEY_GRAY,
                }}
              >
                You signed up to be notified at{' '}
                {userData?.notify_relaunch_email}.{' '}
                <Button
                  label="Unsubscribe"
                  loading={signingUpForRelaunchNotification}
                  onPress={onUnsubscribeRelaunchNotification}
                  style={{
                    backgroundColor: 'transparent',
                    padding: 0,
                  }}
                  containerStyle={{
                    backgroundColor: 'transparent',
                    shadowOpacity: 0,
                  }}
                  labelStyle={{
                    color: KEY_DARK_GREEN,
                  }}
                />
              </SectionText>
            </View>
          </HorizontalContainer>
        ) : (
          <>
            <TextInput
              style={styles('textInput')}
              editable={!signingUpForRelaunchNotification}
              placeholder="Enter your email"
              onChangeText={setNotifyRelaunchEmail}
              onSubmitEditing={onSignUpForRelaunchNotification}
              defaultValue={userData?.notify_relaunch_email ?? ''}
            />

            <Button
              label="Sign Up"
              disabled={!notifyRelaunchEmail.trim()}
              loading={signingUpForRelaunchNotification}
              onPress={onSignUpForRelaunchNotification}
              containerStyle={styles('greenButtonContainer')}
              style={[
                styles('greenButton'),
                {
                  backgroundColor:
                    !notifyRelaunchEmail.trim() ||
                    signingUpForRelaunchNotification
                      ? KEY_LIGHT_GRAY
                      : KEY_GREEN,
                },
              ]}
            />
          </>
        )}
      </View>
    </View>
  ) : null;
}

export default withAuthRequired(TesterDashboard);
