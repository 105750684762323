import React, { useState } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native';
import RichTextCore from './RichTextCore';

interface RichTextProps {
  /**
   * Initial content to populate the editor with.
   * Can be a plain text string or a valid Lexical JSON string.
   */
  content: string;
  dom?: import('expo/dom').DOMProps;
}

export default function RichText({ content, dom }: RichTextProps) {
  const [isLoading, setIsLoading] = useState(Platform.OS !== 'web');

  return (
    <View>
      {isLoading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator />
        </View>
      )}
      <RichTextCore
        content={content}
        dom={{
          matchContents: true,
          scrollEnabled: false,
          startInLoadingState: false,
          javaScriptEnabled: true,
          domStorageEnabled: true,
          onLoadEnd: () => setIsLoading(false),
          style: { backgroundColor: 'transparent', opacity: isLoading ? 0 : 1 },
          ...dom,
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
  },
});
