import { Badge, ListItem } from '@rneui/themed';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import styles from './Accordion.style';
import { KEY_GRAY } from '/constants';
import { StyleProp } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const ACCORDION_ANIMATION_OPTIONS = {
  // type: 'timing',
  duration: 180,
};

interface IAccordionProps {
  title: string | JSX.Element;
  titleStyle?: StyleProp<TextStyle>;
  subtitle?: string | JSX.Element;
  subtitleStyle?: StyleProp<TextStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  icon?: React.ReactElement;
  badgeValue?: number;
  disabled?: boolean;
  expanded?: boolean;
  loading?: boolean;
}

export default function Accordion({
  title,
  titleStyle,
  subtitle,
  subtitleStyle,
  headerStyle,
  contentContainerStyle,
  icon,
  badgeValue,
  children,
  disabled = false,
  expanded: _expanded,
  loading,
}: React.PropsWithChildren<IAccordionProps>) {
  const [expanded, setExpanded] = useState(
    typeof _expanded === 'boolean' ? _expanded : false,
  );

  // If the expanded prop is updated,
  // we assume the desired behavior is to actually
  // update our internal state.
  useEffect(() => {
    if (typeof _expanded === 'boolean') setExpanded(_expanded);
  }, [_expanded]);

  return (
    <ListItem.Accordion
      icon={<MaterialCommunityIcons name="chevron-down" size={24} />}
      animation={ACCORDION_ANIMATION_OPTIONS}
      isExpanded={expanded}
      onPress={() => {
        setExpanded(!expanded);
      }}
      style={[
        styles.accordion,
        {
          opacity: disabled ? 0.4 : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        },
        headerStyle,
      ]}
      containerStyle={styles.accordionContentContainer}
      content={
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {icon}
          <ListItem.Content>
            <ListItem.Title
              style={[
                styles.accordionTitle,
                titleStyle,
                {
                  marginLeft: icon ? 8 : 0,
                },
              ]}
            >
              {title}
            </ListItem.Title>
            {subtitle ? (
              <ListItem.Subtitle
                style={[styles.accordionSubtitle, subtitleStyle]}
              >
                {subtitle}
              </ListItem.Subtitle>
            ) : null}
          </ListItem.Content>
          {loading ? (
            <ActivityIndicator
              style={styles.activityIndicator}
              color={KEY_GRAY}
            />
          ) : (
            badgeValue !== undefined && (
              <Badge
                badgeStyle={{
                  backgroundColor: '#CAFF00',
                  height: 24,
                  minWidth: 24,
                  borderRadius: 12,
                }}
                textStyle={{
                  color: '#000',
                  fontFamily: 'Lato-Bold',
                }}
                value={badgeValue}
              />
            )
          )}
        </View>
      }
    >
      {/* The conditional here is a work-around for a bug in react-native-elements,
       * causing scrollview to be too long
       * https://github.com/react-native-elements/react-native-elements/issues/3200
       */}
      {expanded && (
        <ListItem
          containerStyle={[
            styles.accordionInnerContentContainer,
            contentContainerStyle,
          ]}
        >
          <ListItem.Content
            style={{
              /** Seems to fix issues with this component not taking full height, but would break things on native. */
              height: Platform.OS === 'web' ? '100%' : undefined,
            }}
          >
            {children}
          </ListItem.Content>
        </ListItem>
      )}
    </ListItem.Accordion>
  );
}
