import React from 'react';
import { ViewportRootProvider } from './ViewportRootContext';
import { FlashList } from '@shopify/flash-list';
import { Animated, FlatList } from 'react-native';
import Reanimated from 'react-native-reanimated';
import { ScrollView } from 'react-native';
import { ViewportTrackerExports } from './ViewportTracker';

function withViewportTracking<T extends React.ComponentType<any>>(
  WrappedComponent: T,
): T {
  return React.forwardRef((props: any, ref) => (
    <ViewportRootProvider>
      <WrappedComponent {...props} ref={ref} />
    </ViewportRootProvider>
  )) as unknown as T;
}

const ViewportTrackingScrollView = withViewportTracking(ScrollView);

const ViewportTrackingFlatList = withViewportTracking(FlatList);
const ViewportTrackingFlashList = withViewportTracking(FlashList);
const ViewportTrackingAnimatedFlatList = withViewportTracking(
  Animated.FlatList,
);
const ViewportTrackingAnimatedScrollView = withViewportTracking(
  Animated.ScrollView,
);
const ViewportTrackingReanimatedScrollView = withViewportTracking(
  Reanimated.ScrollView,
);
const ViewportTrackingReanimatedFlatList = withViewportTracking(
  Reanimated.FlatList,
);

const AnimatedFlashList = Animated.createAnimatedComponent(FlashList);
const ReanimatedFlashList = Reanimated.createAnimatedComponent(FlashList);

const ViewportTrackingAnimatedFlashList =
  withViewportTracking(AnimatedFlashList);
const ViewportTrackingReanimatedFlashList =
  withViewportTracking(ReanimatedFlashList);

export default {
  ScrollView: ViewportTrackingScrollView,
  KeyboardAwareScrollView: ViewportTrackingScrollView as any,
  FlatList: ViewportTrackingFlatList,
  FlashList: ViewportTrackingFlashList,
  AnimatedFlatList: ViewportTrackingAnimatedFlatList,
  AnimatedScrollView: ViewportTrackingAnimatedScrollView,
  ReanimatedFlatList: ViewportTrackingReanimatedFlatList,
  ReanimatedScrollView: ViewportTrackingReanimatedScrollView,
  AnimatedFlashList: ViewportTrackingAnimatedFlashList,
  ReanimatedFlashList: ViewportTrackingReanimatedFlashList,
} satisfies ViewportTrackerExports;
