import React, { useEffect, useRef, useContext } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { ViewportRootContext } from './ViewportRootContext';

export interface ViewportAwareViewProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  threshold?: number;
  onViewportEnter?: () => void;
  onViewportLeave?: () => void;
  testID?: string;
}

export default function ViewportAwareView({
  children,
  style,
  threshold = 0,
  onViewportEnter,
  onViewportLeave,
  testID,
}: ViewportAwareViewProps) {
  const ref = useRef<HTMLDivElement>(null);
  const root = useContext(ViewportRootContext);
  const isInViewportRef = useRef<boolean>(false);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const wasInViewport = isInViewportRef.current;
          const isInViewport = entry.isIntersecting;

          // Only update state and trigger callbacks if there's an actual change
          if (wasInViewport !== isInViewport) {
            isInViewportRef.current = isInViewport;

            if (isInViewport) {
              onViewportEnter?.();
            } else {
              onViewportLeave?.();
            }
          }
        });
      },
      {
        root: root?.current || null,
        threshold,
      },
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [onViewportEnter, onViewportLeave, threshold, root]);

  return (
    <div ref={ref} style={style as any} data-testid={testID}>
      {children}
    </div>
  );
}
