import { FontAwesome } from '@expo/vector-icons';
import { ResizeMode } from 'expo-av';
import React, { useCallback, useRef, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
  ViewToken,
} from 'react-native';
import Button from '../Button';
import ProgressiveImage from '../ProgressiveImage';
import UploadMedia from '../UploadMedia/UploadMedia';
import VideoPlayer from '../VideoPlayer';
import PlusSignCircle from '/assets/jsicons/PlusSignCircle';
import { KEY_GRAY, KEY_GREEN, TEXT_INPUT } from '/constants';
import { CarouselCard } from '/generated/graphql';
import { determineIfVideo } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

export interface CarouselCardData extends CarouselCard {
  title?: string | (() => JSX.Element) | undefined;
  footerComponent?: (() => JSX.Element | null) | JSX.Element | null;
}

type CarouselEditMode = 'add-remove-only' | 'all';

interface Props {
  darkTheme?: boolean;
  // Default is `280`
  itemWidth?: number;
  // Default is `350`
  itemHeight?: number;
  data: CarouselCardData[] | undefined | null;
  /** Default is `all` */
  editMode?: CarouselEditMode;
  /** Style for root container of each carousel item */
  carouselItemContainerStyle?: StyleProp<ViewStyle>;
  /** Sets text to show user on the add button */
  addCardPromptText?: string;
  /** When set, Carousel will allow user to edit contents */
  isEditing?: boolean;
  captionStyle?: StyleProp<TextStyle>;
  titleStyle?: StyleProp<TextStyle>;
  /** Changes placeholder text for caption input. If unset, placeholder will be non-specific */
  captionRequired?: boolean;
  /** If set, Carousel will render a loading indicator after the last element in the Carousel */
  isFetchingMore?: boolean;
  imageResizeMode?: ResizeMode;
  mediaStyle?: StyleProp<ViewStyle>;
  /** Called when user makes changes to carousel
   * @param {CarouselCard[]} data Updated carousel
   * @param {number} removedIndex If a card was removed, this will be the index of that card
   */
  onChange?: (data: CarouselCard[], removedIndex?: number) => void; // onChange data type is CarouselCard, not CarouselCardData
  carouselItemOnPress?: (index: number) => void;
  /** Called when user scrolls to end of Carousel. Forwarded from internal FlatList */
  onEndReached?: (info: { distanceFromEnd: number }) => void;
  /** Called whenever Carousel begins uploading files */
  onUploadStart?: () => void;
  /** Called whenever Carousel is no longer uploading files */
  onUploadEnd?: () => void;
  /** Called whenever Carousel runs into an upload error */
  onUploadError?: () => void;
}

/** Enforced by server */
const MAX_CAPTION_LENGTH = 180;

const DEFAULT_ITEM_WIDTH = 280;
const DEFAULT_ITEM_HEIGHT = 350;

const VIEWABILITY_CONFIG = {
  viewAreaCoveragePercentThreshold: Platform.OS === 'web' ? 30 : 50,
};

export default function Carousel(props: Props) {
  const itemWidth = props.itemWidth ?? DEFAULT_ITEM_WIDTH;
  const itemHeight = props.itemHeight ?? DEFAULT_ITEM_HEIGHT;

  /** Array of indices of carousel cards that are uploading media */
  const [uploading, setUploading] = useState<number[]>([]);

  const [viewableItemIndices, setViewableItemIndices] = useState<number[]>([]);

  /** Used to only show one deletion confirmation prompt accross the carousel */
  const [promptDeleteIndex, setPromptDeleteIndex] = useState<number>();

  const flatListRef = useRef<FlatList>();

  const onAddCard = () => {
    props.onChange?.([
      ...(props.data ?? []),
      {
        caption: '',
        media: '',
        thumbnail: '',
      },
    ]);
  };

  const onRemoveCard = (index: number) => {
    setPromptDeleteIndex(undefined);

    const card = props.data?.[index];

    if (!card) {
      console.warn(
        'Carousel onRemoveCard failed - Cannot find card with index',
        index,
      );
      return;
    }

    const carousel = [...(props.data ?? [])];
    carousel?.splice(index, 1);
    props.onChange?.(carousel, index);
  };

  const handleDeletePressed = (index: number) => {
    // If there is no data, remove immediately
    if (!props.data?.[index]?.media && !props.data?.[index]?.caption)
      onRemoveCard(index);
    // Otherwise, ask user to confirm their action
    else setPromptDeleteIndex(index);
  };

  const onUploadStart = (index: number) => {
    // If there are no active uploads already, signal that Caoursel has began uploading media
    if (uploading.length === 0) props.onUploadStart?.();

    setUploading((prevState) => [...prevState, index]);
  };

  const onUploadEnd = (index: number) => {
    // If this was the last active upload, signal that Carousel is no longer uploading media
    if (uploading.length === 1) props.onUploadEnd?.();

    setUploading((prevState) => prevState.filter((i) => i !== index));
  };

  const onUploadError = () => {
    props.onUploadError?.();
  };

  const onViewableItemsChanged = useCallback(
    (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      setViewableItemIndices(
        info.viewableItems.map((item) => item.index as number) || [],
      );
    },
    [],
  );

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: VIEWABILITY_CONFIG,
      onViewableItemsChanged,
    },
  ]);

  const carouselItemImage = [styles.carouselItemImage, { height: itemHeight }];

  return (
    <FlatList
      ref={(r) => {
        if (r && flatListRef) {
          flatListRef.current = r;
        }
      }}
      horizontal
      // Reset scroll offset if data length becomes 0
      contentOffset={(props.data?.length ?? 0) > 0 ? undefined : { x: 0, y: 0 }}
      scrollEnabled={(props.data?.length ?? 0) > 0}
      style={[
        styles.carousel,
        {
          display:
            // Conditionally render FlatList so it does not add blank space to the component when empty
            (props.data?.length ?? 0) > 0 || props.isEditing ? 'flex' : 'none',
        },
      ]}
      viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
      removeClippedSubviews
      data={props.data}
      ListFooterComponent={
        props.isEditing ? (
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <AddImageButton
              promptText={props.addCardPromptText}
              onPress={onAddCard}
              width={itemWidth}
              height={itemHeight}
            />
            {!props.data?.length &&
              [1, 2, 3].map((_, index) => (
                <ImageCarouselPlaceholder
                  key={index}
                  width={itemWidth}
                  height={itemHeight}
                />
              ))}
          </View>
        ) : props.isFetchingMore ? (
          <View
            style={{
              height: itemHeight,
              width: itemWidth / 2,
              justifyContent: 'center',
            }}
          >
            <ActivityIndicator
              size="large"
              style={{
                alignSelf: 'center',
              }}
              color={props.darkTheme ? 'white' : KEY_GRAY}
            />
          </View>
        ) : null
      }
      onEndReached={props.onEndReached}
      keyExtractor={(_, index) => `${index}`}
      renderItem={({ item, index }) => {
        if (!item) return null;

        return (
          <Pressable
            onPress={() => props.carouselItemOnPress?.(index)}
            style={[
              styles.carouselItemContainer,
              { width: itemWidth },
              props.carouselItemContainerStyle,
            ]}
            onTouchStart={() => {
              // If we are prompting deletion on another card and we just focused this card,
              // hide the prompt
              if (promptDeleteIndex !== index) setPromptDeleteIndex(undefined);
            }}
          >
            {typeof item.title === 'function'
              ? item.title()
              : item.title && (
                  <View style={styles.carouselItemTitleContainer}>
                    <Text
                      style={[
                        styles.carouselItemTitle,
                        props.darkTheme ? { color: 'white' } : {},
                        props.titleStyle,
                      ]}
                    >
                      {item.title}
                    </Text>
                  </View>
                )}
            {props.isEditing && props.editMode !== 'add-remove-only' ? (
              <>
                <UploadMedia
                  title={`Click to choose media${
                    Platform.OS === 'web'
                      ? ', or drop files here to upload'
                      : ''
                  }`}
                  onUploadStart={() => onUploadStart(index)}
                  targetMediaDimensions={{
                    width: itemWidth,
                    height: itemHeight,
                  }}
                  onUploadEnd={() => onUploadEnd(index)}
                  onUploadError={onUploadError}
                  onChangeReadyMedia={(media) => {
                    const mediaItem = media[0];

                    const carousel = Array.from(props.data!); // Definitely defined if we are in this block

                    carousel[index] = {
                      ...carousel[index],
                      media: mediaItem?.uri,
                      thumbnail:
                        mediaItem?.thumbnailUri ||
                        getCDNImageUri({
                          uri: mediaItem?.uri,
                          isThumbnail: true,
                        }) ||
                        '',
                    };

                    props.onChange?.(carousel);
                  }}
                  media={[
                    {
                      uri: item.media ?? '',
                      thumbnailUri: item.thumbnail,
                    },
                  ]}
                  style={[carouselItemImage, { backgroundColor: 'gray' }]}
                  mediaType="All"
                />
                <TextInput
                  style={[
                    TEXT_INPUT,
                    styles.carouselItemCaptionInput,
                    props.captionStyle,
                  ]}
                  value={item.caption ?? undefined}
                  multiline
                  maxLength={MAX_CAPTION_LENGTH}
                  placeholder={`Add a caption...${
                    props.captionRequired === true
                      ? ' (required)'
                      : props.captionRequired === false
                      ? ' (optional)'
                      : ''
                  }`}
                  onChangeText={(text) => {
                    const carousel = Array.from(props.data!);

                    carousel[index] = {
                      ...carousel[index],
                      caption: text,
                    };

                    props.onChange?.(carousel);
                  }}
                />
              </>
            ) : (
              <>
                {determineIfVideo(item.media) ? (
                  <VideoPlayer
                    suppress={!viewableItemIndices.includes(index)}
                    sourceUri={item.media}
                    thumbnailSource={{
                      uri: getCDNImageUri({
                        uri: item.thumbnail,
                        isThumbnail: true,
                        dimensions: {
                          width: itemWidth,
                          height: itemHeight,
                        },
                      }),
                    }}
                    resizeMode={props.imageResizeMode ?? ResizeMode.COVER}
                    style={[carouselItemImage, props.mediaStyle]}
                  />
                ) : (
                  <ProgressiveImage
                    containerStyle={[carouselItemImage, props.mediaStyle]}
                    style={{ flex: 1 }}
                    showLoadingIndicator
                    resizeMode={props.imageResizeMode}
                    thumbnailSource={{
                      uri: getCDNImageUri({
                        uri: item.thumbnail,
                        isThumbnail: true,
                        dimensions: {
                          width: 28,
                          height: 35,
                        },
                      }),
                    }}
                    source={{
                      uri: getCDNImageUri({
                        uri: item.media,
                        dimensions: {
                          width: itemWidth,
                          height: itemHeight,
                        },
                      }),
                    }}
                  />
                )}
                <Text
                  style={[
                    styles.carouselItemCaption,
                    props.darkTheme ? { color: 'white' } : {},
                    props.captionStyle,
                  ]}
                >
                  {item.caption}
                </Text>
              </>
            )}

            {/* Spacer */}
            <View
              style={{
                marginTop: 8,
              }}
            />

            {promptDeleteIndex === index ? (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Lato-Bold',
                  }}
                >
                  Are you sure?
                </Text>
                <View
                  style={{
                    flexDirection: 'row-reverse',
                  }}
                >
                  <Button
                    label="Delete"
                    containerStyle={{
                      marginLeft: 8,
                    }}
                    labelStyle={{
                      color: 'white',
                    }}
                    style={{
                      backgroundColor: 'crimson',
                    }}
                    onPress={() => onRemoveCard(index)}
                  />
                  <Button
                    label="Cancel"
                    labelStyle={{}}
                    onPress={() => setPromptDeleteIndex(undefined)}
                  />
                </View>
              </View>
            ) : (
              <>
                {typeof item.footerComponent === 'function'
                  ? item.footerComponent()
                  : item.footerComponent
                  ? item.footerComponent
                  : null}
                {props.isEditing ? (
                  <Button
                    containerStyle={{
                      backgroundColor: '#ccc',
                      marginTop: 8,
                    }}
                    onPress={() => handleDeletePressed(index)}
                    label={
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesome
                          size={17}
                          name="trash-o"
                          style={{
                            marginRight: 4,
                          }}
                          color={KEY_GRAY}
                        />
                        <Text
                          style={{
                            color: KEY_GRAY,
                            fontFamily: 'Lato-Bold',
                            fontSize: 17,
                          }}
                        >
                          Remove
                        </Text>
                      </View>
                    }
                  />
                ) : null}
              </>
            )}
          </Pressable>
        );
      }}
    />
  );
}

const AddImageButton = ({
  onPress,
  promptText,
  width,
  height,
}: {
  onPress: () => void;
  promptText: string | undefined;
  width: number;
  height: number;
}) => {
  return (
    <Pressable
      style={[
        styles.carouselItemContainer,
        styles.carouselAddItemButtonContainer,
        {
          width,
          height: height + CAROUSEL_PLACEHOLDER_EXTRA_HEIGHT,
        },
      ]}
      onPress={onPress}
    >
      <PlusSignCircle fill={KEY_GREEN} />
      <Text style={styles.carouselAddItemText}>
        {promptText?.trim() ||
          'Add images & captions to tell more of your story'}
      </Text>
    </Pressable>
  );
};

const ImageCarouselPlaceholder = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  return (
    <View
      style={[
        styles.carouselItemContainer,
        styles.carouselPlaceholderItemContainer,
        {
          width: width,
          height: height + CAROUSEL_PLACEHOLDER_EXTRA_HEIGHT,
        },
      ]}
    />
  );
};

const CAROUSEL_PLACEHOLDER_EXTRA_HEIGHT = 0;

const styles = StyleSheet.create({
  carousel: {
    marginTop: 12,
    paddingBottom: 8,
  },
  carouselItemContainer: {
    marginVertical: 8,
    marginRight: 16,
    width: DEFAULT_ITEM_WIDTH,
  },
  carouselItemTitle: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 17,
  },
  carouselItemTitleContainer: {
    justifyContent: 'flex-end',
    marginBottom: 4,
  },
  carouselPlaceholderItemContainer: {
    height: DEFAULT_ITEM_HEIGHT + CAROUSEL_PLACEHOLDER_EXTRA_HEIGHT,
    backgroundColor: '#ddd',
    borderRadius: 6,
  },
  carouselAddItemButtonContainer: {
    height: DEFAULT_ITEM_HEIGHT + CAROUSEL_PLACEHOLDER_EXTRA_HEIGHT,
    padding: 8,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: '#ddd',
    alignItems: 'center',
    justifyContent: 'center',
  },
  carouselAddItemText: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    textAlign: 'center',
    margin: 4,
  },
  carouselItemImage: {
    width: '100%',
    height: DEFAULT_ITEM_HEIGHT,
    borderRadius: 6,
    marginBottom: 8,
    overflow: 'hidden',
    backgroundColor: '#eee',
  },
  carouselItemCaptionInput: {
    flex: undefined,
    height: 120,
  },
  carouselItemCaption: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'black',
    textAlign: 'center',
  },
});
