import React from 'react';
import { StyleSheet, ActivityIndicator, Animated, Text } from 'react-native';
import { ACTIVITY_INDICATOR_DEFAULT } from '../constants';

// Loading overlay is a component that will absolutely fill its container
// when the props 'loading' is true and display a loading indicator,
// which can be customized by the parent component

interface ILoadingOverlayProps {
  loading: boolean;
  /** Message to display next to loading spinner */
  label?: string;
  /** activeOpacity: Opacity of loading overlay when it is active - Default is 0.7 */
  activeOpacity?: number;
  /** backgroundColor: Background color of overlay - Default is black */
  backgroundColor?: string;
}

const isTestEnv = process.env.NODE_ENV === 'test';

export default class LoadingOverlay extends React.Component<ILoadingOverlayProps> {
  opacity: Animated.Value;

  animateIn: Animated.CompositeAnimation;

  animateOut: Animated.CompositeAnimation;

  isAnimating: number; // 0 = not animating, 1 = animating in, -1 = animating out

  constructor(props: ILoadingOverlayProps) {
    super(props);

    this.isAnimating = 0;

    this.opacity = new Animated.Value(0);
    this.animateIn = Animated.timing(this.opacity, {
      useNativeDriver: !isTestEnv,
      toValue: this.props.activeOpacity || 0.7,
      duration: 150,
    });
    this.animateOut = Animated.timing(this.opacity, {
      useNativeDriver: !isTestEnv,
      toValue: 0,
      duration: 120,
    });
  }

  componentDidMount() {
    this.handleChange();
  }

  componentDidUpdate() {
    this.handleChange();
  }

  handleChange() {
    if (this.props.loading) {
      if (this.isAnimating === 1) return;

      this.isAnimating = 1;
      this.animateIn.start(() => {
        this.isAnimating = 0;
      });
    } else {
      if (this.isAnimating === -1) return;

      this.isAnimating = -1;
      this.animateOut.start(() => {
        this.isAnimating = 0;
      });
    }
  }

  render() {
    const overlayStyle = [
      styles.load_overlay,
      {
        backgroundColor: this.props.backgroundColor || 'black',
        opacity: this.opacity,
      },
    ];

    return (
      <Animated.View
        style={[
          overlayStyle,
          {
            pointerEvents: this.props.loading ? 'auto' : 'none',
          },
        ]}
      >
        <ActivityIndicator size="large" color={ACTIVITY_INDICATOR_DEFAULT} />

        <Text
          style={[
            styles.label,
            { display: this.props.label?.trim?.() ? 'flex' : 'none' },
          ]}
        >
          {this.props.label}
        </Text>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  load_overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    elevation: 999,
  },
  load_text: {
    fontWeight: 'bold',
    color: 'white',
  },
  label: {
    fontFamily: 'Lato-Bold',
    color: 'white',
    fontSize: 16,
    paddingVertical: 8,
  },
});
