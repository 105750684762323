import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { CommonActions } from '@react-navigation/native';
import dayjs from 'dayjs';
import React from 'react';
import { ScrollView, Text } from 'react-native';
import Avatar from '../Avatar';
import Button from '../Button';
import Link from '../Link';
import HorizontalContainer from '../common/Generic/HorizontalContainer';

import SectionText from '../common/Section/SectionText';
import commonModalStyles from './commonModalStyles.style';
import { BUTTON_LABEL_STYLE, KEY_GRAY, KEY_GREEN } from '/constants';
import {
  CampaignConnectInvite,
  useDeleteCampaignConnectInviteMutation,
  User,
} from '/generated/graphql';
import Alert from '/Alert';

interface ICampaignConnectInvite
  extends Pick<
    CampaignConnectInvite,
    'message' | 'expiresAt' | 'creativeConnectProjectId' | 'invitedUserEmail'
  > {
  id?: string;
  invitedUser?: Pick<User, 'id' | 'name' | 'profile_image'> | null | undefined;
}

type Props = {
  invite: ICampaignConnectInvite;
  onRequestClose: (shouldRemove: boolean) => void;
};

export default function ViewCampaignConnectInviteModal(props: Props) {
  const [{ fetching: deleting }, deleteInvite] =
    useDeleteCampaignConnectInviteMutation();

  function onDeleteInvite() {
    const inviteId = props.invite.id;
    if (!inviteId) return;

    const _delete = async () => {
      try {
        const { error: err } = await deleteInvite({
          inviteId,
        });
        if (err) {
          throw err;
        }

        props.onRequestClose(true);

        Alert.notify({
          message: 'Invite deleted successfully',
          color: KEY_GREEN,
        });
      } catch (error) {
        console.error('Error deleting campaign connect invite', error);
        Alert.alert(
          'Oh no',
          'There was a problem deleting the invite. Please try again later.',
        );
      }
    };

    Alert.alert(
      'Are you sure?',
      'Are you sure you want to delete this invite?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: _delete,
        },
      ],
    );
  }

  return (
    <ScrollView>
      <Text style={commonModalStyles.fieldTitle}>INVITED USER</Text>
      <Link
        to={
          props.invite.invitedUser ? `/user/${props.invite.invitedUser.id}` : ''
        }
        style={{
          pointerEvents: props.invite.invitedUser ? 'auto' : 'none',
        }}
        disabled={!props.invite.invitedUser}
        action={CommonActions.navigate({
          name: 'Profile',
          params: {
            id: props.invite.invitedUser?.id,
          },
        })}
        onPress={() => {
          props.onRequestClose(false);
        }}
      >
        <HorizontalContainer>
          <Avatar
            rounded
            size={32}
            containerStyle={{
              marginRight: 6,
            }}
            source={{
              uri: props.invite.invitedUser?.profile_image,
            }}
          />
          <Text style={commonModalStyles.invitedUserName} numberOfLines={2}>
            {props.invite.invitedUser?.name ||
              props.invite.invitedUserEmail ||
              'Unknown User'}
          </Text>
        </HorizontalContainer>
      </Link>

      <Text style={commonModalStyles.fieldTitle}>MESSAGE</Text>
      <SectionText>"{props.invite.message}"</SectionText>

      {props.invite.expiresAt ? (
        <HorizontalContainer
          style={{
            marginTop: 8,
          }}
        >
          <FontAwesome5
            name="clock"
            size={17}
            color={
              dayjs(props.invite.expiresAt).isBefore(dayjs().add(1, 'day'))
                ? 'crimson'
                : KEY_GRAY
            }
          />
          <SectionText
            style={{
              color: dayjs(props.invite.expiresAt).isBefore(
                dayjs().add(1, 'day'),
              )
                ? 'crimson'
                : KEY_GRAY,
            }}
          >
            {` `} Invitation expires{' '}
            {dayjs(props.invite.expiresAt).format('MMMM DD, YYYY h:mm A')}
          </SectionText>
        </HorizontalContainer>
      ) : null}

      {!props.invite.id ? null : (
        <Button
          onPress={onDeleteInvite}
          loading={deleting}
          containerStyle={{
            marginTop: 8,
            alignSelf: 'flex-end',
          }}
          label={
            <HorizontalContainer>
              <FontAwesome name="trash-o" size={17} color="crimson" />
              <Text style={[BUTTON_LABEL_STYLE, { color: 'crimson' }]}>
                {` `}Delete Invite
              </Text>
            </HorizontalContainer>
          }
        />
      )}
    </ScrollView>
  );
}
