// Mock TurboModuleRegistry
export const TurboModuleRegistry = {
  getEnforcing: (name) => {
    if (name === 'Compressor') {
      return {
        compress: async () => {
          console.warn('Image compression is not supported on web');
          return null;
        },
        compressImage: async () => {
          console.warn('Image compression is not supported on web');
          return null;
        },
        compressVideo: async () => {
          console.warn('Video compression is not supported on web');
          return null;
        },
        generateThumbnail: async () => {
          console.warn('Thumbnail generation is not supported on web');
          return null;
        },
      };
    }
    return null;
  },
};

// Mock Video compression namespace
export const Video = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  compress: async (uri, options = {}) => {
    console.warn('Video compression is not supported on web');
    return uri;
  },
};

export default TurboModuleRegistry.getEnforcing('Compressor');
