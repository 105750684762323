import { FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import React from 'react';
import { Text, View } from 'react-native';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import CampaignConnectLinkablePostPreview from '/components/CampaignConnect/CampaignConnectLinkablePostPreview/CampaignConnectLinkablePostPreview';
import commonModalStyles from '/components/CampaignConnect/commonModalStyles.style';
import CampaignPicker, {
  ICampaignPickerCampaign,
} from '/components/CampaignPicker/CampaignPicker';
import Link from '/components/Link';
import TruncatableText from '/components/TruncatableText';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionText from '/components/common/Section/SectionText';
import { ALERT_RED, KEY_GRAY, KEY_GREEN } from '/constants';
import { useAuthContext, useTeamContext } from '/context';
import {
  CampaignConnectInvite,
  TeamMemberRole,
  TeamMembershipStatus,
  User,
  UserRole,
  useDeleteCampaignConnectInviteMutation,
  useFulfillCampaignConnectInviteMutation,
} from '/generated/graphql';
import { isUnderPrivileged, shorten } from '/util';
import { ScrollView } from 'react-native';

export interface ICampaignConnectInvite
  extends Pick<
    CampaignConnectInvite,
    | 'id'
    | 'message'
    | 'expiresAt'
    | 'created_at'
    | 'creativeConnectProjectId'
    | 'invitedUserId'
  > {}

type Props = {
  invite: ICampaignConnectInvite;
  invitingUser: Pick<User, 'id' | 'name' | 'profile_image'> | null | undefined;
  onRequestClose: () => void;
  hideCTA?: boolean;
};

const RESPONSE_BUTTON_WIDTH = 180;

export default function CampaignConnectInviteCTAModal(props: Props) {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const { userData } = useAuthContext();
  const { teams } = useTeamContext();

  const [selectedCampaign, setSelectedCampaign] =
    React.useState<ICampaignPickerCampaign>();

  const [{ fetching: fulfilling }, fulfillCampaignConnectInvite] =
    useFulfillCampaignConnectInviteMutation();
  const [{ fetching: deleting }, deleteCampaignConnectInvite] =
    useDeleteCampaignConnectInviteMutation();

  function onDeleteInvite() {
    const _delete = async () => {
      try {
        const { error: err } = await deleteCampaignConnectInvite({
          inviteId: props.invite.id,
        });
        if (err) {
          throw err;
        }

        props.onRequestClose();

        Alert.notify({
          message: 'Invite deleted successfully',
          color: KEY_GREEN,
        });
      } catch (error) {
        console.error('Error deleting campaign connect invite', error);
        Alert.alert(
          'Oh no',
          'There was a problem deleting the invite. Please try again later.',
        );
      }
    };

    Alert.alert(
      'Are you sure?',
      'Are you sure you want to delete this invite?',
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: _delete,
        },
      ],
    );
  }

  async function onSubmitExistingCampaign() {
    if (!selectedCampaign) return;
    const { error } = await fulfillCampaignConnectInvite({
      inviteId: props.invite.id,
      campaignId: selectedCampaign.id,
    });

    if (error) {
      console.error('Error fulfilling campaign connect invite', error);
      Alert.alert(
        'Oh no',
        'There was a problem submitting the campaign. Please try again later.',
      );
      return;
    }

    props.onRequestClose();
    Alert.notify({
      message: 'Campaign submitted',
      color: KEY_GREEN,
    });
  }

  function onCreateNewCampaign() {
    props.onRequestClose();

    const params: any = {
      campaignConnectInviteId: props.invite.id,
    };

    if (userData?.role !== UserRole.Conservationist) {
      const team = teams.find((t) => {
        return (
          t.user.id === props.invite.invitedUserId &&
          t.membership?.membership_status === TeamMembershipStatus.Confirmed &&
          !isUnderPrivileged(TeamMemberRole.Creator, t.membership.team_role)
        );
      });
      if (team) {
        params.activeTeamId = team.id;
      }
    }

    navigate('CreateCampaign', params);
  }

  const busy = deleting || fulfilling;

  return (
    <ScrollView
      style={{
        pointerEvents: busy ? 'none' : 'auto',
        opacity: busy ? 0.6 : 1,
      }}
    >
      <SectionText>
        You were invited to create or assign a campaign to be featured in a post
        and help drive action.
      </SectionText>

      <Text style={commonModalStyles.fieldTitle}>INVITED BY</Text>
      <View style={commonModalStyles.invitedByContainer}>
        <HorizontalContainer>
          <Link
            to={props.invitingUser ? `/user/${props.invitingUser.id}` : ''}
            disabled={!props.invitingUser}
            action={CommonActions.navigate({
              name: 'Profile',
              params: {
                id: props.invitingUser?.id,
              },
            })}
            style={{
              flex: 1,
            }}
          >
            <HorizontalContainer>
              <Avatar
                rounded
                size={24}
                containerStyle={{
                  marginRight: 4,
                }}
                source={{
                  uri: props.invitingUser?.profile_image,
                }}
              />
              <Text style={commonModalStyles.invitedUserName} numberOfLines={2}>
                {props.invitingUser?.name || 'Unknown User'}
              </Text>
            </HorizontalContainer>
          </Link>
          {props.invitingUser?.id !== userData?.id ? (
            <Button
              style={{
                padding: 5,
                paddingHorizontal: 8,
                backgroundColor: 'white',
              }}
              label={
                <MaterialIcons name="message" size={20} color={KEY_GRAY} />
              }
              onPress={() => {
                navigate('DirectMessageScreen', {
                  userId: props.invitingUser?.id,
                });
              }}
            />
          ) : null}
        </HorizontalContainer>

        {props.invite.message?.trim() ? (
          <SectionText
            style={{
              fontFamily: 'Lato-Italic',
            }}
          >
            <Text
              style={{
                fontFamily: 'Lato-Bold',
              }}
            >
              Message:
            </Text>{' '}
            "
            <TruncatableText text={props.invite.message} />"
          </SectionText>
        ) : null}
      </View>

      {props.invite.expiresAt ? (
        <HorizontalContainer>
          <FontAwesome5
            name="clock"
            size={17}
            color={
              dayjs(props.invite.expiresAt).isBefore(dayjs().add(1, 'day'))
                ? 'crimson'
                : KEY_GRAY
            }
          />
          <SectionText
            style={{
              color: dayjs(props.invite.expiresAt).isBefore(
                dayjs().add(1, 'day'),
              )
                ? 'crimson'
                : KEY_GRAY,
            }}
          >
            {` `} Invitation expires{' '}
            {dayjs(props.invite.expiresAt).format('MMMM DD, YYYY h:mm A')}
          </SectionText>
        </HorizontalContainer>
      ) : null}

      <Text style={commonModalStyles.fieldTitle}>LINK TO</Text>
      <SectionText
        style={{
          marginBottom: 8,
        }}
      >
        This is the post where your campaign would be featured
      </SectionText>

      <CampaignConnectLinkablePostPreview invite={props.invite} />

      {props.hideCTA ? null : (
        <>
          <Text style={commonModalStyles.fieldTitle}>
            RESPOND TO THIS INVITE
          </Text>
          <SectionText>
            You can create a new Campaign or submit an existing one.
          </SectionText>

          {selectedCampaign ? null : (
            <HorizontalContainer
              style={{
                marginTop: 8,
                alignItems: 'flex-start',
              }}
            >
              <Button
                containerStyle={{
                  marginRight: 8,
                }}
                style={{
                  width: RESPONSE_BUTTON_WIDTH,
                  backgroundColor: KEY_GREEN,
                }}
                label="Create Campaign"
                onPress={onCreateNewCampaign}
              />
              <SectionText>Create a new Campaign.</SectionText>
            </HorizontalContainer>
          )}

          {selectedCampaign ? (
            <Text style={commonModalStyles.fieldTitle}>SUBMIT CAMPAIGN</Text>
          ) : null}
          <HorizontalContainer
            style={{
              marginTop: 8,
              alignItems: 'flex-start',
            }}
          >
            <CampaignPicker
              buttonLabel={selectedCampaign ? 'Change' : 'Submit Campaign'}
              emptyPlaceholderText="You have not created any campaigns."
              filter={{
                userId: props.invite.invitedUserId || undefined,
                draft: false,
                closed: false,
                archived: false,
              }}
              style={{
                width: selectedCampaign ? '100%' : undefined,
              }}
              showFilters={{}}
              buttonContainerStyle={{
                display: selectedCampaign ? 'none' : 'flex',
                marginRight: 8,
                width: RESPONSE_BUTTON_WIDTH,
              }}
              buttonStyle={{
                width: RESPONSE_BUTTON_WIDTH,
              }}
              selection={selectedCampaign ? [selectedCampaign] : []}
              onChangeSelection={(campaigns) => {
                setSelectedCampaign(campaigns[0]);
              }}
              maxSelectionSize={1}
            />
            {selectedCampaign ? null : (
              <SectionText>
                If you've already created a Campaign that you'd like to submit,
                select it here.
              </SectionText>
            )}
          </HorizontalContainer>
          {selectedCampaign ? (
            <>
              <SectionText>
                After you submit this Campaign,{' '}
                <SectionText
                  style={{
                    fontFamily: 'Lato-Bold',
                  }}
                >
                  {shorten(props.invitingUser?.name || 'the inviting user', 28)}{' '}
                </SectionText>
                will be notified and will have to approve it before it is
                featured in the post.
              </SectionText>
              <HorizontalContainer
                style={{
                  justifyContent: 'flex-end',
                  marginTop: 4,
                }}
              >
                <Button
                  label="Go back"
                  containerStyle={{
                    marginRight: 8,
                  }}
                  onPress={() => {
                    setSelectedCampaign(undefined);
                  }}
                />
                <Button
                  label="Submit Campaign"
                  style={{
                    backgroundColor: KEY_GREEN,
                  }}
                  loading={fulfilling}
                  onPress={onSubmitExistingCampaign}
                />
              </HorizontalContainer>
            </>
          ) : null}

          {selectedCampaign ? null : (
            <HorizontalContainer
              style={{
                marginTop: 8,
                alignItems: 'flex-start',
              }}
            >
              <Button
                containerStyle={{
                  marginRight: 8,
                }}
                style={{
                  width: RESPONSE_BUTTON_WIDTH,
                  backgroundColor: ALERT_RED,
                }}
                labelStyle={{
                  color: 'white',
                }}
                label="Delete Invite"
                loading={deleting}
                onPress={onDeleteInvite}
              />
              <SectionText>
                Delete this invite. This cannot be undone.
              </SectionText>
            </HorizontalContainer>
          )}
        </>
      )}
    </ScrollView>
  );
}
