import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useScrollToTop } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ResizeMode } from 'expo-av';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  LayoutChangeEvent,
  Platform,
  Pressable,
  RefreshControl,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import {
  DEVICE_SIZES,
  maxSize,
  useResponsiveStyle,
} from 'rn-responsive-styles';
import AccountApprovalAlert from '../components/AccountApprovalAlert/AccountApprovalAlert';
import CampaignPost from '../components/CampaignPost/CampaignPost';
import ProfileCompletionWidget from '../components/ProfileCompletionWidget/ProfileCompletionWidget';
import Lightening from '/assets/jsicons/bottomnavigation/Lightening';
import tutorialSlideshow from '/assets/json/TutorialSlideshow.json';
import Button from '/components/Button';
import CampaignPostSkeleton from '/components/CampaignPostSkeleton';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import MomentInNatureCarousel from '/components/FeedScreen/MomentInNatureCarousel';
import JobPostCard from '/components/JobBoard/elements/JobPostCard';
import KeyBulletinWidget from '/components/KeyBulletinWidget/KeyBulletinWidget';
import MediaFlatList from '/components/MediaFlatList/MediaFlatList';
import MediaItem from '/components/MediaViewer/elements/MediaItem';
import NewsPostCard from '/components/NewsPostCard/NewsPostCard';
import SupportKeyBanner from '/components/SupportKeyBanner';
import UpdateAvailableWidget from '/components/UpdateAvailableWidget';
import ViewportTracker from '/components/ViewportAware/ViewportTracker';
import { CARD_TITLE_FONT_SIZE, KEY_GRAY, SECTION_CONTAINER } from '/constants';
import { useAuthContext } from '/context';
import { useFullscreenMediaViewer } from '/context/FullscreenMediaViewerProvider';
import {
  GetFeedQuery,
  ListJobPostsQuery,
  ListNewsPostsQuery,
  MomentInNatureFeedType,
  useGetFeedQuery,
  useListJobPostsQuery,
  useListNewsPostsQuery,
  useSetHasSeenTutorialMutation,
} from '/generated/graphql';
import { useIsTesterOnlyMode } from '/hooks/useIsTesterOnlyMode';
import { getLocaleCurrencyCode, getScrollableNode } from '/util';

const currencyCode = getLocaleCurrencyCode();

type FeedItem =
  | {
      type: 'campaign_post';
      data: GetFeedQuery['getRecentCampaigns']['items'][0];
    }
  | {
      type: 'moment_in_nature_carousel';
    }
  | {
      type: 'feed_title_card';
    }
  | {
      type: 'job_post_card';
      data: ListJobPostsQuery['listJobPosts']['items'];
    }
  | {
      type: 'news_post_card';
      data: ListNewsPostsQuery['listNewsPosts']['items'];
    };

type Props = {
  navigation: StackNavigationProp<any>;
};

const FeedScreen = (props: Props) => {
  const [nextToken, setNextToken] = useState<string | null>();

  const { userData } = useAuthContext();
  const { showMedia } = useFullscreenMediaViewer();
  const isTesterOnlyMode = useIsTesterOnlyMode();
  const [, setHasSeenTutorial] = useSetHasSeenTutorialMutation();
  const [viewportWidth, setViewportWidth] = useState(0);
  const windowDimensions = useWindowDimensions();
  const [isTutorialCollapsed, setIsTutorialCollapsed] = useState(
    !!userData?.has_seen_tutorial,
  );
  const shouldDisplayCardsInRow = windowDimensions.width > 640;

  const { deviceSize } = useResponsiveStyle({})();

  /** Used to control whether the FlatList method OnEndReached should paginate.
   * We only set this to true whenever we focus on the screen after a set delay
   * because FlashList will momentarily have an incorrect height of zero causing
   * OnEndReached to be called prematurely. This prevents us from paginating immedately
   * upon focusing the screen, even when the user has not scrolled to the bottom.
   */
  const paginateOnEndReached = useRef(false);
  const setPaginatedOnEndReachedTiemout = useRef<NodeJS.Timeout>();

  useFocusEffect(() => {
    setPaginatedOnEndReachedTiemout.current = setTimeout(() => {
      paginateOnEndReached.current = true;
      setPaginatedOnEndReachedTiemout.current = undefined;
    }, 300);

    return () => {
      if (setPaginatedOnEndReachedTiemout.current) {
        clearTimeout(setPaginatedOnEndReachedTiemout.current);
        setPaginatedOnEndReachedTiemout.current = undefined;
      }

      paginateOnEndReached.current = false;
    };
  });

  const [{ data, fetching, error, stale }, getFeed] = useGetFeedQuery({
    variables: {
      nextToken,
      currency: currencyCode,
    },
    requestPolicy: 'cache-and-network',
  });

  const [jobPostsQuery] = useListJobPostsQuery({
    variables: {
      limit: 10,
      filter: {
        closed: false,
      },
    },
    requestPolicy: 'cache-and-network',
  });

  const [newsPostsQuery] = useListNewsPostsQuery({
    variables: {
      limit: 10,
    },
    requestPolicy: 'cache-and-network',
  });

  const flatlistRef = useRef<any>();

  useScrollToTop(flatlistRef);

  const ListComponent =
    Platform.OS === 'android'
      ? ViewportTracker.FlatList
      : ViewportTracker.FlashList;

  const ListComponentProps = Platform.select({
    default: {
      estimatedItemSize: windowDimensions.height * 1.2,
    },
    android: {},
  });

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setViewportWidth(width);
  }, []);

  const feedItems = useMemo(() => {
    // Based on MOMENT_IN_NATURE_INTERVAL, insert a moment in nature carousel
    // into the feed
    const JOB_POST_INTERVAL = 2;
    const NEWS_POST_INTERVAL = 3;

    const CARDS_PER_ROW = shouldDisplayCardsInRow ? 2 : 1;

    const items: FeedItem[] = [];

    items.push({ type: 'moment_in_nature_carousel' });

    items.push({
      type: 'feed_title_card',
    });

    if (data?.getRecentCampaigns.items) {
      data.getRecentCampaigns.items.forEach((item, index) => {
        const shouldAddJobPost = index % JOB_POST_INTERVAL === 0;
        const shouldAddNewsPost = index % NEWS_POST_INTERVAL === 0;

        const addJobPostStartIndex =
          (index / JOB_POST_INTERVAL) * CARDS_PER_ROW;
        const addNewsPostStartIndex =
          (index / NEWS_POST_INTERVAL) * CARDS_PER_ROW;

        if (
          shouldAddJobPost &&
          jobPostsQuery.data?.listJobPosts.items?.length &&
          jobPostsQuery.data.listJobPosts.items.length > addJobPostStartIndex
        ) {
          items.push({
            type: 'job_post_card',
            data: jobPostsQuery.data.listJobPosts.items.slice(
              addJobPostStartIndex,
              Math.min(
                jobPostsQuery.data.listJobPosts.items.length,
                addJobPostStartIndex + CARDS_PER_ROW,
              ),
            ),
          });
        }

        if (
          shouldAddNewsPost &&
          newsPostsQuery.data?.listNewsPosts.items?.length &&
          newsPostsQuery.data.listNewsPosts.items.length > addNewsPostStartIndex
        ) {
          items.push({
            type: 'news_post_card',
            data: newsPostsQuery.data.listNewsPosts.items.slice(
              addNewsPostStartIndex,
              Math.min(
                newsPostsQuery.data.listNewsPosts.items.length,
                addNewsPostStartIndex + CARDS_PER_ROW,
              ),
            ),
          });
        }

        items.push({
          type: 'campaign_post',
          data: item,
        });
      });
    }

    return items;
  }, [
    data?.getRecentCampaigns.items,
    jobPostsQuery.data?.listJobPosts.items,
    newsPostsQuery.data?.listNewsPosts.items,
    shouldDisplayCardsInRow,
  ]);

  const [isSkeleton, setIsSkeleton] = useState(true);
  useEffect(() => {
    if (!isSkeleton) return;
    if (data?.getRecentCampaigns.items.length) {
      setIsSkeleton(false);
    }
  }, [data?.getRecentCampaigns.items.length, isSkeleton]);

  return (
    <View style={styles.container} onLayout={onLayout}>
      <UpdateAvailableWidget />
      <ListComponent
        ref={(r: any) => {
          if (r) {
            const node = getScrollableNode(r);
            flatlistRef.current = node;
          }
        }}
        {...ListComponentProps}
        ListHeaderComponent={
          <>
            {/* Only display this alert on devices that are too small to display the sidebar,
              which already displays this alert */}
            {deviceSize === DEVICE_SIZES.LARGE_DEVICE ||
            deviceSize === DEVICE_SIZES.EXTRA_LARGE_DEVICE ? null : (
              <AccountApprovalAlert />
            )}

            {!isTesterOnlyMode ? (
              <SupportKeyBanner />
            ) : (
              <View>
                <Pressable
                  onPress={() => {
                    if (!userData?.has_seen_tutorial) {
                      setHasSeenTutorial({});
                    }
                    setIsTutorialCollapsed(!isTutorialCollapsed);
                  }}
                  style={styles.topBannerHeader}
                >
                  <View style={styles.tutorialHeaderContent}>
                    <View style={styles.tutorialLabelContainer}>
                      <MaterialCommunityIcons
                        name="lightbulb-outline"
                        size={24}
                        color={KEY_GRAY}
                        style={{ marginRight: 8 }}
                      />
                      <Text style={styles.tutorialLabel}>
                        Getting Started Guide
                      </Text>
                    </View>

                    <MaterialCommunityIcons
                      name={isTutorialCollapsed ? 'chevron-down' : 'chevron-up'}
                      size={24}
                      color={KEY_GRAY}
                    />
                  </View>
                </Pressable>

                {!isTutorialCollapsed && (
                  <View>
                    <MediaFlatList
                      data={tutorialSlideshow}
                      renderItem={({ item, index }) => (
                        <MediaItem
                          key={item}
                          onPress={() => {
                            showMedia({
                              sourceUris: tutorialSlideshow,
                              initialIndex: index,
                              useOriginalSource: true,
                            });
                          }}
                          shouldAutoplayVideo={false}
                          isVideoPlayable={false}
                          uri={item}
                          style={{
                            width: viewportWidth,
                            height: viewportWidth * 0.56,
                          }}
                          targetCDNMediaHeight={viewportWidth * 0.56}
                          targetCDNMediaWidth={viewportWidth}
                          useOriginalSource={false}
                          hideErrorRetryButton={false}
                          resizeMode={ResizeMode.CONTAIN}
                        />
                      )}
                      snapToInterval={viewportWidth}
                    />
                    <View
                      style={{
                        position: 'absolute',
                        bottom: 8,
                        right: 8,
                        padding: 8,
                        backgroundColor: 'rgba(230,230,230,0.75)',
                        borderRadius: 7,
                      }}
                      pointerEvents="none"
                    >
                      <FontAwesome5
                        style={{
                          pointerEvents: 'none',
                        }}
                        name="expand-alt"
                        size={20}
                        color="black"
                      />
                    </View>
                  </View>
                )}

                {isTutorialCollapsed && <SupportKeyBanner />}

                {userData?.application?.completed_survey ? (
                  <ProfileCompletionWidget />
                ) : null}

                {maxSize(DEVICE_SIZES.MEDIUM_DEVICE).includes(deviceSize) ? (
                  <KeyBulletinWidget
                    contentContainerStyle={{
                      padding: 8,
                    }}
                  />
                ) : null}
              </View>
            )}
          </>
        }
        ListFooterComponent={
          <>
            {(stale || fetching) && isSkeleton ? (
              <View style={styles.footerContainer}>
                <CampaignPostSkeleton />
                <CampaignPostSkeleton />
              </View>
            ) : null}

            <View
              style={{
                marginTop: 24,
              }}
            >
              {data?.getRecentCampaigns.items?.length ? (
                stale || fetching ? (
                  <ActivityIndicator
                    style={styles.activityIndicator}
                    size="large"
                    color={KEY_GRAY}
                  />
                ) : data.getRecentCampaigns.nextToken ? (
                  <View>
                    <Button
                      containerStyle={{
                        alignSelf: 'center',
                        marginBottom: 40,
                      }}
                      label="Load more"
                      loading={fetching}
                      onPress={() => {
                        setNextToken(data.getRecentCampaigns.nextToken);
                      }}
                    />
                  </View>
                ) : (
                  <View
                    style={{
                      width: 12,
                      height: 12,
                      backgroundColor: '#ccc',
                      borderRadius: 20,
                      alignSelf: 'center',
                      marginBottom: 40,
                    }}
                  />
                )
              ) : error && !fetching ? (
                <View>
                  <Text style={styles.errorText}>
                    We ran into a problem while fetching the feed. Please check
                    your connection or try again.
                  </Text>
                  <Button
                    label="Retry"
                    containerStyle={{ alignSelf: 'center' }}
                    onPress={() => getFeed()}
                  />
                </View>
              ) : data?.getRecentCampaigns.items?.length === 0 ? (
                <View>
                  <Text style={styles.emptyText}>You're very early!</Text>
                  <Text style={styles.emptyText}>
                    Thanks for being here! There aren't any campaigns to show
                    here, yet.
                  </Text>
                </View>
              ) : null}
            </View>
          </>
        }
        refreshControl={
          <RefreshControl
            enabled
            tintColor={'black'}
            refreshing={fetching}
            onRefresh={() => getFeed({ requestPolicy: 'network-only' })}
          />
        }
        onEndReached={() => {
          if (paginateOnEndReached.current === false) return;

          if (data?.getRecentCampaigns.nextToken) {
            setNextToken(data?.getRecentCampaigns.nextToken);
          }
        }}
        data={feedItems}
        renderItem={({ item }: { item: FeedItem; index: number }) => {
          switch (item.type) {
            case 'moment_in_nature_carousel': {
              return (
                <MomentInNatureCarousel
                  feedType={MomentInNatureFeedType.ChronologicalGlobal}
                />
              );
            }
            case 'feed_title_card': {
              return (
                <View style={styles.feedTitleCard}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Lightening width={24} height={24} />
                    <Text style={styles.feedTitleText}>LIVE FEED</Text>
                  </View>
                  <Text style={styles.feedSubtitleText}>
                    Explore campaigns and updates from the field
                  </Text>
                </View>
              );
            }
            case 'news_post_card': {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'stretch',
                  }}
                >
                  {item.data.map((newsPost, index) => (
                    <View
                      key={`news-post-card-${newsPost.id}`}
                      style={[
                        SECTION_CONTAINER,
                        {
                          flex: 1,
                          padding: 0,
                          marginLeft:
                            shouldDisplayCardsInRow && index === 1 ? 0 : 8,
                        },
                      ]}
                    >
                      <NewsPostCard
                        data={newsPost}
                        hideTimestamp
                        onPress={() => {
                          props.navigation.navigate('ViewNewsPost', {
                            id: newsPost.id,
                          });
                        }}
                      />
                    </View>
                  ))}
                </View>
              );
            }
            case 'job_post_card': {
              return (
                <HorizontalContainer
                  style={{
                    alignItems: 'stretch',
                  }}
                >
                  {item.data.map((jobPost, index) => (
                    <View
                      key={`job-post-card-${jobPost.id}`}
                      style={[
                        SECTION_CONTAINER,
                        {
                          flex: 1,
                          padding: 0,
                          marginLeft:
                            index === 1 && shouldDisplayCardsInRow ? 0 : 8,
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontFamily: 'Lato-BoldItalic',
                          fontSize: 16,
                          color: 'gray',
                          margin: 8,
                          marginBottom: 2,
                        }}
                      >
                        Job Opportunity
                      </Text>
                      <JobPostCard
                        data={jobPost}
                        hideTimestamp
                        style={{
                          flex: 1,
                        }}
                        onPress={() => {
                          props.navigation.navigate('ViewJobPost', {
                            id: jobPost.id,
                          });
                        }}
                      />
                    </View>
                  ))}
                </HorizontalContainer>
              );
            }
            case 'campaign_post': {
              const campaign = item.data;
              const post = campaign?.posts.items[0];

              return post ? (
                <View style={styles.postContainer}>
                  <CampaignPost
                    page="feed"
                    hideTimestamp
                    campaign={campaign}
                    data={post}
                    disableAnimateIn
                  />
                </View>
              ) : null;
            }
            default:
              return null;
          }
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F2F2FB',
    flex: 1,
    height: '100%',
  },
  postContainer: {
    paddingHorizontal: 8,
  },
  footerContainer: {
    marginBottom: -50,
  },
  activityIndicator: {
    marginBottom: 32,
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    padding: 24,
    color: 'gray',
    textAlign: 'center',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    padding: 24,
    color: 'gray',
    textAlign: 'center',
    alignSelf: 'center',
  },
  feedTitleCard: {
    ...SECTION_CONTAINER,
  },
  feedTitleText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
    marginLeft: 4,
  },
  feedSubtitleText: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: 'gray',
    marginTop: 4,
  },
  topBannerHeader: {
    padding: 12,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.05)',
  },
  tutorialHeaderContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tutorialLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tutorialLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    color: KEY_GRAY,
  },
});

export default FeedScreen;
