import { useCallback, useRef } from 'react';
import { Platform, View, ViewProps } from 'react-native';
import { getScrollableNode } from '/util';

interface CellRendererProps extends ViewProps {
  index: number;
  children: React.ReactNode;
  style?: any;
}

interface UseScrollViewScrollToIndexReturn {
  CellRenderer: React.FC<CellRendererProps>;
  scrollToIndex: (index: number, offset?: number) => void;
  sectionPositions: React.MutableRefObject<number[]>;
}

/**
 * Hook to scroll to a specific index in a ScrollView (similar to VirtualizedList.scrollToIndex)
 * To use, wrap each item in the list with the returned CellRenderer, and call scrollToIndex with
 * the index of the item to scroll to.
 * @param scrollViewRef - The ref of the ScrollView
 * @returns An object containing the CellRenderer, scrollToIndex, and sectionPositions.
 */
export const useScrollViewScrollToIndex = (
  scrollViewRef: React.RefObject<any>,
): UseScrollViewScrollToIndexReturn => {
  const sectionPositions = useRef<number[]>([]);

  const scrollToIndex = useCallback(
    (index: number, offset: number = 0) => {
      const scrollView = getScrollableNode(scrollViewRef.current);

      if (typeof scrollView?.scrollTo === 'function') {
        scrollView?.scrollTo({
          y: Math.max(0, sectionPositions.current[index] - offset),
          animated: true,
        });
      } else if (typeof scrollView?.scrollToOffset === 'function') {
        scrollView?.scrollToOffset({
          offset: Math.max(0, sectionPositions.current[index] - offset),
          animated: true,
        });
      } else if (typeof scrollView?.scrollResponderScrollTo === 'function') {
        scrollView?.scrollResponderScrollTo({
          y: Math.max(0, sectionPositions.current[index] - offset),
          animated: true,
        });
      } else if (typeof scrollView?.scrollToIndex === 'function') {
        scrollView?.scrollToIndex({
          index,
          animated: true,
        });
      } else {
        throw new Error('Scrollable node does not support scrollTo');
      }
    },
    [scrollViewRef],
  );

  const CellRenderer = useCallback(
    ({ children, index, style, ...props }: CellRendererProps) => {
      return (
        <View
          {...props}
          style={style}
          {...Platform.select({
            web: {
              ref: (element) => {
                if (element) {
                  const measure = () => {
                    const scrollElement = Platform.select({
                      web: scrollViewRef.current?._listRef?._scrollRef,
                      default: null,
                    });

                    element.measure((x, y, width, height, pageX, pageY) => {
                      sectionPositions.current[index] =
                        pageY + (scrollElement?.scrollTop ?? 0);
                    });
                  };

                  setTimeout(measure, 500);
                }
              },
            },
            default: {
              onLayout: (event) => {
                const { y } = event.nativeEvent.layout;
                sectionPositions.current[index] = y;
              },
            },
          })}
        >
          {children}
        </View>
      );
    },
    [scrollViewRef],
  );

  return {
    CellRenderer,
    scrollToIndex,
    sectionPositions,
  };
};
