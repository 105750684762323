import { ResizeMode } from 'expo-av';
import * as DocumentPicker from 'expo-document-picker';
import {
  MediaType as ExpoMediaType,
  launchImageLibraryAsync,
} from 'expo-image-picker';
import React, { Component, useEffect } from 'react';
import { Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import ffmpeg from '/util/ffmpeg';

import {
  ActionSheetProps,
  connectActionSheet,
} from '@mtourj/react-native-action-sheet';

import { isCancelError } from 'aws-amplify/storage';
import withFileUpload, { FileUploadProps } from '../withFileUpload';
import { determineIfVideo } from '/util';

import _ from 'lodash';
import { useDropzone as _useDropzone } from 'react-dropzone';
import { MediaEditorMedia } from '../MediaEditor/MediaEditor';
import withMediaEditor, { WithMediaEditorProps } from '../withMediaEditor';
import CustomUploadComponent, {
  CustomUploadComponentProps,
} from './components/CustomUploadComponent';
import DefaultUploadComponent from './components/DefaultUploadComponent';
import Alert from '/Alert';

const MEDIA_TYPES = {
  All: ['videos', 'images'],
  Images: ['images'],
  Videos: ['videos'],
} as Record<string, ExpoMediaType[]>;

const DragAndDrop = ({
  children,
  style,
  onDrop,
  onDragEnter,
  onDragLeave,
  accept,
  multiple = false,
}: React.PropsWithChildren<any>) => {
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    multiple,
    accept,
    onDrop,
  });

  useEffect(() => {
    if (isDragActive) {
      onDragEnter?.();
    } else {
      onDragLeave?.();
    }
  }, [isDragActive, onDragEnter, onDragLeave]);

  const inputProps = getInputProps();
  const rootProps = getRootProps();

  return Platform.OS === 'web' ? (
    <div
      {...rootProps}
      onClick={undefined}
      style={{ display: 'flex', flexDirection: 'column', ...style }}
    >
      <input
        {...getInputProps()}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();

          inputProps?.onDrop?.(e);
        }}
      />
      {children}
    </div>
  ) : (
    <View style={style}>{children}</View>
  );
};

const useDropzone =
  Platform.OS === 'web'
    ? _useDropzone
    : () => ({
        getRootProps() {
          return undefined;
        },
        getInputProps() {
          return undefined;
        },
        isDragActive: false,
      });

export type UploadMediaItem = {
  uri: string;
  thumbnailUri?: string;
};

type UploadMediaUploadProgress = {
  progress: number;
  loadedBytes: number;
  totalBytes: number;
  uploads: { uri: string; thumbnailUri?: string }[];
};

export interface IUploadMediaProps extends React.PropsWithRef<{}> {
  media?: {
    uri: string;
    thumbnailUri?: string;
  }[];
  /** ONLY WORKS ON IMAGES. If set, upload media will use those dimensions when opening ImagePicker */
  targetMediaDimensions?: {
    width: number;
    height: number;
  };
  /** If true, images uploaded here will not be deleted when user's data is deleted */
  persistAfterUserDeletion?: boolean;
  disabled?: boolean;
  /** Default is 'All' */
  mediaType?: 'All' | 'Images' | 'Videos';
  style?: StyleProp<ViewStyle>;
  fontSize?: number;
  circular?: boolean;
  multiple?: boolean;
  /** If `multiple` is set, this dictates the maximum number of files */
  selectionLimit?: number;
  /** Setting to `true` will display a placeholder image when empty */
  isAvatar?: boolean;
  size?: 'auto' | number;
  removable?: boolean;
  title?: string;
  hideProgressIndicator?: boolean;
  skipThumbnailGeneration?: boolean;
  previewResizeMode?: ResizeMode;
  /** Replace default UploadMedia component with a custom component */
  renderComponent?: (props: CustomUploadComponentProps) => JSX.Element;
  placeholderImage?: JSX.Element | (() => JSX.Element);
  /** Callback function called when ready media items (fully uploaded) change */
  onChangeReadyMedia?: (readyMedia: UploadMediaItem[]) => void;
  /** Internal callback for tracking all media states including pending uploads and errors.
   * Most applications should use onChangeReadyMedia instead. */
  _onChangeMediaState?: (allMedia: MediaItemState[]) => void;
  /** Callback function called when an upload starts. Can be called while an upload is already in progress. */
  onUploadStart?: (media: UploadMediaItem[]) => void;
  /** Callback function called when upload progress has been made */
  onUploadProgress?: (info: UploadMediaUploadProgress) => void;
  /** Callback function called once all uploads have ended successfully */
  onUploadSuccess?: () => void;
  /** Callback function called when an upload ends in a failure.
   * @param retry Callback function to retry all failed uploads */
  onUploadError?: (retry: () => void) => void;
  /** Callback function called once all uploads have ended, regardless of success. Called with an array of
   * media URIs that failed to upload */
  onUploadEnd?: () => void;
}

export type MediaItemState = Omit<MediaEditorMedia, 'asset'> & {
  pendingUpload: boolean;
  upload?: UploadMediaUpload;
  pickerRequestId?: string;
  asset?: MediaEditorMedia['asset'];
};

type UploadMediaUpload = {
  uploading: boolean;
  uploadLoaded: number;
  uploadTotal: number;
  uploadError: boolean;
  generatingThumbnail: boolean;
  cancelUpload: (() => void) | undefined;
};

function toUploadMediaItems(media: MediaItemState[]): UploadMediaItem[] {
  return media.map((m) => ({
    uri: m.uri,
    thumbnailUri: m.thumbnailUri,
  }));
}

interface IUploadMediaState {
  media: MediaItemState[];
  currentMedia: MediaItemState | undefined;
  currentIndex: number;
  /** Any object URLs that need to be revoked on component unmount to avoid
   * memory leaks */
  revokeObjectURLs: string[];
}

export class UploadMediaClass extends Component<
  IUploadMediaProps & ActionSheetProps & FileUploadProps & WithMediaEditorProps,
  IUploadMediaState
> {
  constructor(
    props: IUploadMediaProps &
      ActionSheetProps &
      FileUploadProps &
      WithMediaEditorProps,
  ) {
    super(props);

    this.state = {
      media:
        this.props.media?.map((m) => ({
          uri: m?.uri || '',
          thumbnailUri: m?.thumbnailUri || '',
          cropData: undefined,
          pendingUpload: false,
        })) ?? [],
      currentIndex: 0,
      currentMedia: undefined,
      revokeObjectURLs: [],
    };
  }

  get uploading() {
    return this.state.media.some((u) => u.upload?.uploading);
  }

  get uploadProgress(): {
    progress: number;
    loadedBytes: number;
    totalBytes: number;
  } {
    const uploads = this.state.media
      .map((m) => m.upload)
      .filter((upload): upload is UploadMediaUpload => !!upload);
    if (!uploads.length) return { progress: 0, loadedBytes: 0, totalBytes: 0 };

    const [totalLoaded, total] = uploads.reduce(
      (acc, up) => {
        return [acc[0] + up.uploadLoaded, acc[1] + up.uploadTotal];
      },
      [0, 0],
    );

    return {
      progress: total ? (totalLoaded / total) * 100 : 0,
      totalBytes: total,
      loadedBytes: totalLoaded,
    };
  }

  get generatingThumbnail() {
    return this.state.media.some((m) => m.upload?.generatingThumbnail);
  }

  _getActionSheetOptions(mediaIndex: number) {
    const mediaType =
      this.props.mediaType === 'Images'
        ? 'Image'
        : this.props.mediaType === 'Videos'
        ? 'Video'
        : 'Media';

    return {
      cached: {
        title: 'Media Picker',
        options: [
          'Adjust Crop',
          `Change ${mediaType}`,
          `Remove ${mediaType}`,
          'Cancel',
        ],
        cancelButtonIndex: 3,
        destructiveButtonIndex: 2,
        onPress: (index: number | undefined) => {
          switch (index) {
            case 0: {
              this._editItems();
              return;
            }
            case 1: {
              this._pickMedia();
              return;
            }
            case 2: {
              this.removeMedia(mediaIndex);
              return;
            }
            default: {
              return;
            }
          }
        },
      },
      uncached: {
        title: 'Media Picker',
        options: [`Change ${mediaType}`, `Remove ${mediaType}`, 'Cancel'],
        cancelButtonIndex: 2,
        destructiveButtonIndex: 1,
        onPress: (index: number | undefined) => {
          switch (index) {
            case 0: {
              this._pickMedia();
              return;
            }
            case 1: {
              this.removeMedia(mediaIndex);
              return;
            }
            default: {
              return;
            }
          }
        },
      },
    };
  }

  _editItems = () => {
    this.props.openEditor({
      media: this.state.media,
      onFinishEditing: this.onEditorClose,
    });
  };

  /** Cancels all uploads and removes media pending upload */
  cancelAllUploads() {
    this.state.media.forEach((m) => m.upload?.cancelUpload?.());

    this.setState((prevState) => ({
      currentMedia: undefined,
      media: prevState.media.filter((m) => !m.pendingUpload),
    }));
  }

  _pickMedia = async (files?: File[]) => {
    try {
      /**
       * If we are on Web, then we want to use the document picker instead because
       * ExpoMediaLibrary, which ImagePicker depends on, does not support the browser.
       */
      if (Platform.OS === 'web') {
        let targetTypes;

        if (this.props.mediaType === 'All' || !this.props.mediaType) {
          targetTypes = ['image/*', 'video/*'];
        } else if (this.props.mediaType === 'Videos') {
          targetTypes = ['video/*'];
        } else {
          targetTypes = ['image/*'];
        }

        let _files: DocumentPicker.DocumentPickerAsset[] = [];

        if (!files?.length) {
          const pickerResult = await DocumentPicker.getDocumentAsync({
            type: targetTypes,
            multiple: this.props.multiple,
          });

          if (pickerResult.canceled || !pickerResult.assets?.length) return;

          if (
            typeof this.props.selectionLimit === 'number' &&
            pickerResult.assets.length > this.props.selectionLimit
          ) {
            // eslint-disable-next-line no-alert
            alert(`You can only select ${this.props.selectionLimit} items`);
            _files = pickerResult.assets.slice(0, this.props.selectionLimit);
          }

          _files = pickerResult.assets;
        } else {
          _files = files.map((f) => ({
            file: f,
            uri: URL.createObjectURL(f),
            name: f.name,
            lastModified: f.lastModified,
            mimeType: f.type,
            size: f.size,
          }));
        }

        let validatedMedia: {
          item: DocumentPicker.DocumentPickerAsset;
          dimensions: {
            width: number | undefined;
            height: number | undefined;
          };
        }[] = [];

        const getImageDimensions = async (
          doc: DocumentPicker.DocumentPickerAsset,
        ) => {
          const { width, height }: any = await new Promise((resolve) => {
            const img = new Image();
            img.addEventListener('load', function () {
              resolve({
                width: this.naturalWidth,
                height: this.naturalHeight,
              });
            });
            img.src = (doc as any).uri;
          });

          return {
            width: width as number,
            height: height as number,
          };
        };

        for (let i = 0; i < _files.length; i++) {
          const item = _files[i];

          if (!item) continue;

          const isVideo = determineIfVideo(item.name);

          // Make sure the file matches the media type specified
          if (
            (this.props.mediaType === 'Images' && isVideo) ||
            (this.props.mediaType === 'Videos' && !isVideo)
          ) {
            URL.revokeObjectURL(item.uri);
            continue;
          }

          // Get the size of the file in MB
          if (item.size) {
            const documentSize = item.size / 1024 / 1024;

            if (documentSize > 200) {
              Alert.alert(
                'Media too large',
                'Please select a file smaller than 200MB',
              );
              URL.revokeObjectURL(item.uri);
              continue;
            }
          }

          validatedMedia.push({
            item,
            dimensions: isVideo
              ? { width: undefined, height: undefined }
              : await getImageDimensions(item),
          });
        }

        if (!validatedMedia.length) return;

        // Add selected files' object URL to the revokeObjectURLs array so that they can be
        // revoked on component unmount
        this.setState((prevState) => ({
          revokeObjectURLs: [
            ...prevState.revokeObjectURLs,
            ...validatedMedia.map((m) => m.item.uri),
          ],
        }));

        const newMedia: MediaItemState[] = validatedMedia.map((media) => ({
          uri: media.item.uri,
          pendingUpload: true,
          asset: {
            uri: media.item.uri,
            duration: 0,
            width: media.dimensions.width as any,
            height: media.dimensions.height as any,
            mediaType: determineIfVideo(media.item.name) ? 'video' : 'photo',
          },
        }));

        /** If at least one item is a photo, open the editor for cropping */
        if (newMedia.some((m) => m.asset?.mediaType === 'photo')) {
          this.props.openEditor({
            media: newMedia,
            onFinishEditing: this.onEditorClose,
            circleCrop: this.props.circular,
            targetMediaDimensions: this.props.targetMediaDimensions,
          });
        } else {
          this.onEditorClose(newMedia as MediaEditorMedia[]);
        }
      } else {
        // Create a unique ID for this picker request
        const requestId = Math.random().toString(36).substring(2);

        // Add a single placeholder
        this.setState((prevState) => ({
          media: this.props.multiple
            ? [
                ...prevState.media,
                { uri: '', pendingUpload: true, pickerRequestId: requestId },
              ]
            : [{ uri: '', pendingUpload: true, pickerRequestId: requestId }],
        }));

        try {
          const result = await launchImageLibraryAsync({
            selectionLimit: this.props.multiple ? this.props.selectionLimit : 1,
            mediaTypes: this.props.mediaType
              ? MEDIA_TYPES[this.props.mediaType]
              : MEDIA_TYPES.All,
            allowsMultipleSelection: this.props.multiple,
          });

          if (result.canceled || !result.assets.length) return;

          const media = result.assets.map((asset) => {
            const type = asset.type;

            const isVideo = type
              ? type === 'video'
              : determineIfVideo(asset.uri); // `type` is optional, so we fallback on checking the uri

            return {
              uri: asset.uri,
              pendingUpload: true,
              asset: {
                id: asset.assetId,
                uri: asset.uri,
                duration: asset.duration,
                width: asset.width,
                height: asset.height,
                filename: asset.fileName || undefined,
                mediaType: isVideo ? 'video' : 'photo',
              },
            } as MediaItemState;
          });

          /** If at least one item is a photo, open the editor for cropping */
          if (media.some((m) => m.asset?.mediaType === 'photo')) {
            this.props.openEditor({
              media,
              onFinishEditing: this.onEditorClose,
              circleCrop: this.props.circular,
              targetMediaDimensions: this.props.targetMediaDimensions,
            });
            /** Otherwise skip the editor (No thumbnail picker support on web yet) */
          } else {
            this.onEditorClose(media as MediaEditorMedia[]);
          }
        } catch (error) {
          Alert.alert(
            'Error',
            'Something went wrong while trying to pick media. Please try again.',
          );
          console.error(error);
        } finally {
          // Remove the placeholder for this request
          this.setState((prevState) => ({
            media: prevState.media.filter(
              (m) => m.pickerRequestId !== requestId,
            ),
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onEdit = () => {
    // things break when we try to edit while uploading
    if (this.uploading) return;

    if (
      !this.props.multiple &&
      this.props.removable &&
      this.state.currentMedia?.uri
    ) {
      // TODO: Bring this back (where original media is stored to allow re-adjusting the crop)
      // Important consideration: Preventing/Supporting re-editing crop after it's been uploaded
      // const actionSheetOptions = this.state.media.original
      //   ? this._getActionSheetOptions(this.state.currentIndex).cached
      //   : this._getActionSheetOptions(this.state.currentIndex).uncached;
      const actionSheetOptions = this._getActionSheetOptions(
        this.state.currentIndex,
      ).uncached;

      this.props.showActionSheetWithOptions(
        actionSheetOptions,
        actionSheetOptions.onPress,
      );
    } else {
      this._pickMedia();
    }
  };

  ensureValidCurrentIndex() {
    let currentIndex = Math.min(
      this.state.currentIndex || 0,
      Math.max(0, this.state.media.length - 1),
    );

    if (this.state.currentIndex !== currentIndex) {
      this.setState({
        currentIndex,
      });
    }

    if (this.state.currentMedia !== this.state.media[currentIndex]) {
      this.setState({
        currentMedia: this.state.media[currentIndex],
      });
    }
  }

  componentDidMount() {
    this.ensureValidCurrentIndex();
  }

  componentDidUpdate(
    prevProps: IUploadMediaProps,
    prevState: IUploadMediaState,
  ) {
    this.ensureValidCurrentIndex();

    const prevStateReadyMedia = toUploadMediaItems(
      prevState.media.filter((m) => !m.pendingUpload),
    );
    const currentStateReadyMedia = toUploadMediaItems(
      this.state.media.filter((m) => !m.pendingUpload),
    );
    if (!_.isEqual(prevStateReadyMedia, currentStateReadyMedia)) {
      this.props.onChangeReadyMedia?.(currentStateReadyMedia);
    }

    if (!_.isEqual(prevState.media, this.state.media)) {
      this.props._onChangeMediaState?.(this.state.media);
    }

    /** If `media` in props change, sync */
    if (!_.isEqual(this.props.media, prevProps.media)) {
      const derivedState =
        this.props.media
          ?.map((m) => {
            const existingItem = this.state.media.find(
              (existing) => existing.uri === m.uri,
            );
            return {
              ...existingItem,
              ...m,
              /** Ensure pendingUpload is correctly set */
              pendingUpload: existingItem?.pendingUpload || false,
              /** Ensure thumbnail is not lost even if parent doesn't store it */
              thumbnailUri: m.thumbnailUri || existingItem?.thumbnailUri,
            };
          })
          // Remove items with no uri
          .filter((m) => m.uri) ?? [];

      if (
        !_.isEqual(
          derivedState,
          this.state.media.filter((m) => !m.pendingUpload),
        )
      ) {
        const removedItems = this.state.media.filter(
          (m) => !derivedState.some((u) => u.uri === m.uri),
        );

        // Appropriate cleanup for removed items
        removedItems.forEach((m) => {
          // Revoke object URLs
          if (m.uri?.startsWith('data:')) {
            URL.revokeObjectURL(m.uri);
          }

          // Cancel upload if it's still in progress
          if (m.upload) {
            m.upload.cancelUpload?.();
          }
        });

        this.setState({
          media: derivedState,
        });
      }
    }
  }

  componentWillUnmount() {
    // Cancel uploads if unmounting
    if (this.uploading) {
      this.cancelAllUploads?.();
      this.props.onUploadEnd?.();
    }

    this.clearState();
  }

  onEditorClose = (media: MediaEditorMedia[]) => {
    if (!media.length) return;

    const newMedia = media.map((m) => ({
      ...m,
      pendingUpload: true,
    }));

    this.setState(
      (prevState) => {
        const updatedMedia = this.props.multiple
          ? prevState.media.concat(...newMedia)
          : newMedia;
        return { media: updatedMedia };
      },
      () => {
        this.uploadMedia();
      },
    );
  };

  onUploadProgress = () => {
    const uploads = this.state.media.filter((media) => !!media.upload);
    const progress = this.uploadProgress;

    this.props.onUploadProgress?.({
      progress: progress.progress,
      loadedBytes: progress.loadedBytes,
      totalBytes: progress.totalBytes,
      uploads,
    });
  };

  // Uploads media in state
  uploadMedia = () => {
    const itemsToUpload = this.state.media.filter(
      (m) => m.pendingUpload && !m.upload?.uploading,
    );

    // If no items to upload, exit early
    if (!itemsToUpload.length) return;

    this.props.onUploadStart?.(toUploadMediaItems(itemsToUpload));

    this.setState((prevState) => {
      return {
        media: prevState.media.map((media) => {
          // Whenever we start a new upload, we want to clear `upload` from media objects
          // that are not pending upload. Allows us to continue getting accurate progress number
          // as user adds uploads.
          if (!media.pendingUpload) return { ...media, upload: undefined };

          /** Don't try to upload if upload is already in progress */
          if (media.upload?.uploading) return media;

          const uris = [media.uri];
          if (media.thumbnailUri) uris.push(media.thumbnailUri);

          const cancelUpload = this.props.uploadMultiple({
            persistAfterUserDeletion: this.props.persistAfterUserDeletion,
            uris,
            onUploadComplete: async ([uri, thumbnailUri]) => {
              const generateThumb = async () => {
                const isVideo = determineIfVideo(uri);

                if (!isVideo) {
                  return uri;
                } else {
                  this.setState((_prevState) => ({
                    media: _prevState.media.map((m) => {
                      if (m.uri !== media.uri) return m;
                      else
                        return {
                          ...m,
                          upload: m.upload
                            ? {
                                ...m.upload,
                                generatingThumbnail: true,
                              }
                            : undefined,
                        };
                    }),
                  }));

                  try {
                    return await ffmpeg.generateVideoThumbnail({
                      source: uri,
                      useOriginalFilename: true,
                    });
                  } catch (error) {
                    console.error(error);
                  } finally {
                    this.setState((_prevState) => ({
                      media: _prevState.media.map((m) => {
                        if (m.uri !== media.uri) return m;
                        else
                          return {
                            ...m,
                            upload: m.upload
                              ? {
                                  ...m.upload,
                                  generatingThumbnail: false,
                                }
                              : undefined,
                          };
                      }),
                    }));
                  }
                }
              };

              // generate a thumbnail if one doesn't exist
              const finalizedThumbnail =
                thumbnailUri ||
                (this.props.skipThumbnailGeneration
                  ? undefined
                  : await generateThumb());

              this.setState(
                (_prevState) => ({
                  media: _prevState.media.map((m) => {
                    if (m.uri !== media.uri) return m;
                    else {
                      return {
                        ...m,
                        pendingUpload: false,
                        upload: m.upload
                          ? {
                              ...m.upload,
                              uploading: false,
                            }
                          : undefined,
                        uri,
                        thumbnailUri: finalizedThumbnail,
                      };
                    }
                  }),
                }),
                () => {
                  const otherUploadsStillInProgress = this.state.media.some(
                    (m) => m.upload?.uploading,
                  );

                  if (!otherUploadsStillInProgress) {
                    this.props.onUploadSuccess?.();
                    this.props.onUploadEnd?.();
                  }
                },
              );
            },
            onUploadProgress: (loaded, total) => {
              this.setState(
                (_prevState) => ({
                  media: _prevState.media.map((m) => {
                    if (m.uri !== media.uri) return m;
                    else {
                      return {
                        ...m,
                        upload: {
                          cancelUpload: m.upload?.cancelUpload,
                          generatingThumbnail: !!m.upload?.generatingThumbnail,
                          uploadError: false,
                          uploading: true,
                          uploadLoaded: loaded,
                          uploadTotal: total,
                        },
                      };
                    }
                  }),
                }),
                () => {
                  this.onUploadProgress();
                },
              );
            },
            onUploadFailed: (error) => {
              console.warn('UPLOAD FAILED:', error);
              if (error?.errors?.every?.((err: any) => isCancelError(err)))
                // If this is a cancel error, then just return
                return;

              this.setState(
                (_prevState) => ({
                  media: _prevState.media.map((m) => {
                    if (m.uri !== media.uri) return m;
                    else {
                      return {
                        ...m,
                        upload: {
                          ...m.upload,
                          cancelUpload: m.upload?.cancelUpload,
                          generatingThumbnail: !!m.upload?.generatingThumbnail,
                          uploadLoaded: 0,
                          uploadTotal: 0,
                          uploading: false,
                          uploadError: true,
                        },
                      };
                    }
                  }),
                }),
                () => {
                  this.props.onUploadError?.(this.retry);
                  const otherUploadsStillInProgress = this.state.media.some(
                    (m) => m.upload?.uploading,
                  );
                  if (!otherUploadsStillInProgress) {
                    this.props.onUploadEnd?.();
                  }
                },
              );
            },
          });

          const upload: UploadMediaUpload = {
            cancelUpload,
            generatingThumbnail: false,
            uploadError: false,
            uploading: true,
            uploadLoaded: 0,
            uploadTotal: 0,
          };

          media.upload = upload;

          return media;
        }),
      };
    });
  };

  retry = () => {
    this.uploadMedia();
  };

  removeCurrentMedia = () => {
    this.removeMedia(this.state.currentIndex);
  };

  removeMedia = (targetIndex: number) => {
    this.setState((prevState) => {
      const mediaToRemove = prevState.media[targetIndex];

      /**
       * If mediaToRemove has a `pickerRequestId`, then soon it either be resolved to a real upload
       * or cancelled. We don't want to remove it until then.
       */
      if (!mediaToRemove || mediaToRemove.pickerRequestId) return prevState;

      const upload = mediaToRemove.upload;
      if (upload?.uploading) {
        // Cannot remove item if its uploading and we can't cancel
        if (!upload.cancelUpload) return prevState;

        upload.cancelUpload?.();
      }

      // Revoke object URLs
      if (mediaToRemove?.uri?.startsWith('data:')) {
        URL.revokeObjectURL(mediaToRemove.uri);
      }

      const newCurrentIndex = Math.min(
        prevState.media.length - 2,
        prevState.currentIndex,
      );

      return {
        revokeObjectURLs: prevState.revokeObjectURLs.filter(
          (url) => url !== mediaToRemove.uri,
        ),
        media: prevState.media.filter((m, i) => i !== targetIndex),
        currentMedia:
          prevState.currentIndex === targetIndex
            ? prevState.media[newCurrentIndex]
            : prevState.currentMedia,
        currentIndex: newCurrentIndex,
      };
    });
  };

  clearState = () => {
    if (this.state.revokeObjectURLs.length) {
      this.state.revokeObjectURLs.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    }

    this.setState({
      media: [],
      revokeObjectURLs: [],
    });
  };

  render() {
    const flattenedStyle = StyleSheet.flatten(this.props.style ?? {});

    let accept: any = {};

    switch (this.props.mediaType) {
      case 'Images':
        accept['image/*'] = [];
        break;
      case 'Videos':
        accept['video/*'] = [];
        break;
      default:
        accept['image/*'] = [];
        accept['video/*'] = [];
        break;
    }

    return (
      <DragAndDrop
        accept={accept}
        multiple={this.props.multiple}
        onDrop={(acceptedFiles: File[]) => {
          if (acceptedFiles.length) this._pickMedia(acceptedFiles);
        }}
      >
        {/* Render custom component */}
        {this.props.renderComponent ? (
          <CustomUploadComponent
            onEdit={this.onEdit}
            style={flattenedStyle}
            uploading={this.uploading}
            disabled={this.props.disabled ?? false}
            renderComponent={this.props.renderComponent}
            media={this.state.media}
            retryUploads={this.retry}
          />
        ) : (
          <DefaultUploadComponent
            onSelectMedia={(index) => {
              this.setState({ currentIndex: index });
            }}
            currentMediaIndex={this.state.currentIndex}
            pickMedia={this._pickMedia}
            retryUploads={this.retry}
            uploadProgress={this.uploadProgress.progress}
            hideProgressIndicator={!!this.props.hideProgressIndicator}
            previewResizeMode={this.props.previewResizeMode}
            isAvatar={this.props.isAvatar ?? false}
            currentMedia={this.state.currentMedia}
            removeCurrentMedia={this.removeCurrentMedia}
            fontSize={this.props.fontSize ?? 10}
            title={this.props.title}
            media={this.state.media}
            mediaType={this.props.mediaType}
            placeholderImage={this.props.placeholderImage}
            onEdit={this.onEdit}
            style={flattenedStyle}
            uploading={this.uploading}
            disabled={this.props.disabled ?? false}
            circular={this.props.circular ?? false}
            size={this.props.size ?? 100}
            multiple={this.props.multiple ?? false}
          />
        )}
      </DragAndDrop>
    );
  }
}

export default connectActionSheet<IUploadMediaProps>(
  withMediaEditor(
    withFileUpload<ActionSheetProps & IUploadMediaProps & WithMediaEditorProps>(
      UploadMediaClass,
    ),
  ),
);
