import _ from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAuthContext } from './AuthProvider';
import Alert from '/Alert';
import { KEY_GREEN } from '/constants';
import {
  CreateUserMutationInput,
  UpdateUserMutationInput,
  useCreateUserMutation,
  UserRole,
  UserSkillPortfolioInput,
  useUpdateUserProfileMutation,
} from '/generated/graphql';
import { filterEmptyValues } from '/util';
import { useLoadingContext } from './LoadingProvider';

type OnboardContext = {
  setData: (data: Partial<OnboardingState>) => void;
  setReferralCode: (referralCode: string) => void;
  submitUser: (data?: Partial<OnboardingState>) => Promise<void>;
  data?: Partial<OnboardingState>;
};

interface IUserSkillPortfolioInput
  extends Pick<UserSkillPortfolioInput, 'skillName'> {}

export type OnboardingState = {
  id: string;
  profile_image: string;
  locations: Partial<{
    name: string;
    latitude: number;
    longitude: number;
  }>[];
  website: string;
  twitter: string;
  facebook: string;
  instagram: string;
  youtube: string;
  linkedin: string;
  phone_number: string;
  role: UserRole;
  allow_skill_solicitation: boolean;
  name: string;
  name_pronounced: string;
  bio: string;
  point_of_contact_name: string;
  point_of_contact_email: string;
  skills: IUserSkillPortfolioInput[];
  // species: Species[];
  languages_fluent: string[];
  languages_conversational: string[];
  languages_beginner: string[];
  partnerships_and_affiliations: string;
  /** Referral code */
  referral_code: string | undefined;
  /** For organizations only */
  applicationDocument: string;
};

const ctx = createContext<OnboardContext>({} as any);

export const useOnboardContext = () => useContext(ctx);

export default function OnboardProvider(props: React.PropsWithChildren<{}>) {
  const [state, _setState_] = useState<Partial<OnboardingState>>({});
  const stateRef = useRef(state);

  const { setShowLoading, setLoadingInfo }: any = useLoadingContext();

  const setState = (_state: Partial<OnboardingState>) => {
    _setState_((prevState) => {
      return {
        ...prevState,
        ..._state,
      };
    });
  };

  const [{ fetching: creating }, createUser] = useCreateUserMutation();
  const [, updateUser] = useUpdateUserProfileMutation();

  const { userAttributes, userData, refresh, isAuthenticating, fetching } =
    useAuthContext();

  const userDataRef = useRef(userData);
  useEffect(() => {
    userDataRef.current = userData;
  }, [userData]);

  useEffect(() => {
    /** Keep state up to date */
    if (userData?.id && !state.id) {
      const { application, ..._userData } = userData;

      setState({
        ..._userData,
        bio: userData?.bio?.text ?? '',
        applicationDocument: application?.document ?? '',
        referral_code: state.referral_code,
      } as OnboardingState);
    } else if (!userData && !!state.id) {
      /** if we logged out, clear state */
      _setState_({});
    }
  }, [userData, state]);

  useEffect(() => {
    /** This effect keeps stateRef up to date */
    stateRef.current = state;
  }, [state]);

  const putUser = useCallback(
    async (
      input: UpdateUserMutationInput,
      document?: string,
      referralCode?: string,
    ) => {
      if (userData?.onboarded) return;

      try {
        if (!userData?.id) {
          setShowLoading(true); // Block further updates to state while we get an ID
          setLoadingInfo('Please wait...');
          // If User record does not already exist, create it
          const { error } = await createUser({
            input: input as CreateUserMutationInput,
            refCode: referralCode,
            /** For organizations, including this will set the `document` field of OrganizationApplication */
            document,
          });

          if (error) throw error;
        } else {
          // Otherwise update it
          const { error } = await updateUser({ input, document });

          if (error) throw error;
        }
      } catch (error) {
        console.log('Error persisting onboarding changes to database', error);
        // Re-throw
        throw error;
      } finally {
        setShowLoading(false);
      }
    },
    [
      createUser,
      setLoadingInfo,
      setShowLoading,
      updateUser,
      userData?.id,
      userData?.onboarded,
    ],
  );

  const generateCreateUpdateUserMutationInput = useCallback(
    (data: Partial<OnboardingState>) => {
      return filterEmptyValues({
        id: userDataRef.current?.id,
        name: data.name || '',
        role: data.role,
        name_pronounced: data.name_pronounced,
        profile_image: data.profile_image,
        locations: data.locations?.map((loc) => ({
          latitude: loc.latitude,
          longitude: loc.longitude,
          name: loc.name,
        })),
        bio: { text: data.bio ?? '' },
        website: data.website,
        twitter: data.twitter,
        facebook: data.facebook,
        instagram: data.instagram,
        linkedin: data.linkedin,
        youtube: data.youtube,
        partnerships_and_affiliations: data.partnerships_and_affiliations,
        phone_number: data.phone_number,
        point_of_contact_name: data.point_of_contact_name!,
        skills: data.skills?.map((skill) => ({
          skillName: skill.skillName,
        })),
        languages_fluent: data.languages_fluent,
        languages_conversational: data.languages_conversational,
        languages_beginner: data.languages_beginner,
      }) as UpdateUserMutationInput;
    },
    [],
  );

  /** Persist state to database */
  const sync = useCallback(
    (data: Partial<OnboardingState>) => {
      /** If we are currently waiting for a `create` request for a new user object, then do not continue
       * because once that operation is done, ego user will have an ID and this function will need
       * to behave differently */
      if (creating) {
        return;
      }

      const input = generateCreateUpdateUserMutationInput(data);

      return putUser(
        input,
        data.applicationDocument,
        stateRef.current.referral_code,
      );
    },
    [creating, generateCreateUpdateUserMutationInput, putUser],
  );

  const setReferralCode = useCallback((referralCode: string) => {
    setState({ referral_code: referralCode });
  }, []);

  /** Submit partial user data */
  const setData = useCallback(
    (data: Partial<OnboardingState>) => {
      /** Don't do anything if we are already onboarded, or if we are still fetching user data */
      if (
        isAuthenticating ||
        fetching ||
        !userAttributes?.sub ||
        (userData?.id && userData.onboarded)
      )
        return;

      const prevState = { ...stateRef.current };
      const newState = { ...prevState, ...data };

      /** If no changes were found, stop here */
      if (_.isEqual(prevState, newState)) return;

      setState(data);

      // Save to database so user can come back and finish later
      sync(data)?.then(() => {
        Alert.notify({
          color: KEY_GREEN,
          message: 'Saved',
        });
      });
    },
    [
      fetching,
      isAuthenticating,
      sync,
      userAttributes?.sub,
      userData?.id,
      userData?.onboarded,
    ],
  );

  /** Validate and create finalized user
   * @param data Any additional changes to apply to onboarding state before submitting
   */
  const submitUser = useCallback(
    async (data?: Partial<OnboardingState>) => {
      try {
        const _state_ = stateRef.current;

        const newState = {
          ..._state_,
          ...(data ?? {}),
        };

        // Update state with changes, if any
        if (data) setState(newState);

        const newUserObj = generateCreateUpdateUserMutationInput(newState);

        /** DEBUG */
        // console.log('Creating new user object:', newUserObj);

        try {
          await putUser(
            {
              ...newUserObj,
              /** Mark user as `onboarded` so they may begin using the app */
              onboarded: true,
            },
            newState.applicationDocument,
            newState.referral_code,
          );
        } catch (error: any) {
          if (error) {
            // Throw errors unless it is a "user already exists" error
            if (!error.message.includes('User already exists')) throw error;
          }
        }

        /** Update state */
        await refresh();
      } catch (error) {
        console.log(error);
        throw new Error('Failed to submit user: ' + error);
      }
    },
    [generateCreateUpdateUserMutationInput, refresh, putUser],
  );

  return (
    <ctx.Provider
      value={{
        setData,
        setReferralCode,
        submitUser,
        data: state,
      }}
    >
      {props.children}
    </ctx.Provider>
  );
}
