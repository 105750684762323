import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import DateTimePicker from '/components/DateTimePicker/DateTimePicker';
import { KEY_DARK_GREEN, KEY_GREEN } from '/constants';
import { EventFilterInput } from '/generated/graphql';
import { ScrollView } from 'react-native';

type Props = {
  initialFilter?: Pick<
    EventFilterInput,
    'minStartDate' | 'maxStartDate'
  > | null;
  onRequestClose: (
    filter: Pick<EventFilterInput, 'minStartDate' | 'maxStartDate'>,
  ) => void;
};

export default function EventDateFilterModal(props: Props) {
  const [minDateOption, setMinDateOption] = useState<'any' | 'specific'>(
    props.initialFilter?.minStartDate ? 'specific' : 'any',
  );
  const [maxDateOption, setMaxDateOption] = useState<'any' | 'specific'>(
    props.initialFilter?.maxStartDate ? 'specific' : 'any',
  );
  const [minDate, setMinDate] = useState<Date | undefined>(
    props.initialFilter?.minStartDate,
  );
  const [maxDate, setMaxDate] = useState<Date | undefined>(
    props.initialFilter?.maxStartDate,
  );

  function onApply() {
    props.onRequestClose({
      minStartDate: minDateOption === 'specific' ? minDate : undefined,
      maxStartDate: maxDateOption === 'specific' ? maxDate : undefined,
    });
  }

  return (
    <View>
      <ScrollView>
        <HorizontalContainer style={styles.container}>
          <View style={styles.dateFieldContainer}>
            <Text style={styles.dateFieldLabel}>Starts After</Text>
            <RadioOption
              label="Any"
              value="any"
              selectedValue={minDateOption}
              onSelect={(value) =>
                setMinDateOption(value as 'any' | 'specific')
              }
            />
            <RadioOption
              label="Specific Date"
              value="specific"
              selectedValue={minDateOption}
              onSelect={(value) =>
                setMinDateOption(value as 'any' | 'specific')
              }
            />
            <DateTimePicker
              mode="date"
              value={minDate}
              onChange={setMinDate}
              style={{
                opacity: minDateOption === 'any' ? 0.5 : 1,
                pointerEvents: minDateOption === 'any' ? 'none' : 'auto',
              }}
            />
          </View>
          <View style={styles.dateFieldContainer}>
            <Text style={styles.dateFieldLabel}>Starts Before</Text>
            <RadioOption
              label="Any"
              value="any"
              selectedValue={maxDateOption}
              onSelect={(value) =>
                setMaxDateOption(value as 'any' | 'specific')
              }
            />
            <RadioOption
              label="Specific Date"
              value="specific"
              selectedValue={maxDateOption}
              onSelect={(value) =>
                setMaxDateOption(value as 'any' | 'specific')
              }
            />
            <DateTimePicker
              mode="date"
              value={maxDate}
              onChange={setMaxDate}
              style={{
                opacity: maxDateOption === 'any' ? 0.5 : 1,
                pointerEvents: maxDateOption === 'any' ? 'none' : 'auto',
              }}
            />
          </View>
        </HorizontalContainer>
      </ScrollView>

      <HorizontalContainer
        style={{ justifyContent: 'flex-end', marginTop: 20 }}
      >
        {minDateOption === 'specific' || maxDateOption === 'specific' ? (
          <Button
            label="Reset"
            onPress={() => {
              setMinDateOption('any');
              setMaxDateOption('any');
              setMinDate(undefined);
              setMaxDate(undefined);
            }}
            containerStyle={{
              marginRight: 8,
            }}
          />
        ) : null}

        <Button
          label="Apply"
          style={{ backgroundColor: KEY_GREEN }}
          onPress={onApply}
        />
      </HorizontalContainer>
    </View>
  );
}

const RadioOption = ({
  label,
  value,
  selectedValue,
  onSelect,
}: {
  label: string;
  value: string;
  selectedValue: string;
  onSelect: (value: string) => void;
}) => (
  <TouchableOpacity style={styles.radioOption} onPress={() => onSelect(value)}>
    <View style={styles.radioButton}>
      {selectedValue === value && <View style={styles.radioButtonInner} />}
    </View>
    <Text>{label}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  dateFieldContainer: {
    alignItems: 'flex-start',
    paddingHorizontal: 4,
    marginTop: 10,
    minWidth: 150,
  },
  dateFieldLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    marginBottom: 8,
  },
  radioOption: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  radioButton: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  radioButtonInner: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: KEY_DARK_GREEN,
  },
});
