import { AntDesign, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Pressable,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import ReactNativePhoneInput from 'react-native-phone-input';
import styles from '../RequestVolunteers.style';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import DateTimePickerCore from '/components/DateTimePicker/DateTimePickerCore';
import GridList from '/components/GridList';
import LocationInputWithMap from '/components/LocationInputWithMap/LocationInputWithMap';
import NumberInput from '/components/NumberInput/NumberInput';
import PhoneInputWithCountryPicker from '/components/PhoneInputWithCountryPicker';
import UploadMedia from '/components/UploadMedia/UploadMedia';
import {
  ValidatedAny,
  ValidatedTextInput,
  useFormValidationContext,
} from '/components/ValidatedForm';
import VolunteerDifficultyButton from '/components/VolunteerDifficultyButton/VolunteerDifficultyButton';
import {
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
  TEXT_INPUT,
  TEXT_INPUT_LARGE,
} from '/constants';
import sharedStyles from '/constants/CampaignBuilder';
import { useTeamContext } from '/context';
import {
  TeamMember,
  User,
  VolunteerRequest,
  VolunteerRequestDifficulty,
  VolunteerRequestPointOfContactInput,
  useGetTeamQuery,
} from '/generated/graphql';
import { isValidEmail } from '/util';

type Props = {
  data: IVolunteerRequest | undefined;
  onChange: (data: Partial<IVolunteerRequest>) => void;
  sectionContainerStyle?: ViewStyle;
  containerStyle?: ViewStyle;
};

export interface IVolunteerRequest
  extends Pick<
    VolunteerRequest,
    | 'accessibility_details'
    | 'activity_waiver_uri'
    | 'accessibility_friendly'
    | 'additional_directions'
    | 'bathrooms_details'
    | 'bathrooms_nearby'
    | 'date'
    | 'description'
    | 'difficulty'
    | 'difficulty_details'
    | 'kid_friendly'
    | 'kid_friendly_details'
    | 'know_before_you_go'
    | 'location'
    | 'latitude'
    | 'longitude'
    | 'volunteers_needed'
  > {
  id?: string;
  point_of_contact: VolunteerRequestPointOfContactInput;
}

export default function VolunteerRequestForm({
  data,
  onChange,
  ...props
}: Props) {
  const { activeTeam } = useTeamContext();

  const { fields } = useFormValidationContext();

  const [pointOfContactMode, setPointOfContactMode] = useState<
    'manual_entry' | 'team_member' | undefined
  >(
    data?.point_of_contact?.userId
      ? 'team_member'
      : Object.entries(data?.point_of_contact ?? {}).some(
          ([key, value]) => !!value && key !== '__typename',
        )
      ? 'manual_entry'
      : undefined,
  );

  const phoneRef = useRef<ReactNativePhoneInput<typeof TextInput>>();

  const sectionStyle = [styles.section, props.sectionContainerStyle];

  return (
    <View style={props.containerStyle}>
      <View style={sectionStyle}>
        <Text style={[sharedStyles.description, { fontFamily: 'Lato-Bold' }]}>
          Describe the in-person volunteer support that you need.
        </Text>

        <Text style={sharedStyles.description}>
          Be as detailed as possible with your request so volunteers know
          exactly how to help and what you want to achieve.
        </Text>

        <ValidatedTextInput
          name="description"
          value={data?.description}
          containerStyle={{
            marginTop: 8,
          }}
          placeholder="Describe the volunteer support you need..."
          placeholderTextColor="gray"
          maxLength={2000}
          multiline
          onChangeText={(description) => {
            onChange({
              ...data,
              description,
            });
          }}
          style={TEXT_INPUT_LARGE}
        />
      </View>

      <ValidatedAny
        name="volunteers_needed"
        value={data?.volunteers_needed}
        validate={(value) => {
          return !!value && value > 0 && value < 1000;
        }}
        containerStyle={[
          sectionStyle,
          {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor:
              fields.volunteers_needed?.valid === false
                ? 'crimson'
                : 'transparent',
          },
        ]}
      >
        <>
          <Text style={sharedStyles.subheading}>VOLUNTEERS NEEDED</Text>
          <Text style={sharedStyles.description}>
            Once your requested number of volunteers have signed up, the request
            will stop accepting new volunteers.
          </Text>

          <NumberInput
            value={data?.volunteers_needed ?? 0}
            onChange={(volunteers_needed) => {
              onChange({
                ...data,
                volunteers_needed,
              });
            }}
            minValue={1}
            maxValue={1000}
            style={{
              alignSelf: 'flex-end',
              marginTop: 8,
              maxWidth: 248,
            }}
          />
        </>
      </ValidatedAny>

      <View
        style={[
          sectionStyle,
          {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor:
              fields.date?.valid === false ? 'crimson' : 'transparent',
          },
        ]}
      >
        <Text style={sharedStyles.subheading}>SELECT TIME AND DATE</Text>

        <Text style={sharedStyles.description}>
          What time should volunteers show up?
        </Text>

        <Text
          style={{
            display: fields.date?.valid === false ? 'flex' : 'none',
            fontFamily: 'Lato-Bold',
            color: 'crimson',
            paddingBottom: 8,
          }}
        >
          Date must be set at least 6 hours in the future.
        </Text>

        <ValidatedAny
          name="date"
          value={data?.date}
          validate={(value) => {
            // A valid date is at least 6 hours from now
            return new Date(value).getTime() > Date.now() + 1000 * 3600 * 6;
          }}
        >
          <DateTimePickerCore
            value={new Date(data?.date ?? Date.now())}
            onChange={(date) => {
              onChange({
                ...data,
                date: date.toISOString(),
              });
            }}
          />
        </ValidatedAny>
      </View>

      <View
        style={[
          sectionStyle,
          {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor:
              fields.location?.valid === false ? 'crimson' : 'transparent',
          },
        ]}
      >
        <Text style={sharedStyles.subheading}>MEETING LOCATION</Text>
        <Text style={sharedStyles.description}>
          Please provide the address of the meet-up location. You can drag the
          marker to the exact location on the map.
        </Text>
        <ValidatedAny
          name="location"
          containerStyle={{
            zIndex: 1,
            borderRadius: 8,
            marginBottom: 8,
          }}
          value={data?.latitude}
          validate={(value) => !!value}
        >
          <LocationInputWithMap
            value={{
              name: data?.location,
              latitude: data?.latitude || undefined,
              longitude: data?.longitude || undefined,
            }}
            onChange={(location) => {
              onChange({
                ...data,
                location: location.name,
                latitude: location.latitude,
                longitude: location.longitude,
              });
            }}
            inputStyle={{
              borderWidth: 1,
              borderColor:
                fields.location?.valid === false ? 'crimson' : 'transparent',
            }}
          />
        </ValidatedAny>
        <Text style={[sharedStyles.description, { marginVertical: 8 }]}>
          Please provide any additional directions for volunteers when they
          arrive (optional)
        </Text>
        <TextInput
          value={data?.additional_directions}
          style={TEXT_INPUT_LARGE}
          multiline
          placeholder="For example: Check in with security desk at entrance..."
          placeholderTextColor="gray"
          onChangeText={(value) => {
            onChange({
              ...data,
              additional_directions: value,
            });
          }}
        />
      </View>

      <View
        style={[
          sectionStyle,
          {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor:
              fields.point_of_contact_type?.valid === false
                ? 'crimson'
                : 'transparent',
          },
        ]}
      >
        <Text style={sharedStyles.subheading}>POINT OF CONTACT</Text>
        <Text style={sharedStyles.description}>
          Designate a contact person for volunteers to meet or contact. This is
          required and will be visible to all individuals that apply.
        </Text>

        <Text
          style={[
            sharedStyles.description,
            {
              fontFamily: 'Lato-Bold',
              alignSelf: 'center',
              marginVertical: 8,
            },
          ]}
        >
          SELECT ONE
        </Text>
        <ValidatedAny
          name="point_of_contact_type"
          value={pointOfContactMode}
          containerStyle={{
            flexDirection: 'row',
          }}
        >
          {/* MODE SELECTOR BUTTONS */}
          <TouchableOpacity
            style={[styles.pointOfContactModeButton, { marginRight: 8 }]}
            onPress={() => {
              setPointOfContactMode('manual_entry');

              /** If point_of_contact.userId is set, clear it */
              if (data?.point_of_contact?.userId)
                onChange({
                  ...data,
                  point_of_contact: {
                    ...data?.point_of_contact,
                    userId: undefined,
                    profile_image: undefined,
                    position: '',
                  },
                });
            }}
          >
            {pointOfContactMode === 'manual_entry' ? (
              <View style={styles.selectedCheckmark}>
                <FontAwesome5 name="check" size={16} color="black" />
              </View>
            ) : null}
            <View
              style={{
                height: 80,
                justifyContent: 'center',
              }}
            >
              <AntDesign name="form" size={64} color="black" />
            </View>
            <Text style={styles.pointOfContactModeButtonText}>
              Enter point of contact details
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setPointOfContactMode('team_member');
            }}
            style={styles.pointOfContactModeButton}
          >
            {pointOfContactMode === 'team_member' ? (
              <View style={styles.selectedCheckmark}>
                <FontAwesome5 name="check" size={16} color="black" />
              </View>
            ) : null}
            <Avatar
              source={{ uri: activeTeam?.user.profile_image ?? '' }}
              size={80}
              rounded
            />
            <Text style={styles.pointOfContactModeButtonText}>
              Auto-fill from team member
            </Text>
          </TouchableOpacity>
        </ValidatedAny>

        {/* TEAM MEMBER SELECTION */}
        {pointOfContactMode === 'team_member' ? (
          <TeamMemberMenu
            pointOfContactUserId={data?.point_of_contact?.userId || undefined}
            onSelectTeamMember={(teamMember) => {
              onChange({
                ...data,
                point_of_contact: {
                  ...data?.point_of_contact,
                  userId: teamMember.user.id,
                  name: teamMember.user.name,
                  profile_image: teamMember.user.profile_image ?? '',
                  position: teamMember.title ?? 'Team Member',
                },
              });
            }}
          />
        ) : null}

        {/* MANUAL ENTRY */}
        {pointOfContactMode === 'manual_entry' ? (
          <>
            <View style={styles.pointOfContactPhotoUploadContainer}>
              <Text style={sharedStyles.description}>
                Add a photo (optional){`\n`}This will help volunteers identify
                this contact when they arrive
              </Text>
              <UploadMedia
                circular
                isAvatar
                media={[
                  {
                    uri: data?.point_of_contact?.profile_image ?? '',
                  },
                ]}
                mediaType="Images"
                onChangeReadyMedia={(media) => {
                  const item = media[0];

                  onChange({
                    ...data,
                    point_of_contact: {
                      ...data?.point_of_contact,
                      profile_image: item?.uri,
                    },
                  });
                }}
                size={120}
                style={{
                  alignSelf: 'center',
                  marginVertical: 12,
                }}
              />
            </View>
            <ValidatedTextInput
              containerStyle={{
                marginTop: 8,
              }}
              name="name"
              placeholder="Full Name"
              autoCapitalize="words"
              placeholderTextColor={'gray'}
              style={TEXT_INPUT}
              value={data?.point_of_contact?.name || ''}
              onChangeText={(value) => {
                onChange({
                  ...data,
                  point_of_contact: {
                    ...data?.point_of_contact,
                    name: value,
                  },
                });
              }}
            />
            <TextInput
              value={data?.point_of_contact?.position || ''}
              style={[TEXT_INPUT, { marginTop: 8 }]}
              placeholder="Team Position (optional)"
              placeholderTextColor="gray"
              onChangeText={(value) => {
                onChange({
                  ...data,
                  point_of_contact: {
                    ...data?.point_of_contact,
                    position: value,
                  },
                });
              }}
            />
          </>
        ) : null}
        {pointOfContactMode ? (
          <ValidatedAny
            name="contact_method"
            disableAutoValidation
            value={{
              email: data?.point_of_contact?.email,
              phone_number: data?.point_of_contact?.phone_number,
            }}
            validate={(value) => {
              return (
                isValidEmail(value?.email ?? '') ||
                !!phoneRef.current?.isValidNumber()
              );
            }}
          >
            {fields.contact_method?.valid === false && (
              <Text style={styles.invalidFieldText}>
                Please enter a valid email or phone number, at least one is
                required.
              </Text>
            )}

            <ValidatedTextInput
              name="email"
              autoCapitalize="none"
              autoCorrect={false}
              textContentType="emailAddress"
              required={false}
              value={data?.point_of_contact?.email || ''}
              style={[TEXT_INPUT, { marginTop: 8 }]}
              placeholder="Email Address"
              placeholderTextColor="gray"
              keyboardType="email-address"
              onChangeText={(value) => {
                onChange({
                  ...data,
                  point_of_contact: {
                    ...data?.point_of_contact,
                    email: value,
                  },
                });
              }}
              validate={(value) => isValidEmail(value ?? '')}
            />
            <ValidatedAny
              containerStyle={{
                marginTop: 8,
              }}
              name="phone_number"
              required={false}
              value={data?.point_of_contact?.phone_number}
              validate={() => {
                return !!phoneRef.current?.isValidNumber();
              }}
            >
              <PhoneInputWithCountryPicker
                style={{
                  borderWidth: 1,
                  borderColor: fields.phone_number?.valid
                    ? 'transparent'
                    : 'crimson',
                }}
                innerRef={(r) => (phoneRef.current = r)}
                initialValue={data?.point_of_contact?.phone_number || ''}
                onChangePhoneNumber={(number) => {
                  onChange({
                    ...data,
                    point_of_contact: {
                      ...data?.point_of_contact,
                      phone_number: number,
                    },
                  });
                }}
              />
            </ValidatedAny>
          </ValidatedAny>
        ) : null}

        <Text style={[sharedStyles.description, { marginTop: 6 }]}>
          Volunteers will also be able to contact you via a group discussion
          board that will be open to everyone who is signed up. Team members
          with the "Admin" and "Creator" roles will also have access to the
          discussion board.
        </Text>
      </View>

      <View style={sectionStyle}>
        <Text style={sharedStyles.subheading}>KNOW BEFORE YOU GO</Text>
        <Text style={sharedStyles.description}>
          Provide any information that volunteers should know before they
          arrive.{' '}
          <Text
            style={[sharedStyles.description, { fontFamily: 'Lato-Italic' }]}
          >
            For example: what to wear, whether they should bring food, etc.
          </Text>
        </Text>
        <TextInput
          style={[TEXT_INPUT_LARGE, { marginTop: 8 }]}
          multiline
          value={data?.know_before_you_go}
          onChangeText={(value) => {
            onChange({
              ...data,
              know_before_you_go: value,
            });
          }}
        />
      </View>

      {/* <View style={sectionStyle}>
        <Text style={sharedStyles.subheading}>ACTIVITY WAIVER</Text>
        <Text style={sharedStyles.description}>
          Does this activity require volunteers to sign a waiver to participate?
          Upload it here for volunteers to sign before they arrive.
        </Text>
        <UploadDocument
          placeholderText="Upload a waiver (optional)"
          document={{
            uri: data?.activity_waiver_uri,
          }}
          style={{ flex: 1 }}
          onDocumentUploaded={(uri) => {
            onChange({
              ...data,
              activity_waiver_uri: uri,
            });
          }}
        />
      </View> */}

      <View style={sectionStyle}>
        <Text style={sharedStyles.subheading}>SUITABILITY</Text>
        <Text style={sharedStyles.description}>
          Do any of the following options apply to this activity?
        </Text>
        <View style={styles.switchFieldContainer}>
          <View style={sharedStyles.horizontalContainer}>
            <View style={styles.switchFieldIconContainer}>
              <FontAwesome5 name="child" size={20} color="black" />
            </View>
            <Text style={styles.switchFieldLabel}>Kid friendly?</Text>
          </View>
          <Switch
            trackColor={{
              true: KEY_GREEN,
              false: KEY_LIGHT_GRAY,
            }}
            value={!!data?.kid_friendly}
            onValueChange={(value) => {
              onChange({
                ...data,
                kid_friendly: value,
              });
            }}
          />
        </View>
        {data?.kid_friendly && (
          <>
            <Text style={sharedStyles.description}>
              Please share more information to help supporters (optional)
            </Text>
            <TextInput
              maxLength={1000}
              value={data?.kid_friendly_details || ''}
              style={[TEXT_INPUT_LARGE, { marginTop: 8 }]}
              multiline
              onChangeText={(value) => {
                onChange({
                  ...data,
                  kid_friendly_details: value,
                });
              }}
            />
          </>
        )}
        <View style={styles.switchFieldContainer}>
          <View style={sharedStyles.horizontalContainer}>
            <View style={styles.switchFieldIconContainer}>
              <FontAwesome name="wheelchair-alt" size={20} color="black" />
            </View>
            <Text style={styles.switchFieldLabel}>Accessibility friendly?</Text>
          </View>
          <Switch
            trackColor={{
              true: KEY_GREEN,
              false: KEY_LIGHT_GRAY,
            }}
            value={!!data?.accessibility_friendly}
            onValueChange={(value) => {
              onChange({
                ...data,
                accessibility_friendly: value,
              });
            }}
          />
        </View>
        {data?.accessibility_friendly && (
          <>
            <Text style={sharedStyles.description}>
              Please share more information to help supporters (optional)
            </Text>
            <TextInput
              maxLength={1000}
              value={data?.accessibility_details || ''}
              style={[TEXT_INPUT_LARGE, { marginTop: 8 }]}
              multiline
              onChangeText={(value) => {
                onChange({
                  ...data,
                  accessibility_details: value,
                });
              }}
            />
          </>
        )}
        <View style={styles.switchFieldContainer}>
          <View style={sharedStyles.horizontalContainer}>
            <View style={styles.switchFieldIconContainer}>
              <FontAwesome5 name="building" size={20} color="black" />
            </View>
            <Text style={styles.switchFieldLabel}>
              Bathroom facilities nearby?
            </Text>
          </View>
          <Switch
            trackColor={{
              true: KEY_GREEN,
              false: KEY_LIGHT_GRAY,
            }}
            value={!!data?.bathrooms_nearby}
            onValueChange={(value) => {
              onChange({
                ...data,
                bathrooms_nearby: value,
              });
            }}
          />
        </View>
        {data?.bathrooms_nearby && (
          <>
            <Text style={sharedStyles.description}>
              Please share more information to help supporters (optional)
            </Text>
            <TextInput
              maxLength={1000}
              value={data?.bathrooms_details || ''}
              style={[TEXT_INPUT_LARGE, { marginTop: 8 }]}
              multiline
              onChangeText={(value) => {
                onChange({
                  ...data,
                  bathrooms_details: value,
                });
              }}
            />
          </>
        )}
      </View>

      <View style={sectionStyle}>
        <Text style={sharedStyles.subheading}>DIFFICULTY</Text>
        <Text style={sharedStyles.description}>
          What level of difficulty is this volunteer opportunity?{` `}
          <Text style={{ fontFamily: 'Lato-Italic' }}>Select One</Text>
        </Text>
        <View style={styles.difficultyContainer}>
          <VolunteerDifficultyButton
            difficulty={VolunteerRequestDifficulty.Easy}
            onSelect={(difficulty) => {
              onChange({
                ...data,
                difficulty,
              });
            }}
            style={{
              flex: 1,
              marginRight: 8,
            }}
            selectedDifficulty={data?.difficulty}
          />
          <VolunteerDifficultyButton
            difficulty={VolunteerRequestDifficulty.Moderate}
            onSelect={(difficulty) => {
              onChange({
                ...data,
                difficulty,
              });
            }}
            style={{
              flex: 1,
              marginRight: 8,
            }}
            selectedDifficulty={data?.difficulty}
          />
          <VolunteerDifficultyButton
            difficulty={VolunteerRequestDifficulty.Difficult}
            onSelect={(difficulty) => {
              onChange({
                ...data,
                difficulty,
              });
            }}
            style={{
              flex: 1,
            }}
            selectedDifficulty={data?.difficulty}
          />
        </View>
        <Text style={[sharedStyles.description, { marginTop: 8 }]}>
          Add any relevant details. (Optional)
        </Text>
        <TextInput
          style={[
            TEXT_INPUT_LARGE,
            {
              marginTop: 4,
            },
          ]}
          multiline
          value={data?.difficulty_details}
          onChangeText={(value) => {
            onChange({
              ...data,
              difficulty_details: value,
            });
          }}
        />
      </View>
    </View>
  );
}

interface ITeamMember extends Pick<TeamMember, 'title'> {
  user: Pick<User, 'id' | 'name' | 'profile_image'>;
}
interface ITeamMemberMenuProps {
  pointOfContactUserId: string | undefined;
  onSelectTeamMember: (teamMember: ITeamMember) => void;
}

function TeamMemberMenu(props: ITeamMemberMenuProps) {
  const { activeTeam } = useTeamContext();

  const [page, setPage] = useState(0);
  const [pageTokenMap, setPageTokenMap] = useState<{
    [key: number]: string | undefined;
  }>({});

  const [{ data, fetching, error }, refetch] = useGetTeamQuery({
    variables: {
      teamId: activeTeam?.id as string,
      limit: 9,
      nextToken: pageTokenMap[page],
    },
    pause: !activeTeam?.id,
  });

  useEffect(() => {
    setPageTokenMap((prevState) => ({
      ...prevState,
      [page + 1]: data?.getTeam.members.nextToken || undefined,
    }));
  }, [data, page]);

  function next() {
    if (pageTokenMap[page + 1] === undefined) return;

    setPage((prev) => prev + 1);
  }

  function previous() {
    if (page === 0) return;

    setPage((prev) => prev - 1);
  }

  return fetching ? (
    <ActivityIndicator
      size="small"
      color={KEY_GRAY}
      style={styles.activityIndicator}
    />
  ) : !activeTeam?.id || error ? (
    <>
      <Text style={styles.teamMemberMenuErrorText}>
        Failed to fetch team members
      </Text>
      <Button
        label="Retry"
        onPress={refetch}
        containerStyle={{
          alignSelf: 'center',
          marginTop: 8,
        }}
      />
    </>
  ) : data?.getTeam.members.total === 0 ? (
    <Text style={styles.emptyText}>No team members</Text>
  ) : (
    <>
      <GridList
        style={{
          marginVertical: 8,
        }}
        data={data?.getTeam.members.items}
        maxTileWidth={112}
        renderItem={({ item, tileWidth }) => {
          return (
            <>
              {props.pointOfContactUserId === item.user.id ? (
                <View style={[styles.selectedCheckmark, { top: 8, right: 8 }]}>
                  <FontAwesome5 name="check" size={16} color="black" />
                </View>
              ) : null}
              <Pressable
                style={{
                  alignItems: 'center',
                }}
                onPress={() => {
                  props.onSelectTeamMember(item);
                }}
              >
                <Avatar
                  source={{
                    uri: item.user.profile_image ?? '',
                  }}
                  rounded
                  size={tileWidth - 16}
                />
                <Text numberOfLines={2} style={styles.pointOfContactName}>
                  {item.user.name}
                </Text>
                <Text numberOfLines={2} style={styles.pointOfContactPosition}>
                  {item.title ?? 'Team Member'}
                </Text>
              </Pressable>
            </>
          );
        }}
      />
      <View style={styles.teamMemberMenuFooter}>
        <Button
          label="Back"
          style={{
            display: page > 0 ? 'flex' : 'none',
          }}
          onPress={previous}
        />
        <Button
          label="Next"
          style={{
            display: pageTokenMap[page + 1] ? 'flex' : 'none',
          }}
          onPress={next}
        />
      </View>
    </>
  );
}
