import { Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
import React, { ComponentProps, useState } from 'react';
import {
  Pressable,
  Text,
  View,
  ViewStyle,
  Image as ImageComponent,
  ActivityIndicator,
  FlatList,
  StyleSheet,
} from 'react-native';
import styles from './DefaultUploadComponent.style';
import Button from '/components/Button';
import UploadMedia, { MediaItemState } from '../UploadMedia';
import { KEY_GRAY, KEY_GREEN } from '/constants';
import { ResizeMode, Video } from 'expo-av';
import { Platform } from 'react-native';
import getCDNImageUri from '/util/getCDNImageUri';
import { determineIfVideo } from '/util';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';

type Props = {
  onEdit: () => void;
  removeCurrentMedia: () => void;
  retryUploads: () => void;
  onSelectMedia: (index: number) => void;
  pickMedia: () => void;
  currentMediaIndex: number;
  placeholderImage: React.ReactNode | (() => React.ReactNode);
  uploadProgress: number;
  previewResizeMode: ResizeMode | undefined;
  hideProgressIndicator: boolean;
  isAvatar: boolean;
  currentMedia: MediaItemState | undefined;
  style: ViewStyle;
  uploading: boolean;
  disabled: boolean;
  circular: boolean;
  size: number | 'auto';
  multiple: boolean;
  media: MediaItemState[];
  fontSize: number;
  title: string | undefined;
  mediaType: ComponentProps<typeof UploadMedia>['mediaType'];
};

const PLACEHOLDER = require('/assets/images/blank-profile-picture.webp');

export default function DefaultUploadComponent(props: Props) {
  const styleWidth =
    typeof props.style?.width !== 'number' ? undefined : props.style.width;
  const styleHeight =
    typeof props.style?.height !== 'number' ? undefined : props.style.height;
  const sizeProp = typeof props.size === 'string' ? undefined : props.size;
  const componentWidth = styleWidth || sizeProp || 100;
  const componentHeight = styleHeight || sizeProp || 100;

  const hasErrors = props.media.some((m) => m.upload?.uploadError);

  const isGeneratingThumbnail = Boolean(
    props.currentMedia?.upload?.generatingThumbnail,
  );

  const textStyle = [
    styles.touchableText,
    {
      fontSize: props.fontSize || 10,
    },
  ];

  function renderPlaceholderImage() {
    if (!props.placeholderImage) return null;

    return typeof props.placeholderImage === 'function'
      ? props.placeholderImage()
      : props.placeholderImage;
  }

  return (
    <>
      {/* Render default UploadMedia component */}
      <Pressable
        onPress={props.onEdit}
        style={[
          styles.container,
          {
            pointerEvents: props.disabled ? 'none' : 'auto',
            borderRadius: props.circular ? 2048 : 8,
            width: props.size === 'auto' ? undefined : props.size || 100,
            height: props.size === 'auto' ? '100%' : props.size || 100,
          },
          props.style,
        ]}
      >
        {/* Error Overlay */}
        {props.currentMedia?.upload?.uploadError ? (
          <View style={styles.errorOverlay}>
            <Feather name="alert-triangle" size={28} color="crimson" />
            {(componentWidth + componentHeight) / 2 < 112 ? null : (
              <Text style={styles.errorText}>Upload failed</Text>
            )}
            {componentHeight > 200 ? (
              <Button
                label="Try Again"
                containerStyle={{
                  marginTop: 8,
                }}
                onPress={() => {
                  props.retryUploads();
                }}
              />
            ) : null}
          </View>
        ) : null}

        {!props.multiple ||
        (props.media.length === 0 && !isGeneratingThumbnail) ? (
          <View
            style={[
              styles.imageButton,
              {
                backgroundColor: isGeneratingThumbnail
                  ? 'rgba(0,0,0,0.5)'
                  : 'transparent',
              },
            ]}
          >
            {/* TEXT OVERLAYS */}
            {props.currentMedia?.uri && !isGeneratingThumbnail ? (
              <>
                <View style={styles.editOverlay}>
                  <Text
                    style={[
                      ...textStyle,
                      {
                        color: 'white',
                        fontSize: 11,
                        width: '100%',
                        textAlign: 'center',
                      },
                    ]}
                  >
                    Change
                  </Text>
                </View>
              </>
            ) : (
              (!isGeneratingThumbnail && renderPlaceholderImage?.()) || (
                <HorizontalContainer>
                  {isGeneratingThumbnail && (
                    <ActivityIndicator
                      style={{
                        marginRight: 8,
                      }}
                      color="white"
                      size="small"
                    />
                  )}
                  <Text style={textStyle}>
                    {isGeneratingThumbnail
                      ? 'Creating thumbnail...'
                      : props.title ||
                        `Click to choose ${
                          props.mediaType === 'Images'
                            ? 'image'
                            : props.mediaType === 'Videos'
                            ? 'video'
                            : 'media'
                        }...`}
                  </Text>
                </HorizontalContainer>
              )
            )}
          </View>
        ) : props.multiple && props.currentMedia ? (
          <Button
            label={
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <FontAwesome
                  size={17}
                  name="trash-o"
                  style={{
                    marginRight: 4,
                  }}
                  color={KEY_GRAY}
                />
                <Text
                  style={{
                    color: KEY_GRAY,
                    fontFamily: 'Lato-Bold',
                    fontSize: 17,
                  }}
                >
                  Remove
                </Text>
              </View>
            }
            onPress={() => {
              props.removeCurrentMedia();
            }}
            containerStyle={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 1,
            }}
          />
        ) : null}

        <MediaPreview
          currentMedia={props.currentMedia}
          hideProgressIndicator={!!props.hideProgressIndicator}
          isAvatar={!!props.isAvatar}
          isUploading={
            (!!props.currentMedia?.upload?.uploading ||
              !!props.currentMedia?.pickerRequestId) &&
            !isGeneratingThumbnail
          }
          isGeneratingThumbnail={isGeneratingThumbnail}
          resizeMode={props.previewResizeMode}
          shouldRenderAvatarPlaceholder={!props.placeholderImage}
          uploadProgress={props.uploadProgress}
          key={props.currentMedia?.uri || 'no-media'}
        />
      </Pressable>
      {props.uploading && !props.isAvatar ? (
        <View
          style={[
            styles.uploadIndicator,
            {
              display: props.hideProgressIndicator ? 'none' : 'flex',
            },
          ]}
        >
          <ActivityIndicator size="small" />
          <Text
            style={[
              styles.uploadIndicatorText,
              {
                fontSize: props.fontSize || 14,
              },
            ]}
          >
            Uploading... ({Math.round(props.uploadProgress)}%)
          </Text>
        </View>
      ) : (
        hasErrors &&
        !props.isAvatar &&
        props.media.some((m) => m.pendingUpload) && (
          <View style={styles.uploadIndicator}>
            <Text style={styles.uploadErrorText}>Upload failed</Text>
            <Pressable
              style={styles.retryUploadButton}
              onPress={() => {
                props.retryUploads();
              }}
            >
              <Text style={styles.retryUploadButtonText}>Retry</Text>
            </Pressable>
          </View>
        )
      )}

      {/* LIST MEDIA */}
      {props.multiple ? (
        <FlatList
          horizontal
          style={{
            marginTop: 8,
          }}
          data={props.media}
          renderItem={({ item, index }) => {
            return (
              <Pressable
                onPress={() => {
                  props.onSelectMedia(index);
                }}
                style={[
                  styles.mediaTileContainer,
                  {
                    borderColor:
                      props.currentMediaIndex === index
                        ? KEY_GREEN
                        : 'transparent',
                  },
                ]}
              >
                <MediaPreview
                  currentMedia={item}
                  isAvatar={false}
                  isUploading={item.pendingUpload}
                  isGeneratingThumbnail={!!item.upload?.generatingThumbnail}
                  resizeMode={props.previewResizeMode}
                  shouldRenderAvatarPlaceholder={false}
                  hideProgressIndicator
                  uploadProgress={props.uploadProgress}
                />

                {item.pendingUpload ? (
                  <View
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 1,
                    }}
                  >
                    {props.uploading || item.pickerRequestId ? (
                      <ActivityIndicator size={32} color={'white'} />
                    ) : item.upload?.uploadError ? (
                      <Feather
                        style={{
                          zIndex: 10,
                          elevation: 10,
                        }}
                        name="alert-triangle"
                        size={32}
                        color="crimson"
                      />
                    ) : null}
                  </View>
                ) : null}
              </Pressable>
            );
          }}
          ListFooterComponent={
            <Pressable onPress={props.pickMedia}>
              <View
                style={[
                  styles.mediaTileContainer,
                  {
                    backgroundColor: 'transparent',
                  },
                ]}
              >
                <View
                  style={{
                    flex: 1,
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.1)',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Ionicons name="add" size={28} color={'black'} />
                </View>
              </View>
            </Pressable>
          }
        />
      ) : null}
    </>
  );
}

type MediaPreviewProps = {
  currentMedia: MediaItemState | undefined;
  resizeMode: ResizeMode | undefined;
  isUploading: boolean;
  uploadProgress: number;
  isAvatar: boolean;
  hideProgressIndicator: boolean;
  shouldRenderAvatarPlaceholder: boolean;
  isGeneratingThumbnail: boolean;
};

function MediaPreview({
  currentMedia,
  resizeMode,
  isUploading,
  uploadProgress,
  isAvatar,
  shouldRenderAvatarPlaceholder,
  hideProgressIndicator,
  isGeneratingThumbnail,
}: MediaPreviewProps) {
  const [isLoading, setIsLoading] = useState(!!currentMedia?.uri);

  return (
    <View style={styles.imageContain}>
      {/* Media Preview - changes based on type and availability */}
      {currentMedia?.asset?.mediaType === 'video' ? (
        Platform.OS === 'web' ? (
          <video
            src={currentMedia.asset.uri}
            style={{
              width: '100%',
              height: '100%',
              objectFit:
                resizeMode === ResizeMode.CONTAIN ? 'contain' : 'cover',
            }}
            onLoadedMetadata={() => {
              setIsLoading(false);
            }}
            autoPlay={false}
            muted
            playsInline
            preload="metadata"
            controls={false}
          />
        ) : (
          <Video
            testID="upload-media-image"
            source={{
              uri: currentMedia.asset.uri,
            }}
            onReadyForDisplay={() => {
              if (isLoading) setIsLoading(false);
            }}
            shouldPlay={false}
            isMuted
            resizeMode={resizeMode ?? ResizeMode.CONTAIN}
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: 'gray',
            }}
          />
        )
      ) : currentMedia?.uri ? (
        <ImageComponent
          testID="upload-media-image"
          onLoadEnd={() => {
            if (isLoading) setIsLoading(false);
          }}
          source={{
            uri:
              currentMedia.asset?.uri ??
              (currentMedia.uri.startsWith('data:video')
                ? undefined
                : getCDNImageUri({
                    uri: currentMedia.thumbnailUri || currentMedia.uri,
                    isThumbnail: true,
                  }) || undefined),
          }}
          resizeMode={resizeMode ?? 'contain'}
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'gray',
          }}
        />
      ) : isAvatar && shouldRenderAvatarPlaceholder ? (
        <ImageComponent
          source={PLACEHOLDER}
          resizeMode="cover"
          style={{
            backgroundColor: 'black',
            opacity: 0.3,
            height: '100%',
            width: '100%',
          }}
        />
      ) : null}

      {/* Loading/Upload Overlay */}
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            pointerEvents: 'none',
            backgroundColor: 'rgba(10, 10, 10, 0.4)',
            display:
              (isLoading || isUploading) &&
              !hideProgressIndicator &&
              !isGeneratingThumbnail
                ? 'flex'
                : 'none',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
            elevation: 1,
          },
        ]}
      >
        <ActivityIndicator color="white" size="small" />
        {isUploading ? (
          <Text
            style={{
              color: 'white',
              marginLeft: 6,
              fontFamily: 'Lato-Bold',
            }}
          >
            {Math.round(uploadProgress)}%
          </Text>
        ) : null}
      </View>

      {/* Video Icon Overlay */}
      <View
        style={[
          styles.videoIconContainer,
          {
            display: determineIfVideo(currentMedia?.uri) ? 'flex' : 'none',
          },
        ]}
      >
        <ImageComponent
          source={require('/assets/icons/video.png')}
          style={{
            width: 16,
            height: 16,
          }}
          resizeMode={'contain'}
        />
      </View>
    </View>
  );
}
