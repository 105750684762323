import { FontAwesome5 } from '@expo/vector-icons';
import { HeaderBackButton } from '@react-navigation/elements';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { IOurSpeciesSpeciesUserContentMedia } from '../OurSpecies';
import commonStyles from './styles';
import Alert from '/Alert';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import GridList from '/components/GridList';
import { KEY_GRAY, KEY_GREEN, TITLE_FONT_SIZE } from '/constants';
import { useAuthContext } from '/context';
import {
  useCountSpeciesUserContentMediaCopiedMutation,
  useDeleteSpeciesMediaMutation,
  useGetSpeciesUserContentMediaQuery,
} from '/generated/graphql';
import useBinaryTimingAnimation from '/hooks/useBinaryTimingAnimation';
import { determineIfVideo, isCloseToBottom } from '/util';
import getCDNImageUri from '/util/getCDNImageUri';

interface Props {
  visible: boolean;
  userId: string | undefined;
  speciesTaxonId: number | undefined;
  alreadyIncludedIds: string[] | undefined;
  onRequestClose: (data?: IOurSpeciesSpeciesUserContentMedia) => void;
  onDeleteMedia: (id: string) => void;
}

export default function BrowseUserContentMediaModal(props: Props) {
  const { userData } = useAuthContext();

  const userId = props.userId || userData?.id;

  const [publicMediaNextToken, setPublicMediaNextToken] = useState<string>();
  const [ownMediaNextToken, setOwnMediaNextToken] = useState<string>();

  const [promptDeleteMedia, setPromptDeleteMedia] = useState<string>();
  const [deletingMediaIds, setDeletingMediaIds] = useState<{
    [key: string]: boolean;
  }>({});

  const [gridListTileWidth, setGridListTileWidth] = useState(400);

  const [
    {
      data: ownMedia,
      fetching: fetchingOwnMedia,
      error: ownMediaError,
      stale: ownMediaStale,
    },
    getOwnMedia,
  ] = useGetSpeciesUserContentMediaQuery({
    variables: {
      taxonID: props.speciesTaxonId!,
      createdByUserId: userId,
      nextToken: ownMediaNextToken,
      limit: 6,
    },
    pause: !props.visible,
    requestPolicy: 'cache-and-network',
  });

  const [
    {
      data: publicMedia,
      fetching: fetchingPublicMedia,
      error: publicMediaError,
    },
    getPublicMedia,
  ] = useGetSpeciesUserContentMediaQuery({
    variables: {
      taxonID: props.speciesTaxonId!,
      notCreatedByUserId: userId,
      nextToken: publicMediaNextToken,
    },
    pause: !props.visible,
    requestPolicy: 'cache-and-network',
  });

  const [, countSpeciesUserContentMediaCopied] =
    useCountSpeciesUserContentMediaCopiedMutation();
  const [, deleteSpeciesMedia] = useDeleteSpeciesMediaMutation();

  const animatedOpacity = useBinaryTimingAnimation({ value: props.visible });
  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: animatedOpacity.value,
  }));

  function onUseMedia(media: IOurSpeciesSpeciesUserContentMedia) {
    if (!media?.id) return;

    // When someone uses someone else's media, we should increment "count" on it so we can
    // sort all media by popularity
    if (media.created_by?.id && media.created_by?.id !== userId) {
      countSpeciesUserContentMediaCopied({
        countSpeciesUserContentMediaCopiedId: media.id,
      });
    }

    props.onRequestClose(media);
  }

  async function onDeleteMedia(media: IOurSpeciesSpeciesUserContentMedia) {
    try {
      if (!media.id) throw new Error('Cannot delete media: No ID');

      setDeletingMediaIds((prevState) => ({
        ...prevState,
        [media.id as string]: true,
      }));

      const { error } = await deleteSpeciesMedia({
        deleteSpeciesMediaId: media.id,
      });

      if (error) throw error;

      /** Notify parent of deletion */
      props.onDeleteMedia(media.id);

      Alert.notify({ message: 'Deleted successfully', color: KEY_GREEN });
    } catch (err) {
      console.log(err);
    } finally {
      setDeletingMediaIds((prevState) => ({
        ...prevState,
        [media.id as string]: false,
      }));
    }
  }

  return (
    <Animated.View
      pointerEvents={props.visible ? 'auto' : 'none'}
      style={[
        styles.container,
        {
          zIndex: props.visible ? 1 : -1,
        },
        animatedContainerStyle,
      ]}
    >
      <View style={commonStyles.headerContainer}>
        <HeaderBackButton
          onPress={() => props.onRequestClose()}
          tintColor={KEY_GRAY}
        />
        <Text style={commonStyles.headerText}>BROWSE SPECIES MEDIA</Text>
      </View>
      <ScrollView
        style={{ flex: 1 }}
        scrollEventThrottle={100}
        onScroll={(event) => {
          if (
            isCloseToBottom(event.nativeEvent) &&
            publicMedia?.getSpeciesUserContentMedia_v2.nextToken
          ) {
            setPublicMediaNextToken(
              publicMedia.getSpeciesUserContentMedia_v2.nextToken,
            );
          }
        }}
        contentContainerStyle={commonStyles.contentContainerStyle}
      >
        <Text style={commonStyles.sectionText}>
          Find photos and videos of this species that you or others have already
          added
        </Text>

        <Text
          style={[commonStyles.sectionTitle, { fontSize: TITLE_FONT_SIZE }]}
        >
          UPLOADED BY YOU
        </Text>
        {ownMedia?.getSpeciesUserContentMedia_v2.total === 0 &&
        !fetchingOwnMedia ? (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              You have not yet uploaded media for this species
            </Text>
          </View>
        ) : null}
        {fetchingOwnMedia &&
        !ownMedia?.getSpeciesUserContentMedia_v2?.items.length ? (
          <View style={styles.emptyContainer}>
            <ActivityIndicator color={KEY_GRAY} size="large" />
          </View>
        ) : null}
        {ownMediaError && !ownMedia ? (
          <View>
            <Text style={commonStyles.errorText}>
              There was a problem fetching your media
            </Text>
            <Button label="Retry" onPress={getOwnMedia} />
          </View>
        ) : (
          <View>
            <GridList
              data={ownMedia?.getSpeciesUserContentMedia_v2.items}
              maxTileWidth={400}
              renderItem={({ item }) => {
                const alreadyIncluded = props.alreadyIncludedIds?.includes(
                  item.id,
                );

                return (
                  <View style={styles.mediaCard}>
                    <ImageBackground
                      style={styles.mediaCardThumbnail}
                      resizeMode="contain"
                      source={{
                        uri: getCDNImageUri({
                          uri: item.thumbnail,
                          isThumbnail: true,
                          dimensions: {
                            width: 280,
                            height: 350,
                          },
                        }),
                      }}
                    >
                      {/* 
                            This renders the small overlay that allows users
                            to quickly differentiate between videos and photos
                            in their library
                        */}
                      {determineIfVideo(item.uri) ? (
                        <View
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: 20,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#0006',
                            alignItems: 'center',
                            paddingHorizontal: 8,
                          }}
                        >
                          <Image
                            source={require('/assets/icons/video.png')}
                            style={{
                              width: 16,
                              height: 16,
                            }}
                            resizeMode={'contain'}
                          />
                        </View>
                      ) : null}
                    </ImageBackground>

                    <View style={styles.mediaCardBottomSection}>
                      {item.caption ? (
                        <Text style={styles.mediaCardCaption}>
                          {item.caption}
                        </Text>
                      ) : null}

                      <View
                        style={{
                          marginVertical: 6,
                        }}
                      >
                        <View style={commonStyles.createdByContainer}>
                          <Avatar
                            rounded
                            size={20}
                            source={{
                              uri: item.created_by.profile_image ?? '',
                            }}
                          />
                          <Text style={commonStyles.createdByText}>
                            {item.created_by.name}
                          </Text>
                        </View>
                        <View style={commonStyles.createdByContainer}>
                          {item.public ? (
                            <FontAwesome5
                              name="globe-africa"
                              size={18}
                              color="gray"
                            />
                          ) : (
                            <FontAwesome5 name="lock" color="gray" size={18} />
                          )}
                          <Text style={commonStyles.createdByText}>
                            {item.public ? 'PUBLIC' : 'PRIVATE'}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          opacity: deletingMediaIds[item.id] ? 0.7 : 1,
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        {promptDeleteMedia === item.id ? (
                          <>
                            <Text style={styles.promptDeleteText}>
                              Are you sure?
                            </Text>
                            <Button
                              loading={deletingMediaIds[item.id]}
                              label="Delete"
                              style={{
                                backgroundColor: 'crimson',
                              }}
                              labelStyle={{
                                color: 'white',
                              }}
                              onPress={() => onDeleteMedia(item)}
                              containerStyle={{
                                marginRight: 8,
                              }}
                            />
                            <Button
                              label="Cancel"
                              onPress={() => setPromptDeleteMedia(undefined)}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              containerStyle={{
                                marginRight: 8,
                              }}
                              onPress={() => setPromptDeleteMedia(item.id)}
                              label={'Delete'}
                            />
                            <Button
                              style={{
                                backgroundColor: alreadyIncluded
                                  ? '#eee'
                                  : KEY_GREEN,
                              }}
                              disabled={alreadyIncluded}
                              onPress={() => onUseMedia(item)}
                              label={alreadyIncluded ? 'Used' : 'Use'}
                            />
                          </>
                        )}
                      </View>
                    </View>
                  </View>
                );
              }}
            />
          </View>
        )}
        {ownMedia?.getSpeciesUserContentMedia_v2.nextToken ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginVertical: 8,
              marginBottom: 16,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setOwnMediaNextToken(
                  ownMedia.getSpeciesUserContentMedia_v2.nextToken!,
                );
              }}
              style={styles.seeAllMyMediaButtonContainer}
            >
              {fetchingOwnMedia || ownMediaStale ? (
                <ActivityIndicator color={KEY_GRAY} />
              ) : (
                <Text style={styles.seeAllMyMediaButtonText}>See More</Text>
              )}
            </TouchableOpacity>
          </View>
        ) : null}

        <Text
          style={[commonStyles.sectionTitle, { fontSize: TITLE_FONT_SIZE }]}
        >
          FROM THE COMMUNITY
        </Text>
        <View style={commonStyles.createdByContainer}>
          <FontAwesome5 name="globe-africa" size={16} color={KEY_GRAY} />
          <Text style={commonStyles.createdByText}>PUBLIC</Text>
        </View>
        {publicMedia?.getSpeciesUserContentMedia_v2.total === 0 &&
        !fetchingPublicMedia ? (
          <View style={styles.emptyContainer}>
            <Text style={styles.emptyText}>
              No other organization has uploaded any media for this species yet
            </Text>
          </View>
        ) : null}
        {fetchingPublicMedia &&
        !publicMedia?.getSpeciesUserContentMedia_v2?.items.length ? (
          <View style={styles.emptyContainer}>
            <ActivityIndicator color={KEY_GRAY} size="large" />
          </View>
        ) : null}
        {publicMediaError && !publicMedia ? (
          <View>
            <Text style={commonStyles.errorText}>
              There was a problem fetching public media
            </Text>
            <Button label="Retry" onPress={getPublicMedia} />
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            <GridList
              data={publicMedia?.getSpeciesUserContentMedia_v2.items}
              maxTileWidth={400}
              onTileWidthChanged={setGridListTileWidth}
              renderItem={({ item }) => {
                const alreadyIncluded = props.alreadyIncludedIds?.includes(
                  item.id,
                );

                return (
                  <View style={styles.mediaCard}>
                    <ImageBackground
                      style={[
                        styles.mediaCardThumbnail,
                        {
                          height: (350 / 280) * gridListTileWidth - 10, // -10 accounts for padding,
                        },
                      ]}
                      resizeMode="contain"
                      source={{
                        uri: getCDNImageUri({
                          uri: item.thumbnail,
                          isThumbnail: true,
                          dimensions: {
                            width: 280,
                            height: 350,
                          },
                        }),
                      }}
                    >
                      {/* 
                            This renders the small overlay that allows users
                            to quickly differentiate between videos and photos
                            in their library
                        */}
                      {determineIfVideo(item.uri) ? (
                        <View
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: 20,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: '#0006',
                            alignItems: 'center',
                            paddingHorizontal: 8,
                          }}
                        >
                          <Image
                            source={require('/assets/icons/video.png')}
                            style={{
                              width: 16,
                              height: 16,
                            }}
                            resizeMode={'contain'}
                          />
                        </View>
                      ) : null}
                    </ImageBackground>

                    <View style={styles.mediaCardBottomSection}>
                      {item.caption ? (
                        <Text style={styles.mediaCardCaption}>
                          {item.caption}
                        </Text>
                      ) : null}

                      <View
                        style={{
                          marginVertical: 6,
                        }}
                      >
                        <View style={commonStyles.createdByContainer}>
                          <Avatar
                            rounded
                            size={20}
                            source={{
                              uri: item.created_by.profile_image ?? '',
                            }}
                          />
                          <Text style={commonStyles.createdByText}>
                            {item.created_by.name}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: alreadyIncluded
                              ? undefined
                              : KEY_GREEN,
                          }}
                          onPress={() => onUseMedia(item)}
                          disabled={alreadyIncluded}
                          label={alreadyIncluded ? 'Used' : 'Use'}
                        />
                      </View>
                    </View>
                  </View>
                );
              }}
            />
          </View>
        )}
      </ScrollView>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: { ...StyleSheet.absoluteFillObject, backgroundColor: 'white' },
  emptyContainer: {
    width: '100%',
    padding: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 16,
    textAlign: 'center',
  },
  mediaCard: {
    paddingTop: 6,
    width: '100%',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#eee',
  },
  mediaCardCaption: {
    fontFamily: 'Lato-Bold',
    fontSize: 16,
    color: KEY_GRAY,
  },
  mediaCardThumbnail: {
    alignSelf: 'center',
    backgroundColor: 'gray',
    width: '100%',
    height: 280,
  },
  mediaCardBottomSection: {
    padding: 10,
  },
  promptDeleteText: {
    fontFamily: 'Lato-Bold',
    marginRight: 6,
  },
  seeAllMyMediaButtonContainer: {
    padding: 8,
  },
  seeAllMyMediaButtonText: {
    fontFamily: 'Lato-Bold',
    fontSize: 18,
    color: KEY_GRAY,
  },
});
