/**
 * This drawer is for use on web where account related options & settings slide in from the right instead of being
 * accessed via the gear icon in the profile screen like on native.
 */
import { EvilIcons } from '@expo/vector-icons';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import React, { useRef, useState } from 'react';
import {
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import Alert from '/Alert';

import Constants from 'expo-constants';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TesterGeneralFeedbackWidget from '../Testers/TesterGeneralFeedbackWidget';
import Hoverable from '/components/Hoverable';
import { useAuthContext, useModalContext } from '/context';
import { TesterStatus, UserRole } from '/generated/graphql';
import {
  canChangeEmailAndPassword,
  createUniversalURL,
  shortenEmail,
} from '/util';
import { useIsTesterOnlyMode } from '/hooks/useIsTesterOnlyMode';

type AccountSettingsDrawerProps = {
  onCloseDrawer: () => void;
  isTesterDashboard?: boolean;
};

export default function AccountSettingsDrawer(
  props: AccountSettingsDrawerProps,
) {
  const { spawnModal, closeModal } = useModalContext();
  const { logOut, userData, user, userAttributes } = useAuthContext();
  const isTesterOnlyMode = useIsTesterOnlyMode();

  const feedbackModalId = useRef<string>();

  const safeAreaInsets = useSafeAreaInsets();

  const linkTo = useLinkTo();

  const { navigate } = useNavigation<any>();

  const onLogOut = (e: DrawerButtonPressEvent) => {
    e.preventDefault();

    Alert.alert('Sign out', "Are you sure you'd like to sign out?", [
      {
        text: 'Sign Out',
        style: 'default',
        onPress: () => {
          logOut();
          props.onCloseDrawer?.();
        },
      },
      {
        style: 'cancel',
      },
    ]);
  };

  const onManageNotifications = () => {
    if (props.isTesterDashboard && isApprovedTester) {
      linkTo('/settings/notifications');
    } else {
      navigate('NotificationPreferences');
    }
  };

  const onLogIn = () => {
    navigate('auth');
  };

  const onChangeEmail = () => {
    if (isApprovedTester && props.isTesterDashboard) {
      linkTo('/settings/email');
    } else {
      navigate('ChangeEmail');
    }
  };

  const onChangePassword = () => {
    if (isApprovedTester && props.isTesterDashboard) {
      linkTo('/settings/password');
    } else {
      navigate('ChangePassword');
    }
  };

  const onManageTeam = () => {
    if (props.isTesterDashboard) {
      linkTo('/manage-team');
    } else {
      navigate('ManageTeam');
    }
  };

  const onViewMyTeams = () => {
    if (props.isTesterDashboard) {
      linkTo('/my-teams');
    } else {
      navigate('ViewMyTeams');
    }
  };

  const onViewBlockedUsers = () => {
    if (props.isTesterDashboard) {
      linkTo('/settings/blocked-users');
    } else {
      navigate('ListBlockedUsers');
    }
  };

  const onManageDonations = () => {
    if (props.isTesterDashboard) {
      linkTo('/settings/donations');
    } else {
      navigate('DonationSettings');
    }
  };

  const onViewBookmarks = () => {
    if (props.isTesterDashboard) {
      linkTo('/bookmarks');
    } else {
      navigate('Bookmarks');
    }
  };

  function onDeleteProfile() {
    // deleteProfile();
    if (isApprovedTester && props.isTesterDashboard) {
      linkTo('/settings/delete-account');
    } else {
      navigate('DeleteAccount');
    }
  }

  const isApprovedTester =
    userData?.tester_status === TesterStatus.Approved || userData?.admin;

  return (
    <View
      style={[
        styles.container,
        {
          paddingRight: safeAreaInsets.right,
        },
      ]}
    >
      <ScrollView
        style={styles.scrollViewContainer}
        contentContainerStyle={{
          paddingTop: safeAreaInsets.top,
          paddingBottom: safeAreaInsets.bottom,
        }}
      >
        <View style={styles.topSection}>
          <Pressable
            accessibilityHint="Close the account settings drawer"
            style={styles.closeButton}
            onPress={props.onCloseDrawer}
          >
            <EvilIcons
              name="close"
              size={40}
              style={{
                alignSelf: 'flex-end',
              }}
            />
          </Pressable>
        </View>

        {userData?.id ? (
          <>
            {props.isTesterDashboard ? null : (
              <>
                {userData?.role === UserRole.Conservationist &&
                userData?.application?.completed_survey ? (
                  <>
                    <DrawerButton
                      closeDrawer={props.onCloseDrawer}
                      label="DONATION SETTINGS"
                      onPress={onManageDonations}
                    />
                    <DrawerButton
                      closeDrawer={props.onCloseDrawer}
                      label="MANAGE TEAM"
                      onPress={onManageTeam}
                    />
                  </>
                ) : userData?.role === UserRole.Supporter ? (
                  <DrawerButton
                    closeDrawer={props.onCloseDrawer}
                    label="MY TEAMS"
                    onPress={onViewMyTeams}
                  />
                ) : null}
              </>
            )}

            <DrawerButton
              closeDrawer={props.onCloseDrawer}
              label="NOTIFICATION PREFERENCES"
              onPress={onManageNotifications}
            />

            {props.isTesterDashboard ? null : (
              <>
                <DrawerButton
                  closeDrawer={props.onCloseDrawer}
                  label="BLOCKED USERS"
                  onPress={onViewBlockedUsers}
                />
                <View style={styles.spacer} />

                <DrawerButton
                  closeDrawer={props.onCloseDrawer}
                  label="BOOKMARKS"
                  onPress={onViewBookmarks}
                />
              </>
            )}

            <View style={styles.spacer} />

            {canChangeEmailAndPassword(user, userAttributes) ? (
              <>
                <DrawerButton
                  closeDrawer={props.onCloseDrawer}
                  label="CHANGE EMAIL"
                  onPress={onChangeEmail}
                />
                <DrawerButton
                  closeDrawer={props.onCloseDrawer}
                  label="CHANGE PASSWORD"
                  onPress={onChangePassword}
                />
              </>
            ) : null}

            <DrawerButton
              closeDrawer={props.onCloseDrawer}
              label="DELETE MY ACCOUNT"
              onPress={onDeleteProfile}
            />

            <View style={styles.spacer} />

            {!isApprovedTester && !props.isTesterDashboard ? (
              <DrawerButton
                closeDrawer={props.onCloseDrawer}
                label="BECOME A TESTER"
                onPress={() => {
                  navigate('TesterDashboard');
                }}
              />
            ) : null}

            {isTesterOnlyMode ? (
              <DrawerButton
                closeDrawer={props.onCloseDrawer}
                label="FREQUENTLY ASKED QUESTIONS"
                onPress={() => {
                  Linking.openURL(createUniversalURL('faq'));
                }}
              />
            ) : null}

            <DrawerButton
              closeDrawer={props.onCloseDrawer}
              label="SEND FEEDBACK"
              onPress={() => {
                feedbackModalId.current = spawnModal({
                  title: 'Send Feedback',
                  component: (
                    <TesterGeneralFeedbackWidget
                      onRequestClose={() => {
                        if (feedbackModalId.current)
                          closeModal(feedbackModalId.current);
                      }}
                    />
                  ),
                });
              }}
            />

            <View style={styles.spacer} />

            <DrawerButton
              closeDrawer={props.onCloseDrawer}
              label="LOG OUT"
              sublabel={shortenEmail(userAttributes?.email ?? '', 40)}
              onPress={onLogOut}
            />
          </>
        ) : (
          <DrawerButton
            closeDrawer={props.onCloseDrawer}
            label="SIGN IN"
            onPress={onLogIn}
          />
        )}
      </ScrollView>
      <Text style={styles.versionText}>
        App version {Constants.expoConfig?.version}
      </Text>
    </View>
  );
}

interface DrawerButtonPressEvent {
  /** Prevents closing the drawer after button has been pressed */
  preventDefault: () => void;
}

type DrawerButtonProps = {
  label: string;
  sublabel?: string;
  closeDrawer: () => void;
  onPress: (e: DrawerButtonPressEvent) => void;
};

function DrawerButton(props: DrawerButtonProps) {
  const [isHovering, setHovering] = useState(false);

  const onPress = () => {
    let _preventDefault = false;

    props.onPress({
      preventDefault: () => {
        _preventDefault = true;
      },
    });

    /** If default wasn't prevented, close the drawer */
    if (_preventDefault === false) {
      props.closeDrawer?.();
    }
  };

  return (
    <Hoverable
      onHoverIn={() => setHovering(true)}
      onHoverOut={() => setHovering(false)}
    >
      <Pressable
        testID="account-settings-drawer-button"
        style={({ pressed }) => [
          drawerButtonStyles.container,
          isHovering ? drawerButtonStyles.hoverStyle : null,
          pressed ? drawerButtonStyles.pressedStyle : null,
        ]}
        onPress={onPress}
      >
        <Text style={drawerButtonStyles.label}>{props.label}</Text>
        {props.sublabel ? (
          <Text style={drawerButtonStyles.sublabel}>{props.sublabel}</Text>
        ) : null}
      </Pressable>
    </Hoverable>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 1,
    borderRightWidth: 1,
    borderRightColor: '#eee',
    backgroundColor: 'white',
  },
  scrollViewContainer: {
    paddingVertical: 32,
    flex: 1,
  },
  topSection: {
    paddingTop: 0,
    padding: 16,
    paddingHorizontal: 32,
  },
  closeButton: {
    flex: 0,
  },
  spacer: {
    height: 24,
  },
  versionText: {
    padding: 4,
    color: 'gray',
    fontFamily: 'Lato',
    fontSize: 14,
    alignSelf: 'flex-end',
    textAlign: 'right',
  },
});

const drawerButtonStyles = StyleSheet.create({
  container: {
    height: 64,
    justifyContent: 'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#eee',
    marginVertical: 2,
  },
  label: {
    fontFamily: 'Lato-Bold',
    fontSize: 17,
    paddingHorizontal: 16,
  },
  sublabel: {
    fontFamily: 'Lato',
    color: 'gray',
    fontSize: 15,
    paddingHorizontal: 16,
  },
  hoverStyle: {
    backgroundColor: '#eee',
  },
  pressedStyle: {
    backgroundColor: '#e0e0e0',
  },
});
