import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';

import TabNavigator from './navigators/TabNavigator';

import { isEmpty } from '/util';

import {
  NavigationProp,
  useLinkTo,
  useNavigation,
} from '@react-navigation/native';
import { ActivityIndicator, Platform, TextInput } from 'react-native';
import LoginStack from './navigators/LoginStack';
import { useAuthContext } from '/context';
import TakeSurveyScreen from '/screens/TakeSurveyScreen/TakeSurveyScreen';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking';
import { View } from 'react-native';
import TesterSignupStack from './navigators/TesterSignupStack';
import Button from '/components/Button';
import SectionText from '/components/common/Section/SectionText';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import env from '/env';
import { TesterStatus } from '/generated/graphql';
import TesterOnlyModeLandingScreen from '/screens/TesterOnlyMode/TesterOnlyModeLandingScreen';
import TesterDashboard from '../screens/TesterDashboard/TesterDashboard';
import { useIsTesterOnlyMode } from '/hooks/useIsTesterOnlyMode';
import FAQScreen from '/screens/FAQScreen';

const OnboardStackComponent = React.lazy(
  () => import('./navigators/OnboardStack'),
);
const OnboardStack = () => {
  return (
    <React.Suspense fallback={<LoadingSpinnerOverlay />}>
      <OnboardStackComponent />
    </React.Suspense>
  );
};

const RootStack = createStackNavigator();

const AppNavigator = () => {
  const isTesterOnlyMode = useIsTesterOnlyMode();
  const {
    user: cognitoUser,
    userData,
    fetchUserError,
    hasInitialized,
    setReturnTo,
  } = useAuthContext();

  const navigation = useNavigation<NavigationProp<any>>();

  const [checkingDevEnvAuth, setCheckingDevEnvAuth] = useState(
    env.ENV_NAME === 'development',
  );
  const [storedDevPw, setStoredDevPw] = useState<string>();
  const [devPwInput, setDevPwInput] = useState<string>('');

  const hasCheckedDevEnvAuth = useRef(false);
  useEffect(() => {
    if (hasCheckedDevEnvAuth.current) return;
    hasCheckedDevEnvAuth.current = true;

    if (env.ENV_NAME === 'development') {
      const init = async () => {
        try {
          const pw = await AsyncStorage.getItem('devPw');
          if (!pw) return;
          setStoredDevPw(pw);
        } catch {
        } finally {
          setCheckingDevEnvAuth(false);
        }
      };
      init();
    }
  }, []);

  const handleSubmitDevPw = () => {
    if (devPwInput && devPwInput === env.DEV_ENV_PW) {
      setStoredDevPw(devPwInput);
      AsyncStorage.setItem('devPw', devPwInput);
    }
  };

  const userId = cognitoUser?.userId;
  const onboarded = userData?.onboarded;

  const shouldForceShowTesterDashboard =
    !!userId &&
    !!onboarded &&
    isTesterOnlyMode &&
    userData?.tester_status !== TesterStatus.Approved;

  const shouldShowOnboardStack =
    !!userId &&
    !onboarded &&
    !fetchUserError &&
    !shouldForceShowTesterDashboard;

  const shouldShowTesterOnlyModeLanding =
    isTesterOnlyMode && userData?.tester_status !== TesterStatus.Approved;

  const shouldHideTabs =
    (!userId || (!!userId && fetchUserError && !onboarded)) &&
    (Platform.OS !== 'web' || isTesterOnlyMode);

  const shouldHideAuthStack = !!userId && !fetchUserError;

  const linkTo = useLinkTo();
  const linkingUrl = Linking.useURL();

  const hasHandledLinkingUrl = useRef(false);
  const handleLinkingUrl: Linking.URLListener = useCallback(
    ({ url }) => {
      try {
        if (hasHandledLinkingUrl.current) return;
        hasHandledLinkingUrl.current = true;

        const { hostname, path, queryParams, scheme } = Linking.parse(url);

        const isCustomScheme = scheme === 'keyconservation';
        const hasQueryParams = !isEmpty(queryParams);

        // If this is a deep link that uses the keyconservation:// scheme,
        // then the `hostname` will actually be part of the path
        let fullPath = `${
          isCustomScheme && hostname?.trim().length ? '/' + hostname : ''
        }${path?.trim().length ? '/' + path : ''}`;

        let queryParamsString = hasQueryParams
          ? url.slice(url.indexOf('?'))
          : '';

        const isAuthPath = [
          '/login',
          '/welcome',
          '/tester-signup',
          '/onboard',
          '/resetpassword',
          '/faq',
        ].some((authPath) => fullPath.startsWith(authPath));

        // Linking is automatically handled by React Navigation on web
        if (!!userData && Platform.OS !== 'web')
          linkTo(fullPath + queryParamsString);
        else if (!userData && !isAuthPath && shouldHideTabs) {
          setReturnTo(fullPath + queryParamsString);
          navigation.setParams({
            returnto: encodeURIComponent(fullPath + queryParamsString),
          });
        }
      } catch (error) {
        console.warn('Error handling linking url', error);
      }
    },
    [linkTo, navigation, setReturnTo, shouldHideTabs, userData],
  );

  useEffect(() => {
    if (!linkingUrl || !hasInitialized) return;

    handleLinkingUrl({ url: linkingUrl });
  }, [handleLinkingUrl, hasInitialized, linkingUrl]);

  // useful debug:
  // console.log(
  //   'AppNavigator',
  //   sub,
  //   onboarded,
  //   isAuthenticating,
  //   fetching,
  //   fetchUserError,
  // );

  const requirePassword =
    !__DEV__ &&
    env.ENV_NAME === 'development' &&
    (checkingDevEnvAuth || storedDevPw !== env.DEV_ENV_PW);

  if (requirePassword) {
    return checkingDevEnvAuth ? (
      <ActivityIndicator color={KEY_GRAY} size="large" />
    ) : (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <SectionText
          style={{
            marginBottom: 8,
          }}
        >
          Enter Password
        </SectionText>
        <TextInput
          style={TEXT_INPUT}
          value={devPwInput}
          onChangeText={setDevPwInput}
          onSubmitEditing={handleSubmitDevPw}
          secureTextEntry
        />
        <Button
          label="Submit"
          onPress={handleSubmitDevPw}
          containerStyle={{
            marginTop: 8,
          }}
        />
      </View>
    );
  }

  if (!hasInitialized) {
    return <LoadingSpinnerOverlay />;
  }

  return (
    <>
      <RootStack.Navigator
        screenOptions={{
          title: 'Key Conservation',
          headerShown: false,
          cardStyle: {
            height: '100%',
          },
        }}
      >
        {/* Conditionally replace TabNavigator stack with Onboard stack if authenticated but not onboarded */}
        {shouldForceShowTesterDashboard ? (
          <RootStack.Screen
            name="TesterSignupStack"
            component={TesterSignupStack}
          />
        ) : shouldShowOnboardStack ? (
          <RootStack.Screen
            options={{
              headerShown: false,
            }}
            name="Onboard"
            component={OnboardStack}
          />
        ) : // If running natively, require user to log in before accessing content
        shouldHideTabs && (Platform.OS !== 'web' || isTesterOnlyMode) ? null : (
          <>
            <RootStack.Screen name="tabs" component={TabNavigator} />
            {shouldHideAuthStack ? (
              <RootStack.Screen
                name="TesterDashboard"
                component={TesterDashboard}
                options={{
                  title: 'Tester Dasboard',
                  headerTitle: 'TESTER DASHBOARD',
                }}
              />
            ) : null}
          </>
        )}
        {shouldShowTesterOnlyModeLanding ? (
          <RootStack.Screen
            name="TesterOnlyModeLandingScreen"
            component={TesterOnlyModeLandingScreen}
          />
        ) : null}
        {/* Conditionally include auth stack if not authenticated  */}
        {shouldHideAuthStack ? null : (
          <RootStack.Screen
            options={() => ({
              title: 'Sign In',
              headerShown: false,
            })}
            name="auth"
            component={LoginStack}
          />
        )}

        {/* Migration / tester-only mode FAQ */}
        {isTesterOnlyMode ? (
          <RootStack.Screen
            name="FAQ"
            component={FAQScreen}
            options={FAQScreenOptions}
          />
        ) : null}

        {/* Shared modal screens */}
        <RootStack.Screen
          component={TakeSurveyScreen}
          name="TakeSurvey"
          options={TakeSurveyScreenOptions}
        />
      </RootStack.Navigator>
    </>
  );
};

const TakeSurveyScreenOptions = (): StackNavigationOptions => ({
  headerTitle: 'SURVEY',
  presentation: 'modal',
});

const FAQScreenOptions = (): StackNavigationOptions => ({
  title: 'Frequently Asked Questions',
  headerTitle: 'FREQUENTLY ASKED QUESTIONS',
  headerShown: false,
});

export default AppNavigator;
