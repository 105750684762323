import React from 'react';
import Button from '/components/Button';
import ResearchTopicPicker from '/components/ResearchTopicPicker';
import { KEY_GREEN } from '/constants';
import { ScrollView } from 'react-native';

type Props = {
  initialState: string[];
  onRequestClose: (topics: string[]) => void;
};

export default function ResearchTopicPickerModal(props: Props) {
  const [state, setState] = React.useState<string[]>(props.initialState);

  return (
    <>
      <ScrollView>
        <ResearchTopicPicker
          selectedTopics={state}
          onSelectTopic={(t) => {
            setState((prev) => {
              if (prev.includes(t)) {
                return prev.filter((topic) => topic !== t);
              } else {
                return [...prev, t];
              }
            });
          }}
          onRemoveTopic={(t) => {
            setState((prev) => prev.filter((topic) => topic !== t));
          }}
        />
      </ScrollView>
      <Button
        label="Apply"
        containerStyle={{
          marginTop: 8,
          alignSelf: 'flex-end',
        }}
        style={{
          backgroundColor: KEY_GREEN,
        }}
        onPress={() => {
          props.onRequestClose(state);
        }}
      />
    </>
  );
}
