import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Markdown from 'react-native-markdown-display';
import { Linking } from 'react-native';
import Collapsible from '/components/Collapsible';
import { KEY_DARK_GREEN } from '/constants';

export const FAQ_ITEMS: {
  title: string;
  content: string;
}[] = [
  {
    title:
      'Can my organization still join the Key Conservation platform if we have not signed up yet?',
    content:
      'Yes! You can still apply to be on the Key platform during our overhaul. Please apply on the Key platform [here](#join-us).',
  },
  {
    title:
      'Can I, as an individual, still join the Key Conservation platform if I have not signed up yet?',
    content:
      "Yes! You can still sign up during our overhaul, but you'll need to **opt in as a tester** to access the platform.\n\nTo get started, [sign up here](#join-us), then go to your **Dashboard** and enable tester access.\n\nBy joining now, you'll get an early look at new features and help shape the next phase of Key Conservation!",
  },
  {
    title: 'Can organizations be testers during the overhaul?',
    content:
      "Yes! Organizations can help test new features during the transition.\n\n- If your organization **already has a Key account**, sign up for testing on your **Dashboard** within the **Our Impact** section.\n- If your organization is **new to Key**, you'll need to [apply here](#join-us). Once approved, you'll gain access to sign up for testing.\n\nYour insights will help shape the next phase of the platform and ensure it meets the needs of conservation organizations like yours!",
  },
  {
    title: 'Can individuals be testers during the overhaul?',
    content:
      "Yes! Individuals can help test new features during this transition.\n\n- If you **already have a Key account**, simply [log in](#join-us) and go to your **Impact tab → Dashboard** to opt in as a tester.\n- If you're **new to Key**, you'll need to [sign up](#join-us) first. Once you're in, go to your **Dashboard** and opt in as a tester to gain access.\n\nYour feedback will play a crucial role in shaping the next phase of the platform!",
  },
  {
    title: 'Why is Key Conservation going offline?',
    content:
      "We're overhauling the platform to provide a stronger, more impactful, and more user-friendly experience for conservation organizations.",
  },
  {
    title:
      'When will the platform go offline for the overhaul and when will it relaunch?',
    content:
      'The platform will go offline on Wednesday, February 19, 2025, with the relaunch scheduled for Spring 2025.',
  },
  {
    title:
      'Will my current profile and data be preserved during the transition?',
    content:
      'Yes, all profiles and data will be securely preserved. You will have access to your data at all times, and everything will be publicly available again when we relaunch.',
  },
  {
    title: 'What is Godwit Key, and how does it benefit my organization?',
    content:
      'Godwit Key is the new commercial subsidiary of Key Conservation, designed to facilitate relationships between the private sector, conservationists, and local communities. It helps businesses financially contribute to biodiversity targets while creating both immediate and sustainable revenue streams for partner organizations.',
  },
  {
    title: 'Are Key Conservation and Godwit Key the same thing?',
    content:
      'No, but they are closely connected. Key Conservation remains at the heart of our mission to support conservation efforts, and Godwit Key is an addition that takes this support to the next level. Key Conservation was built to connect organizations with supporters in real-time, and now, Godwit Key expands on that foundation by providing new opportunities for sustainable funding and deeper engagement.\n\nThis next phase is designed to give organizations even more tools and resources to succeed, while continuing the work that Key Conservation started.',
  },
  {
    title:
      'How is Key Conservation and Godwit Key sustainable or making money?',
    content:
      'A portion of corporate fees will go towards covering our operating costs, allowing us to maintain and grow the platform while continuing to offer free services to conservation organizations.',
  },
  {
    title:
      'If my organization already has a Key Conservation account, will we automatically be enrolled in the Godwit Key opportunity?',
    content:
      "No, but having a Key Conservation account is the first step!\n\nTo get access to the Godwit Key opportunity, sign in to the Key platform [here](#join-us) and opt in through your **Impact tab** on your Dashboard.\n\n🚀 **Don't miss out on this exclusive opportunity** to be among the first organizations to access new funding tools, deeper supporter engagement, and game-changing features designed to take your impact to the next level! [Sign in here](#join-us), then head to your **Dashboard** to secure your spot.",
  },
  {
    title: 'What is the testing process for the new Key Conservation platform?',
    content:
      "You'll have access to a dashboard where you can explore new features, provide feedback, and stay updated on upcoming testing opportunities.",
  },
  {
    title:
      "How can I provide feedback on the Key Conservation platform while it's being worked on?",
    content:
      "The best way to provide feedback is by logging into the Key platform and accessing your testing dashboard. We'll regularly provide new opportunities for both general and specific feedback on various topics. You may also email our team directly at [contact@keyconservation.org](mailto:contact@keyconservation.org).",
  },
];

interface FAQProps {
  onLinkPress?: (url: string) => boolean;
}

export default function FAQ({ onLinkPress }: FAQProps) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Frequently Asked{`\n`}Questions</Text>

      <View style={styles.faqContainer}>
        {FAQ_ITEMS.map((item, i) => (
          <Collapsible
            key={i}
            title={item.title}
            titleStyle={styles.faqCollapsibleTitle}
            style={styles.faqCollapsible}
            collapsed={i !== 0}
          >
            <View style={styles.contentContainer}>
              <Markdown
                style={{
                  body: {
                    color: 'black',
                    fontSize: 20,
                    fontFamily: 'Lato',
                  },
                  strong: {
                    fontFamily: 'Lato-Bold',
                  },
                  link: {
                    color: KEY_DARK_GREEN,
                    textDecorationLine: 'underline',
                  },
                }}
                onLinkPress={(url) => {
                  if (onLinkPress) {
                    return onLinkPress(url);
                  }
                  Linking.openURL(url);
                  return false;
                }}
              >
                {item.content}
              </Markdown>
            </View>
          </Collapsible>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 56,
    marginTop: 48,
    textAlign: 'center',
    alignSelf: 'center',
    color: 'black',
    fontFamily: 'FranklinGothic-Demi',
  },
  faqContainer: {
    flex: 1,
    padding: 32,
    alignItems: 'center',
  },
  faqCollapsible: {
    width: '100%',
    borderWidth: 0,
    maxWidth: 720,
  },
  faqCollapsibleTitle: {
    fontFamily: 'Lato-Bold',
    fontSize: 22,
  },
  contentContainer: {
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
});
