import React from 'react';

import withAdminAccess from '/components/withAdminAccess';
import SectionContainer from '/components/common/Section/SectionContainer';
import SectionTitle from '/components/common/Section/SectionTitle';
import Button from '/components/Button';
import { ScrollView, StyleSheet, View } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  useGetTotalUnreadTesterGeneralFeedbackQuery,
  useGetTotalUnreadTesterObjectiveFeedbackQuery,
} from '/generated/graphql';
import { Badge } from '@rneui/base';
import { SCROLL_VIEW_CONTENT_CONTAINER } from '/constants';

type Props = {
  navigation: StackNavigationProp<any>;
};

function Testers(props: Props) {
  const [generalFeedbackQuery] = useGetTotalUnreadTesterGeneralFeedbackQuery({
    requestPolicy: 'cache-and-network',
  });
  const [objectivesFeedbackQuery] =
    useGetTotalUnreadTesterObjectiveFeedbackQuery({
      requestPolicy: 'cache-and-network',
    });

  return (
    <ScrollView contentContainerStyle={SCROLL_VIEW_CONTENT_CONTAINER}>
      <SectionContainer style={styles.section}>
        <SectionTitle>MANAGE TESTERS</SectionTitle>
        <Button
          label="View"
          onPress={() => {
            props.navigation.navigate('ManageTesters');
          }}
        />
      </SectionContainer>

      <SectionContainer style={styles.section}>
        <SectionTitle>GENERAL FEEDBACK</SectionTitle>
        <View>
          {generalFeedbackQuery.data?.listAllTesterGeneralFeedback
            .totalUnreads ? (
            <Badge
              value={
                generalFeedbackQuery.data?.listAllTesterGeneralFeedback
                  .totalUnreads
              }
              textStyle={{
                fontSize: 12,
                fontFamily: 'Lato-Bold',
              }}
              containerStyle={{
                position: 'absolute',
                right: -9,
                top: -8,
                zIndex: 1,
              }}
              badgeStyle={{
                backgroundColor: 'red',
                width: 20,
                height: 20,
                borderRadius: 10,
              }}
            />
          ) : null}
          <Button
            label="View"
            onPress={() => {
              props.navigation.navigate('ListTesterGeneralFeedback');
            }}
          />
        </View>
      </SectionContainer>

      <SectionContainer style={styles.section}>
        <SectionTitle>TEST OBJECTIVES</SectionTitle>
        <View>
          {objectivesFeedbackQuery.data
            ?.getTotalUnreadTesterObjectiveFeedback ? (
            <Badge
              value={Math.min(
                objectivesFeedbackQuery.data
                  ?.getTotalUnreadTesterObjectiveFeedback ?? 0,
                99,
              )}
              textStyle={{
                fontSize: 12,
                fontFamily: 'Lato-Bold',
              }}
              containerStyle={{
                position: 'absolute',
                right: -9,
                top: -8,
                zIndex: 1,
              }}
              badgeStyle={{
                backgroundColor: 'red',
                width:
                  objectivesFeedbackQuery.data
                    .getTotalUnreadTesterObjectiveFeedback > 99
                    ? 26
                    : 20,
                height: 20,
                borderRadius: 10,
              }}
            />
          ) : null}

          <Button
            label="View"
            onPress={() => {
              props.navigation.navigate('ListTesterObjectives');
            }}
          />
        </View>
      </SectionContainer>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default withAdminAccess(Testers);
