import React from 'react';
import { ScrollView, Linking, View, Image, Text } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { Feather } from '@expo/vector-icons';
import FAQ from '/components/FAQ/FAQ';
import Button from '/components/Button';
import { KEY_GRAY } from '/constants';
import { useAuthContext } from '/context';

export default function FAQScreen() {
  const linkTo = useLinkTo();
  const { user } = useAuthContext();

  return (
    <ScrollView style={{ flex: 1, backgroundColor: 'white' }}>
      <View style={{ alignItems: 'flex-end', padding: 16 }}>
        <Button
          containerStyle={{ maxWidth: 180 }}
          style={{
            backgroundColor: 'white',
            borderWidth: 1,
            borderColor: KEY_GRAY,
            paddingVertical: 8,
            paddingHorizontal: 16,
          }}
          label={
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image
                source={require('/assets/images/keyFullBlack.png')}
                style={{ width: 20, height: 20 }}
                resizeMode="contain"
              />
              <View style={{ width: 8 }} />
              <Text
                style={{
                  fontFamily: 'Lato-Bold',
                  fontSize: 14,
                  color: KEY_GRAY,
                  letterSpacing: 0.5,
                }}
              >
                GO TO PLATFORM
              </Text>
              <View style={{ width: 4 }} />
              <Feather name="chevron-right" size={16} color={KEY_GRAY} />
            </View>
          }
          onPress={() => (user ? linkTo('/') : linkTo('/welcome'))}
        />
      </View>
      <FAQ
        onLinkPress={(url) => {
          if (url === '#join-us') {
            Linking.openURL('https://keyconservation.io/welcome#join-us');
            return false;
          }
          Linking.openURL(url);
          return false;
        }}
      />
    </ScrollView>
  );
}
