'use dom';

import { MaterialIcons } from '@expo/vector-icons';
import {
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import {
  EditorState,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  TextFormatType,
} from 'lexical';
import React, { useCallback, useMemo } from 'react';
import {
  ErrorBoundary,
  baseStyles,
  getBaseLexicalConfig,
  textFormatStyles,
} from './utils';
import { KEY_LIGHT_GRAY } from '/constants';
import debounce from 'lodash/debounce';

interface RichTextEditorProps {
  /**
   * Initial content to populate the editor with.
   * Can be a plain text string or a valid Lexical JSON string.
   */
  initialContent?: string;
  onChange?: (content: string) => void;
  placeholder?: string;
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as const,
    backgroundColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    overflow: 'hidden',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row' as const,
    padding: '6px 8px',
    border: '1px solid #eee',
    backgroundColor: '#fff',
    gap: '2px',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  toolbarGroup: {
    display: 'flex',
    flexDirection: 'row' as const,
    gap: '2px',
  },
  toolbarButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    padding: 0,
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    ':hover': {
      backgroundColor: '#f0f0f0',
    },
    ':active': {
      backgroundColor: '#e8e8e8',
    },
  },
  editor: {
    flex: 1,
    position: 'relative' as const,
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  contentEditable: {
    ...baseStyles.contentEditable,
    minHeight: '200px',
    maxHeight: '400px',
    margin: 0,
    padding: '16px',
    paddingTop: '0px',
    paddingBottom: '0px',
    overflowY: 'auto' as const,
  },
  placeholder: {
    position: 'absolute' as const,
    top: '16px',
    left: '16px',
    right: '16px',
    color: '#999',
    pointerEvents: 'none' as const,
    userSelect: 'none' as const,
    fontFamily: 'Lato-Regular',
    fontSize: '16px',
    lineHeight: '1.5',
  },
};

const ToolbarButton = React.memo(
  ({
    onClick,
    isActive,
    icon,
    title,
    ariaLabel,
  }: {
    onClick: () => void;
    isActive: boolean;
    icon: keyof typeof MaterialIcons.glyphMap;
    title: string;
    ariaLabel: string;
  }) => {
    return (
      <button
        onClick={onClick}
        style={{
          ...styles.toolbarButton,
          backgroundColor: isActive ? '#e8e8e8' : 'transparent',
        }}
        title={title}
        aria-label={ariaLabel}
      >
        <MaterialIcons
          name={icon}
          size={18}
          color={isActive ? '#000' : '#444'}
        />
      </button>
    );
  },
);

const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const isFormatActive = useCallback(
    (format: TextFormatType) => {
      return editor.getEditorState().read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return false;
        return selection.hasFormat(format);
      });
    },
    [editor],
  );

  const isListActive = useCallback(() => {
    return editor.getEditorState().read(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return false;
      const nodes = selection.getNodes();
      return nodes.some((node) => {
        let parent = node.getParent();
        while (parent) {
          if (parent instanceof ListNode) return true;
          parent = parent.getParent();
        }
        return false;
      });
    });
  }, [editor]);

  const handleBulletList = useCallback(() => {
    if (isListActive()) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
  }, [editor, isListActive]);

  const handleBold = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  }, [editor]);

  const handleItalic = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
  }, [editor]);

  return (
    <div style={styles.toolbar} role="toolbar">
      <div style={styles.toolbarGroup}>
        <ToolbarButton
          onClick={handleBulletList}
          isActive={isListActive()}
          icon="format-list-bulleted"
          title="Bullet List"
          ariaLabel="Insert bullet list"
        />
        <ToolbarButton
          onClick={handleBold}
          isActive={isFormatActive('bold')}
          icon="format-bold"
          title="Bold"
          ariaLabel="Format text as bold"
        />
        <ToolbarButton
          onClick={handleItalic}
          isActive={isFormatActive('italic')}
          icon="format-italic"
          title="Italic"
          ariaLabel="Format text as italic"
        />
      </div>
    </div>
  );
};

function OnChangePluginWrapper({
  onChange,
}: {
  onChange?: (content: string) => void;
}) {
  const debouncedOnChange = useMemo(
    () =>
      debounce(
        (editorState: EditorState) => {
          if (onChange) {
            const json = JSON.stringify(editorState.toJSON());
            onChange(json);
          }
        },
        250,
        { leading: true, trailing: true },
      ),
    [onChange],
  );

  React.useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return <OnChangePlugin onChange={debouncedOnChange} />;
}

export default function RichTextEditor({
  initialContent,
  onChange,
  placeholder,
}: RichTextEditorProps) {
  const initialConfig = getBaseLexicalConfig(
    'MyRichTextEditor',
    initialContent,
    true,
  );

  return (
    <>
      <style>{textFormatStyles}</style>
      <LexicalComposer initialConfig={initialConfig}>
        <div style={styles.container}>
          <ToolbarPlugin />
          <div style={styles.editor}>
            <RichTextPlugin
              contentEditable={
                <ContentEditable style={styles.contentEditable} />
              }
              placeholder={
                placeholder ? (
                  <div style={styles.placeholder}>
                    <span>{placeholder}</span>
                  </div>
                ) : null
              }
              ErrorBoundary={ErrorBoundary}
            />
            <ListPlugin />
            <HistoryPlugin />
            {onChange && <OnChangePluginWrapper onChange={onChange} />}
          </div>
        </div>
      </LexicalComposer>
    </>
  );
}
