import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';
import { UserMention } from '/generated/graphql';
import Hyperlink from 'react-native-hyperlink';
import { HYPERLINK_STYLE } from '/constants';

type Props = {
  value: string;
  mentions: Pick<UserMention, 'start' | 'end' | 'userId'>[] | undefined | null;
  style?: StyleProp<TextStyle>;
  mentionStyle?: StyleProp<TextStyle>;
  numberOfLines?: number;
  withHyperlink?: boolean;
};

export default function UserMentionText(props: Props) {
  const { push } = useNavigation<StackNavigationProp<any>>();

  const bodyTextComponent = useMemo(() => {
    const elements: (JSX.Element | string)[] = [];

    const getMentionByPosition = (position: number) => {
      return props.mentions?.find(
        (_mention) => _mention.start - 1 <= position && _mention.end > position,
      );
    };

    const segments = !props.mentions?.length
      ? [
          {
            start: 0,
            end: props.value.length,
          },
        ]
      : props.mentions
          ?.sort((a, b) => a.start - b.start)
          .reduce((acc, mention, index, mentions) => {
            if (index === 0 && mention.start > 0) {
              acc.push({ start: 0, end: mention.start - 1 });
            }

            acc.push({ start: mention.start, end: mention.end });

            if (index < mentions.length - 1) {
              acc.push({
                start: mention.end,
                end: mentions[index + 1].start - 1,
              });
            } else if (mention.end < props.value.length) {
              acc.push({ start: mention.end, end: props.value.length });
            }

            return acc;
          }, [] as { start: number; end: number }[]);

    segments?.forEach((segment) => {
      const mention = getMentionByPosition(segment.start);

      if (mention) {
        elements.push(
          <Text
            key={segment.start}
            onPress={() => {
              push('Profile', {
                id: mention.userId,
              });
            }}
            style={[
              {
                color: '#14C691',
                fontFamily: 'Lato-Bold',
              },
              props.mentionStyle,
            ]}
          >
            {props.value.slice(segment.start, segment.end)}
          </Text>,
        );
      } else {
        elements.push(props.value.slice(segment.start, segment.end));
      }
    });

    if (props.withHyperlink) {
      return (
        <Hyperlink linkDefault linkStyle={HYPERLINK_STYLE}>
          <Text style={props.style} numberOfLines={props.numberOfLines}>
            {elements.map((element, index) => {
              if (typeof element === 'string') {
                return element;
              }
              return React.cloneElement(element, {
                key: `mention-${index}`,
              });
            })}
          </Text>
        </Hyperlink>
      );
    }

    return (
      <Text style={props.style} numberOfLines={props.numberOfLines}>
        {elements.map((element, index) => {
          if (typeof element === 'string') {
            return element;
          }
          return React.cloneElement(element, {
            key: `mention-${index}`,
          });
        })}
      </Text>
    );
  }, [
    props.mentionStyle,
    props.mentions,
    props.numberOfLines,
    props.style,
    props.value,
    props.withHyperlink,
    push,
  ]);

  return bodyTextComponent;
}
