import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { KEY_GREEN } from '/constants';

import { KEY_GRAY, OFFWHITE } from '../../constants';
import hyphenate from '/util/hyphenate';

export interface ButtonToggleProps {
  label: string | (() => JSX.Element) | JSX.Element;
  labelStyle?: TextStyle;
  style?: ViewStyle;
  selected?: boolean;
  onPress?: () => void;
  /** Default is `OFFWHITE` */
  color?: string;
  /** Default is `KEY_GRAY` */
  selectedColor?: string;
  /** Default is `KEY_GRAY` */
  textColor?: string;
  /** Default is `white` */
  selectedTextColor?: string;
  disabled?: boolean;
}

const SELECTED_COLOR = KEY_GRAY;
const UNSELECTED_COLOR = OFFWHITE;

const SELECTED_TEXT_COLOR = 'white';
const UNSELECTED_TEXT_COLOR = KEY_GRAY;

export default function ButtonToggle(props: ButtonToggleProps) {
  return (
    <TouchableOpacity
      testID="button"
      onPress={props.onPress}
      disabled={props.disabled}
      style={[
        styles.container,
        {
          backgroundColor: props.selected
            ? props.selectedColor ?? SELECTED_COLOR
            : props.color ?? UNSELECTED_COLOR,
        },
        props.style,
      ]}
    >
      {typeof props.label === 'function' ? (
        props.label()
      ) : typeof props.label === 'string' ? (
        <Text
          style={[
            styles.buttonText,
            props.labelStyle,
            {
              color: props.selected
                ? props.selectedTextColor ?? SELECTED_TEXT_COLOR
                : props.textColor ?? UNSELECTED_TEXT_COLOR,
            },
          ]}
        >
          {hyphenate(props.label?.toUpperCase?.())}
        </Text>
      ) : (
        props.label
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 4,
    minHeight: 30,
    borderRadius: 6,
    marginRight: 7,
    paddingHorizontal: 12,
    backgroundColor: KEY_GREEN,
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: 'Lato-Bold',
    letterSpacing: 1,
    textAlign: 'center',
    color: KEY_GRAY,
    fontSize: 14,
  },
});
