import React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import X from '../assets/jsicons/miscIcons/X';
import { KEY_GRAY } from '/constants';

interface IRemovableBadgeProps {
  label: string;
  labelColor?: string;
  color?: string;
  onRemove?: () => void;
  style?: StyleProp<ViewStyle>;
  removeButtonColor?: string;
}

const RemovableBadge = (props: IRemovableBadgeProps) => {
  return (
    <TouchableOpacity
      style={[
        styles.aroundName,
        { backgroundColor: props.color ?? '#3FFFB3' },
        props.style,
      ]}
    >
      <Text style={[styles.text, { color: props.labelColor ?? KEY_GRAY }]}>
        {props.label}
      </Text>
      <TouchableOpacity
        testID={`removable-badge-remove-button-${props.label}`}
        style={styles.aroundX}
        onPress={props.onRemove}
      >
        <X fill={props.removeButtonColor} />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  aroundName: {
    backgroundColor: '#3FFFB3',
    marginTop: 15,
    marginHorizontal: 8,
    borderRadius: 5,
    alignSelf: 'flex-start',
  },
  text: {
    textTransform: 'uppercase',
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    padding: 10,
  },
  aroundX: {
    position: 'absolute',
    right: -10,
    top: -10,
  },
});

export default RemovableBadge;
