import { Entypo, Ionicons } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import SideMenu from '@mtourj/react-native-side-menu';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  BackHandler,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import useBinaryTimingAnimation from '../../../hooks/useBinaryTimingAnimation';
import Carousel from '../../Carousel/Carousel';
import OrganizationBigIssueDraftMenu from './OrganizationBigIssueDraftMenu';
import Alert from '/Alert';
import Button from '/components/Button';
import CampaignPicker, {
  ICampaignPickerCampaign,
} from '/components/CampaignPicker/CampaignPicker';
import FormList from '/components/FormList';
import LoadingOverlay from '/components/LoadingOverlay';
import {
  ValidatedAny,
  ValidatedTextInput,
  useFormValidationContext,
  withFormValidation,
} from '/components/ValidatedForm';
import {
  CARD_TITLE_FONT_SIZE,
  CONTENT_PARAGRAPH_FONT_SIZE,
  KEY_GRAY,
  KEY_GREEN,
  TEXT_INPUT,
  TEXT_INPUT_LARGE,
} from '/constants';
import {
  Campaign,
  CampaignPost,
  CampaignReferenceInput,
  CarouselCardInput,
  CreateOrganizationBigIssueInput,
  OrganizationBigIssue,
  TitledLink,
  TitledLinkInput,
  UpdateOrganizationBigIssueInput,
  User,
  useCreateOrganizationBigIssueMutation,
  useDeleteOrganizationBigIssueMutation,
  useGetUserBigIssuesQuery,
  useUpdateOrganizationBigIssueMutation,
} from '/generated/graphql';
import { DeepPartial } from '/types';
import { formatURL, isEmpty, isValidURLRegex } from '/util';

interface ICampaignUser extends Pick<User, 'id' | 'name' | 'profile_image'> {}

interface ICampaignPost
  extends Pick<CampaignPost, 'id' | 'media' | 'thumbnail'> {}

interface ICampaign extends Pick<Campaign, 'id' | 'name' | 'created_at'> {
  original_post: ICampaignPost;
  user: ICampaignUser;
}

export interface IBigIssue
  extends Omit<Partial<OrganizationBigIssue>, 'campaigns'> {
  campaigns: {
    total: number;
    nextToken?: string | null | undefined;
    items: ICampaign[];
  };
}

interface Props {
  userId: string;
  data: IBigIssue | undefined;
  setBusy: (busy: boolean) => void;
  refetch: () => void;
  onRequestClose: () => void;
}

/** Used because simply setting state to an empty object does not clear text fields in the browser */
const DEFAULT_STATE: CreateOrganizationBigIssueInput = {
  title: { text: '' },
  main_carousel: [],
  quick_summary_fact1: { text: '' },
  quick_summary_fact2: { text: '' },
  quick_summary_fact3: { text: '' },
  quick_summary_links: [],
  how_this_affects_us: { text: '' },
  how_this_affects_us_carousel: [],
  how_this_affects_us_links: [],
  what_we_are_doing: { text: '' },
  what_we_are_doing_carousel: [],
  what_we_are_doing_links: [],
  how_you_can_help: { text: '' },
  campaigns: [],
  draft: true,
};

const getInvalidCarouselMessage = (
  requireCarousel?: boolean,
  requireCaption?: boolean,
) => {
  return `${
    requireCarousel ? 'Must include at least one item, and e' : 'E'
  }ach item here must include a photo/video${
    requireCaption ? ' and caption' : '.'
  }`;
};

function EditOrganizationBigIssueModal(props: Props) {
  const [{ data, fetching, error: fetchDraftsError, stale }, refetch] =
    useGetUserBigIssuesQuery({
      variables: {
        userId: props.userId!,
        draft: true,
        includeBigIssueCampaigns: true,
      },
      pause: !props.userId,
      requestPolicy: 'cache-and-network',
    });
  const fetchingDrafts = fetching || stale;
  const drafts = useMemo(() => data?.getBigIssues || [], [data?.getBigIssues]);

  const [{ fetching: creating }, createBigIssue] =
    useCreateOrganizationBigIssueMutation();
  const [{ fetching: updating }, updateBigIssue] =
    useUpdateOrganizationBigIssueMutation();
  const [, deleteBigIssue] = useDeleteOrganizationBigIssueMutation();

  const saving = creating || updating;

  const [state, setState] = useState<CreateOrganizationBigIssueInput>(
    props.data ? getBigIssueInputFromDraft(props.data) : DEFAULT_STATE,
  );

  const windowDimensions = useWindowDimensions();

  const scrollViewRef = useRef<ScrollView>();

  const { validateForm, fields } = useFormValidationContext(scrollViewRef);

  const [campaignPickerSelection, _setCampaignPickerSelection] = useState<
    ICampaignPickerCampaign[]
  >([]);

  const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});
  const [uploadErrors, setUploadErrors] = useState<{ [key: string]: boolean }>(
    {},
  );

  const [showDrafts, setShowDrafts] = useState(false);

  /** Used to prompt the user to confirm that they want to exit the modal
   * when they may lose changes they've made */
  const [promptConfirmExit, setPromptConfirmExit] = useState(false);

  const [promptConfirmOverwriteDraftId, setPromptConfirmOverwriteDraftId] =
    useState<string>();

  const [submitWhenUploadsAreFinished, setSubmitWhenUploadsAreFinished] =
    useState(false);
  const [saveDraftWhenUploadsAreFinished, setSaveDraftWhenUploadsAreFinished] =
    useState(false);

  const closeModal = useCallback(
    function () {
      props.onRequestClose();
    },
    [props],
  );

  const saveAndClose = useCallback(
    async function (
      input: CreateOrganizationBigIssueInput | UpdateOrganizationBigIssueInput,
    ) {
      try {
        props.setBusy(true);

        if (input?.id) {
          // If we are editing an existing card
          const { error: err } = await updateBigIssue({
            input: input as UpdateOrganizationBigIssueInput,
          });
          if (err) throw err;
        } else {
          const { error: err } = await createBigIssue({
            input: input as CreateOrganizationBigIssueInput,
            userId: props.userId,
          });
          if (err) throw err;
        }

        Alert.notify({
          message: `Big issue${input.draft ? ' draft' : ''} saved successfully`,
          color: KEY_GREEN,
        });

        props.refetch();
        closeModal();
      } catch (error) {
        console.warn(
          'TheBigIssues.tsx:onEditModalRequestClose(): failed to update big issue',
          error,
        );
        Alert.notify({
          message: `Failed to update big issue${input.draft ? ' draft' : ''}`,
          color: 'crimson',
          textColor: 'white',
        });
      } finally {
        props.refetch();
        props.setBusy(false);
      }
    },
    [closeModal, createBigIssue, props, updateBigIssue],
  );

  /** Helper function that returns `state` with all empty items in carousels removed */
  const getTrimmedState = useCallback(
    function () {
      const newState = Object.assign({}, state);

      /** Filter all empty items from all carousels  */
      newState.main_carousel = newState.main_carousel?.filter(
        (item) => item.caption || item.media,
      );
      newState.how_this_affects_us_carousel =
        newState.how_this_affects_us_carousel?.filter(
          (item) => item.caption || item.media,
        );
      newState.what_we_are_doing_carousel =
        newState.what_we_are_doing_carousel?.filter(
          (item) => item.caption || item.media,
        );

      return newState;
    },
    [state],
  );

  const onSaveDraft = useCallback(
    function () {
      if (isEmpty(state, true) && !props.data) {
        closeModal();
        return;
      }

      setPromptConfirmExit(false);

      if (Object.values(uploading).includes(true)) {
        // wait for uploads to finish if they're still going
        setSaveDraftWhenUploadsAreFinished(true);
        return;
      }

      const newState = getTrimmedState();
      newState.draft = true; // Set as draft

      console.log('onSaveDraft', newState.id);

      saveAndClose(newState);
    },
    [closeModal, getTrimmedState, props.data, saveAndClose, state, uploading],
  );

  const onSubmit = useCallback(
    function () {
      // If the exit prompt is open, close it now
      if (promptConfirmExit) setPromptConfirmExit(false);

      if (!validateForm()) return;

      if (Object.values(uploading).includes(true)) {
        // If there are some uploads still happening, set queueExitModalOnUploadSuccess
        // so that we exit once all uploads are complete
        setSubmitWhenUploadsAreFinished(true);

        return;
      }

      const newState = getTrimmedState();
      newState.draft = false; // No longer a draft

      saveAndClose(newState);
    },
    [getTrimmedState, promptConfirmExit, saveAndClose, uploading, validateForm],
  );

  useEffect(() => {
    const isUploading = Object.values(uploading).includes(true);
    const hasErrors = Object.values(uploadErrors).some((error) => !!error);

    // If we are finished uploading successfully and exit is queued, exit!
    if (!isUploading && !hasErrors) {
      if (submitWhenUploadsAreFinished) onSubmit();
      else if (saveDraftWhenUploadsAreFinished) onSaveDraft();
    }
  }, [
    onSaveDraft,
    onSubmit,
    saveDraftWhenUploadsAreFinished,
    submitWhenUploadsAreFinished,
    uploadErrors,
    uploading,
  ]);

  useEffect(() => {
    setState(
      props.data ? getBigIssueInputFromDraft(props.data) : DEFAULT_STATE,
    );
    _setCampaignPickerSelection(
      props.data?.campaigns?.items?.map((campaign) => ({
        id: campaign.id,
        created_at: campaign.created_at,
        name: campaign.name,
        original_post: campaign.original_post,
        user: campaign.user,
      })) || [],
    );
  }, [props.data]);

  function onChangeCampaignPickerSelection(
    selection: ICampaignPickerCampaign[],
  ) {
    _setCampaignPickerSelection(selection);
    setState((prevState) => ({
      ...prevState,
      campaigns: selection.map((campaign) => ({
        id: campaign.id,
      })),
    }));
  }

  function getBigIssueInputFromDraft(
    draft: DeepPartial<OrganizationBigIssue> | undefined,
  ): CreateOrganizationBigIssueInput {
    if (!draft) return DEFAULT_STATE;

    const main_carousel = (draft?.main_carousel
      ?.filter((item) => !!item?.media)
      .map((item) => ({
        media: item!.media,
        thumbnail: item!.thumbnail,
        caption: item!.caption,
      })) ?? []) as CarouselCardInput[];

    const quick_summary_links = (draft?.quick_summary_links
      ?.filter((item) => !!item?.url)
      .map((item) => ({
        url: item!.url,
        title: item!.title,
      })) ?? []) as TitledLinkInput[];

    const how_this_affects_us_links = (draft?.how_this_affects_us_links
      ?.filter((item) => !!item?.url)
      .map((item) => ({
        url: item!.url,
        title: item!.title,
      })) ?? []) as TitledLinkInput[];

    const how_this_affects_us_carousel = (draft?.how_this_affects_us_carousel
      ?.filter((item) => !!item?.media)
      .map((item) => ({
        media: item!.media,
        thumbnail: item!.thumbnail,
        caption: item!.caption,
      })) ?? []) as CarouselCardInput[];

    const what_we_are_doing_links = (draft?.what_we_are_doing_links
      ?.filter((item) => !!item?.url)
      .map((item) => ({
        url: item!.url,
        title: item!.title,
      })) ?? []) as TitledLinkInput[];

    const what_we_are_doing_carousel = (draft?.what_we_are_doing_carousel
      ?.filter((item) => !!item?.media)
      .map((item) => ({
        media: item!.media,
        thumbnail: item!.thumbnail,
        caption: item!.caption,
      })) ?? []) as CarouselCardInput[];

    const campaigns = (draft?.campaigns?.items
      ?.filter((item) => !!item?.id)
      .map((item) => ({
        id: item!.id,
      })) ?? []) as CampaignReferenceInput[];

    return {
      id: draft.id,
      title: { text: draft.title?.text || '' },
      draft: draft.draft ?? true,
      what_we_are_doing: {
        text: draft.what_we_are_doing?.text || '',
      },
      quick_summary_fact1: {
        text: draft.quick_summary_fact1?.text || '',
      },
      quick_summary_fact2: {
        text: draft.quick_summary_fact2?.text || '',
      },
      quick_summary_fact3: {
        text: draft.quick_summary_fact3?.text || '',
      },
      how_this_affects_us: {
        text: draft.how_this_affects_us?.text || '',
      },
      main_carousel,
      quick_summary_links,
      what_we_are_doing_links,
      how_this_affects_us_links,
      what_we_are_doing_carousel,
      how_this_affects_us_carousel,
      how_you_can_help: {
        text: draft.how_you_can_help?.text || '',
      },
      campaigns: campaigns,
    };
  }

  const onCancel = useCallback(
    function () {
      // If state is empty by `isEmpty`'s definition, then just close without prompting
      if (isEmpty(state, true) && !props.data) {
        closeModal();
        return;
      }

      if (!_.isEqual(state, getBigIssueInputFromDraft(props.data))) {
        setPromptConfirmExit(true);
        return;
      }

      closeModal();
    },
    [closeModal, props.data, state],
  );

  useEffect(() => {
    const listener = BackHandler.addEventListener('hardwareBackPress', () => {
      onCancel();
      return true;
    });

    return () => {
      listener.remove();
    };
  }, [onCancel]);

  function onViewDrafts() {
    setShowDrafts(true);
  }

  const validateCarousel = (
    _data: CarouselCardInput[] | undefined,
    requireCarousel?: boolean,
    requireCaption?: boolean,
  ) => {
    // If no data, return false
    if (!_data?.length && requireCarousel) return false;

    // If all data is just empty items and carousel is not required, return true
    // **NOTE: This only works as long as we filter out all empty items from all carousels in onSubmit
    if (
      _data?.every((item) => !item.caption && !item.media) &&
      !requireCarousel
    )
      return true;

    // If media is missing, or if caption is required and missing, return false
    if (_data?.some((card) => !card.media || (requireCaption && !card.caption)))
      return false;
    else return true;
  };

  const onSelectDraft = useCallback(
    (id: string | undefined, options?: { overwrite?: boolean }) => {
      setShowDrafts(false);

      if (id && state.id === id) return;

      if (id && state.id && !options?.overwrite) {
        setPromptConfirmOverwriteDraftId(id);
      } else {
        const draft = drafts?.find((d) => d.id === id);

        if (draft) {
          setState(getBigIssueInputFromDraft(draft));
        } else {
          setState(DEFAULT_STATE);
        }
      }
    },
    [drafts, state.id],
  );

  function onOverwriteWithDraft() {
    onSelectDraft(promptConfirmOverwriteDraftId, { overwrite: true });
    setPromptConfirmOverwriteDraftId(undefined);
  }

  async function onDeleteDraft(id: string) {
    try {
      props.setBusy(true);

      const result = await deleteBigIssue({
        id,
      });

      if (result.error) {
        console.warn(
          'TheBigIssues.tsx:onDeleteDraft(): failed to delete draft',
        );
        Alert.notify({
          message: 'Failed to delete draft',
          color: 'crimson',
          textColor: 'white',
        });
      } else {
        refetch({ requestPolicy: 'network-only' });

        if (state.id === id) {
          setState(DEFAULT_STATE);
        }
      }
    } catch (error) {
      console.warn(
        'TheBigIssues.tsx:onDeleteDraft(): failed to delete draft',
        error,
      );
      Alert.notify({
        message: 'Failed to delete draft',
        color: 'crimson',
        textColor: 'white',
      });
    } finally {
      props.setBusy(false);
    }
  }

  const carouselUploadStatusProps = (key: string) => ({
    onUploadStart: () => {
      setUploadErrors((errors) => ({
        ...errors,
        [key]: false,
      }));
      setUploading((_uploading) => ({
        ..._uploading,
        [key]: true,
      }));
    },
    onUploadEnd: () => {
      setUploading((_uploading) => ({
        ..._uploading,
        [key]: false,
      }));
    },
    onUploadError: () => {
      setUploadErrors((errors) => ({
        ...errors,
        [key]: true,
      }));
    },
  });

  return (
    <>
      <LoadingOverlay
        loading={
          (Object.values(uploading).includes(true) &&
            (submitWhenUploadsAreFinished ||
              saveDraftWhenUploadsAreFinished)) ||
          saving
        }
        label={saving ? 'Saving...' : 'Uploading media...'}
      />
      <DiscardChangesPrompt
        visible={promptConfirmExit}
        isPublished={props.data?.draft === false}
        onSaveAndExit={onSubmit}
        onSaveDraft={onSaveDraft}
        onDiscardAndExit={() => props.onRequestClose()}
        onCancelExit={() => setPromptConfirmExit(false)}
      />
      <OverwriteFormPrompt
        onCancel={() => setPromptConfirmOverwriteDraftId(undefined)}
        visible={!!promptConfirmOverwriteDraftId}
        onOverwrite={onOverwriteWithDraft}
      />

      <SideMenu
        openMenuOffsetPercentage={0.8}
        maxOpenMenuOffset={400}
        overlayStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.07)',
        }}
        onChange={(isOpen) => setShowDrafts(isOpen)}
        menuPosition="right"
        isOpen={showDrafts}
        menu={
          <OrganizationBigIssueDraftMenu
            drafts={drafts ?? []}
            isFetching={fetchingDrafts}
            hasError={!!fetchDraftsError}
            onRetry={() => refetch()}
            isVisible={showDrafts}
            onSelectDraft={onSelectDraft}
            onDeleteDraft={onDeleteDraft}
            selectedDraftId={state.id || undefined}
          />
        }
        style={{
          flex: 1,
        }}
        animatedContainerStyle={(translateX) => ({
          transform: [
            {
              translateX,
            },
          ],
          backgroundColor: 'white',
          position: 'relative',
          maxWidth: 800,
          width: windowDimensions.width - 48,
          height: windowDimensions.height - 64,
        })}
      >
        <KeyboardAwareScrollView
          ref={(r) => {
            if (r) scrollViewRef.current = r;
          }}
        >
          <View
            style={[
              styles.sectionContainer,
              {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              },
            ]}
          >
            <View style={styles.headerContainer}>
              <TouchableOpacity
                onPress={onViewDrafts}
                style={[
                  styles.draftsButtonContainer,
                  {
                    display: drafts?.length || fetchingDrafts ? 'flex' : 'none',
                  },
                ]}
              >
                {fetchingDrafts ? (
                  <ActivityIndicator
                    color={KEY_GRAY}
                    size={15}
                    style={{
                      marginRight: 4,
                    }}
                  />
                ) : null}
                <Text style={styles.draftsButtonLabel}>Drafts</Text>
              </TouchableOpacity>
              {showDrafts ? (
                <Pressable
                  style={{
                    padding: 12,
                  }}
                  onPress={onCancel}
                >
                  <Ionicons name="caret-back" size={24} />
                </Pressable>
              ) : null}
            </View>

            {/* TITLE */}
            <Text style={styles.sectionLabel}>TITLE</Text>
            <ValidatedTextInput
              autoCapitalize="characters"
              maxLength={64}
              placeholder="Example: CORAL BLEACHING"
              style={[TEXT_INPUT, { flex: undefined }]}
              name="title"
              value={state.title?.text as string}
              onChangeText={(text) => {
                setState({
                  ...state,
                  title: { text: text.toUpperCase() },
                });
              }}
            />
          </View>

          <View style={styles.sectionContainer}>
            {/* WHY IS IT A PROBLEM */}
            <Text style={styles.sectionLabel}>
              WHAT IS IT AND WHY IS IT A PROBLEM?
            </Text>
            <Text style={styles.sectionText}>
              Upload photos and videos to help supporters learn more
            </Text>
            <ValidatedAny
              validate={(_data) => !!_data && validateCarousel(_data, true)}
              name="main_carousel"
              value={state.main_carousel}
              containerStyle={{
                // height: 480,
                borderColor:
                  fields.main_carousel?.valid !== false
                    ? 'transparent'
                    : 'crimson',
                borderWidth: 1,
              }}
            >
              <>
                {fields.main_carousel?.valid === false ? (
                  <Text style={styles.invalidFieldText}>
                    {getInvalidCarouselMessage(true, true)}
                  </Text>
                ) : null}
                <Carousel
                  itemHeight={400}
                  captionRequired
                  addCardPromptText="Add photos and videos"
                  isEditing
                  data={state.main_carousel}
                  onChange={(_data) => {
                    setState({
                      ...state,
                      main_carousel: _data,
                    });
                  }}
                  {...carouselUploadStatusProps('main_carousel')}
                />
              </>
            </ValidatedAny>

            {/* QUICK SUMMARY */}
            <Text style={styles.sectionLabel}>A QUICK SUMMARY</Text>
            <Text style={styles.sectionText}>
              Share a few quick facts to summarize the overall issue
            </Text>
            <View
              style={{
                marginVertical: 6,
              }}
            >
              <Text style={styles.sectionLabel}>FACT 1</Text>
              <ValidatedTextInput
                name="summary_fact1"
                maxLength={1500}
                placeholder="Enter text..."
                multiline
                style={styles.largeTextInput}
                value={state.quick_summary_fact1?.text as string}
                onChangeText={(text) => {
                  setState({
                    ...state,
                    quick_summary_fact1: { text },
                  });
                }}
              />
              <Text style={styles.sectionLabel}>FACT 2</Text>
              <ValidatedTextInput
                name="summary_fact2"
                maxLength={1500}
                placeholder="Enter text..."
                multiline
                style={styles.largeTextInput}
                value={state.quick_summary_fact2?.text as string}
                onChangeText={(text) => {
                  setState({
                    ...state,
                    quick_summary_fact2: { text },
                  });
                }}
              />
              <Text style={styles.sectionLabel}>FACT 3</Text>
              <ValidatedTextInput
                name="summary_fact3"
                maxLength={1500}
                placeholder="Enter text..."
                multiline
                style={styles.largeTextInput}
                value={state.quick_summary_fact3?.text as string}
                onChangeText={(text) => {
                  setState({
                    ...state,
                    quick_summary_fact3: { text },
                  });
                }}
              />
            </View>

            {/* QUICK SUMMARY LINKS */}
            <LinkEditor
              links={state.quick_summary_links as TitledLink[]}
              onChange={(links) => {
                setState({ ...state, quick_summary_links: links });
              }}
              promptText="Add links for supporters to learn more about this issue. Include up to 7 links"
            />
          </View>

          <View style={styles.sectionContainer}>
            {/* HOW THIS ISSUE AFFECTS US */}
            <Text style={styles.sectionLabel}>HOW THIS ISSUE AFFECTS US</Text>
            <Text style={styles.sectionText}>
              Describe how this issue affects the work that you are trying to
              achieve and how you see it in your own work environment.
            </Text>
            <ValidatedTextInput
              name="how_this_affects_us"
              style={styles.largeTextInput}
              multiline
              maxLength={560}
              placeholder="Enter text..."
              value={state.how_this_affects_us?.text as string}
              onChangeText={(text) => {
                setState({
                  ...state,
                  how_this_affects_us: { text },
                });
              }}
            />
            <ValidatedAny
              required={false}
              name="how_this_affects_us_carousel"
              validate={(value) => !!value && validateCarousel(value)}
              value={state.how_this_affects_us_carousel}
              containerStyle={{
                borderColor:
                  fields.how_this_affects_us_carousel?.valid !== false
                    ? 'transparent'
                    : 'crimson',
                borderWidth: 1,
              }}
            >
              <>
                {fields.how_this_affects_us_carousel?.valid === false ? (
                  <Text style={styles.invalidFieldText}>
                    {getInvalidCarouselMessage()}
                  </Text>
                ) : null}
                <Carousel
                  itemHeight={400}
                  isEditing
                  addCardPromptText="Show the effects this problem has on your mission with photos and videos"
                  data={state.how_this_affects_us_carousel}
                  onChange={(_data) => {
                    setState({
                      ...state,
                      how_this_affects_us_carousel: _data,
                    });
                  }}
                  {...carouselUploadStatusProps('how_this_affects_us_carousel')}
                />
              </>
            </ValidatedAny>

            {/* HOW THIS AFFECTS US LINKS */}
            <LinkEditor
              links={state.how_this_affects_us_links as TitledLink[]}
              onChange={(links) => {
                setState({ ...state, how_this_affects_us_links: links });
              }}
              promptText="Add links for supporters to learn more about how this issue affects you. Include up to 7 links"
            />
          </View>

          <View style={styles.sectionContainer}>
            {/* WHAT WE ARE DOING */}
            <Text style={styles.sectionLabel}>WHAT WE ARE DOING</Text>
            <Text style={styles.sectionText}>
              Describe what your organization is doing to fight this issue.
            </Text>
            <ValidatedTextInput
              name="what_we_are_doing"
              style={styles.largeTextInput}
              multiline
              maxLength={560}
              placeholder="Enter text..."
              value={state.what_we_are_doing?.text as string}
              onChangeText={(text) => {
                setState({
                  ...state,
                  what_we_are_doing: { text },
                });
              }}
            />
            <ValidatedAny
              required={false}
              validate={(value) => !!value && validateCarousel(value)}
              name="what_we_are_doing_carousel"
              value={state.what_we_are_doing_carousel}
              containerStyle={{
                borderColor:
                  fields.what_we_are_doing_carousel?.valid !== false
                    ? 'transparent'
                    : 'crimson',
                borderWidth: 1,
              }}
            >
              <>
                {fields.what_we_are_doing_carousel?.valid === false ? (
                  <Text style={styles.invalidFieldText}>
                    {getInvalidCarouselMessage()}
                  </Text>
                ) : null}
                <Carousel
                  itemHeight={400}
                  isEditing
                  addCardPromptText="Show people what your organization is doing with photos and videos"
                  data={state.what_we_are_doing_carousel}
                  onChange={(_data) => {
                    setState({
                      ...state,
                      what_we_are_doing_carousel: _data,
                    });
                  }}
                  {...carouselUploadStatusProps('what_we_are_doing_carousel')}
                />
              </>
            </ValidatedAny>

            {/* WHAT WE ARE DOING LINKS */}
            <LinkEditor
              links={state.what_we_are_doing_links as TitledLink[]}
              onChange={(links) => {
                setState({ ...state, what_we_are_doing_links: links });
              }}
              promptText="Add links for supporters to learn more about what you are doing. Include up to 7 links"
            />
          </View>

          <View
            style={[
              styles.sectionContainer,
              {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            ]}
          >
            {/* HOW YOU CAN HELP */}
            <Text style={styles.sectionLabel}>HOW SUPPORTERS CAN HELP</Text>
            <Text style={styles.sectionText}>
              Describe what supporters can do to help your organization with
              this issue.
            </Text>
            <ValidatedTextInput
              name="how_you_can_help"
              multiline
              maxLength={560}
              placeholder="Enter text..."
              style={styles.largeTextInput}
              value={state.how_you_can_help?.text as string}
              onChangeText={(text) => {
                setState({
                  ...state,
                  how_you_can_help: { text },
                });
              }}
            />

            <Text
              style={[
                styles.sectionText,
                {
                  marginTop: 8,
                },
              ]}
            >
              Select campaigns that supporters can take action on to help your
              organization with this issue. (optional)
            </Text>
            <CampaignPicker
              filter={{
                userId: props.userId,
                closed: false,
              }}
              showFilters={{
                species: true,
                topics: true,
              }}
              selection={campaignPickerSelection}
              onChangeSelection={onChangeCampaignPickerSelection}
              maxSelectionSize={20}
            />
          </View>
        </KeyboardAwareScrollView>
        {/* BUTTONS */}
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: 16,
            paddingHorizontal: 8,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Button
              onPress={onCancel}
              containerStyle={{ marginRight: 8 }}
              label="Cancel"
            />
          </View>
          <View
            style={{
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              onPress={onSubmit}
              label={state.draft === false ? 'Save' : 'Publish'}
              style={{
                backgroundColor: KEY_GREEN,
              }}
            />
            {state.draft === false ? null : (
              <Button
                onPress={onSaveDraft}
                containerStyle={{ marginRight: 8 }}
                label="Save Draft"
              />
            )}
          </View>
        </View>
      </SideMenu>
    </>
  );
}

export default withFormValidation(EditOrganizationBigIssueModal, {
  disableValidateFieldOnChange: true,
});

interface ILinkEditorProps {
  promptText: string;
  links: TitledLink[] | undefined;
  onChange: (links: TitledLink[]) => void;
}

function LinkEditor(props: ILinkEditorProps) {
  return (
    <>
      <Text style={styles.sectionLabel}>LEARN MORE (OPTIONAL)</Text>
      <Text style={styles.sectionText}>{props.promptText}</Text>
      <FormList
        containerStyle={{
          paddingVertical: 8,
        }}
        maxLength={7}
        data={props.links ?? []}
        renderIcon={() => <Entypo name="link" color={KEY_GRAY} size={20} />}
        renderForm={({ item, index, onChange }) => (
          <View style={{ flex: 1, paddingBottom: 4 }}>
            <ValidatedTextInput
              style={TEXT_INPUT}
              containerStyle={{
                marginVertical: 2,
              }}
              maxLength={100}
              autoCapitalize="words"
              placeholder="Link Title..."
              name={`link-title-${index}-${props.promptText}`}
              value={item!.title}
              onChangeText={(text) => {
                onChange({
                  ...item,
                  title: text,
                });
              }}
            />
            <ValidatedTextInput
              containerStyle={{
                marginVertical: 2,
              }}
              onBlur={({ nativeEvent }) => {
                onChange({ ...item, url: formatURL(nativeEvent.text) });
              }}
              autoCorrect={false}
              textContentType="URL"
              keyboardType="url"
              autoCapitalize="none"
              placeholder="www.example.com"
              style={TEXT_INPUT}
              name={`link-url-${index}-${props.promptText}`}
              value={item!.url}
              onChangeText={(text) => {
                onChange({
                  ...item,
                  url: text,
                });
              }}
              validate={isValidURLRegex}
            />
          </View>
        )}
        onChange={props.onChange}
        placeholder="Add a link..."
      />
    </>
  );
}

interface IDiscardChangesPromptProps {
  visible: boolean;
  isPublished: boolean;
  onSaveAndExit: () => void;
  onSaveDraft: () => void;
  onDiscardAndExit: () => void;
  onCancelExit: () => void;
}

function DiscardChangesPrompt(props: IDiscardChangesPromptProps) {
  const animatedOpacity = useBinaryTimingAnimation({ value: props.visible });
  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: animatedOpacity.value,
  }));

  return props.visible ? (
    <Animated.View
      pointerEvents={props.visible ? 'auto' : 'none'}
      style={[
        StyleSheet.absoluteFill,
        styles.promptOverlay,
        animatedContainerStyle,
      ]}
    >
      <View
        style={{
          padding: 10,
        }}
      >
        <Text
          style={{
            fontFamily: 'LeagueSpartan-Bold',
            fontSize: 21,
            color: 'white',
            textAlign: 'center',
          }}
        >
          Discard Changes?
        </Text>
        <Text
          style={{
            fontFamily: 'Lato',
            fontSize: 18,
            color: 'white',
            textAlign: 'center',
          }}
        >
          Looks like you've made some changes, would you like to save or discard
          them?
        </Text>
      </View>
      <View
        style={{
          width: 240,
        }}
      >
        <Button
          onPress={props.onDiscardAndExit}
          containerStyle={{
            marginTop: 8,
          }}
          labelStyle={{
            color: 'crimson',
          }}
          label="Discard and Exit"
        />

        {props.isPublished ? (
          <Button
            onPress={props.onSaveAndExit}
            containerStyle={{
              marginTop: 8,
            }}
            label="Save and Exit"
          />
        ) : (
          <Button
            onPress={props.onSaveDraft}
            containerStyle={{
              marginTop: 8,
            }}
            label="Save as Draft"
          />
        )}

        <Button
          onPress={props.onCancelExit}
          containerStyle={{
            marginTop: 8,
          }}
          label="Cancel"
        />
      </View>
    </Animated.View>
  ) : null;
}

interface IOverwriteFormPromptProps {
  visible: boolean;
  onOverwrite: () => void;
  onCancel: () => void;
}

function OverwriteFormPrompt(props: IOverwriteFormPromptProps) {
  const opacity = useBinaryTimingAnimation({ value: props.visible });
  const animatedContainerStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  return props.visible ? (
    <Animated.View
      pointerEvents={props.visible ? 'auto' : 'none'}
      style={[
        StyleSheet.absoluteFill,
        styles.promptOverlay,
        animatedContainerStyle,
      ]}
    >
      <Text
        style={{
          fontFamily: 'LeagueSpartan-Bold',
          fontSize: 21,
          color: 'white',
          textAlign: 'center',
        }}
      >
        Overwrite Form?
      </Text>
      <Text
        style={{
          fontFamily: 'Lato',
          fontSize: 18,
          color: 'white',
          textAlign: 'center',
        }}
      >
        Loading this draft will unload the Big Issue you are currently working
        on. If you made changes, they will be lost. Are you sure you want to do
        that?
      </Text>
      <Button
        label="Continue"
        containerStyle={{
          marginTop: 8,
        }}
        onPress={props.onOverwrite}
      />
      <Button
        containerStyle={{
          marginTop: 8,
        }}
        label="Cancel"
        onPress={props.onCancel}
      />
    </Animated.View>
  ) : null;
}

const styles = StyleSheet.create({
  sectionContainer: {
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 6,
  },
  promptOverlay: {
    padding: 8,
    zIndex: 99,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(25, 25, 25, 0.8)',
  },
  headerContainer: {
    paddingTop: 8,
    width: '100%',
  },
  draftsButtonContainer: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  draftsButtonLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: KEY_GRAY,
  },
  sectionLabel: {
    marginTop: 0,
    paddingVertical: 4,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
    color: 'black',
  },
  sectionText: {
    fontFamily: 'Lato',
    fontSize: CONTENT_PARAGRAPH_FONT_SIZE,
    color: 'black',
  },
  invalidFieldText: {
    paddingTop: 8,
    paddingHorizontal: 8,
    color: 'crimson',
    fontFamily: 'Lato-Bold',
    fontSize: 16,
  },
  largeTextInput: {
    ...TEXT_INPUT_LARGE,
    marginTop: 4,
  },
});
