import React, { useState } from 'react';
import { ActivityIndicator, Pressable, ScrollView, View } from 'react-native';
import Alert from '/Alert';
import CheckmarkSwitch from '/components/CheckmarkSwitch';
import GenericError from '../../components/common/Generic/GenericError';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionContainer from '/components/common/Section/SectionContainer';
import SectionText from '/components/common/Section/SectionText';
import SectionTitle from '/components/common/Section/SectionTitle';
import withAuthRequired from '/components/withAuthRequired';
import {
  ALERT_RED,
  DESKTOP_MAX_WIDTH,
  KEY_GRAY,
  SCROLL_VIEW_CONTENT_CONTAINER,
} from '/constants';
import {
  UpdateNotificationPreferencesInput,
  useGetNotificationPreferencesQuery,
  useUpdateNotificationPreferencesMutation,
} from '/generated/graphql';
import { useAuthContext } from '/context';

function NotificationPreferences() {
  const { userData } = useAuthContext();

  const [
    { data, fetching, error: fetchError, stale },
    getNotificationPreferences,
  ] = useGetNotificationPreferencesQuery();

  const [, updateNotificationPreferences] =
    useUpdateNotificationPreferencesMutation();

  const updatePreferences = async (
    preferences: UpdateNotificationPreferencesInput,
  ) => {
    const { error } = await updateNotificationPreferences({
      preferences,
      // @ts-ignore
      userId: userData?.id,
    });

    if (error) {
      Alert.notify({
        message: 'Failed to update notification preferences',
        color: ALERT_RED,
        textColor: 'white',
      });
    }
  };

  return (
    <ScrollView
      style={DESKTOP_MAX_WIDTH}
      contentContainerStyle={SCROLL_VIEW_CONTENT_CONTAINER}
    >
      <SectionContainer>
        {!data && !fetching && fetchError ? (
          <GenericError onRetry={getNotificationPreferences} />
        ) : (
          <View
            style={{ flex: 1, opacity: fetching || stale ? 0.6 : 1 }}
            pointerEvents={fetching || stale ? 'none' : 'auto'}
          >
            <SectionTitle>RELEVANT CONTENT</SectionTitle>

            <SettingField
              label="Notify me about new volunteer and skilled impact opportunities that might be relevant to me"
              value={
                !!data?.getNotificationPreferences.notify_on_new_opportunities
              }
              onValueChange={(value) => {
                return updatePreferences({
                  notify_on_new_opportunities: value,
                });
              }}
            />

            <SectionTitle
              style={{
                marginTop: 8,
              }}
            >
              EMAIL NOTIFICATIONS
            </SectionTitle>

            <SettingField
              label="Enable email notifications"
              value={
                !!data?.getNotificationPreferences.allow_email_notifications
              }
              onValueChange={(value) => {
                return updatePreferences({
                  allow_email_notifications: value,
                });
              }}
            />
            <SettingField
              label="Allow marketing emails"
              disabled={
                !data?.getNotificationPreferences.allow_email_notifications
              }
              value={
                !!data?.getNotificationPreferences.allow_email_notifications &&
                !!data?.getNotificationPreferences.allow_marketing_emails
              }
              onValueChange={(value) => {
                return updatePreferences({
                  allow_marketing_emails: value,
                });
              }}
            />
            <SettingField
              label="Notify me about unread notifications"
              disabled={
                !data?.getNotificationPreferences.allow_email_notifications
              }
              value={
                !!data?.getNotificationPreferences.allow_email_notifications &&
                !!data?.getNotificationPreferences
                  .notify_about_unread_notifications
              }
              onValueChange={(value) => {
                return updatePreferences({
                  notify_about_unread_notifications: value,
                });
              }}
            />
          </View>
        )}
      </SectionContainer>
    </ScrollView>
  );
}

type SettingFieldProps = {
  value: boolean;
  onValueChange: (value: boolean) => Promise<void>;
  disabled?: boolean;
  label: string;
};

const SettingField = ({
  label,
  value,
  onValueChange,
  disabled,
}: SettingFieldProps) => {
  const [isLoading, setLoading] = useState(false);

  const onPress = (newValue: boolean) => {
    setLoading(true);
    onValueChange(newValue).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Pressable
      onPress={() => {
        onPress(!value);
      }}
      disabled={disabled}
      style={{
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <HorizontalContainer
        style={{
          justifyContent: 'space-between',
        }}
      >
        <SectionText
          style={{
            flex: 1,
            marginBottom: 8,
          }}
        >
          {label}
        </SectionText>
        {isLoading ? (
          <ActivityIndicator size={22} color={KEY_GRAY} />
        ) : (
          <CheckmarkSwitch value={!!value} onValueChange={onPress} />
        )}
      </HorizontalContainer>
    </Pressable>
  );
};

export default withAuthRequired(NotificationPreferences);
