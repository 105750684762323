import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import dayjs from 'dayjs';
import { StyleSheet, Text, View } from 'react-native';
import { useAuthContext } from '/context';
import {
  Notification as INotification,
  NotificationType,
} from '/generated/graphql';
import { DeepPartial } from '/types';
import { shorten } from '/util';
import getCreativeTypeLabel from '/util/creative-connect/getCreativeTypeLabel';
import HorizontalContainer from '../common/Generic/HorizontalContainer';
import { FontAwesome5 } from '@expo/vector-icons';
import { KEY_GRAY } from '/constants';

const TRUNCATE_USER_NAME_LENGTH = 40;

interface INotificationBodyProps {
  notification: DeepPartial<INotification>;
}
export default function NotificationBody(
  props: INotificationBodyProps,
): JSX.Element {
  const data: any =
    props.notification?.data && JSON.parse(props.notification.data);

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const { userData } = useAuthContext();

  const goToAvatarProfile = () => {
    if (!props.notification.avatar) return null;
    if (props.notification.avatar.id === userData?.id) {
      navigate('ProfileStack');
    } else {
      navigate('Profile', {
        id: props.notification.avatar.id || data?.organizationId,
      });
    }
    return null;
  };

  switch (props.notification.type) {
    case NotificationType.OrganizationInterestedInGodwit: {
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar?.name?.trim() ?? 'An organization',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>
          {` `}
          has expressed interest in Godwit Key.
        </Text>
      );
    }
    case NotificationType.CreativeConnectProjectPremiered: {
      return (
        <Text style={styles.bodyText}>
          <Text
            style={styles.boldText}
            onPress={
              data?.ccProjectId &&
              (() => {
                navigate('ViewCreativeConnectLanding', {
                  id: data?.ccProjectId,
                  vUId: props.notification.userId,
                });
              })
            }
          >
            {shorten(
              data?.ccProject?.title?.text?.trim() ?? 'A creative project',
              40,
            )}
          </Text>{' '}
          {!data?.ccProject?.title?.text?.trim()
            ? 'that you wanted to be notified about '
            : ''}
          has just premiered!
        </Text>
      );
    }
    case NotificationType.EventDateChange: {
      return (
        <Text style={styles.bodyText}>
          The event date and time for{' '}
          <Text
            style={styles.boldText}
            onPress={() => {
              navigate('ViewEvent', { id: data?.eventId });
            }}
          >
            {data?.event?.title?.trim()
              ? `"${data?.event?.title?.trim()}"`
              : "an event you're attending"}
          </Text>{' '}
          has been changed.
        </Text>
      );
    }
    case NotificationType.EventAttendanceConfirmed: {
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar?.name?.trim() ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          will be attending{' '}
          <Text
            style={styles.boldText}
            onPress={() => {
              navigate('ViewEvent', { id: data?.eventId });
            }}
          >
            {data?.event?.title?.trim() ?? 'your event'}!
          </Text>
        </Text>
      );
    }
    case NotificationType.EventPartnershipInviteAccepted: {
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar?.name?.trim() ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          accepted your invitation to appear as a host on{' '}
          <Text
            style={styles.boldText}
            onPress={() => {
              navigate('ViewEvent', { id: data?.eventId });
            }}
          >
            {data?.event?.title ?? 'your event'}
          </Text>
        </Text>
      );
    }
    case NotificationType.EventAttendanceInvite: {
      return (
        <Text style={styles.bodyText} numberOfLines={2}>
          You have been invited to attend{' '}
          <Text
            style={styles.boldText}
            onPress={() => {
              navigate('ViewEvent', {
                id: data?.eventId,
                vUId: props.notification.userId,
              });
            }}
          >
            {data?.event?.title ?? 'an event'}
          </Text>
        </Text>
      );
    }
    case NotificationType.EventPartnershipInvite: {
      return (
        <Text style={styles.bodyText} numberOfLines={2}>
          You have been invited to appear as a host on{` `}
          <Text
            style={styles.boldText}
            onPress={() => {
              navigate('ViewEvent', {
                id: data?.eventId,
                vUId: props.notification.userId,
              });
            }}
          >
            {data?.event?.title ?? 'an event'}
          </Text>
        </Text>
      );
    }
    case NotificationType.UserMentionDiscussionBoardMessage: {
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar?.name?.trim() ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          mentioned you in a{' '}
          <Text style={styles.boldText}>discussion board</Text>!
        </Text>
      );
    }
    case NotificationType.CreativeConnectCollaboratorRoleChanged: {
      const projectName = data?.ccProject?.title?.text;
      const newRole = data?.newRole;

      if (!projectName) break;

      return (
        <Text style={styles.bodyText}>
          Your role on{' '}
          <Text
            onPress={() =>
              navigate('ViewCreativeConnectLanding', {
                id: data.ccProjectId,
                vUId: props.notification.userId,
              })
            }
            style={styles.boldText}
          >
            {projectName}
          </Text>{' '}
          has been changed
          {newRole ? ` to ${newRole}` : ''}.
        </Text>
      );
    }
    case NotificationType.SentCampaignConnectInviteExpired: {
      const userName = props.notification.avatar?.name;
      return (
        <Text style={styles.bodyText}>
          A Campaign Connect invite you sent
          {userName ? (
            <Text style={styles.bodyText}>
              {' '}
              to{' '}
              <Text style={styles.boldText} onPress={goToAvatarProfile}>
                {shorten(userName, TRUNCATE_USER_NAME_LENGTH)}
              </Text>
            </Text>
          ) : null}{' '}
          has expired
        </Text>
      );
    }
    case NotificationType.CampaignConnectInviteCampaignAccepted: {
      let postName = 'a post';
      if (data?.creativeConnectProjectId && data?.ccProject?.title?.text) {
        postName = data.ccProject.title.text;
      }

      return (
        <Text style={styles.bodyText}>
          Your campaign has been featured on{' '}
          <Text
            style={styles.boldText}
            onPress={() => {
              if (data?.creativeConnectProjectId)
                navigate('ViewCreativeConnectLanding', {
                  id: data.ccProjectId ?? data.creativeConnectProjectId,
                  vUId: props.notification.userId,
                });
            }}
          >
            {shorten(postName, 40)}
          </Text>
        </Text>
      );
    }
    case NotificationType.CampaignConnectInviteCampaignSubmitted: {
      return (
        <>
          <Text style={styles.bodyText}>
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {shorten(
                props.notification.avatar?.name ?? 'An organization',
                TRUNCATE_USER_NAME_LENGTH,
              )}
            </Text>
            {` `}has responded to your invite to create a campaign. Review it
            now to feature it on{' '}
            <Text
              style={styles.boldText}
              onPress={() => {
                if (data?.creativeConnectProjectId)
                  navigate('ViewCreativeConnectLanding', {
                    id: data.creativeConnectProjectId,
                    vUId: props.notification.userId,
                  });
              }}
            >
              {shorten(data?.ccProject?.title?.text ?? 'your post', 32)}
            </Text>
          </Text>
        </>
      );
    }
    case NotificationType.CampaignConnectInvite: {
      const invitedBy = props.notification.avatar ? (
        <Text style={styles.boldText} onPress={goToAvatarProfile}>
          {shorten(
            props.notification.avatar.name ?? 'Someone',
            TRUNCATE_USER_NAME_LENGTH,
          )}
        </Text>
      ) : (
        'Someone'
      );
      const invite = data?.campaignConnectInvite;

      let postType = 'post';

      if (data?.creativeConnectProjectId) {
        if (data?.ccProject?.creative_type) {
          postType =
            getCreativeTypeLabel(data.ccProject.creative_type) +
            (data.ccProject.is_series ? ' series' : '');
        } else {
          postType = 'Creative Connect project';
        }
      }

      const isExpiringSoon = dayjs(invite?.expiresAt).isBefore(
        dayjs().add(1, 'day'),
      );

      return (
        <>
          <Text style={styles.bodyText}>
            {invitedBy} has invited you to connect a Campaign to their{' '}
            {postType.toLowerCase()}!
          </Text>
          {!invite?.expiresAt ? null : (
            <HorizontalContainer>
              <FontAwesome5
                name="clock"
                size={15}
                color={isExpiringSoon ? 'crimson' : KEY_GRAY}
              />
              <Text
                style={[
                  styles.boldText,
                  {
                    color: isExpiringSoon ? 'crimson' : KEY_GRAY,
                  },
                ]}
              >
                {` `}Expires {dayjs(invite.expiresAt).fromNow()}
              </Text>
            </HorizontalContainer>
          )}
        </>
      );
    }
    case NotificationType.CreativeConnectProjectInviteDeclined: {
      if (data.ccProject) {
        return (
          <Text style={styles.bodyText}>
            <Text
              style={styles.boldText}
              onPress={() => {
                goToAvatarProfile();
              }}
            >
              {shorten(
                props.notification.avatar?.name || 'A collaborator',
                TRUNCATE_USER_NAME_LENGTH,
              )}
            </Text>{' '}
            declined your invitation to collaborate on{' '}
            <Text
              style={styles.boldText}
              onPress={() => {
                navigate('ViewCreativeConnectLanding', {
                  id: data.ccProjectId,
                  vUId: props.notification.userId,
                });
              }}
            >
              {shorten(data.ccProject.title?.text ?? '', 72) ||
                `a ${getCreativeTypeLabel(data.ccProject?.creative_type)}${
                  data.ccProject.is_series ? ' Series' : ''
                }`}
            </Text>
          </Text>
        );
      }
      break;
    }
    case NotificationType.CreativeConnectProjectFeature: {
      if (data.ccProject) {
        return (
          <Text style={styles.bodyText}>
            You have been featured on{` `}
            <Text
              style={styles.boldText}
              onPress={() => {
                navigate('ViewCreativeConnectLanding', {
                  id: data.ccProjectId,
                  vUId: props.notification.userId,
                });
              }}
            >
              {shorten(data.ccProject.title?.text ?? '', 72) ||
                `a ${getCreativeTypeLabel(data.ccProject?.creative_type)}${
                  data.ccProject.is_series ? ' Series' : ''
                }`}
            </Text>
          </Text>
        );
      }
      break;
    }
    case NotificationType.CreativeConnectProjectJoinRequestAccepted: {
      if (props.notification.avatar) {
        return (
          <Text style={styles.bodyText}>
            Your request to collaborate on{' '}
            {data?.ccProject?.title ? (
              <Text
                style={styles.boldText}
                onPress={() => {
                  navigate('ViewCreativeConnectLanding', {
                    id: data.ccProjectId,
                    vUId: props.notification.userId,
                  });
                }}
              >
                {shorten(data.ccProject.title.text, 80)}
              </Text>
            ) : (
              'a project'
            )}{' '}
            has been accepted!
          </Text>
        );
      }
      break;
    }
    case NotificationType.CreativeConnectProjectJoinRequest: {
      if (props.notification.avatar) {
        return (
          <Text style={styles.bodyText}>
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {shorten(
                props.notification.avatar.name ?? 'Someone',
                TRUNCATE_USER_NAME_LENGTH,
              )}
            </Text>{' '}
            has requested to collaborate on{` `}
            {data?.ccProject?.title ? (
              <Text
                style={styles.boldText}
                onPress={() => {
                  navigate('ViewCreativeConnectLanding', {
                    id: data.ccProjectId,
                    vUId: props.notification.userId,
                  });
                }}
              >
                {shorten(data.ccProject.title.text, 80)}
              </Text>
            ) : (
              'a project'
            )}
          </Text>
        );
      }
      break;
    }
    case NotificationType.CreativeConnectProjectInvite: {
      if (props.notification.avatar) {
        return (
          <Text style={styles.bodyText}>
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {shorten(
                props.notification.avatar.name ?? 'Someone',
                TRUNCATE_USER_NAME_LENGTH,
              )}
            </Text>{' '}
            invited you to collaborate on{' '}
            {data?.ccProject?.title ? (
              <Text
                style={styles.boldText}
                onPress={() => {
                  navigate('ViewCreativeConnectLanding', {
                    id: data.ccProjectId,
                    vUId: props.notification.userId,
                  });
                }}
              >
                {shorten(data.ccProject.title.text, 80)}
              </Text>
            ) : (
              'a project'
            )}
          </Text>
        );
      }
      break;
    }
    case NotificationType.CreativeConnectProjectInviteAccepted: {
      if (props.notification.avatar) {
        return (
          <Text style={styles.bodyText}>
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {shorten(
                props.notification.avatar.name ?? 'Someone',
                TRUNCATE_USER_NAME_LENGTH,
              )}
            </Text>{' '}
            accepted your invitation to collaborate on{' '}
            {data?.ccProject?.title ? (
              <Text
                style={styles.boldText}
                onPress={() => {
                  navigate('ViewCreativeConnectLanding', {
                    id: data.ccProjectId,
                    vUId: props.notification.userId,
                  });
                }}
              >
                {shorten(data.ccProject.title.text, 80)}
              </Text>
            ) : (
              'a project'
            )}
          </Text>
        );
      }
      break;
    }
    case NotificationType.CampaignHidden: {
      if (!data?.campaign) return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          Your campaign{' '}
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaignId,
              })
            }
          >
            {data.campaign.name}
          </Text>{' '}
          has been hidden from public view.
        </Text>
      );
    }
    case NotificationType.CampaignUnhidden: {
      if (!data?.campaign) return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          Your campaign{' '}
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaignId,
              })
            }
          >
            {data.campaign.name}
          </Text>{' '}
          is no longer hidden from public view!
        </Text>
      );
    }
    case NotificationType.CommentReply:
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          replied to your comment!
        </Text>
      );
    case NotificationType.UserMentionCampaignPost:
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          mentioned you in a Campaign post!
        </Text>
      );
    case NotificationType.UserMentionComment:
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          mentioned you in a comment!
        </Text>
      );
    case NotificationType.SharedDocumentSubmitted: {
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;

      let message = 'submitted a new document';

      let key = Object.keys(data)?.reduce(
        (acc: string | undefined, curr: any) => {
          if (curr) return;

          const acceptableKeys = ['groupDiscussionPostId'];

          if (acceptableKeys.includes(curr)) {
            return curr;
          }

          return acc;
        },
      );

      switch (key) {
        case 'groupDiscussionPostId':
          message = 'submitted a new document to your group discussion post';
          break;
      }

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          {message}
        </Text>
      );
    }
    case NotificationType.GroupMembershipRequestAccepted: {
      const groupNameMaybe = data?.group ? (
        <Text
          style={styles.boldText}
          onPress={() => {
            navigate('ViewGroup', { id: data.groupId });
          }}
        >
          {` `}
          {data.group.name}
        </Text>
      ) : null;

      return (
        <Text style={styles.bodyText}>
          Your request to join the group{groupNameMaybe} has been accepted!
        </Text>
      );
    }
    case NotificationType.GroupMembershipRequestReceived: {
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;

      const groupNameMaybe = data?.group ? (
        <Text
          style={styles.boldText}
          onPress={() => {
            navigate('ViewGroup', { id: data.groupId });
          }}
        >
          , {data.group.name}
        </Text>
      ) : null;

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          has requested to join your group{groupNameMaybe}.
        </Text>
      );
    }
    case NotificationType.GroupMembershipInviteAccepted:
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>
          {` `}has joined your group!
        </Text>
      );

    case NotificationType.NewDonation:
      return (
        <Text style={styles.bodyText}>
          You have a new donation from{' '}
          {props.notification.avatar ? (
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {shorten(
                props.notification.avatar.name ?? 'Someone',
                TRUNCATE_USER_NAME_LENGTH,
              )}
            </Text>
          ) : (
            'Anonymous'
          )}
        </Text>
      );
    case NotificationType.NewOrganizationApplication: {
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'A new organization',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          has applied to join the platform.
        </Text>
      );
    }
    case NotificationType.NewMessage: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      if (data?.isTesterFeedback) {
        return (
          <Text style={styles.bodyText}>
            An administrator has replied to your feedback
            {data.testerObjective ? (
              <>
                {` `}on{` `}
                <Text
                  style={styles.boldText}
                  onPress={() => {
                    navigate('TesterDashboard', {
                      focusObjectiveId: data.testerObjectiveId,
                    });
                  }}
                >
                  {shorten(
                    data.testerObjective.name?.text ?? '',
                    TRUNCATE_USER_NAME_LENGTH,
                  )}
                </Text>
              </>
            ) : null}
          </Text>
        );
      }

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar?.name?.trim() ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          sent a message in a{' '}
          <Text style={styles.boldText}>discussion board</Text> you're in!
        </Text>
      );
    }
    case NotificationType.NewBroadcastMessage:
    case NotificationType.NewDirectMessage: {
      if (!props.notification.avatar)
        return <Fallback body={props.notification.body} />;

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {shorten(
              props.notification.avatar.name ?? 'Someone',
              TRUNCATE_USER_NAME_LENGTH,
            )}
          </Text>{' '}
          has sent you a message!
        </Text>
      );
    }
    case NotificationType.TeamMembershipInviteReceived: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.initiatorName}
          </Text>{' '}
          has invited you to join their team
        </Text>
      );
    }
    case NotificationType.TeamMembershipRequestReceived: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.initiatorName}
          </Text>{' '}
          has requested to join your team
        </Text>
      );
    }
    case NotificationType.TeamMembershipInviteConfirmed: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.userName}
          </Text>{' '}
          accepted your invitation to join your team
        </Text>
      );
    }
    case NotificationType.OrganizationApplicationAccepted: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          Your organization application for{' '}
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.orgName}
          </Text>{' '}
          has been approved!
        </Text>
      );
    }
    case NotificationType.NewSkillRequestApplicant: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.supportName}
          </Text>{' '}
          has applied for your{' '}
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaignId,
              })
            }
          >
            {data.campaignName}
          </Text>{' '}
          campaign!
        </Text>
      );
    }
    case NotificationType.NewComment: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      const userName = props.notification.avatar?.name || data.userName;
      const commentPreviewText = data.commentPreview
        ? `: "${shorten(data.commentPreview, 99)}"`
        : '!';

      let targetElement = null;
      if (data.campaign?.name) {
        targetElement = (
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaign?.id,
                postId: data.postId,
              })
            }
          >
            {data.campaign.name} campaign
          </Text>
        );
      } else if (data.momentInNature?.id) {
        targetElement = (
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('MomentInNature', {
                id: data.momentInNature.id,
              })
            }
          >
            moment in nature
          </Text>
        );
      } else {
        targetElement = <Text>post</Text>;
      }

      return (
        <Text style={styles.bodyText} numberOfLines={3}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {userName}
          </Text>
          {' has commented on your '}
          {targetElement}
          {commentPreviewText}
        </Text>
      );
    }
    case NotificationType.SkillRequestApplicationSelectionConfirmed: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      return (
        <View>
          <Text style={styles.bodyText}>
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {data.supportName}
            </Text>{' '}
            has confirmed your selection for support on the
            <Text
              style={styles.boldText}
              onPress={() =>
                navigate('Campaign', {
                  campaignId: data.campaignId,
                })
              }
            >
              {' '}
              {data.campaignName}
            </Text>{' '}
            campaign!
          </Text>
        </View>
      );
    }
    case NotificationType.SkillRequestApplicationSelectionDeclined: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <View>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.supportName}
          </Text>
          <Text style={styles.bodyText}>
            {' '}
            has declined your selection for the{' '}
            <Text
              style={styles.boldText}
              onPress={() =>
                navigate('Campaign', {
                  campaignId: data.campaignId,
                })
              }
            >
              {' '}
              {data.campaignName}
            </Text>
            Campaign
          </Text>
        </View>
      );
    }
    case NotificationType.NewPartnerRequest: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <View>
          <Text style={styles.bodyText}>
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {data.partner.name}{' '}
            </Text>
            has requested to be a partner organization!
          </Text>
        </View>
      );
    }
    case NotificationType.CampaignClosed: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.warningTitle}>Campaign Closed!</Text>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.supportName}
          </Text>{' '}
          has marked the{' '}
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaignId,
              })
            }
          >
            {data.campaignName}
          </Text>{' '}
          Campaign as closed
        </Text>
      );
    }
    case NotificationType.NewFollower: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.initiator}
          </Text>{' '}
          is now following you!
        </Text>
      );
    }

    // Supporters:

    case NotificationType.CampaignUpdate: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.campainUpdate}>Campaign Update! </Text>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.orgName}
          </Text>{' '}
          has a new update on their{' '}
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaignId,
              })
            }
          >
            {data.campaignName}
          </Text>{' '}
          Campaign!
        </Text>
      );
    }
    case NotificationType.SkillRequestApplicationSelected: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      return (
        <Text style={styles.bodyText}>
          <Text style={styles.applicationSelected}>Application Selected! </Text>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {data.orgName}
          </Text>{' '}
          has chosen you for their
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaignId,
              })
            }
          >
            {' '}
            {data.campaignName}
          </Text>{' '}
          Campaign!
        </Text>
      );
    }
    case NotificationType.SkillRequestApplicationDeclined: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      return (
        <Text style={styles.bodyText}>
          You have not been selected for the{' '}
          <Text
            style={styles.boldText}
            onPress={() =>
              navigate('Campaign', {
                campaignId: data.campaignId,
              })
            }
          >
            {data.campaignName}
          </Text>{' '}
          Campaign
        </Text>
      );
    }
    case NotificationType.ConnectionAccepted: {
      if (!props.notification.data)
        return <Fallback body={props.notification.body} />;

      return (
        <View>
          <Text style={styles.bodyText}>
            <Text style={styles.boldText} onPress={goToAvatarProfile}>
              {props.notification.avatar?.name}
            </Text>{' '}
            has accepted your connection request!
          </Text>
        </View>
      );
    }
    case NotificationType.ConnectionRequest: {
      return (
        <Text style={styles.bodyText}>
          <Text style={styles.boldText} onPress={goToAvatarProfile}>
            {props.notification.avatar?.name}
          </Text>{' '}
          has requested to connect with you!
        </Text>
      );
    }
  }
  return <Text style={styles.bodyText}>{props.notification.body}</Text>;
}

type FallbackProps = {
  body: string | undefined;
};

const Fallback = (props: FallbackProps) => {
  return <Text style={styles.bodyText}>{props.body || 'No body text'}</Text>;
};

const styles = StyleSheet.create({
  bodyText: {
    fontFamily: 'Lato',
    fontSize: 15,
    // alignItems: 'center',
  },
  campainUpdate: {
    fontFamily: 'Lato-BoldItalic',
    fontSize: 17,
    color: '#A8E063',
  },
  applicationSelected: {
    fontFamily: 'Lato-BoldItalic',
    fontSize: 17,
    color: '#A8E063',
  },
  boldText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
  },
  titleContainer: {
    width: '20%',
  },
  deadlineThreeDays: {
    color: '#FF0076',
    fontFamily: 'Lato-BoldItalic',
    fontSize: 16,
  },
  deadlineHalfway: {
    color: '#0093FF',
    fontFamily: 'Lato-BoldItalic',
    fontSize: 16,
  },
  deadlineToday: {
    color: '#FF0076',
    fontFamily: 'Lato-BoldItalic',
    fontSize: 16,
  },
  warningTitle: {
    color: '#FF0076',
    fontFamily: 'Lato-BoldItalic',
    fontSize: 16,
  },
});
