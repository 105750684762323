/**
 * This component handles rendering lists of campaigns and providing an easy to use API
 * by which to filter results. Renders campaigns only, NOT update posts.
 */
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { FlashList } from '@shopify/flash-list';
import { useEffect, useMemo, useState } from 'react';
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  ScrollView,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import Button from '../Button';
import CampaignPost from '../CampaignPost/CampaignPost';
import CampaignPreview from '../CampaignPreview/CampaignPreview';
import CampaignPreviewTile from '../CampaignPreviewTile/CampaignPreviewTile';
import GenericListFooter from '../GenericListFooter';
import GridList from '../GridList';
import GenericError from '../common/Generic/GenericError';
import GenericListEmptyComponent from '../common/Generic/GenericListEmptyComponent';

import styles from './CampaignFlatList.style';
import ViewportTracker from '/components/ViewportAware/ViewportTracker';
import { KEY_GRAY } from '/constants';
import {
  GetCampaignsQuery,
  PaginatedCampaignsFilterInput,
  useGetCampaignsQuery,
} from '/generated/graphql';
import { CommonClusterParamList } from '/navigation/navigators/nested/common';
import { getLocaleCurrencyCode, isCloseToBottom } from '/util';

const currencyCode = getLocaleCurrencyCode();

export type CampaignFlatListLayoutMode = 'default' | 'compact' | 'grid';

// Export so components can use this type to construct the filter object
export type CampaignFlatListFilter = {
  /** Filter by user ID */
  userId?: string;
  /** Filter by query string */
  queryString?: string;
  // createdBefore?: string;
  // createdAfter?: string;
  /** Filter by closed campaigns. Default includes any. */
  closed?: boolean;
  /** Filter by draft campaigns. Default is `false`. Only draft owner can see drafts. */
  draft?: boolean;
  /** Filter by archived campaigns. */
  archived?: boolean;
  /** Filter by species - Campaigns must include one of these species IDs */
  speciesTaxonIds?: number[];
  /** Filter by skill name - Campaigns must include a skill request for this skill */
  skillName?: string;
  /** Filter by topic name - Campaigns must include one of these topics */
  topicNames?: string[];
  /** Fetch campaigns that are bookmarked */
  isBookmarked?: boolean;
  /** Filter by big issue ID */
  bigIssueId?: string;
  /** Filter by hidden status */
  hidden?: boolean;
};

interface ICampaignFlatListProps {
  // Required for reporting purposes
  page: string | null;
  /** Useful if you want to wrap this component in another scrolling view that should handle
   * viewport tracking instead in order to get correct offsets */
  disableViewportTracker?: boolean;
  /** Text to display when there are no results matching the filter */
  emptyPlaceholderText?: string;
  /** Filter used to fetch campaigns */
  filter?: CampaignFlatListFilter;
  /** Limit number of results per page. Default is `10` */
  limit?: number;
  /** Default is `true` */
  scrollable?: boolean;
  /** Change how results are displayed. */
  layoutMode?: CampaignFlatListLayoutMode;
  contentContainerStyle?: StyleProp<ViewStyle>;
  /** Style on each CampaignPost component container */
  campaignContainerStyle?: StyleProp<ViewStyle>;
  hideControls?: boolean;
  onLoad?: (data: GetCampaignsQuery) => void;
  onLoadingChange?: (loading: boolean) => void;
  /** Widget to display in Campaign grid rile (`grid` layoutMode only) */
  gridWidget?:
    | ((item: GetCampaignsQuery['getCampaigns']['items'][0]) => JSX.Element)
    | JSX.Element;
  /** Widget to display in CampaignPreview component (`compact` layoutMode only) */
  compactWidget?:
    | ((item: GetCampaignsQuery['getCampaigns']['items'][0]) => JSX.Element)
    | JSX.Element;
  /** Called when CampaignPreview component is pressed. Default behavior goes to ManageCampaignScreen.
   * (`compact` layoutMode only) */
  compactOnPress?: (
    campaignId: string,
    campaign: GetCampaignsQuery['getCampaigns']['items'][0],
  ) => void;
  /** Called when Campaign grid tile is pressed. Default behavior navigates to the campaign.
   * (`grid` layoutMode only) */
  gridOnPress?: (
    campaignId: string,
    campaign: GetCampaignsQuery['getCampaigns']['items'][0],
  ) => void;
}

export default function CampaignFlatList({
  onLoad,
  onLoadingChange,
  ...props
}: ICampaignFlatListProps) {
  const { navigate } =
    useNavigation<
      StackNavigationProp<CommonClusterParamList, 'ManageCampaign'>
    >();

  const [nextToken, setNextToken] = useState<string>();

  const filter = {
    draft: props.filter?.draft ?? false, // Set draft to `false` by default
    archived: props.filter?.archived,
    closed: props.filter?.closed,
    skillName: props.filter?.skillName || undefined,
    speciesTaxonIds: props.filter?.speciesTaxonIds || undefined,
    userId: props.filter?.userId || undefined,
    topics: props.filter?.topicNames || undefined,
    isBookmarked: props.filter?.isBookmarked || undefined,
    bigIssueId: props.filter?.bigIssueId || undefined,
    queryString: props.filter?.queryString || undefined,
  } as PaginatedCampaignsFilterInput;

  const [{ data: campaigns, fetching: loading, error, stale }, getCampaigns] =
    useGetCampaignsQuery({
      variables: {
        filter,
        limit: props.limit ?? 15,
        nextToken,
        currency: currencyCode,
      },
      requestPolicy: 'cache-and-network',
    });

  const openInCampaignBuilder = (campaignId: string) => {
    navigate('CreateCampaign', {
      draftId: campaignId,
    });
  };

  const goToManageCampaign = (campaignId: string) => {
    navigate('ManageCampaign', {
      campaignId,
    });
  };

  const loadMore = () => {
    setNextToken(campaigns?.getCampaigns.nextToken || '');
  };

  const onScroll = ({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (isCloseToBottom(nativeEvent)) {
      if (
        campaigns?.getCampaigns.nextToken &&
        nextToken !== campaigns?.getCampaigns.nextToken
      )
        loadMore();
    }
  };

  useEffect(() => {
    if (campaigns) {
      onLoad?.(campaigns);
    }
  }, [campaigns, onLoad]);

  useEffect(() => {
    onLoadingChange?.(loading);
  }, [loading, onLoadingChange]);

  useEffect(() => {
    // When filters change, we clear `nextToken` as it will no longer match
    // our results and page size limit
    setNextToken('');
  }, [props.filter]);

  const paginate = () => {
    if (campaigns?.getCampaigns.nextToken) {
      setNextToken(campaigns?.getCampaigns.nextToken);
    }
  };

  const ListComponent = useMemo(() => {
    /** FlashList crashes on Android */
    const shouldUseFlashList = Platform.OS !== 'android';

    if (props.disableViewportTracker) {
      return shouldUseFlashList ? FlashList : FlatList;
    }

    return shouldUseFlashList
      ? ViewportTracker.FlashList
      : ViewportTracker.FlatList;
  }, [props.disableViewportTracker]);

  return props.layoutMode === 'grid' ? (
    <ScrollView
      onScroll={(event) => {
        if (isCloseToBottom(event.nativeEvent)) paginate();
      }}
      refreshControl={
        <RefreshControl
          refreshing={loading}
          onRefresh={() => getCampaigns({ requestPolicy: 'network-only' })}
          tintColor={KEY_GRAY}
        />
      }
    >
      <GridList
        style={props.contentContainerStyle}
        maxTileWidth={400}
        data={campaigns?.getCampaigns.items}
        renderItem={({ item, tileWidth }) => {
          return (
            <CampaignPreviewTile
              campaign={item}
              campaignPost={item.original_post}
              style={{
                width: tileWidth - 6,
              }}
              widget={
                typeof props.gridWidget === 'function'
                  ? props.gridWidget(item)
                  : props.gridWidget
              }
              onPress={() => {
                if (typeof props.gridOnPress === 'function') {
                  props.gridOnPress(item.id, item);
                  return;
                }

                navigate('Campaign', {
                  campaignId: item.id,
                });
              }}
            />
          );
        }}
      />

      {error ? (
        <GenericError onRetry={getCampaigns} />
      ) : !campaigns?.getCampaigns.total && !(loading || stale) ? (
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            fontSize: 16,
            color: 'gray',
            alignSelf: 'center',
            textAlign: 'center',
            marginTop: 10,
            padding: 10,
          }}
        >
          {props.emptyPlaceholderText ?? 'No results.'}
        </Text>
      ) : (
        <GenericListFooter
          emptyHeight={0}
          hasMore={!!campaigns?.getCampaigns.nextToken}
          isFirstPage={!nextToken}
          loading={loading || stale}
          onFetchMore={() => {
            paginate();
          }}
        />
      )}
    </ScrollView>
  ) : (
    <ListComponent
      estimatedItemSize={props.layoutMode === 'compact' ? 136 : 650}
      contentContainerStyle={StyleSheet.flatten(props.contentContainerStyle)}
      data={campaigns?.getCampaigns.items ?? []}
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={getCampaigns} />
      }
      scrollEnabled={props.scrollable ?? true}
      ListFooterComponent={
        loading || (!campaigns?.getCampaigns.items.length && stale) ? (
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.loadingText}>Fetching campaigns...</Text>
          </View>
        ) : error ? (
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={styles.errorText}>Failed to get campaigns</Text>
            <Button label="Retry" onPress={getCampaigns} />
          </View>
        ) : campaigns?.getCampaigns.nextToken ? (
          <Button
            label="Load More"
            loading={stale}
            onPress={loadMore}
            containerStyle={{
              marginVertical: 16,
              alignSelf: 'center',
            }}
          />
        ) : !campaigns?.getCampaigns.items.length ? (
          <GenericListEmptyComponent
            placeholderText={props.emptyPlaceholderText}
          />
        ) : null
      }
      onScroll={onScroll}
      extraData={props.compactWidget}
      renderItem={({
        item,
        index,
      }: {
        item: GetCampaignsQuery['getCampaigns']['items'][0];
        index: number;
      }) => {
        const original_post = item.original_post ?? item.posts?.items?.[0];

        if (!original_post) return null;

        return props.layoutMode === 'compact' ? (
          <View
            style={[
              styles.campaignPreviewContainer,
              {
                marginTop: index === 0 ? 10 : 0,
              },
            ]}
          >
            <CampaignPreview
              campaign={item}
              campaignPost={original_post}
              showSocialControls={!props.hideControls && !item.draft}
              showChevronArrow
              widget={
                typeof props.compactWidget === 'function'
                  ? props.compactWidget(item)
                  : props.compactWidget
                  ? props.compactWidget
                  : undefined
              }
              onPress={() => {
                if (typeof props.compactOnPress === 'function') {
                  props.compactOnPress(item?.id, item);
                  return;
                }

                if (item.id) {
                  if (item.draft) {
                    openInCampaignBuilder(item.id);
                    return;
                  }

                  goToManageCampaign(item.id);
                }
              }}
            />
          </View>
        ) : (
          <View
            style={[styles.campaignContainer, props.campaignContainerStyle]}
          >
            <CampaignPost
              recyclingKey={item.id}
              page={props.page}
              campaign={item}
              data={original_post}
              disableControls={props.hideControls || !!item.draft}
            />
          </View>
        );
      }}
    />
  );
}
