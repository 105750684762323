import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    // flex: 1,
  },
  input: {
    borderRadius: 5,
    fontSize: 16,
    marginVertical: Platform.OS === 'web' ? 6 : 0,
    paddingHorizontal: 8,
    textAlignVertical: 'top',
    width: '100%',
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 6,
    marginLeft: 6,
    borderRadius: 5,
    backgroundColor: '#f5f5f5',
    flex: 1,
  },
  buttonWrapper: { position: 'absolute', right: 12, bottom: 7.5 },
  commentButton: {
    color: '#14C691',
    fontSize: 17,
    fontFamily: 'Lato-Bold',
    alignSelf: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  replyView: {
    paddingTop: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  replyAvatar: {
    marginVertical: 3,
  },
  loginPrompt: {
    fontFamily: 'Lato',
    fontSize: 16,
  },
  loginPromptPressable: {
    fontFamily: 'Lato-Bold',
  },
  loginPromptContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
  },
});
