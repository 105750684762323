import {
  Image,
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import AccountApprovalAlert from './AccountApprovalAlert/AccountApprovalAlert';
import ManageAccountWidget from './ManageAccountWidget';
import ReferralWidget from './ReferralWidget/ReferralWidget';
import { KEY_LIGHT_GRAY } from '/constants';
import { useAuthContext } from '/context';
import KeyBulletinWidget from './KeyBulletinWidget/KeyBulletinWidget';
import { ScrollView } from 'react-native';

interface ISidebarProps {
  onOpenSideDrawer: () => void;
}

export default function Sidebar(props: ISidebarProps) {
  const { userData } = useAuthContext();

  function goToAppStore() {
    Linking.openURL(
      'https://apps.apple.com/us/app/key-conservation/id1478339964',
      // @ts-ignore - untyped
      '_blank',
    );
  }

  function goToPlayStore() {
    Linking.openURL(
      'https://play.google.com/store/apps/details?id=com.key.keyconservation',
      // @ts-ignore - untyped
      '_blank',
    );
  }

  return (
    <ScrollView
      style={{
        flex: 1,
      }}
      contentContainerStyle={styles.container}
    >
      <View
        style={{
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <View>
          <ManageAccountWidget onManageAccount={props.onOpenSideDrawer} />
          <View style={styles.accountApprovalAlertContainer}>
            <AccountApprovalAlert />
          </View>

          <KeyBulletinWidget initiallyOpen />

          {userData?.id ? (
            <ReferralWidget
              style={{
                marginHorizontal: 0,
                backgroundColor: KEY_LIGHT_GRAY,
              }}
            />
          ) : null}
        </View>

        {Platform.OS === 'web' ? (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Pressable onPress={() => goToAppStore()}>
              <Image
                resizeMode="contain"
                source={require('/assets/images/apple-app-store.png')}
                style={{
                  width: 128,
                  height: 50,
                  marginHorizontal: 2,
                }}
              />
            </Pressable>

            <Pressable onPress={() => goToPlayStore()}>
              <Image
                resizeMode="contain"
                source={require('/assets/images/google-play-store.png')}
                style={{
                  width: 128,
                  height: 50,
                  marginHorizontal: 2,
                }}
              />
            </Pressable>
          </View>
        ) : null}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 24,
    paddingBottom: 48,
    backgroundColor: 'white',
  },
  accountApprovalAlertContainer: {
    marginVertical: 10,
    borderRadius: 6,
    overflow: 'hidden',
  },
});
