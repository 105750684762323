import { Entypo } from '@expo/vector-icons';
import React, { useRef, useState } from 'react';
import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import JobBoardSkillFilterModal from './JobBoardSkillFilterModal';
import SpeciesFilterButton from '../../FilterButtons/SpeciesFilterButton/SpeciesFilterButton';
import { ISpeciesPickerSpecies } from '/components/SpeciesPicker/SpeciesPickerModal';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { BUTTON_SHADOW, KEY_GRAY, KEY_LIGHT_GRAY } from '/constants';
import { useModalContext } from '/context';
import {
  JobPostFilter,
  JobPostJobType,
  JobPostWorkplaceType,
} from '/generated/graphql';

interface Props {
  filter: JobPostFilter;
  setFilter: (filter: JobPostFilter) => void;
}

export default function JobBoardFilter(props: Props) {
  const [species, setSpecies] = useState<ISpeciesPickerSpecies[]>([]);
  const [skillName, setSkillName] = useState<string>('');

  const { spawnModal, closeModal: _closeModal } = useModalContext();

  const modalId = useRef<string | null>(null);
  const closeModal = () => {
    modalId.current && _closeModal(modalId.current);
    modalId.current = null;
  };

  function onSkillFilterPressed() {
    modalId.current = spawnModal({
      title: 'Filter by Skill',
      component: (
        <JobBoardSkillFilterModal
          initialSkillName={skillName}
          onRequestClose={(_skillName) => {
            setSkillName(_skillName);
            closeModal();

            props.setFilter({
              ...props.filter,
              skill: _skillName,
            });
          }}
        />
      ),
    });
  }

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>FILTER</Text>
      <ScrollView
        horizontal
        style={{
          marginLeft: -4,
          paddingLeft: 4,
        }}
        contentContainerStyle={{
          paddingBottom: 4,
        }}
        showsHorizontalScrollIndicator={Platform.OS === 'web'}
      >
        {/* WORKPLACE TYPE */}
        <View style={styles.fieldContainer}>
          <Text style={styles.fieldLabel}>WORKPLACE TYPE</Text>
          <WorkplaceTypeDropdown
            value={props.filter.workplace_type}
            onChange={(workplaceType) => {
              props.setFilter({
                ...props.filter,
                workplace_type: workplaceType,
              });
            }}
          />
        </View>

        {/* LOCATION */}

        {/* JOB TYPE */}
        <View style={styles.fieldContainer}>
          <Text style={styles.fieldLabel}>JOB TYPE</Text>
          <JobTypeDropdown
            value={props.filter.job_type}
            onChange={(jobType) => {
              props.setFilter({
                ...props.filter,
                job_type: jobType,
              });
            }}
          />
        </View>

        {/** POSTED DATE */}

        {/* SKILL */}
        <View style={styles.fieldContainer}>
          <Text style={styles.fieldLabel}>SKILL</Text>
          <Pressable onPress={onSkillFilterPressed} style={styles.filterButton}>
            <HorizontalContainer
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Text numberOfLines={1} style={styles.filterButtonText}>
                {skillName || 'Any'}
              </Text>
              <Entypo
                name="chevron-small-down"
                size={20}
                style={{
                  height: 20,
                  width: 20,
                }}
                color="gray"
              />
            </HorizontalContainer>
          </Pressable>
        </View>

        {/* SPECIES */}
        <View style={styles.fieldContainer}>
          <Text style={styles.fieldLabel}>SPECIES</Text>

          <SpeciesFilterButton
            species={species}
            onChange={(speciesFilter) => {
              setSpecies(speciesFilter);
              props.setFilter({
                ...props.filter,
                species: speciesFilter.map((s) => ({
                  taxonID: s.acceptedNameUsageID,
                })) as JobPostFilter['species'],
              });
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

interface IWorkplaceTypeDropdownProps {
  value: JobPostFilter['workplace_type'];
  onChange: (value: JobPostFilter['workplace_type']) => void;
}

function WorkplaceTypeDropdown(props: IWorkplaceTypeDropdownProps) {
  return (
    <Dropdown
      style={styles.filterButton}
      selectedTextStyle={styles.filterButtonText}
      data={[
        {
          label: 'Any',
          value: undefined,
        },
        {
          label: 'On-Site',
          value: JobPostWorkplaceType.OnSite,
        },
        {
          label: 'Remote',
          value: JobPostWorkplaceType.Remote,
        },
        {
          label: 'Hybrid',
          value: JobPostWorkplaceType.Hybrid,
        },
      ]}
      labelField="label"
      valueField="value"
      onChange={(item) => {
        props.onChange(item.value);
      }}
      placeholder="Select..."
      placeholderStyle={{
        color: KEY_GRAY,
        fontFamily: 'Lato-Bold',
      }}
      value={props.value}
    />
  );
}

interface IJobTypeDropdownProps {
  value: JobPostFilter['job_type'];
  onChange: (value: JobPostFilter['job_type']) => void;
}

function JobTypeDropdown(props: IJobTypeDropdownProps) {
  return (
    <Dropdown
      style={styles.filterButton}
      selectedTextStyle={styles.filterButtonText}
      data={[
        {
          label: 'Any',
          value: undefined,
        },
        {
          label: 'Full Time',
          value: JobPostJobType.FullTime,
        },
        {
          label: 'Part Time',
          value: JobPostJobType.PartTime,
        },
        {
          label: 'Contract',
          value: JobPostJobType.Contract,
        },
        {
          label: 'Internship',
          value: JobPostJobType.Internship,
        },
        {
          label: 'Fellowship',
          value: JobPostJobType.Fellowship,
        },
        {
          label: 'Other',
          value: JobPostJobType.Other,
        },
      ]}
      labelField="label"
      valueField="value"
      onChange={(item) => {
        props.onChange(item.value);
      }}
      placeholder="Select..."
      placeholderStyle={{
        color: KEY_GRAY,
        fontFamily: 'Lato-Bold',
      }}
      value={props.value}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  titleText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 16,
  },
  fieldLabel: {
    fontFamily: 'Lato-Bold',
    fontSize: 14,
    marginTop: 8,
    marginBottom: 4,
  },
  fieldContainer: {
    marginRight: 6,
  },
  filterButton: {
    borderWidth: 1,
    borderColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
    marginBottom: 6,
    padding: 8,
    backgroundColor: 'white',
    alignSelf: 'flex-start',
    minWidth: 140,
    ...BUTTON_SHADOW,
    ...(Platform.OS === 'web'
      ? {
          // @ts-ignore
          cursor: 'pointer',
        }
      : {}),
  },
  filterButtonText: {
    fontFamily: 'Lato-Bold',
    color: 'black',
    fontSize: 16,
  },
});
