import { AntDesign, Entypo, FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Button from './Button';
import ButtonToggle from './ButtonToggle/ButtonToggle';
import GenericError from './common/Generic/GenericError';
import SearchBar from './SearchBar/SearchBar';
import {
  KEY_GRAY,
  KEY_LIGHT_GRAY,
  TEXT_INPUT,
  TITLE_FONT_SIZE,
} from '/constants';
import {
  SortDirection,
  SystemSurveyPlacement,
  useSearchResearchTopicsQuery,
} from '/generated/graphql';

const DEFAULT_PAGE_SIZE = 50;

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
  selectedTopics?: string[] | undefined;
  pageSize?: number;
  onSelectTopic: (topicName: string) => void;
  onRemoveTopic: (topicName: string) => void;
}

export default function ResearchTopicPicker(props: Props) {
  const [query, setQuery] = useState('');

  const selectedTopics = props.selectedTopics ?? [];

  const [nextToken, setNextToken] = useState<string>();

  const [{ data, fetching, stale, error }, refetch] =
    useSearchResearchTopicsQuery({
      variables: {
        query,
        nextToken,
        sortDirection: SortDirection.Asc,
        limit: props.pageSize ?? DEFAULT_PAGE_SIZE,
      },
    });

  useEffect(() => {
    /** When 'query' changes, nextToken becomes invalid, so we clear it */
    setNextToken(undefined);
  }, [query]);

  function onLoadNext() {
    if (data?.searchResearchTopics.nextToken) {
      setNextToken(data.searchResearchTopics.nextToken);
    }
  }

  function onSelectTopic(topicName: string) {
    props.onSelectTopic?.(topicName);
  }

  function onRemoveTopic(topicName: string) {
    props.onRemoveTopic?.(topicName);
  }

  return (
    <View style={props.containerStyle}>
      {/* In a box, render selected topics */}
      <View style={styles.selectedTopicsContainer}>
        <Text style={styles.headerText}>SELECTED HASHTAGS</Text>
        {!selectedTopics.length ? (
          <Text style={styles.emptyText}>No topics selected</Text>
        ) : (
          <View style={styles.hashtagButtonsContainer}>
            {selectedTopics.map((topic) => {
              return (
                <ButtonToggle
                  key={topic}
                  style={{
                    padding: 0,
                    paddingHorizontal: 0,
                    overflow: 'hidden',
                  }}
                  label={
                    <View
                      style={{
                        paddingHorizontal: 16,
                        paddingRight: 0,
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 1,
                      }}
                    >
                      <Text style={[styles.topicLabel]}>
                        #{topic.replace(/ /g, '')}
                      </Text>
                      <Pressable
                        onPress={() => onRemoveTopic(topic)}
                        style={({ pressed }) => ({
                          height: '100%',
                          paddingRight: 12,
                          justifyContent: 'center',
                          backgroundColor: pressed ? 'gray' : 'transparent',
                        })}
                      >
                        <AntDesign
                          name="close"
                          style={{ paddingLeft: 4 }}
                          size={20}
                          color="black"
                        />
                      </Pressable>
                    </View>
                  }
                  labelStyle={{
                    textTransform: 'uppercase',
                  }}
                />
              );
            })}
          </View>
        )}
      </View>

      <SearchBar
        containerStyle={[
          TEXT_INPUT,
          {
            marginVertical: 8,
            marginTop: 16,
          },
        ]}
        loading={fetching}
        autoCorrect={false}
        placeholder="Search topics..."
        onChangeText={(value) => {
          setQuery(value);
        }}
        defaultValue={query}
      />

      {error ? <GenericError onRetry={refetch} /> : null}

      {/* Map over search results, filtering already-selected topics, using ButtonToggles */}
      {!data?.searchResearchTopics.total ? (
        fetching || stale ? (
          <ActivityIndicator
            style={styles.loadMoreButtonContainer}
            size={18}
            color={KEY_GRAY}
          />
        ) : query ? (
          <>
            <Text style={styles.emptyText}>No results</Text>
            <TopicSuggestionPrompt />
          </>
        ) : (
          <Text style={styles.emptyText}>
            Results will appear here when you start typing
          </Text>
        )
      ) : (
        <>
          <View style={styles.hashtagButtonsContainer}>
            {data.searchResearchTopics.items.map((topic) => {
              const selected = selectedTopics.includes(topic.topic);

              return (
                <ButtonToggle
                  key={topic.topic}
                  selected={selected}
                  label={
                    <View
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      {selected ? (
                        <FontAwesome
                          name="check"
                          size={18}
                          style={{
                            paddingHorizontal: 2,
                          }}
                          color={KEY_LIGHT_GRAY}
                        />
                      ) : (
                        <Entypo name="plus" size={22} color={KEY_GRAY} />
                      )}
                      <Text
                        style={[
                          styles.topicLabel,
                          {
                            color: selected ? 'white' : KEY_GRAY,
                          },
                        ]}
                      >
                        #{topic.topic.replace(/ /g, '')}
                      </Text>
                    </View>
                  }
                  onPress={() => {
                    if (selected) onRemoveTopic(topic.topic);
                    else onSelectTopic(topic.topic);
                  }}
                />
              );
            })}
          </View>
          {data.searchResearchTopics.nextToken ? (
            fetching || stale ? (
              <ActivityIndicator
                style={styles.loadMoreButtonContainer}
                size={18}
                color={KEY_GRAY}
              />
            ) : (
              <TouchableOpacity
                onPress={onLoadNext}
                style={styles.loadMoreButtonContainer}
              >
                <Text style={styles.loadMoreButtonText}>Load more...</Text>
              </TouchableOpacity>
            )
          ) : null}
          <TopicSuggestionPrompt />
        </>
      )}

      {/* Conditionally render topic group editor:
        - Shows all topic groups, with a button to "Use topics in group"
        - Shows a "Create new group" button
      */}
    </View>
  );
}

function TopicSuggestionPrompt() {
  const { navigate } = useNavigation<StackNavigationProp<any>>();

  function onSuggestNewTopic() {
    navigate('TakeSurvey', {
      placement: SystemSurveyPlacement.NewResearchTopicSuggestion,
    });
  }

  return (
    <View style={styles.suggestionPromptContainer}>
      <ButtonToggle
        label="#OTHER"
        color="white"
        disabled
        style={{
          alignSelf: 'center',
        }}
      />
      <Text style={styles.suggestionPromptText}>
        Is there a topic missing you'd like us to add? Let us know
      </Text>
      <Button
        label="Suggest New Topic"
        containerStyle={{
          alignSelf: 'center',
        }}
        onPress={() => {
          onSuggestNewTopic();
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  emptyText: {
    fontFamily: 'Lato-Bold',
    color: 'gray',
    fontSize: 15,
    padding: 24,
    textAlign: 'center',
    alignSelf: 'center',
  },
  hashtagButtonsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 8,
  },
  topicLabel: {
    fontFamily: 'Lato-Bold',
    letterSpacing: 1,
    color: KEY_GRAY,
    textTransform: 'uppercase',
  },
  loadMoreButtonContainer: {
    padding: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  loadMoreButtonText: {
    fontFamily: 'Lato-Bold',
    color: 'black',
    fontSize: 16,
  },
  selectedTopicsContainer: {
    padding: 12,
    paddingVertical: 16,
    borderColor: 'black',
    borderRadius: 10,
    borderWidth: 1,
    margin: 8,
  },
  errorText: {
    fontFamily: 'Lato-Bold',
    color: 'crimson',
    fontSize: 15,
  },
  suggestionPromptContainer: {
    borderRadius: 6,
    backgroundColor: '#F5F5F5',
    padding: 15,
    marginVertical: 8,
  },
  suggestionPromptText: {
    fontFamily: 'Lato',
    fontSize: 16,
    paddingVertical: 6,
    textAlign: 'center',
  },
});
