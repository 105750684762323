import React, { createContext, useRef, RefObject } from 'react';
import { View } from 'react-native';

export const ViewportRootContext =
  createContext<RefObject<HTMLDivElement> | null>(null);

interface ViewportRootProviderProps {
  children: React.ReactNode;
}

export function ViewportRootProvider({ children }: ViewportRootProviderProps) {
  const rootRef = useRef<HTMLDivElement | null>(null);

  return (
    <ViewportRootContext.Provider value={rootRef}>
      <View
        ref={(r: any) => {
          rootRef.current = r;
        }}
        style={{
          pointerEvents: 'box-none',
          flex: 1,
        }}
      >
        {children}
      </View>
    </ViewportRootContext.Provider>
  );
}
