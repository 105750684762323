import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import CampaignPost from '../CampaignPost/CampaignPost';
import Carousel from '../Carousel/Carousel';
import TruncatedList from '../TruncatedList/TruncatedList';
import LinksViewer from '/components/LinksViewer';
import { CARD_TITLE_FONT_SIZE, KEY_GRAY, KEY_LIGHT_GRAY } from '/constants';
import {
  CarouselCard,
  OrganizationBigIssue,
  TitledLink,
  TranslatableText,
  useGetCampaignsQuery,
  useGetOrganizationBigIssueQuery,
} from '/generated/graphql';
import GenericError from '../common/Generic/GenericError';
import ListLoading from '../ListLoading';
import { TranslationProp } from '/types/translation';
import TranslateButton from '../common/TranslatableText/TranslateButton';
import { useLocales } from 'expo-localization';
import { ScrollView } from 'react-native';

interface ITranslatableText
  extends Pick<TranslatableText, 'text' | 'language'> {}

interface IOrganizationBigIssue
  extends Pick<
    OrganizationBigIssue,
    | 'id'
    | 'main_carousel'
    | 'what_we_are_doing_carousel'
    | 'how_this_affects_us_carousel'
    | 'quick_summary_links'
    | 'what_we_are_doing_links'
    | 'how_this_affects_us_links'
  > {
  title?: ITranslatableText | null;
  quick_summary_fact1?: ITranslatableText | null;
  quick_summary_fact2?: ITranslatableText | null;
  quick_summary_fact3?: ITranslatableText | null;
  how_this_affects_us?: ITranslatableText | null;
  how_you_can_help?: ITranslatableText | null;
  what_we_are_doing?: ITranslatableText | null;
}

interface Props {
  visible: boolean;
  data: IOrganizationBigIssue | undefined;
  onRequestClose: () => void;
  translation?: TranslationProp;
}

export default function ViewOrganizationBigIssueModal({
  data: _data,
  ...props
}: Props) {
  const data = { ..._data };

  const navigation = useNavigation<StackNavigationProp<any>>();

  const [shouldTranslate, setShouldTranslate] = useState(false);
  const { languageCode } = useLocales()[0];
  const [translatedBigIssue] = useGetOrganizationBigIssueQuery({
    pause: !shouldTranslate || !data?.id,
    variables: {
      id: data.id!,
      languageCode,
    },
  });

  const fields = [
    'title',
    'quick_summary_fact1',
    'quick_summary_fact2',
    'quick_summary_fact3',
    'how_this_affects_us',
    'how_you_can_help',
    'what_we_are_doing',
  ] as const;

  const hasForeignLanguage = fields.some(
    (field) =>
      data?.[field]?.language && data?.[field]?.language !== languageCode,
  );

  const [relatedCampaigns, getRelatedCampaigns] = useGetCampaignsQuery({
    pause: !data?.id,
    variables: {
      filter: {
        bigIssueId: data?.id,
      },
    },
  });

  if (shouldTranslate && translatedBigIssue.data) {
    fields.forEach((field) => {
      if (translatedBigIssue.data?.getOrganizationBigIssue?.[field]) {
        data[field] = translatedBigIssue.data.getOrganizationBigIssue[field];
      }
    });
  }

  function onClose() {
    props.onRequestClose?.();
    setShouldTranslate(false);
  }

  function onSeeAllCampaigns() {
    if (!data?.id) return;

    navigation.navigate('ListCampaigns', {
      filter: {
        bigIssueId: data.id,
      },
    });

    onClose();
  }

  function onSelectCampaign() {
    onClose();
  }

  if (!data) return <></>;

  return (
    <Modal
      visible={props.visible}
      onRequestClose={onClose}
      animationType="slide"
      presentationStyle="formSheet"
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={styles.contentContainer}
      >
        {/* HEADER */}
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>{data.title?.text}</Text>
          <Pressable
            onPress={onClose}
            style={{
              padding: 16,
            }}
          >
            <AntDesign name="close" onPress={onClose} size={24} />
          </Pressable>
        </View>

        {hasForeignLanguage ? (
          <View style={styles.foreignLanguageContainer}>
            <Text style={styles.foreignLanguageLabel}>
              Some of this content appears to be in another language.
            </Text>
            <TranslateButton
              theme="dark"
              isTranslated={shouldTranslate && !!translatedBigIssue.data}
              isTranslating={translatedBigIssue.fetching}
              onPress={() => setShouldTranslate((prev) => !prev)}
              fromLanguage="any"
            />
          </View>
        ) : null}

        {/* WHAT IS IT AND WHY IS IT A PROBLEM */}
        <Text style={styles.sectionText}>
          What is this and why is it a problem?
        </Text>
        <Carousel
          itemHeight={400}
          captionStyle={styles.carouselCaptionStyle}
          data={data.main_carousel as CarouselCard[]}
        />

        {/* A QUICK SUMMARY */}
        <Text style={styles.sectionTitle}>A QUICK SUMMARY</Text>
        <Text style={[styles.sectionText, { marginVertical: 2 }]}>
          1. {data.quick_summary_fact1?.text}
        </Text>
        <Text style={[styles.sectionText, { marginVertical: 2 }]}>
          2. {data.quick_summary_fact2?.text}
        </Text>
        <Text style={[styles.sectionText, { marginVertical: 2 }]}>
          3. {data.quick_summary_fact3?.text}
        </Text>

        {/* QUICK SUMMARY LINKS */}
        <Links
          links={(data.quick_summary_links as TitledLink[]) ?? []}
          subheading="Click the links to learn more about this issue"
        />

        {/* HOW THIS AFFECTS US */}
        <Text style={styles.sectionTitle}>HOW THIS PROBLEM AFFECTS US</Text>
        <Text style={styles.sectionText}>{data.how_this_affects_us?.text}</Text>

        {data.how_this_affects_us_carousel?.length ? (
          <Carousel
            itemHeight={400}
            captionStyle={styles.carouselCaptionStyle}
            data={data.how_this_affects_us_carousel as CarouselCard[]}
          />
        ) : null}

        {/* HOW THIS AFFECTS US LINKS */}
        <Links
          links={(data.how_this_affects_us_links as TitledLink[]) ?? []}
          subheading="Click the links to learn more about how this issue affects us"
        />

        {/* WHAT WE ARE DOING */}
        <Text style={styles.sectionTitle}>WHAT WE ARE DOING</Text>
        <Text style={styles.sectionText}>{data.what_we_are_doing?.text}</Text>
        {data.what_we_are_doing_carousel?.length ? (
          <Carousel
            itemHeight={400}
            captionStyle={styles.carouselCaptionStyle}
            data={data.what_we_are_doing_carousel as CarouselCard[]}
          />
        ) : null}

        {/* WHAT WE ARE DOING LINKS */}
        <Links
          links={(data.what_we_are_doing_links as TitledLink[]) ?? []}
          subheading="Click the links to learn more about what we are doing about this issue"
        />

        {/* HOW YOU CAN HELP */}
        <Text style={styles.sectionTitle}>HOW YOU CAN HELP</Text>
        <Text style={styles.sectionText}>{data.how_you_can_help?.text}</Text>

        {/* CAMPAIGNS */}
        {relatedCampaigns.data?.getCampaigns?.total ||
        relatedCampaigns.fetching ? (
          <>
            <Text style={styles.sectionTitle}>
              CAMPAIGNS RELATED TO THIS ISSUE
            </Text>
            <Text style={styles.sectionText}>
              Find other campaigns related to this issue from the same
              organization
            </Text>
            {relatedCampaigns.fetching &&
            !relatedCampaigns.data?.getCampaigns.total ? (
              <ListLoading />
            ) : null}
            <View
              style={{
                width: '100%',
                maxWidth: 640,
                alignSelf: 'center',
              }}
            >
              <TruncatedList
                items={relatedCampaigns.data?.getCampaigns.items ?? []}
                maxLength={4}
                total={relatedCampaigns?.data?.getCampaigns.total ?? undefined}
                onViewMore={onSeeAllCampaigns}
                renderItem={(item) => (
                  <CampaignPost
                    onGoToCampaign={() => onSelectCampaign()}
                    page="research_topic_summary"
                    data={item.original_post || null}
                    campaign={item}
                    disableAnimateIn
                  />
                )}
              />
            </View>
          </>
        ) : relatedCampaigns.error ? (
          <GenericError
            message="We ran into a problem while fetching related campaigns"
            onRetry={getRelatedCampaigns}
          />
        ) : null}
      </ScrollView>
    </Modal>
  );
}

interface ILinksProps {
  links: TitledLink[] | undefined;
  subheading: string;
}

/**
 * Renders big issue links if `links` has a length
 * Otherwise returns null
 */
function Links(props: ILinksProps) {
  return props.links?.length ? (
    <>
      <Text style={styles.sectionTitle}>LEARN MORE</Text>
      <Text style={styles.sectionText}>{props.subheading}</Text>
      <LinksViewer links={props.links} />
    </>
  ) : null;
}

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
    alignSelf: 'center',
    padding: 12,
    paddingBottom: 48,
    maxWidth: 800,
  },
  foreignLanguageContainer: {
    alignSelf: 'flex-start',
    marginVertical: 4,
    padding: 8,
    backgroundColor: KEY_LIGHT_GRAY,
    borderRadius: 6,
  },
  foreignLanguageLabel: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 18,
  },
  headerContainer: {
    paddingTop: 8,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: 24,
    color: 'black',
    textTransform: 'uppercase',
  },
  sectionTitle: {
    marginTop: 16,
    paddingVertical: 4,
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: CARD_TITLE_FONT_SIZE,
    color: 'black',
  },
  sectionText: {
    fontFamily: 'Lato',
    fontSize: 16,
    color: 'black',
  },
  linkButton: {
    backgroundColor: '#eee',
    borderRadius: 6,
    padding: 8,
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkButtonText: {
    flex: 1,
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    marginLeft: 4,
    fontSize: 15,
    textAlignVertical: 'center',
  },
  carouselCaptionStyle: {
    fontSize: 16,
  },
});
