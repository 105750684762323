// Types
export const FlipType = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
};

export const SaveFormat = {
  JPEG: 'jpeg',
  PNG: 'png',
};

export const ActionExtent = {
  PRESERVE_ASPECT: 'preserveAspect',
  COVER: 'cover',
  CONTAIN: 'contain',
  STRETCH: 'stretch',
};

export const ActionFlip = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const ActionResize = {
  mode: ActionExtent.PRESERVE_ASPECT,
  width: 0,
  height: 0,
};

export const ActionRotate = {
  angle: 0,
};

// Mock manipulator functionality
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function manipulateAsync(uri, actions = [], options = {}) {
  console.warn('Image manipulation is not supported on web');
  return {
    uri,
    width: 0,
    height: 0,
  };
}

// Default export
export default {
  ActionExtent,
  ActionFlip,
  ActionResize,
  ActionRotate,
  FlipType,
  SaveFormat,
  manipulateAsync,
};
