import React from 'react';
import MediaEditor, {
  MediaEditorProps,
} from '/components/MediaEditor/MediaEditor';
import { useModalContext } from '/context';

type UseMediaEditor = {
  openEditor: (options: MediaEditorProps) => void;
};

export default function useMediaEditor(): UseMediaEditor {
  const { spawnModal, closeModal } = useModalContext();

  const openEditor = (options: MediaEditorProps) => {
    let modalId: string;
    modalId = spawnModal({
      title: 'Edit Media',
      animation: 'slide',
      fullscreen: true,
      component: (
        <MediaEditor
          {...options}
          onFinishEditing={(result) => {
            closeModal(modalId);
            options.onFinishEditing(result);
          }}
        />
      ),
    });
  };

  return { openEditor };
}
