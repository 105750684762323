import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';
import React, { useEffect, useState } from 'react';
import { FlatList, Keyboard, Text, View } from 'react-native';
import {
  SortDirection,
  UserRole,
  useSearchGroupsQuery,
} from '/generated/graphql';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Button from '../Button';
import GenericListFooter from '../GenericListFooter';
import GroupCard from '../GroupCard/GroupCard';
import commonStyles from './common/styles';
import { useAuthContext } from '/context';
import { isOrganizationProfileComplete } from '/util';

interface Props {
  query: string;
}

export default function GroupSearch(props: Props) {
  const [nextToken, setNextToken] = useState<string>();

  const { userData } = useAuthContext();

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const [{ data, fetching, stale, error }] = useSearchGroupsQuery({
    variables: {
      limit: 20,
      filter: {
        query: props.query || '',
      },
      sortDirection: SortDirection.Asc,
      nextToken,
    },
  });

  useEffect(() => {
    // If we change our search query, nextToken is no longer valid in this context
    // and so we should set it to undefined
    setNextToken(undefined);
  }, [props.query]);

  function onGroupPressed(groupId: string) {
    navigate('ViewGroup', { id: groupId });
  }

  function onPressCreateNewGroup() {
    navigate('CreateGroup');
  }

  return (
    <FlatList
      onEndReached={() => {
        if (data?.searchGroups.nextToken) {
          setNextToken(data.searchGroups.nextToken);
        }
      }}
      renderScrollComponent={(svProps) => (
        <KeyboardAwareScrollView {...svProps} />
      )}
      onEndReachedThreshold={0.2}
      onScrollBeginDrag={() => {
        Keyboard.dismiss();
      }}
      ListEmptyComponent={
        fetching || stale ? null : props.query && error ? (
          <Text style={commonStyles.errorText}>
            There was a problem searching groups
          </Text>
        ) : props.query && !data?.searchGroups.items.length ? (
          <Text style={commonStyles.emptyText}>No results</Text>
        ) : (
          <Text style={commonStyles.emptyText}>Search groups</Text>
        )
      }
      ListHeaderComponent={
        fetching ||
        !userData?.role ||
        (userData?.role === UserRole.Conservationist &&
          !isOrganizationProfileComplete(userData)) ? null : (
          <View
            style={{
              paddingHorizontal: 24,
              alignSelf: 'center',
              maxWidth: 320,
            }}
          >
            <Button
              label="Create New Group"
              containerStyle={{
                marginVertical: 6,
                alignSelf: 'center',
              }}
              onPress={onPressCreateNewGroup}
            />
          </View>
        )
      }
      ItemSeparatorComponent={ItemSeparator}
      ListFooterComponent={
        <GenericListFooter
          hasMore={!!data?.searchGroups.nextToken}
          loading={fetching || stale}
          isFirstPage={!nextToken}
          onFetchMore={() =>
            data?.searchGroups.nextToken &&
            setNextToken(data?.searchGroups.nextToken)
          }
        />
      }
      renderItem={({ item }) => {
        return (
          <GroupCard
            item={item}
            key={item.id}
            onPress={() => {
              onGroupPressed(item.id);
            }}
          />
        );
      }}
      style={{ flex: 1 }}
      data={data?.searchGroups.items}
    />
  );
}

function ItemSeparator() {
  return <View style={commonStyles.itemSeparator} />;
}
