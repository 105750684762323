import { forwardRef } from 'react';
import useMediaEditor from '/hooks/useMediaEditor';
import { MediaEditorProps } from './MediaEditor/MediaEditor';

export type WithMediaEditorProps = {
  openEditor: (options: MediaEditorProps) => void;
};

/**
 * Allows class components to use the `useMediaEditor` hook's functionality
 */
export default function withMediaEditor<P extends object>(
  WrappedComponent: React.ComponentType<P & WithMediaEditorProps>,
) {
  return forwardRef<any, P>((props, ref) => {
    const mediaEditor = useMediaEditor();
    const componentProps = { ...props, ...mediaEditor, ref } as P &
      WithMediaEditorProps;
    return <WrappedComponent {...componentProps} />;
  });
}
