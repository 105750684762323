import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TextInput } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';
import ErrorText from '../common/Generic/ErrorText';
import SectionText from '../common/Section/SectionText';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import RichTextEditor from '../RichText/RichTextEditor';
import {
  useFormValidationContext,
  ValidatedAny,
  ValidatedTextInput,
} from '../ValidatedForm';
import { DROPDOWN_STYLE, TEXT_INPUT, TEXT_INPUT_LARGE } from '/constants';
import {
  PutTesterObjectiveInput,
  TesterObjectiveTargetDeviceType,
  UserRole,
} from '/generated/graphql';
import { isValidJson, isValidURLRegex } from '/util';
import { createRichTextStateFromPlainText } from '../RichText/utils';

export interface ITesterObjectiveFormState
  extends Pick<
    PutTesterObjectiveInput,
    | 'id'
    | 'description'
    | 'timeToComplete'
    | 'dueDate'
    | 'name'
    | 'howToTest'
    | 'whatToExpect'
    | 'userRole'
    | 'calEventLink'
    | 'minimumClientVersion'
    | 'targetDeviceType'
    | 'completionStatusRequired'
  > {}

type Props = {
  state?: ITesterObjectiveFormState;
  onChange: (state: ITesterObjectiveFormState) => void;
};

function TesterObjectiveForm(props: Props) {
  const [isLiveSession, setIsLiveSession] = useState(
    !!props.state?.calEventLink,
  );

  useEffect(() => {
    if (props.state?.calEventLink?.trim()) setIsLiveSession(true);
  }, [props.state?.calEventLink]);

  const { fields } = useFormValidationContext();

  return (
    <>
      <Text
        style={[
          styles.fieldLabel,
          {
            marginTop: 0,
          },
        ]}
      >
        NAME
      </Text>
      <ValidatedTextInput
        name="name"
        placeholder="Enter the name of the objective"
        placeholderTextColor="gray"
        value={props.state?.name?.text || ''}
        style={TEXT_INPUT}
        onChangeText={(value) =>
          props.onChange({
            name: {
              text: value,
            },
          })
        }
      />

      <Text style={styles.fieldLabel}>GOAL</Text>
      <ValidatedTextInput
        name="description"
        multiline
        placeholder="Succinctly describe the goal of the test"
        placeholderTextColor="gray"
        style={[
          TEXT_INPUT_LARGE,
          {
            minHeight: 64,
          },
        ]}
        maxLength={200}
        value={props.state?.description?.text || ''}
        onChangeText={(value) =>
          props.onChange({
            description: {
              text: value,
            },
          })
        }
      />

      <Text style={styles.fieldLabel}>TIME TO COMPLETE</Text>
      <ValidatedTextInput
        name="timeToComplete"
        placeholder="e.g. 5 minutes"
        placeholderTextColor="gray"
        style={TEXT_INPUT}
        value={props.state?.timeToComplete?.text || ''}
        onChangeText={(value) =>
          props.onChange({
            timeToComplete: {
              text: value,
            },
          })
        }
      />

      <Text style={styles.fieldLabel}>WHAT TO EXPECT</Text>
      <ValidatedAny name="whatToExpect" value={props.state?.whatToExpect?.text}>
        <RichTextEditor
          initialContent={
            typeof props.state?.whatToExpect?.text === 'string' &&
            isValidJson(props.state?.whatToExpect?.text)
              ? props.state.whatToExpect.text
              : createRichTextStateFromPlainText(
                  props.state?.whatToExpect?.text ?? '',
                )
          }
          onChange={(content) =>
            props.onChange({
              whatToExpect: {
                text: content,
              },
            })
          }
        />
      </ValidatedAny>

      <Text style={styles.fieldLabel}>HOW TO TEST</Text>
      <ValidatedAny name="howToTest" value={props.state?.howToTest?.text}>
        <RichTextEditor
          initialContent={
            typeof props.state?.howToTest?.text === 'string' &&
            isValidJson(props.state?.howToTest.text ?? '')
              ? props.state.howToTest.text
              : createRichTextStateFromPlainText(
                  props.state?.howToTest?.text ?? '',
                )
          }
          onChange={(content) => {
            props.onChange({
              howToTest: {
                text: content,
              },
            });
          }}
        />
      </ValidatedAny>

      <Text style={styles.fieldLabel}>DUE DATE</Text>
      <ValidatedAny
        name="dueDate"
        containerStyle={{
          borderWidth: fields.dueDate?.valid === false ? 1 : 0,
          borderColor: 'crimson',
        }}
        value={props.state?.dueDate}
        validate={(val) => {
          return !!val && new Date(val) >= new Date();
        }}
      >
        <DateTimePicker
          minDate={new Date()}
          mode="datetime"
          onChange={(date) => props.onChange({ dueDate: date })}
          value={props.state?.dueDate}
        />
      </ValidatedAny>
      {fields.dueDate?.valid === false ? (
        <ErrorText>Due date must be in the future</ErrorText>
      ) : null}

      <Text style={styles.fieldLabel}>USER ROLE</Text>
      <SectionText>
        Optionally limit this objective to one user role
      </SectionText>
      <Dropdown
        style={[
          DROPDOWN_STYLE,
          {
            marginTop: 8,
            width: 150,
          },
        ]}
        dropdownPosition="top"
        data={
          [
            {
              label: 'Any',
              value: undefined,
            },
            {
              label: 'Supporters',
              value: UserRole.Supporter,
            },
            {
              label: 'Conservationists',
              value: UserRole.Conservationist,
            },
          ] as { label: string; value: UserRole | undefined }[]
        }
        labelField="label"
        valueField="value"
        value={props.state?.userRole}
        onChange={(option) => props.onChange({ userRole: option.value })}
      />

      <Text style={styles.fieldLabel}>COMPLETION STATUS</Text>
      <SectionText>
        Should the user be asked whether they were able to complete this
        objective?
      </SectionText>
      <Dropdown
        style={[
          DROPDOWN_STYLE,
          {
            marginTop: 8,
            width: 150,
          },
        ]}
        dropdownPosition="top"
        data={
          [
            {
              label: 'Ask',
              value: 'ask',
            },
            {
              label: "Don't ask",
              value: 'dont-ask',
            },
          ] as { label: string; value: 'ask' | 'dont-ask' }[]
        }
        labelField="label"
        valueField="value"
        value={
          props.state?.completionStatusRequired ?? true ? 'ask' : 'dont-ask'
        }
        onChange={(option) =>
          props.onChange({
            completionStatusRequired: option.value === 'ask',
          })
        }
      />

      <Text style={styles.fieldLabel}>MINIMUM APP VERSION</Text>
      <SectionText>
        The minimum version required to be able to test this objective. Defaults
        to the version you're currently running, which is not necessarily the
        latest version.
      </SectionText>
      <TextInput
        style={TEXT_INPUT}
        value={props.state?.minimumClientVersion || ''}
        onChangeText={(value) =>
          props.onChange({ minimumClientVersion: value })
        }
      />

      <Text style={styles.fieldLabel}>TEST TYPE</Text>
      <SectionText>
        Testers can either test independently or with supervision, for which
        they'd have to book a live session on the calendar.
      </SectionText>
      <Dropdown
        disable={!!props.state?.id}
        style={[
          DROPDOWN_STYLE,
          {
            marginTop: 8,
            opacity: props.state?.id ? 0.5 : 1,
            pointerEvents: props.state?.id ? 'none' : 'auto',
          },
        ]}
        dropdownPosition="top"
        data={
          [
            {
              label: 'Self-report',
              value: 'self-report',
            },
            {
              label: 'Live session',
              value: 'live-session',
            },
          ] as { label: string; value: 'self-report' | 'live-session' }[]
        }
        labelField="label"
        valueField="value"
        value={isLiveSession ? 'live-session' : 'self-report'}
        onChange={(option) => setIsLiveSession(option.value === 'live-session')}
      />

      <Text style={styles.fieldLabel}>TARGET DEVICE TYPE</Text>
      <SectionText>
        The device type that testers are intended to use for this objective.
      </SectionText>
      <Dropdown
        style={[
          DROPDOWN_STYLE,
          {
            marginTop: 8,
          },
        ]}
        dropdownPosition="top"
        data={
          [
            {
              label: 'Any',
              value: undefined,
            },
            {
              label: 'Mobile Only',
              value: TesterObjectiveTargetDeviceType.MobileOnly,
            },
            {
              label: 'Desktop Only',
              value: TesterObjectiveTargetDeviceType.DesktopOnly,
            },
          ] as {
            label: string;
            value: TesterObjectiveTargetDeviceType | undefined;
          }[]
        }
        labelField="label"
        valueField="value"
        value={props.state?.targetDeviceType}
        onChange={(option) =>
          props.onChange({ targetDeviceType: option.value })
        }
      />

      {isLiveSession ? (
        <>
          <Text style={styles.fieldLabel}>Cal.com Event Link</Text>
          <ValidatedTextInput
            editable={!props.state?.id}
            name="calEventLink"
            validate={(val) => isValidURLRegex(val, 'cal.com')}
            style={[
              TEXT_INPUT,
              props.state?.id
                ? {
                    opacity: 0.5,
                    pointerEvents: 'none',
                  }
                : {},
            ]}
            value={props.state?.calEventLink || ''}
            onChangeText={(value) => props.onChange({ calEventLink: value })}
          />
        </>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  fieldLabel: {
    fontSize: 15,
    textTransform: 'uppercase',
    fontFamily: 'LeagueSpartan-Bold',
    marginBottom: 8,
    marginTop: 16,
  },
});

export default TesterObjectiveForm;
