import { ListItemNode, ListNode } from '@lexical/list';
import { TextNode } from 'lexical';
import React from 'react';
import { isValidJson } from '/util';

export interface ErrorBoundaryProps {
  children: React.ReactElement;
  onError: (error: Error) => void;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error) {
    this.props.onError(error);
  }

  render() {
    return this.props.children;
  }
}

export const textFormatStyles = `
  .editor-textBold {
    font-weight: bold;
  }
  .editor-textItalic {
    font-style: italic;
  }
  .editor-textUnderline {
    text-decoration: underline;
  }
  .editor-textStrikethrough {
    text-decoration: line-through;
  }
  .editor-textUnderlineStrikethrough {
    text-decoration: underline line-through;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

export const baseTheme = {
  text: {
    base: 'font-family: "Lato", sans-serif; color: #333; font-size: 16px; line-height: 1.5;',
    bold: 'editor-textBold',
    italic: 'editor-textItalic',
    underline: 'editor-textUnderline',
    strikethrough: 'editor-textStrikethrough',
    underlineStrikethrough: 'editor-textUnderlineStrikethrough',
  },
  paragraph: 'margin: 0 0 8px 0;',
  list: {
    ul: 'padding-left: 20px; margin: 0 0 8px 0; list-style-type: disc;',
    ol: 'padding-left: 20px; margin: 0 0 8px 0; list-style-type: decimal;',
    listitem: 'margin: 0 0 4px 0;',
  },
};

export const baseStyles = {
  contentEditable: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#333',
    outline: 'none',
    overflow: 'hidden',
    '::selection': {
      backgroundColor: '#E8F0FE',
    },
  },
};

export const EMPTY_CONTENT = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
        textFormat: 0,
        textStyle: '',
      },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};

export function createRichTextStateFromPlainText(text: string): string {
  // Create a simple editor state with the given text
  const state = {
    ...EMPTY_CONTENT,
    root: {
      ...EMPTY_CONTENT.root,
      children: [
        {
          ...EMPTY_CONTENT.root.children[0],
          children: text
            ? [
                {
                  detail: 0,
                  format: 0,
                  mode: 'normal',
                  style: '',
                  text: text || '',
                  type: 'text',
                  version: 1,
                },
              ]
            : [],
        },
      ],
    },
  };

  return JSON.stringify(state);
}

/**
 * Try to get plain text from stringified Lexical rich text JSON object. If object
 * is not a valid Lexical rich text object, just returns the original content.
 * @param content - The potential rich text state to get the plain text from
 * @returns The plain text from the rich text state
 */
export function getPlainTextFromRichtext(content: string): string {
  try {
    const state = JSON.parse(content);

    if (!state?.root?.children) return content;

    function extractTextFromNode(node: any): string {
      if (!node) return '';

      // If node has text property, it's a text node
      if (node.text) {
        return node.text;
      }

      // If node has children, recursively extract text from children
      if (Array.isArray(node.children)) {
        return node.children.map(extractTextFromNode).join('');
      }

      return '';
    }

    return extractTextFromNode(state.root);
  } catch (err) {
    return content;
  }
}

export const getBaseLexicalConfig = (
  namespace: string,
  content?: string,
  editable = false,
) => ({
  namespace,
  theme: baseTheme,
  nodes: [ListNode, ListItemNode, TextNode],
  editorState:
    typeof content === 'string' && isValidJson(content)
      ? content
      : createRichTextStateFromPlainText(content ?? ''),
  editable,
  onError: (error: Error) => {
    console.error(`${namespace} error:`, error);
  },
});
