import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { CampaignBuilderComponentProps } from '../CampaignBuilder';
import CampaignBuilderFooter from './CampaignBuilderFooter';
import ResearchTopicGroupPicker from '/components/ResearchTopicGroupPicker';
import ResearchTopicPicker from '/components/ResearchTopicPicker';
import { KEY_GRAY, TITLE_FONT_SIZE } from '/constants';
import { KeyboardAwareScrollView } from 'react-native-keyboard-controller';

export default function CampaignResearchTopics(
  props: CampaignBuilderComponentProps,
) {
  function onSelectTopic(topicName: string | string[]) {
    if (
      props.data?.campaign?.topics?.some((topic) => topic.topic === topicName)
    ) {
      props.setData({
        campaign: {
          topics: props.data?.campaign.topics?.filter(
            (topic) => topic.topic !== topicName,
          ),
        },
      });
    } else {
      const newTopics = Array.from(
        new Set(
          [
            ...(props.data?.campaign.topics?.map((topic) => topic.topic) ?? []),
            topicName,
          ].flat(),
        ),
      ).map((topic) => ({
        topic,
      }));

      props.setData({
        campaign: {
          // Ensure same sorting everytime
          topics: newTopics.sort((a, b) => a.topic.localeCompare(b.topic)),
        },
      });
    }
  }

  function onRemoveTopic(topicName: string) {
    props.setData({
      campaign: {
        topics:
          props.data?.campaign.topics?.filter(
            (topic) => topic.topic !== topicName,
          ) ?? [],
      },
    });
  }

  return (
    <>
      <KeyboardAwareScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[
          styles.containerStyle,
          {
            backgroundColor: 'white',
            marginTop: 8,
          },
        ]}
      >
        <Text style={styles.headerText}>RELEVANT RESEARCH TOPICS</Text>
        <Text style={styles.paragraph}>
          <Text style={styles.paragraphItalic}>(Optional)</Text>
          {` `}Add topics that are relevant to this campaign to help supporters,
          organizations, and researchers find your work.{` `}
          <Text style={styles.paragraphItalic}>
            Only select topics that apply to this campaign.
          </Text>
        </Text>

        <ResearchTopicPicker
          onRemoveTopic={onRemoveTopic}
          onSelectTopic={onSelectTopic}
          selectedTopics={props.data?.campaign.topics?.map(
            (topic) => topic.topic,
          )}
        />
        <ResearchTopicGroupPicker
          selectedTopics={props.data?.campaign.topics?.map(
            (topic) => topic.topic,
          )}
          containerStyle={{
            paddingVertical: 8,
          }}
          onSelectTopic={onSelectTopic}
        />
      </KeyboardAwareScrollView>
      <CampaignBuilderFooter
        {...props}
        onNext={() => props.next()}
        //   This is always valid
        validateForm={() => true}
      />
    </>
  );
}

const styles = StyleSheet.create({
  headerText: {
    fontFamily: 'LeagueSpartan-Bold',
    fontSize: TITLE_FONT_SIZE,
  },
  paragraph: {
    fontFamily: 'Lato-Bold',
    color: KEY_GRAY,
    fontSize: 15,
  },
  paragraphItalic: {
    fontFamily: 'Lato-BoldItalic',
  },
  containerStyle: {
    padding: 10,
  },
});
