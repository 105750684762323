import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import Button from '../Button';
import styles from './AccountApprovalAlert.style';
import { ALERT_YELLOW, KEY_GRAY, KEY_GREEN } from '/constants';
import { useAuthContext } from '/context';
import {
  Maybe,
  OrganizationApplication,
  OrganizationApplicationStatus,
  UserRole,
} from '/generated/graphql';
import { ViewStyle } from 'react-native';
import { StyleProp } from 'react-native';

interface IOrganizationApplication
  extends Pick<OrganizationApplication, 'status' | 'completed_survey'> {}

type AccountApprovalAlertProps = {
  /** Override the default labels */
  overrideLabels?: {
    denied?: string;
    submitted?: string;
    approved?: string;
  };
  style?: StyleProp<ViewStyle>;
};

export default function AccountApprovalAlert(props: AccountApprovalAlertProps) {
  const { userData } = useAuthContext();

  const application: Maybe<IOrganizationApplication> | undefined =
    userData?.application;

  const { navigate } = useNavigation<any>();

  let ALERT_BACKGROUND_COLOR;
  let ALERT_TITLE;
  let ALERT_TEXT;

  /**
   * Show different message based on current vetting status
   */
  switch (application?.status) {
    case OrganizationApplicationStatus.Denied: {
      ALERT_TITLE = 'Account Not Approved';
      ALERT_TEXT =
        props.overrideLabels?.denied ||
        'We were unable to verify your account. Please review your information. If you believe this is an error, please contact us at contact@keyconservation.org';
      ALERT_BACKGROUND_COLOR = '#ffa196';
      break;
    }
    case OrganizationApplicationStatus.Submitted: {
      ALERT_TITLE = 'Account Under Review';
      ALERT_TEXT =
        props.overrideLabels?.submitted ||
        'You will have limited access to the platform while we review your account and verify your documentation.';
      ALERT_BACKGROUND_COLOR = ALERT_YELLOW;
      break;
    }
    case OrganizationApplicationStatus.Approved: {
      if (application.completed_survey) break;

      ALERT_TITLE = 'Account Approved!';
      ALERT_TEXT =
        props.overrideLabels?.approved ||
        'Finish your account set-up to get started on Key Conservation';
      ALERT_BACKGROUND_COLOR = '#9effab';
    }
  }

  const onFinishAccountSetup = () => {
    navigate('OrgDataSurveySetup');
  };

  // Only render for organizations
  return userData?.role !== UserRole.Conservationist ? null : (
    <View
      testID="account-approval-alert"
      style={[
        styles.container,
        props.style,
        {
          display: ALERT_TITLE ? 'flex' : 'none',
          backgroundColor: ALERT_BACKGROUND_COLOR,
        },
      ]}
    >
      <View style={styles.alertTitleContainer}>
        <FontAwesome name="info-circle" size={16} color={KEY_GRAY} />
        <Text style={styles.alertTitle}>{ALERT_TITLE}</Text>
      </View>
      <Text style={styles.alertText}>{ALERT_TEXT}</Text>

      {/* If application is approved, but user needs to complete setup, show "Finish set-up" button */}
      {application?.status === OrganizationApplicationStatus.Approved &&
        application.completed_survey === false && (
          <Button
            label="FINISH SET-UP"
            containerStyle={{
              alignSelf: 'flex-end',
              marginTop: 8,
            }}
            style={{
              backgroundColor: KEY_GREEN,
            }}
            labelStyle={{
              fontSize: 16,
            }}
            onPress={onFinishAccountSetup}
          />
        )}
    </View>
  );
}
