import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import addItemToPaginatedField from '/util/cache/addItemToPaginatedField';

const InteractionsFragment = gql`
  fragment AddCommentInteractionsFragment on PostInteractions {
    id
    comments(
      limit: $limit
      nextToken: $nextToken
      startWithCommentId: $startWithCommentId
    ) {
      total
      prevToken
      nextToken
      items {
        id
        created_at
        body_translatable {
          id
          text
          language
        }
        mentions {
          id
          start
          end
          userId
        }
        user {
          id
          name
          profile_image
        }
        replies {
          total
          nextToken
          prevToken
          items {
            id
            created_at
            body_translatable {
              id
              text
              language
            }
            mentions {
              id
              start
              end
              userId
            }
            user {
              id
              name
              profile_image
            }
          }
        }
      }
    }
  }
`;

const RepliesFragment = gql`
  fragment AddCommentRepliesFragment on NewComment {
    id
    replies(
      limit: $limit
      nextToken: $nextToken
      prevToken: $prevToken
      sortDirection: $sortDirection
      startWithId: $startWithId
    ) {
      total
      nextToken
      prevToken
      items {
        id
        created_at
        body_translatable {
          id
          text
          language
        }
        mentions {
          id
          start
          end
          userId
        }
        user {
          id
          name
          profile_image
        }
      }
    }
  }
`;

const addComment: UpdateResolver = (
  result: { addComment: any },
  args,
  cache,
) => {
  if (result.addComment?.replyToId) {
    addItemToPaginatedField({
      cache,
      entity: {
        __typename: 'NewComment',
        id: result.addComment.replyToId,
      },
      newItem: {
        ...result.addComment,
        replies: {
          __typename: 'PaginatedNewComments',
          total: 0,
          nextToken: null,
          prevToken: null,
          items: [],
        },
      },
      paginatedField: {
        name: 'replies',
        fragment: RepliesFragment,
      },
    });
  } else {
    addItemToPaginatedField({
      cache,
      entity: {
        __typename: 'PostInteractions',
        id: args.interactionsId as string,
      },
      newItem: {
        ...result.addComment,
        replies: {
          __typename: 'PaginatedNewComments',
          total: 0,
          nextToken: null,
          prevToken: null,
          items: [],
        },
      },
      paginatedField: {
        name: 'comments',
        fragment: InteractionsFragment,
      },
    });
  }
};

export default addComment;
