import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Platform } from 'react-native';

type MetaTagContextType = {
  setPageTitle: (pageTitle: string) => void;
  setPageThumbnail: (pageThumbnail: string) => void;
  setPageDescription: (pageDescription: string) => void;
};

export const MetaTagContext = React.createContext({} as MetaTagContextType);

const FALLBACK_PAGE_TITLE = 'Key Conservation';
const FALLBACK_PAGE_THUMBNAIL = '/og-image.png';
const FALLBACK_PAGE_DESCRIPTION = 'Empowering Hope';

/** Up to 10 keywords for SEO */
const KEYWORDS = [
  'Conservation',
  'Wildlife',
  'Biodiversity',
  'Endangered species',
  'Animal rescue app',
  'Conservation app',
  'Wildlife support',
  'Volunteer opportunities',
  'Community conservation',
  'Skill sharing',
].join(',');

export default function MetaTagProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const navigation = useNavigation<StackNavigationProp<any>>();

  const [pageTitle, setPageTitle] = React.useState('');
  const [pageThumbnail, setPageThumbnail] = React.useState('');
  const [pageDescription, setPageDescription] = React.useState('');

  /**
   * Remove the fallback og:image tag now that this JavaScript is running.
   */
  useEffect(() => {
    // Find and remove the fallback og:image tag
    const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
    if (ogImageMetaTag) {
      ogImageMetaTag.remove();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setPageTitle(FALLBACK_PAGE_TITLE);
      setPageThumbnail(FALLBACK_PAGE_THUMBNAIL);
    });

    return unsubscribe;
  }, [navigation]);

  const title = pageTitle?.trim()
    ? `${pageTitle} - Key Conservation`
    : FALLBACK_PAGE_TITLE;
  const thumbnail = pageThumbnail || FALLBACK_PAGE_THUMBNAIL;
  const description = pageDescription || FALLBACK_PAGE_DESCRIPTION;

  return (
    <MetaTagContext.Provider
      value={{
        setPageTitle,
        setPageThumbnail,
        setPageDescription,
      }}
    >
      {Platform.OS === 'web' ? (
        <Helmet>
          <meta name="description" content={description} />
          <meta name="keywords" content={KEYWORDS} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={thumbnail} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:image" content={thumbnail} />
          <meta property="twitter:description" content={description} />
        </Helmet>
      ) : null}
      {children}
    </MetaTagContext.Provider>
  );
}
