import React from 'react';
import { Switch, Text, View } from 'react-native';
import Language from '/assets/jsicons/Language';
import LanguagePicker from '/components/LanguagePicker/LanguagePicker';
import RemovableBadge from '/components/RemovableBadge';
import ValidatedAny from '/components/ValidatedForm/ValidatedAny';
import { ValidatedFormFields } from '/components/ValidatedForm/ValidatedForm';
import { KEY_GREEN } from '/constants';
import styles from '/constants/CampaignBuilder/SkillImpact/SkillExpand';
import { SkillRequest } from '/generated/graphql';

type Props = {
  fields: ValidatedFormFields;
  skill: Partial<SkillRequest>;
  onChange: (data: Partial<SkillRequest>) => void;
};

const Languages = ({ onChange, ...props }: Props) => {
  const requiredLangs = props.skill?.required_languages ?? [];
  const prefferedLangs = props.skill?.preferred_languages ?? [];

  return (
    <ValidatedAny
      name="languages"
      value={props.skill?.required_languages}
      validate={(value) => {
        // Valid if "Any language can apply is selected",
        // or if at least one language is selected
        return props.skill.any_language_apply || !!value?.length;
      }}
    >
      <View style={styles.itemContainers}>
        <View style={styles.itemContentRows}>
          <View style={styles.itemContentIconContainer}>
            <Language width={38} height={38} fill="#555555" />
          </View>
          <Text style={styles.itemContentTitle}>Languages Needed</Text>
        </View>
        <View style={styles.itemContentRows}>
          <Text style={styles.itemBodyText}>
            What language do you need this skilled professional to speak or
            understand to help with this skill request? Select one or multiple
            languages.
          </Text>
        </View>
        <View
          style={[
            styles.itemContentRows,
            {
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          ]}
        >
          <Text style={[styles.itemBodyText, { fontFamily: 'Lato-Bold' }]}>
            Any language can apply
          </Text>
          <Switch
            trackColor={{ true: KEY_GREEN }}
            // thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
            // ios_backgroundColor="#3e3e3e"
            onValueChange={() =>
              onChange({
                any_language_apply: !props.skill.any_language_apply,
              })
            }
            value={props.skill.any_language_apply}
          />
        </View>
        <View
          style={{
            display: props.skill.any_language_apply ? 'none' : 'flex',
          }}
        >
          <View
            style={[
              styles.itemContentRows,
              props.fields.languages?.valid === false
                ? {
                    borderColor: 'crimson',
                    borderWidth: 1,
                  }
                : null,
            ]}
          >
            <Text style={[styles.itemBodyText, { fontFamily: 'Lato-Bold' }]}>
              Our supporter must speak the following language(s)
            </Text>
            <LanguagePicker
              buttonLabel="Add languages"
              buttonContainerStyle={{
                width: '100%',
                marginVertical: 10,
              }}
              onSelectLanguage={(language) => {
                onChange({
                  required_languages: Array.from(
                    new Set([...requiredLangs, language]),
                  ),
                });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {requiredLangs?.map((_lang, i) => (
              <RemovableBadge
                key={i}
                label={_lang}
                onRemove={() => {
                  onChange({
                    required_languages: requiredLangs.filter(
                      (l) => l !== _lang,
                    ),
                  });
                }}
              />
            ))}
          </View>
          <View style={styles.itemContentRows}>
            <Text style={[styles.itemBodyText, { fontFamily: 'Lato-Bold' }]}>
              Would like our supporter to speak the following language(s) but
              they are not required
            </Text>
            <LanguagePicker
              buttonLabel="Add languages"
              buttonContainerStyle={{
                width: '100%',
                marginVertical: 10,
              }}
              onSelectLanguage={(language) => {
                onChange({
                  preferred_languages: Array.from(
                    new Set([...prefferedLangs, language]),
                  ),
                });
              }}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {prefferedLangs?.map((_lang, i) => (
              <RemovableBadge
                key={i}
                label={_lang}
                onRemove={() => {
                  onChange({
                    preferred_languages: prefferedLangs.filter(
                      (l) => l !== _lang,
                    ),
                  });
                }}
              />
            ))}
          </View>
        </View>
      </View>
    </ValidatedAny>
  );
};

export default Languages;
