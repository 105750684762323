import { Entypo } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Switch, Text, View } from 'react-native';
import { useCommonStyles } from '../styles';
import EditSkillsModal from './EditSkillsModal';
import Alert from '/Alert';
import Sync from '/assets/jsicons/bottomnavigation/Sync';
import Button from '/components/Button';
import ButtonToggle from '/components/ButtonToggle/ButtonToggle';
import UserSkillPortfolioModal from '/components/UserSkillPortfolioModal';
import { ALERT_RED, KEY_GRAY, KEY_GREEN, SECTION_CONTAINER } from '/constants';
import { useAuthContext } from '/context';
import {
  Skill,
  User,
  UserSkillPortfolio,
  useUpdateUserProfileMutation,
} from '/generated/graphql';

interface ISkillPortfolio
  extends Pick<
    UserSkillPortfolio,
    | 'links'
    | 'media_carousel'
    | 'relevant_experience'
    | 'thumbnail'
    | 'skillName'
  > {
  skill: Pick<Skill, 'name' | 'thumbnail'>;
}

interface IData extends Pick<User, 'id' | 'allow_skill_solicitation'> {
  skills: ISkillPortfolio[];
}
interface Props {
  data: IData | undefined;
  setData: (data: Partial<IData>) => void;
  isEditing: boolean;
}

export default function MySkills(props: Props) {
  const { userData } = useAuthContext();

  const { styles: commonStyles } = useCommonStyles();

  const [allowSkillSolicitation, setAllowSkillSolicitation] = useState(
    props.data?.allow_skill_solicitation ?? false,
  );

  const [selectedSkillPortfolio, setSelectedSkillPortfolio] =
    useState<Partial<UserSkillPortfolio>>();

  const [showEditSkillsModal, setShowEditSkillsModal] = useState(false);

  const [, updateUser] = useUpdateUserProfileMutation();

  useEffect(() => {
    if (props.data?.allow_skill_solicitation !== undefined) {
      setAllowSkillSolicitation(props.data?.allow_skill_solicitation);
    }
  }, [props.data?.allow_skill_solicitation]);

  function onSetAllowSkillSolicitation(allow: boolean) {
    /** This component will update this property directly because we want this to be as simply
     * as hitting the switch, whether or not we are currently editing the profile */
    updateUser({
      input: {
        id: userData?.id,
        allow_skill_solicitation: allow,
      },
    }).then(({ error }) => {
      if (error) {
        /** If request has failed, revert value */
        setAllowSkillSolicitation(
          props.data?.allow_skill_solicitation ?? false,
        );
        Alert.notify({
          color: ALERT_RED,
          textColor: 'white',
          message: 'Failed to update preference',
        });
      } else
        Alert.notify({
          color: KEY_GREEN,
          message: 'Preference updated',
        });
    });

    setAllowSkillSolicitation(allow);
  }

  function onEditSkills() {
    setShowEditSkillsModal(true);
  }

  return !props.data?.skills?.length && !props.isEditing ? null : (
    <View style={SECTION_CONTAINER}>
      <UserSkillPortfolioModal
        visible={!!selectedSkillPortfolio}
        data={selectedSkillPortfolio}
        onRequestClose={() => {
          setSelectedSkillPortfolio(undefined);
        }}
      />

      <View
        style={[
          commonStyles('sectionTitleContainer'),
          { justifyContent: 'flex-start' },
        ]}
      >
        <Sync width={28} height={28} />
        <Text style={[commonStyles('sectionTitle'), { marginLeft: 8 }]}>
          MY SKILLS
        </Text>
      </View>

      {props.data?.skills?.length === 0 ? (
        <Text style={styles.emptyText}>
          You have not added any skills to your profile yet
        </Text>
      ) : (
        <View style={styles.skillsContainer}>
          {props.data?.skills?.map((skill) => {
            const hasContent =
              !!skill?.links?.length ||
              !!skill?.media_carousel?.length ||
              !!skill?.relevant_experience?.trim();

            const userHasThisSkill = userData?.skills?.some(
              (s) => s.skillName === skill?.skillName,
            );

            return (
              <ButtonToggle
                onPress={() => {
                  setSelectedSkillPortfolio(skill as UserSkillPortfolio);
                }}
                selected={userHasThisSkill}
                disabled={!hasContent}
                key={skill?.skillName}
                label={
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                      style={{
                        fontFamily: 'Lato-Bold',
                        fontSize: 14,
                        letterSpacing: 1,
                        textTransform: 'uppercase',
                        color: userHasThisSkill ? 'white' : KEY_GRAY,
                      }}
                    >
                      {skill?.skillName ?? ''}
                    </Text>
                    {hasContent ? (
                      <Entypo
                        name="text"
                        size={18}
                        style={{
                          marginLeft: 4,
                        }}
                        color={userHasThisSkill ? 'white' : KEY_GRAY}
                      />
                    ) : null}
                  </View>
                }
              />
            );
          })}
        </View>
      )}

      {props.isEditing ? (
        <Button
          label="Edit your skills"
          containerStyle={{
            alignSelf: 'center',
          }}
          onPress={onEditSkills}
        />
      ) : null}

      {userData?.id === props.data?.id ? (
        <View style={styles.solicitationContainer}>
          <Text style={styles.solicitationPrompt}>
            Conservation organizations and researchers can contact me about my
            skills and expertise
          </Text>
          <Switch
            trackColor={{ true: KEY_GREEN }}
            value={allowSkillSolicitation}
            onValueChange={(value) => {
              onSetAllowSkillSolicitation(value);
            }}
          />
        </View>
      ) : null}

      {props.isEditing ? (
        <EditSkillsModal
          visible={showEditSkillsModal}
          onClose={() => {
            setShowEditSkillsModal(false);
          }}
          data={props.data?.skills ?? []}
          onChange={(data) => {
            props.setData({
              skills: data,
            });
          }}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  emptyText: {
    fontFamily: 'Lato-Bold',
    textAlign: 'center',
    alignSelf: 'center',
    padding: 24,
    color: 'gray',
    fontSize: 15,
  },
  skillsContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingVertical: 8,
  },
  solicitationContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
  },
  solicitationPrompt: {
    flex: 1,
    fontFamily: 'Lato',
    color: 'gray',
    paddingRight: 8,
  },
  skillItemContainer: {
    alignSelf: 'center',
    alignItems: 'center',
  },
});
