'use dom';

import React from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import {
  ErrorBoundary,
  baseStyles,
  getBaseLexicalConfig,
  textFormatStyles,
} from './utils';

interface RichTextCoreProps {
  content: string;
  dom?: import('expo/dom').DOMProps;
}

const styles = {
  container: {
    overflow: 'hidden',
    height: 'auto',
    width: '100%',
  },
  contentEditable: {
    ...baseStyles.contentEditable,
  },
};

// This is the DOM component that will be rendered in the WebView
export default function RichTextCore({ content }: RichTextCoreProps) {
  const initialConfig = getBaseLexicalConfig(
    'MyRichTextDisplay',
    content,
    false,
  );

  return (
    <>
      <style>{textFormatStyles}</style>
      <LexicalComposer key={content} initialConfig={initialConfig}>
        <div style={styles.container}>
          <RichTextPlugin
            contentEditable={
              <div style={{ display: 'block', overflow: 'hidden' }}>
                <ContentEditable style={styles.contentEditable} />
              </div>
            }
            placeholder={null}
            ErrorBoundary={ErrorBoundary}
          />
          <ListPlugin />
        </div>
      </LexicalComposer>
    </>
  );
}
