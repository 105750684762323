import { Feather } from '@expo/vector-icons';
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Pressable, View, ViewToken } from 'react-native';
import Animated, {
  useAnimatedRef,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import Hoverable from '../Hoverable';
import { KEY_GRAY } from '/constants';
import useBinaryTimingAnimation from '/hooks/useBinaryTimingAnimation';

const VIEWABILITY_CONFIG = {
  viewAreaCoveragePercentThreshold: 50,
};

const MediaFlatList: <ItemT extends any>(
  props: ComponentProps<typeof Animated.FlatList<ItemT>> & {
    snapToInterval: number;
    renderItem: ({
      item,
      index,
      isViewable,
    }: {
      item: ItemT;
      index: number;
      isViewable: boolean;
    }) => JSX.Element;
    onChangeCurrentIndex?: (index: number) => void;
  },
) => JSX.Element = ({
  data,
  renderItem,
  onChangeCurrentIndex,
  snapToInterval,
  ...props
}) => {
  const dataLength = Array.isArray(data) && data?.length ? data.length : 0;

  const scrollX = useSharedValue(0);
  const flatListRef = useAnimatedRef<Animated.FlatList<any>>();

  /** This is a workaround to access updated snapToInterval from
   * timeout closure */
  const snapToIntervalRef = React.useRef(snapToInterval);
  useEffect(() => {
    snapToIntervalRef.current = snapToInterval;
  }, [snapToInterval]);

  const [index, setIndex] = React.useState(0);

  const onChangeIndex = React.useCallback(
    (
      newIndex: number,
      disableNotifyParent?: boolean,
      disableAnimate?: boolean,
    ) => {
      if (newIndex < 0 || newIndex >= dataLength) return;

      // @ts-ignore
      flatListRef.current?.scrollToIndex({
        index: newIndex,
        animated: !disableAnimate,
      });
      setIndex(newIndex);
      if (!disableNotifyParent) onChangeCurrentIndex?.(newIndex);
    },
    [dataLength, flatListRef, onChangeCurrentIndex],
  );

  useEffect(() => {
    if (dataLength === 0) {
      setIndex(0);
    }
  }, [dataLength]);

  useEffect(() => {
    typeof props.initialScrollIndex === 'number' &&
      onChangeIndex(props.initialScrollIndex, true, true);
  }, [props.initialScrollIndex, onChangeIndex]);

  const [hoveringPrevious, setHoveringPrevious] = React.useState(false);
  const [hoveringNext, setHoveringNext] = React.useState(false);

  const [viewableItemIndices, setViewableItemIndices] = useState<number[]>([]);

  const hasNext = useMemo(() => index < dataLength - 1, [index, dataLength]);
  const hasPrevious = useMemo(() => index > 0, [index]);

  const onViewableItemsChanged = useCallback(
    (info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
      setViewableItemIndices(
        info.viewableItems.map((item) => item.index as number) || [],
      );
    },
    [],
  );

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: VIEWABILITY_CONFIG,
      onViewableItemsChanged,
    },
  ]);

  const snapToPrevious = React.useCallback(() => {
    const newIndex = Math.round(
      (scrollX.value - snapToInterval) / snapToInterval,
    );

    if (newIndex >= 0) {
      onChangeIndex(newIndex);
    }
  }, [scrollX.value, onChangeIndex, snapToInterval]);

  const snapToNext = React.useCallback(() => {
    const newIndex = Math.round(
      (scrollX.value + snapToInterval) / snapToInterval,
    );

    if (newIndex < dataLength) {
      onChangeIndex(newIndex);
    }
  }, [dataLength, scrollX.value, onChangeIndex, snapToInterval]);

  const snapTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const scrollHandler = useAnimatedScrollHandler(
    {
      onScroll: (event) => {
        if (snapTimeout.current) {
          clearTimeout(snapTimeout.current);
        }

        scrollX.value = event.contentOffset.x;

        snapTimeout.current = setTimeout(() => {
          const newIndex = Math.round(
            scrollX.value / snapToIntervalRef.current,
          );

          onChangeIndex(newIndex);
        }, 120);
      },
    },
    [snapToIntervalRef, onChangeIndex],
  );

  const prevButtonOpacity = useBinaryTimingAnimation({
    value: hoveringPrevious,
    disabledValue: hasPrevious ? 0.6 : 0,
    enabledValue: hasPrevious ? 1 : 0,
  });

  const PrevButtonAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: prevButtonOpacity.value,
      zIndex: 1,
      // elevation: 2,
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [prevButtonOpacity],
  );

  const nextButtonOpacity = useBinaryTimingAnimation({
    value: hoveringNext,
    disabledValue: hasNext ? 0.6 : 0,
    enabledValue: hasNext ? 1 : 0,
  });

  const NextButtonAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: nextButtonOpacity.value,
      zIndex: 1,
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [nextButtonOpacity],
  );

  return (
    <>
      <Animated.FlatList
        // @ts-ignore - works on web
        ref={flatListRef}
        data={data}
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        horizontal
        renderItem={({ item, index: idx }) =>
          renderItem({
            item,
            index,
            isViewable: viewableItemIndices.includes(idx),
          })
        }
        viewabilityConfig={VIEWABILITY_CONFIG}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        showsHorizontalScrollIndicator={false}
        bounces={false}
        decelerationRate="fast"
        {...props}
      />
      <View
        pointerEvents="box-none"
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Animated.View
          style={[PrevButtonAnimatedStyle]}
          pointerEvents={hasPrevious ? 'box-none' : 'none'}
        >
          <Hoverable
            onHoverIn={() => {
              setHoveringPrevious(true);
            }}
            onHoverOut={() => {
              setHoveringPrevious(false);
            }}
          >
            <Pressable
              onPress={() => {
                snapToPrevious();
              }}
              style={{
                padding: 12,
                alignSelf: 'center',
              }}
            >
              <View
                style={{
                  borderRadius: 50,
                  width: 30,
                  height: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255,255,255,0.5)',
                }}
              >
                <Feather name="arrow-left-circle" size={28} color={KEY_GRAY} />
              </View>
            </Pressable>
          </Hoverable>
        </Animated.View>

        <Animated.View
          style={NextButtonAnimatedStyle}
          pointerEvents={hasNext ? 'box-none' : 'none'}
        >
          <Hoverable
            onHoverIn={() => {
              setHoveringNext(true);
            }}
            onHoverOut={() => {
              setHoveringNext(false);
            }}
          >
            <Pressable
              onPress={() => {
                snapToNext();
              }}
              style={{
                padding: 12,
              }}
            >
              <View
                style={{
                  borderRadius: 50,
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  width: 30,
                  height: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Feather name="arrow-right-circle" size={28} color={KEY_GRAY} />
              </View>
            </Pressable>
          </Hoverable>
        </Animated.View>
      </View>
    </>
  );
};

export default MediaFlatList;
