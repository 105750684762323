import React from 'react';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import { MediaItemState } from '../UploadMedia';

export type CustomUploadComponentProps = {
  onEdit: () => void;
  style: StyleProp<ViewStyle>;
  uploading: boolean;
  disabled: boolean;
  media: MediaItemState[];
  retryUploads: () => void;
  pressed: boolean;
};

type Props = Omit<CustomUploadComponentProps, 'pressed'> & {
  renderComponent: (props: CustomUploadComponentProps) => React.ReactNode;
};

export default function CustomUploadComponent(props: Props) {
  return (
    <Pressable
      onPress={props.onEdit}
      style={[
        props.style,
        {
          opacity: props.uploading ? 0.5 : 1,
          pointerEvents: props.disabled || props.uploading ? 'none' : 'auto',
        },
      ]}
    >
      {({ pressed }) =>
        props.renderComponent?.(Object.assign({}, props, { pressed }))
      }
    </Pressable>
  );
}
