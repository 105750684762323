import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import {
  GetNotificationsForUserDocument,
  GetMyTeamMembershipsDocument,
} from '/generated/graphql';

const markAllNotificationsRead: UpdateResolver = (result, args, cache) => {
  cache.updateQuery(
    {
      query: GetNotificationsForUserDocument,
      variables: {
        userId: args.userId,
      },
    },
    (data) => {
      return {
        __typename: 'Query',
        ...data,
        getNotificationsForUser: {
          __typename: 'GetNotificationsForUserResponse',
          ...(data as any)?.getNotificationsForUser,
          has_unreads: false,
        },
      };
    },
  );

  // Update unread notifications in all GetMyTeamMemberships queries in cache
  const root = cache.inspectFields('Query');
  const teamMembershipFields = root.filter(
    (field) => field.fieldName === 'getMyTeamMemberships',
  );

  for (const field of teamMembershipFields) {
    cache.updateQuery(
      {
        query: GetMyTeamMembershipsDocument,
        variables: field.arguments,
      },
      (data: any) => {
        if (!data?.getMyTeamMemberships?.items) return data;

        return {
          ...data,
          getMyTeamMemberships: {
            ...data.getMyTeamMemberships,
            items: data.getMyTeamMemberships.items.map((item: any) => ({
              ...item,
              team: {
                ...item.team,
                user: {
                  ...item.team.user,
                  unread_notifications:
                    item.team.user.id === args.userId
                      ? 0
                      : item.team.user.unread_notifications,
                },
              },
            })),
          },
        };
      },
    );
  }

  const query = gql`
    query MeQuery {
      me {
        id
        unread_notifications
      }
    }
  `;

  const data = cache.readQuery({ query }) as any;

  if (!data?.me) return;

  cache.invalidate(
    {
      __typename: 'User',
      id: data.me.id,
    },
    'unread_notifications',
  );
};

export default markAllNotificationsRead;
