import { useLinkTo } from '@react-navigation/native';
import { ResizeMode, Video } from 'expo-av';
import { BlurView } from 'expo-blur';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  Animated,
  Easing,
  Image,
  LayoutChangeEvent,
  Linking,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
  minSize,
} from 'rn-responsive-styles';
import awsconfig from '/aws-exports';
import AuthForm, { AuthFormMode } from '/components/Auth/AuthForm/AuthForm';
import Button from '/components/Button';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import LoadingSpinnerOverlay from '/components/LoadingSpinnerOverlay';
import VideoPlayer from '/components/VideoPlayer';
import ViewportTracker from '/components/ViewportAware/ViewportTracker';
import FAQ from '/components/FAQ/FAQ';
import {
  KEY_DARK_GREEN,
  KEY_GRAY,
  KEY_GREEN,
  KEY_LIGHT_GRAY,
} from '/constants';
import { useAuthContext } from '/context';
import getCDNImageUri from '/util/getCDNImageUri';
import getCDNVideoUri from '/util/getCDNVideoUri';

/** Instantly determine if autoplay is blocked using a fake video element */
let isAutoplayBlocked = false;

if (Platform.OS === 'web') {
  const checkAutoplayBlocked = () => {
    setTimeout(() => {
      const video = document.createElement('video');
      video.src = getTesterOnlyModeHeroVideoUri(
        'ExtremeCloseBlueButterfly',
        '360p',
      );
      video.muted = true;
      video
        .play()
        .catch((error) => {
          console.log('error', error);
          isAutoplayBlocked = true;
        })
        .finally(() => {
          video.remove();
        });
    }, 0);
  };

  checkAutoplayBlocked();
}

export default function TesterOnlyModeLandingScreen() {
  const { styles, deviceSize } = useStyles();
  const linkTo = useLinkTo();

  const [suppressButterfulyVideo, setSuppressButterfulyVideo] = useState(false);
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [hideParagraphAboveAuthForm, setHideParagraphAboveAuthForm] =
    useState(false);
  const fadeAnim = React.useRef(new Animated.Value(0)).current;
  const buttonFadeAnim = React.useRef(new Animated.Value(1)).current;

  const { user, isAuthenticating, fetching } = useAuthContext();

  const scrollYRef = useRef<number>(0);
  const scrollY = useRef(new Animated.Value(0)).current;

  const birdsSectionRef = React.useRef<View>(null);
  const [birdsY, setBirdsY] = useState(0);

  const scrollViewRef = useRef<any>(null);

  const scrollToSection = useCallback((sectionRef: React.RefObject<View>) => {
    sectionRef.current?.measureInWindow((x, y) => {
      const yOffset = Platform.OS === 'web' ? scrollYRef.current : 0;
      const scrollPosition = y + yOffset;

      scrollViewRef.current?.scrollTo({ y: scrollPosition, animated: true });
    });
  }, []);

  const handleBirdsSectionLayout = useCallback(() => {
    const yOffset = Platform.OS === 'web' ? scrollYRef.current : 0;
    birdsSectionRef.current?.measureInWindow((x, y) => setBirdsY(y + yOffset));
  }, []);

  const handleJoinUsPress = useCallback(() => {
    if (user) {
      linkTo('/tester-signup');
      return;
    }

    Animated.parallel([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 400,
        useNativeDriver: true,
        easing: Easing.bezier(0.4, 0, 0.2, 1),
      }),
      Animated.timing(buttonFadeAnim, {
        toValue: 0,
        duration: 400,
        useNativeDriver: true,
        easing: Easing.bezier(0.4, 0, 0.2, 1),
      }),
    ]).start();
    setShowAuthForm(true);
  }, [fadeAnim, buttonFadeAnim, user, linkTo]);

  const onAuthFormModeChange = useCallback((mode: AuthFormMode) => {
    if (mode === AuthFormMode.confirm_email) {
      setHideParagraphAboveAuthForm(true);
    } else {
      setHideParagraphAboveAuthForm(false);
    }
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // Check if the URL has #join-us hash
      if (window.location.hash === '#join-us') {
        // Remove the hash to prevent future auto-scrolls
        window.history.replaceState(null, '', window.location.pathname);
        // Scroll to the join us section
        scrollToSection(birdsSectionRef);
      }
    }
  }, [scrollToSection]);

  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        {
          backgroundColor: KEY_LIGHT_GRAY,
        },
      ]}
    >
      <ViewportTracker.AnimatedScrollView
        ref={scrollViewRef}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          {
            useNativeDriver: true,
            listener(event: NativeSyntheticEvent<NativeScrollEvent>) {
              scrollYRef.current = event.nativeEvent?.contentOffset.y;
            },
          },
        )}
        scrollEventThrottle={16}
      >
        <FixedHeightSection
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'black',
          }}
        >
          <View style={[StyleSheet.absoluteFill, { opacity: 0.8 }]}>
            <Animated.View
              style={[
                StyleSheet.absoluteFill,
                {
                  transform: [
                    {
                      translateY: scrollY.interpolate({
                        inputRange: [0, 100],
                        outputRange: [0, 40],
                        extrapolate: 'extend',
                      }),
                    },
                  ],
                },
              ]}
            >
              <Image
                source={require('/assets/images/ExtremeCloseButterflyThumb.webp')}
                style={[StyleSheet.absoluteFill, { zIndex: -2 }]}
              />
              {suppressButterfulyVideo || isAutoplayBlocked ? null : (
                <Video
                  isLooping={false}
                  shouldPlay
                  isMuted
                  onPlaybackStatusUpdate={(status) => {
                    if (status.isLoaded && status.didJustFinish) {
                      setSuppressButterfulyVideo(true);
                    }
                  }}
                  source={{
                    uri: getTesterOnlyModeHeroVideoUri(
                      'ExtremeCloseBlueButterfly',
                      '720p',
                    ),
                  }}
                  videoStyle={{
                    width: '100%',
                    height: '100%',
                  }}
                  resizeMode={ResizeMode.COVER}
                  style={[
                    StyleSheet.absoluteFill,
                    {
                      pointerEvents: 'none',
                      zIndex: -1,
                      backgroundColor: 'black',
                    },
                  ]}
                />
              )}
            </Animated.View>
          </View>

          <View
            style={{
              flex: 1,
              maxWidth: '100%',
              alignSelf: 'center',
              paddingHorizontal: 46,
              paddingRight: 40,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              source={require('/assets/images/keyFullWhite.png')}
              style={{
                width:
                  deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? 256 : 288,
                height:
                  deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? 256 : 288,
                margin: -36,
                marginTop: -24,
                marginBottom:
                  deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE ? 8 : -8,
              }}
            />

            <View>
              <HorizontalContainer
                style={{
                  flexWrap: 'wrap',
                }}
              >
                <Text style={styles('heroText')}>OUR </Text>
                <Text
                  style={[
                    styles('heroText'),
                    {
                      color: KEY_GREEN,
                    },
                    deviceSize === DEVICE_SIZES.EXTRA_SMALL_DEVICE && {
                      fontSize: 48,
                      lineHeight: 48,
                    },
                  ]}
                >
                  NEXT PHASE
                </Text>
              </HorizontalContainer>
              <Text
                style={[
                  styles('heroText'),
                  deviceSize !== DEVICE_SIZES.EXTRA_SMALL_DEVICE && {
                    fontSize: 74,
                  },
                ]}
              >
                IS TAKING FLIGHT
              </Text>
            </View>

            <Text style={styles('heroSubtext')}>
              Stronger Tools. Bigger Impact. Bolder Action.
            </Text>

            <View style={styles('launchingSpring2025Pill')}>
              <Text style={styles('launchingSpring2025Text')}>
                Launching Spring 2025
              </Text>
            </View>
          </View>
        </FixedHeightSection>

        <FixedHeightSection style={styles('backedBy1MSection')}>
          <HorizontalContainer
            style={styles('horizontalContainerIfLargerThanMediumReverse')}
          >
            <View style={styles('horizontalHalfContainer')}>
              <VideoPlayer
                style={[
                  styles('heartLagoonFlyover'),
                  {
                    borderRadius: 500,
                    overflow: 'hidden',
                    alignSelf: 'center',
                  },
                ]}
                mode="default"
                multiplay
                hideBufferIndicator
                sourceUri={getTesterOnlyModeHeroVideoUri(
                  'HeartLagoonFlyover',
                  '480p',
                )}
                resizeMode={ResizeMode.COVER}
                thumbnailSource={{
                  uri: getTesterOnlyModeHeroVideoThumbnailUri(
                    'HeartLagoonFlyover',
                  ),
                }}
                hideControls
                isLooping
                shouldPlay
                isMuted
              />
            </View>

            <View
              style={[
                styles('horizontalHalfContainer'),
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingHorizontal: 40,
                },
              ]}
            >
              <Text
                style={[
                  styles('sectionTitle'),
                  {
                    lineHeight: 56 * 0.95,
                    marginBottom: 16,
                  },
                ]}
              >
                Backed by{' '}
                <Text
                  style={{
                    color: KEY_DARK_GREEN,
                  }}
                >
                  $1M in Funding
                </Text>{' '}
                from the National Science Foundation
              </Text>

              <Text style={styles('sectionText')}>
                We're embarking on a transformative journey to create a platform
                that empowers you to achieve even greater conservation impact.
                This exciting new chapter is all about growth, innovation, and
                providing the tools you need to make a difference like never
                before.
              </Text>

              <HorizontalContainer
                style={{
                  marginRight: 64,
                  alignSelf: 'flex-start',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignItems: 'flex-end',
                }}
              >
                <Image
                  source={require('/assets/images/nsf-logo.png')}
                  resizeMode="contain"
                  style={styles('nsfLogo')}
                />
                <Image
                  source={require('/assets/images/keyFullBlack.png')}
                  resizeMode="contain"
                  style={styles('keyLogo')}
                />
                <View>
                  <Image
                    source={require('/assets/images/godwit-logo.png')}
                    resizeMode="contain"
                    style={styles('godwitLogo')}
                  />
                  <Image
                    source={require('/assets/images/coming-soon-arrow.png')}
                    resizeMode="contain"
                    style={styles('comingSoonArrow')}
                  />
                </View>
              </HorizontalContainer>
            </View>
          </HorizontalContainer>
        </FixedHeightSection>

        <FixedHeightSection
          ref={birdsSectionRef}
          onLayout={handleBirdsSectionLayout}
        >
          <View style={StyleSheet.absoluteFill}>
            <Animated.View
              style={[
                StyleSheet.absoluteFill,
                {
                  transform: [
                    {
                      translateY: scrollY.interpolate({
                        inputRange: [birdsY, birdsY + 100],
                        outputRange: [0, 40],
                        extrapolate: 'extend',
                      }),
                    },
                  ],
                },
              ]}
            >
              <VideoPlayer
                hideControls
                isLooping
                shouldPlay
                hideBufferIndicator
                isMuted
                multiplay
                mode="autoplay-viewport"
                thumbnailResizeMode={ResizeMode.COVER}
                thumbnailSource={{
                  uri: getTesterOnlyModeHeroVideoThumbnailUri('BirdsLanding'),
                }}
                sourceUri={getTesterOnlyModeHeroVideoUri(
                  'BirdsLanding',
                  '720p',
                )}
                resizeMode={ResizeMode.COVER}
                style={{
                  zIndex: -1,
                  width: '100%',
                  flex: 1,
                }}
              />
            </Animated.View>
          </View>

          <View style={styles('getInvolvedSectionContainer')}>
            <Animated.View
              style={{
                opacity: buttonFadeAnim,
                transform: [
                  {
                    translateX: buttonFadeAnim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [40, 0],
                    }),
                  },
                ],
              }}
            >
              <BlurView
                tint="systemThinMaterialDark"
                style={styles('darkGlassContainer')}
              >
                <View style={styles('darkGlassInnerContainer')}>
                  <Text
                    style={[
                      styles('sectionTitle'),
                      styles('fontSize48'),
                      {
                        color: 'white',
                      },
                    ]}
                  >
                    Get Involved
                  </Text>

                  <Text
                    style={[
                      styles('sectionText'),
                      {
                        color: 'white',
                      },
                    ]}
                  >
                    While we undergo a major overhaul and prepare to launch a
                    revolutionary way for conservation organizations to get
                    support like never before, we're inviting you to be part of
                    the transformation.{`\n\n`}
                    <Text>
                      Just select Join Us below to get started.{' '}
                      <Text style={{ color: KEY_GREEN }}>
                        Already a member?
                      </Text>{' '}
                      Great, you will be able to log-in when you select Join Us
                      below.
                    </Text>
                  </Text>

                  <Button
                    label="JOIN US"
                    onPress={handleJoinUsPress}
                    containerStyle={{
                      marginTop: 32,
                    }}
                    style={{
                      backgroundColor: '#10d198',
                      paddingVertical: 12,
                    }}
                    labelStyle={{
                      fontSize: 19,
                    }}
                  />
                </View>
              </BlurView>
            </Animated.View>

            <Animated.View
              style={[
                {
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: '50%',
                  transform: [
                    {
                      translateY: '-50%',
                    },
                    {
                      translateX: fadeAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-40, 0],
                      }),
                    },
                  ],
                  opacity: fadeAnim,
                  pointerEvents: showAuthForm ? 'auto' : 'none',
                },
              ]}
            >
              <BlurView
                tint="systemThinMaterialDark"
                style={styles('darkGlassContainer')}
              >
                <View style={styles('darkGlassInnerContainer')}>
                  <Text
                    style={[
                      styles('sectionTitle'),
                      styles('fontSize48'),
                      { color: 'white' },
                    ]}
                  >
                    Sign In / Sign Up
                  </Text>

                  {hideParagraphAboveAuthForm ? null : (
                    <Text
                      style={[
                        styles('sectionText'),
                        { color: 'white', marginBottom: 20 },
                      ]}
                    >
                      To learn more about the different ways you can get
                      involved, stay up to date on our re-launch, and get
                      involved, sign in using one of the options below.
                    </Text>
                  )}

                  <AuthForm onModeChange={onAuthFormModeChange} />
                </View>

                {isAuthenticating || fetching ? (
                  <LoadingSpinnerOverlay />
                ) : null}
              </BlurView>
            </Animated.View>
          </View>
        </FixedHeightSection>

        <FixedHeightSection>
          <FAQ
            onLinkPress={(url) => {
              if (url === '#join-us') {
                scrollToSection(birdsSectionRef);
                return false;
              }
              Linking.openURL(url);
              return false;
            }}
          />
        </FixedHeightSection>
      </ViewportTracker.AnimatedScrollView>
    </View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    fixedHeightSection: {
      width: '100%',
      overflow: 'hidden',
    },
    launchingSpring2025Text: {
      fontSize: 28,
      padding: 1,
      color: 'black',
      letterSpacing: 2,
      paddingHorizontal: 70,
      fontFamily: 'Lato-BoldItalic',
    },
    darkGlassContainer: {
      borderRadius: 6,
      maxWidth: 480,
      alignSelf: 'center',
      overflow: 'hidden',
      borderWidth: 1,
      borderColor: 'rgba(61,61,64,0.5)',
    },
    darkGlassInnerContainer: {
      flex: 1,
      padding: 32,
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    heroText: {
      fontSize: 72,
      color: 'white',
      letterSpacing: -2,
      lineHeight: 0.85 * 72,
      fontFamily: 'LeagueSpartan-Bold',
    },
    sectionTitle: {
      fontSize: 56,
      color: 'black',
      fontFamily: 'FranklinGothic-Demi',
    },
    fontSize48: {
      fontSize: 48,
    },
    sectionText: {
      flexShrink: 1,
      fontSize: 20,
      color: 'black',
      fontFamily: 'Lato',
    },
    heroSubtext: {
      fontSize: 32,
      marginTop: 6,
      color: 'white',
      fontFamily: 'Lato-Bold',
    },
    heartLagoonFlyover: {
      width: 540,
      height: 540,
    },
    horizontalHalfContainer: { padding: 32 },
    horizontalContainerIfLargerThanMedium: {
      flex: 1,
    },
    horizontalContainerIfLargerThanMediumReverse: {
      flex: 1,
      flexDirection: 'row-reverse',
    },
    backedBy1MSection: {
      paddingHorizontal: 40,
    },
    launchingSpring2025Pill: {
      padding: 10,
      marginTop: 74,
      backgroundColor: KEY_GREEN,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
    },
    nsfLogo: {
      marginHorizontal: 12,
      width: 120,
      height: 120,
      marginTop: 32,
      marginBottom: 48,
    },
    keyLogo: {
      marginHorizontal: 12,
      marginTop: 32,
      width: 100,
      height: 100,
      marginBottom: 58,
    },
    godwitLogo: {
      marginHorizontal: -24,
      width: 172,
      height: 172,
    },
    comingSoonArrow: {
      position: 'absolute',
      right: -120,
      top: 10,
      width: 120,
      height: 60,
    },
    getInvolvedSectionContainer: {
      margin: 12,
      flex: 1,
      justifyContent: 'center',
      position: 'relative',
    },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      horizontalHalfContainer: {
        flex: 1,
      },
      getInvolvedSectionContainer: {
        width: '50%',
        alignSelf: 'flex-start',
        marginLeft: '5%',
      },
    },

    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      comingSoonArrow: {
        right: -95,
        width: 90,
        height: 45,
      },
      godwitLogo: {
        width: 129,
        height: 129,
      },
      keyLogo: {
        width: 75,
        height: 75,
        marginBottom: 43.5,
      },
      nsfLogo: {
        marginHorizontal: 6,
        width: 90,
        height: 90,
        marginTop: 32,
        marginBottom: 36,
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      sectionTitle: {
        fontSize: 48,
      },
      heroText: {
        fontSize: 50,
        lineHeight: 50,
      },
      heroSubtext: {
        fontSize: 20,
        textTransform: 'uppercase',
      },
      launchingSpring2025Text: {
        fontSize: 25,
        color: KEY_GRAY,
        paddingHorizontal: 0,
        marginHorizontal: -2,
        letterSpacing: 0,
      },
      launchingSpring2025Pill: {
        width: '100%',
      },
      comingSoonArrow: {
        right: -95,
        width: 81,
        height: 40.5,
      },
      godwitLogo: {
        width: 96,
        height: 96,
      },
      keyLogo: {
        width: 56,
        height: 56,
        marginBottom: 32,
      },
      nsfLogo: {
        marginHorizontal: 0,
        width: 67,
        height: 67,
        marginTop: 24,
        marginBottom: 27,
      },
    },
    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      backedBy1MSection: {
        paddingHorizontal: 0,
      },
      heartLagoonFlyover: {
        width: 480,
        height: 480,
      },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      horizontalContainerIfLargerThanMedium: {
        flexDirection: 'column',
      },
      horizontalContainerIfLargerThanMediumReverse: {
        flexDirection: 'column',
      },
      heartLagoonFlyover: {
        width: 300,
        height: 300,
      },
      horizontalHalfContainer: {
        flexShrink: 1,
        padding: 16,
      },
    },
  },
);

const FixedHeightSection = React.forwardRef<
  View,
  React.PropsWithChildren<{
    style?: StyleProp<ViewStyle>;
    onLayout?: (event: LayoutChangeEvent) => void;
  }>
>((props, ref) => {
  const windowDimensions = useWindowDimensions();
  const { styles } = useStyles();

  return (
    <View
      ref={ref}
      onLayout={props.onLayout}
      style={[
        styles('fixedHeightSection'),
        props.style,
        {
          minHeight: Math.max(720, windowDimensions.height),
        },
      ]}
    >
      {props.children}
    </View>
  );
});

type TesterOnlyModeHeroVideo =
  | 'AntarcticPenguinJump'
  | 'BirdsLanding'
  | 'ExtremeCloseBlueButterfly'
  | 'HeartLagoonFlyover'
  | 'PenguinGroupSwim';

export function getTesterOnlyModeHeroVideoUri(
  video: TesterOnlyModeHeroVideo,
  preset: Parameters<typeof getCDNVideoUri>[0]['preset'] = '1080p',
) {
  return getCDNVideoUri({
    preset,
    uri: `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/_allUsers/mp4/${video}.mp4`,
  });
}

export function getTesterOnlyModeHeroVideoThumbnailUri(
  video: TesterOnlyModeHeroVideo,
) {
  return getCDNImageUri({
    uri: `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/_allUsers/mp4/${video}.mp4`,
    isThumbnail: true,
  });
}
